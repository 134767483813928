import {
  AddIcon,
  Spacer,
  useToast,
} from '@colombalink/basedui'
import DefaultNavigation from '@shared/components/page/navigation/DefaultNavigation'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'
import React, { useEffect } from 'react'
import { DynamicButtonRow } from '@shared/components/core/DynamicButtonRow'
import { RowMenu } from './RowMenu'
import { Page } from '@shared/components/page/Page'
import { Title } from '@shared/components/core/Title'
import { api } from '@shared/index'
import { useLocation } from 'wouter'
import { useTable } from '@shared/components/core/table/Table'
import AddApiTokenDialog from './AddDialog'
import { Role, getRoleLabel } from '@server/shared'
import { useRouteParams } from '@shared/router'
import { WithI18n } from '@shared/i18n/withI18n'
import i18n from './i18n'
import { useT } from '@app/i18n'

type PageProps = { params: { orgAliasId: string } }
export type PageParams = PageProps['params']

const SettingsApiTokensPage = ({ params }: { params: { orgAliasId: string } }) => {
  return (
    <Page>
      <DefaultNavigation />
      <Main orgAliasId={params.orgAliasId} />
    </Page>
  )
}

const Main = ({ orgAliasId }: { orgAliasId: string }) => {
  return (
    <WithI18n i18n={i18n}>
      <ManageApiTokensMembers orgAliasId={orgAliasId} /> 
    </WithI18n>
  )
}

Main.displayName = 'Main'

export default SettingsApiTokensPage

const ManageApiTokensMembers = ({ orgAliasId }: { orgAliasId: string }) => {
  const t = useT()

  return (
    <div className="flex flex-col">
      <DynamicButtonRow buttons={[
        {
          icon: <AddIcon />,
          dialogComponent: <AddApiTokenDialog />,
          label: t["SettingsApiTokens:Create"].get(),
          style: { cursor: 'pointer' }
        }
      ]
      } />
      <ApiTokenTable />
    </div>
  )
}

function ApiTokenTable() {
  const t = useT()
  const org = api.organization.apiTokens.getAll.useLiveData();
  const removeToken = api.settings.tokens.delete.useMutation()
  const [, setLocation] = useLocation()
  const removeOrganization = api.organization.deleteOrganization.useMutation()
  const toast = useToast()
  const [tableData, setTableData] = React.useState([])


  const [table, TableComponent] = useTable({
    header: [
      { label: t["SettingsApiTokens:Name"].get(), key: 'name' },
      { label: t["SettingsApiTokens:Actions"].get(), key: 'action' },
    ],
    data: tableData,
  })


  useEffect(() => {
    if (org.data?.apiTokens) {
      //console.log(org.data?.apiTokens)
      setTableData(
        org.data.apiTokens.map((token) => {
          return {
            ...token,
            action: (<RowMenu apiToken={token} />),
            customAttribute: {
            },
          }
        })
      )
    }
  }, [org.data?.apiTokens])

  if (org.error) {
    throw org.error
  }
  if (org.loading) {
    return <>{t["SettingsApiTokens:Loading"].get()} <LoadingIcon /></>
  }
  return (
    <>
      {TableComponent}
    </>
  )
}



function OrgOwnersTable() {
  const t = useT()
  const { orgAliasId } = useRouteParams<PageParams>()
  const org = api.organization.getOrganizationById.withMembers.useLiveData({ orgAliasId });

  const [, setLocation] = useLocation()
  const removeOrganization = api.organization.deleteOrganization.useMutation()
  const toast = useToast()
  const [tableData, setTableData] = React.useState([])


  const [table, TableComponent] = useTable({
    header: [
      { label: 'Name', key: 'user.name' },
    ],
    data: tableData,
  })


  useEffect(() => {
    if (org.data?.members) {
      setTableData(
        org.data.members
          .filter((member) => member.role === 'OWNER')
          .map((member) => {
            return {
              ...member,
              roleLabel: getRoleLabel(member.role as Role, 'en'),
              action: (<RowMenu member={member} />),
              customAttribute: {
              },
            }
          })
      )
    }
  }, [org.data?.members])

  if (org.loading) {
    return <>{t["SettingsApiTokens:Loading"].get()}  <LoadingIcon /></>
  }
  return (
    <>
      {TableComponent}
    </>
  )
}


