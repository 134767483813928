
const AuditLogPageContentI18n = {
    'AuditLog:Time': {
        'de': 'Zeitpunkt',
        'en': 'Time',
    },
    'AuditLog:UserName': {
        'de': 'Benutzer Name',
        'en': 'User name',
    },
    'AuditLog:UserEmail': {
        'de': 'Benutzer E-mail',
        'en': 'User email',
    },
    'AuditLog:Actor': {
        'de': 'Akteur ',
        'en': 'Actor',
    },
    'AuditLog:Action': {
        'de': 'Aktion',
        'en': 'Action',
    },
    'AuditLog:Details': {
        'de': 'Details',
        'en': 'Details',
    },
    'AuditLog:NoAuditLog': {
        'de': 'Keine Audit Logs',
        'en': 'No audit logs',
    },
    'AuditLog:Loading': {
        'de': 'Laden',
        'en': 'Loading',
    },
    'AuditLog:FailedToLoad': {
        'de': 'Audit Logs konnten nicht geladen werden',
        'en': 'Failed to load audit logs',
    },
    'AuditLog:ActorEmail': {
        'de': 'Akteur Email',
        'en': 'Actor Email',
    },
    'AuditLog:LogMessage': {
        'de': 'Log Nachricht',
        'en': 'Log Message',
    },
    'AuditLog:NoEntries': {
        'de': 'Keine Einträge für diesen Zeitraum',
        'en': 'No entries for this time period',
    },
    'AuditLog:DefaultTimePeriod': {
        'en': 'The default retention period for audit logs is 30 day.',
        'de': 'Der Standard Aufbewahrungszeitraum für Audit Logs beträgt 30 Tage.',
    },
    'AuditLog:OlderThan': {
        'en': 'In case you need to access older logs, please contact support.',
        'de': 'Falls Sie ältere Logs benötigen, kontaktieren Sie bitte den Support.',
    },
    'AuditLog:ErrorJson': {
        'en': 'Error parsing JSON: Invalid JSON format',
        'de': 'Fehler beim Parsen von JSON: Ungültiges JSON-Format',
    },
    'AuditLog:ErrorUnexpected': {
        'en': 'Unexpected error',
        'de': 'Unerwarteter Fehler',
    },
}


export default AuditLogPageContentI18n
export type AuditLogPageContentI18nType = typeof AuditLogPageContentI18n
