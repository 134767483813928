import {
  Checkbox,
  ColorPicker,
  Dialog,
  Input,
  MultiSelect,
  Spacer,
  Text,
  Toast,
  useDialog,
  useToast,
} from '@colombalink/basedui'
import React, { useEffect, useState } from 'react'
import { useProjectDashboardContext } from '@app/pages/Orgs/Project/DashboardContext'
import { api } from '@shared/index'
import { Title } from '@shared/components/core/Title'
import PropertySelection from '@app/pages/Orgs/Project/ExplorerFragment/PropertySelection'
import { useProjectExplorerContext } from '@app/pages/Orgs/Project/ExplorerFragment/ExplorerContext'
import { WithI18n } from '@shared/i18n/withI18n'
import i18n from '../../i18n'
import { useT } from '@app/i18n'

type EditProps = {
  viewId: string
}
export const EditViewDialog = (props: EditProps) => {
  const t = useT()
  const { views } = useProjectExplorerContext()
  const view = views.values[props.viewId]
  const { things } = useProjectDashboardContext()
  const toast = useToast()
  const [viewName, setViewName] = useState(view.name)
  const [selectedThingsIds, setSelectedThingsIds] = useState<string[]>(
    view.selections.map((item) => item.thingId)
  )
  const thingsOptions = things.array
  .filter(thing => thing.isConfigured === true)
  .map((thing) => ({
    label: thing.name,
    value: thing.id,
  }))

  const editView = api.project.editExplorerView.useMutation()

  const [selections, setSelections] = useState(
    view.selections.map((selection) => ({
      thingId: selection.thingId,
      properties: selection.thingProperties,
      settings: selection.settings
    }))
  )

  // Update the selections state when selectedThingsIds changes
  useEffect(() => {
    setSelections(
      selectedThingsIds.map((thingId) => ({
        thingId,
        properties:
          selections.find((s) => s.thingId === thingId)?.properties || [],
        settings: selections.find(s => s.thingId === thingId)?.settings || {
          chart: {
            grid: {
              showHorizontal: false,
              showVertical: false,
              colorRgba: 'rgba(1,1,1,1)'
            }
          }
        }
      }))
    )
  }, [selectedThingsIds, things])

  const handlePropertySelectionChange = (
    thingId,
    propertyKey,
    isSelected,
    color
  ) => {
    setSelections((prevSelections) => {
      return prevSelections.map((selection) => {
        if (selection.thingId === thingId) {
          let updatedProperties

          if (isSelected) {
            // Add property only if it doesn't exist
            const propertyExists = selection.properties.some(
              (prop) => prop.key === propertyKey
            )

            updatedProperties = propertyExists
              ? selection.properties.map((prop) =>
                  prop.key === propertyKey ? { ...prop, color } : prop
                )
              : [...selection.properties, { key: propertyKey, color }]
          } else {
            // Remove property if it exists
            updatedProperties = selection.properties.filter(
              (prop) => prop.key !== propertyKey
            )
          }

          return {
            ...selection,
            properties: updatedProperties,
          }
        }
        return selection
      })
    })
  }

  return (
    <>
      <div className="flex flex-col m-4">
        <Input
          value={viewName}
          label={t['SProject:ViewName'].get()}
          type="text"
          placeholder={t['SProject:EnterViewName'].get()}
          onChange={(name) => setViewName(name)}
        />
        <Spacer />
        <MultiSelect
          label={t['SProject:SelectDevices'].get()}
          values={selectedThingsIds}
          options={thingsOptions}
          onChange={(ids: string[]) => setSelectedThingsIds(ids)}
        />
        {selections.map((selection) => {
          const thing = things.map[selection.thingId]
          return (
            <PropertySelection
              key={selection.thingId}
              thingId={selection.thingId}
              thingName={thing?.name}
              influxFields={thing?.payloadSchema?.influxFields || []}
              selectedProperties={selection.properties}
              onPropertySelectionChange={handlePropertySelectionChange}
            />
          )
        })}
      </div>
      <Dialog.Buttons>
        <Dialog.Confirm
          onConfirm={async () => {
            const viewConfig = {
              id: props.viewId,
              name: viewName,
              selections: selections.map((selection) => ({
                thingId: selection.thingId,
                thingProperties: selection.properties.map((prop) => ({
                  key: prop.key,
                  color: prop.color,
                })),
                settings: selection.settings
              })),
            }

            try {
              await editView.mutateAsync(viewConfig)
              toast.add(
                <Toast
                  label={t['SProject:UpdatedView'].get()}
                  type="success"
                  description={
                    <Text>
                      View <b>{viewConfig.name}</b> {t['SProject:UpdatedSuccessfully'].get()}
                    </Text>
                  }
                />
              )
            } catch (e) {
              toast.add(
                <Toast
                  label={t['SProject:FailedToUpdateView'].get()}
                  type="error"
                  description={
                    <Text style={{ whiteSpace: 'normal' }}>{e.message}</Text>
                  }
                />
              )
              throw e
            }
          }}
        >
          {t['SProject:Save'].get()}
        </Dialog.Confirm>
      </Dialog.Buttons>
    </>
  )
}

export default (props)=> (
  <WithI18n i18n={i18n}>
    <EditViewDialog {...props}  />
  </WithI18n>
)