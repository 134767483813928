import { PageRoleSwitcher } from "@shared/components/page/PageRoleSwitcher"
import { OwnerPage } from "./Page.owner"
import DefaultErrorPage from "@shared/components/errors/DefaultErrorPage"
import { Page } from "@colombalink/basedui"
import { WithI18n } from "@shared/i18n/withI18n"
import i18n from "./i18n"
import { useT } from "@app/i18n"

type PageProps = { params: { orgAliasId: string, projectAliasId: string, thingAliasId: string } }
export type PageParams = PageProps['params']

const DefaultPage = () => {
  const t = useT()
  return DefaultErrorPage(t["ThingConfigure:PleaseContact"].get()  ,'401')
}

DefaultPage.displayName = 'DefaultPage'

export default (props: PageProps) => {
  return (
    <PageRoleSwitcher>
      <OwnerPage />
      <Main />
    </PageRoleSwitcher>
  )
} 

const Main = () => {
  return (
    <WithI18n i18n={i18n}>
      <DefaultPage  />
    </WithI18n>
  )
}

Main.displayName = 'Main'