import { useDialog } from "@shared/components/core/useDialog"
import AddActionDialog from "./dialogs/AddDialog"
import { Role } from "@server/shared"
import { api } from "@shared/index"
import { useRouteParams } from "@shared/router"
import { NoItemGuidance } from "@shared/components/page/generic/NoItemGuidance"
import { Props, useEffect, useState } from "react"
import { AddIcon} from "@colombalink/basedui"
import { CustomizableButton } from "@shared/components/core/CustomizableButton"
import { WithI18n } from "@shared/i18n/withI18n"
import i18n from "../i18n"
import { useT } from "@app/i18n"
import { LoadingIcon } from '@shared/components/core/LoadingIcon'

const _ActionLandingFragment = ({ children }) => {
    const t = useT()
    const createActionDialog = useDialog(<AddActionDialog />)
    const { orgAliasId } = useRouteParams<{ orgAliasId: string }>()
    const roles = api.user.getRoles.useLiveData()
    const actions = api.organization.monitor.action.getAll.useLiveData()
    const [hasActions, setHasActions] = useState<boolean>(null)

    useEffect(() => {
        if (!roles.data?.asArray) {
            return
        }
        if (!actions.data) {
            return
        }
        const hasActions = roles.data.asArray.some((role) => {
            if (Array.isArray(role)
                && role[0].startsWith(orgAliasId)
                && Array.isArray(actions.data) && actions.data.length > 0
            ) {
                return true
            }

        })

        setHasActions(hasActions)

    }, [roles.data?.asArray, actions.data, orgAliasId])



    if (roles.error) {
        throw roles.error
    }


    if (roles.loading || hasActions === null) {
        return <LoadingIcon />
    }


    if (hasActions) {
        return children
    }

    const orgRole = roles.data[orgAliasId] as Role
    if (orgRole === "OWNER") {
        return (
            <NoItemGuidance>

                {
                t["Monitor:NoActionsFound"].get()
                }
                <CustomizableButton
                    icon={<AddIcon />}
                    onClick={async () => {
                        await createActionDialog.open()
                    }}
                >
                    {t["Monitor:CreateAnAction"].get()}
                </CustomizableButton>
            </NoItemGuidance>
        )
    }

    return (
        <NoItemGuidance
        >
            {
                t["Monitor:NoActionsFound"].get()
            }
            {
                t["Monitor:ContactTheOwner"].get()
            }
        </NoItemGuidance>
    )

}


export const ActionLandingFragment = (props) => (
    <WithI18n i18n={i18n}>
      <_ActionLandingFragment {...props} />
    </WithI18n>
  )