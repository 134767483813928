
const OrgIntegrationsPageContentI18n = {

    'OrgIntegration:Add': {
        'de': 'Hinzufügen',
        'en': 'Add',
    },
    'OrgIntegration:Type': {
        'de': 'Typ',
        'en': 'Type',
    },
    'OrgIntegration:Integration': {
        'de': 'Integration',
        'en': 'Integration',
    },
    'OrgIntegration:AddIntegration': {
        'de': 'Integration hinzufügen',
        'en': 'Add Integration',
    },
    'OrgIntegration:AddedIntegration': {
        'de': 'Integration hinzugefügt',
        'en': 'Added integration',
    },

    'OrgIntegration:FailedToAddIntegration': {
        'de': 'Integration konnte nicht hinzugefügt werden',
        'en': 'Failed to add integration',
    },
    'OrgIntegration:EditIntegration': {
        'de': 'Integration bearbeiten',
        'en': 'Edit Integration',
    },
    
    'OrgIntegration:UpdatedIntegration': {
        'de': 'Integration aktualisiert',
        'en': 'Integration updated',
    },
    'OrgIntegration:UpdatedIntegrationText': {
        'de': 'Die Integration wurde erfolgreich aktualisiert.',
        'en': 'The integration was updated successfully.',
    },
    'OrgIntegration:FailedToUpdateIntegration': {
        'de': 'Integration konnte nicht aktualisiert werden',
        'en': 'Failed to update integration',
    },
    'OrgIntegration:DeleteIntegration': {
        'de': 'Integration löschen',
        'en': 'Delete Integration',
    },
    'OrgIntegration:DeletedIntegration': {
        'de': 'Integration gelöscht',
        'en': 'Integration deleted',
    },
    'OrgIntegration:DeletedIntegrationText': {
        'de': 'Die Integration wurde erfolgreich gelöscht.',
        'en': 'The integration was deleted successfully.',
    },
    'OrgIntegration:FailedToDeleteIntegration': {
        'de': 'Integration konnte nicht gelöscht werden',
        'en': 'Failed to delete integration',
    },


    'OrgIntegration:Organizations': {
        'de': 'Organisationen',
        'en': 'Organizations',
    },
    'OrgIntegration:Organization': { 
        'de': 'Organisation',
        'en': 'Organization',
    },
    'OrgIntegration:Path': {
        'de': 'Pfad',
        'en': 'Path',
    },
    'OrgIntegration:Cancel': {
        'de': 'Abbrechen',
        'en': 'Cancel',
    },
    'OrgIntegration:Close': {
        'de': 'Schliessen',
        'en': 'Close',
    },
    'OrgIntegration:Save': {
        'de': 'Speichern',
        'en': 'Save?',
        'de-CH': 'Speichern',
    },
    'OrgIntegration:Delete': {
        'de': 'Löschen',
        'en': 'Delete',
    },
    'OrgIntegration:View': {
        'de': 'Ansehen',
        'en': 'View',
    },
    'OrgIntegration:Edit': {
        'de': 'Bearbeiten',
        'en': 'Edit',
    },

    'OrgIntegration:Loading': {
        'de': 'Laden',
        'en': 'Loading',
    },
    'OrgIntegration:Name': {
        'de': 'Name',
        'en': 'Name',
    },

    'OrgIntegration:CreatedSuccessfully': {
        'de': 'erfolgreich erstellt',
        'en': 'created successfully',
    },

}


export default OrgIntegrationsPageContentI18n
export type OrgIntegrationsPageContentI18nType = typeof OrgIntegrationsPageContentI18n
