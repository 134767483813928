import { Dialog, Input, Text, Toast, useToast, Select, Label } from '@colombalink/basedui'
import React from 'react'
import { api } from '@shared/index'
import { ROLES, Role, getRoleLabel } from '@server/shared'
import { useT } from '@app/i18n'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'

const EditMemberOfOrganization = ({ member }: { member: { id: string, role: Role, user: { name: string, id: string } } }) => {
  const t = useT()
  const updateMemberRole = api.organization.updateMemberRole.useMutation()
  const [updateMemberArgs, setUpdateOrgParams] = React.useState({
    memberId: member.id,
    role: member.role,
  })

  const toast = useToast()

  if (!updateMemberArgs) {
    return <LoadingIcon />
  }

  return (
    <Dialog label={t['Member:UpdateRole'].get()}>
      <div>
        <Input
          style={{ marginBottom: 6 }}
          label={t['Member:User'].get()}
          value={member.user.name}
          disabled={true}
        />
        <Label
          label={t['Member:Role'].get()}
          style={{ marginBottom: 6, marginLeft: 4 }}
        />
        <Select
          value={updateMemberArgs.role}
          options={ROLES.map((role) => ({
            label: getRoleLabel(role, 'en'),
            value: role,
          }))}
          onChange={(role: Role) => {
            setUpdateOrgParams({ ...updateMemberArgs, role })
          }}
        />

        <Dialog.Buttons>
          <Dialog.Cancel displayShortcut={false}>{t['Member:Close'].get()}</Dialog.Cancel>
          <Dialog.Confirm displayShortcut={false} onConfirm={async () => {

            try {
              //console.log('updateMemberArgs: ', updateMemberArgs)
              await updateMemberRole.mutateAsync(updateMemberArgs)
              toast.add(
                <Toast
                  label={t['Member:RoleUpdated'].get()}
                  type="success"
                  description={
                    <Text>
                      {t['Member:SetRoleTo'].get()} <b>{getRoleLabel(updateMemberArgs.role, 'en')}</b> {t['Member:Successfully'].get()} 
                    </Text>
                  }
                />
              )
            } catch (e) {
              toast.add(
                <Toast
                  label={t['Member:UpdateFailed'].get()}
                  type="error"
                  description={
                    <Text style={{ whiteSpace: 'normal' }}>
                      {e.message}
                    </Text>
                  }
                />
              )
              throw e
            }
          }}>Save</Dialog.Confirm>
        </Dialog.Buttons>
      </div>
    </Dialog>
  )
}



export default EditMemberOfOrganization;