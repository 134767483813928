const SharedErrorsPageContentI18n = {


    "Errors:SorrySomethingWentWrong":{
        en: "Sorry, something went wrong.",
        de: "Entschuldigen Sie, etwas ist schief gelaufen.",
    },
    "Errors:PageNotFound":{
        en: "Page not found.",
        de: "Seite nicht gefunden.",
    },
    "Errors:ThePageYouAreLookingForDoesNotExist":{
        en: "The page you are looking for does not exist.",
        de: "Die Seite, die Sie suchen, existiert nicht.",
    },







}

export default SharedErrorsPageContentI18n
export type SharedErrorsPageContentI18nType = typeof SharedErrorsPageContentI18n
