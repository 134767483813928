const SharedCorePageContentI18n = {

    "CoreAuthorize:LoggedIn": {
        en: "Logged in",
        de: "Eingeloggt",
        
    },
    "CoreAuthorite:NotAuthorized": {
        en: "Not authorized...redirecting",
        de: "Nicht eingeloggt...weiterleiten",
    },
    "CoreCharts:SorryNoData": {
        en: "Sorry, we did not find any data for that period.",
        de: "Leider haben wir keine Daten für diesen Zeitraum gefunden.",
    },
    "CoreCharts:January": {
        en: "January",
        de: "Januar",
    },
    "CoreCharts:February": {
        en: "February",
        de: "Februar",
    },
    "CoreCharts:March": {
        en: "March",
        de: "März",
    },
    "CoreCharts:April": {
        en: "April",
        de: "April",
    },
    "CoreCharts:May": {
        en: "May",
        de: "Mai",
    },
    "CoreCharts:June": {
        en: "June",
        de: "Juni",
    },
    "CoreCharts:July": {
        en: "July",
        de: "Juli",
    },
    "CoreCharts:August": {
        en: "August",
        de: "August",
    },
    "CoreCharts:September": {
        en: "September",
        de: "September",
    },
    "CoreCharts:October": {
        en: "October",
        de: "Oktober",
    },
    "CoreCharts:November": {
        en: "November",
        de: "November",
    },
    "CoreCharts:December": {
        en: "December",
        de: "Dezember",
    },
    "CoreCharts:NoDataAvailable": {
        en: "No data available",
        de: "Keine Daten verfügbar",
    },
    "CoreCharts:NoPropertiesSelected": {
        en: "No properties selected!",
        de: "Keine Eigenschaften ausgewählt!",
    },
    "CoreDashboard:Close": {
        en: "Close",
        de: "Schliessen",
    },
    "CoreDashboard:Last30D":{
        en: "Last 30 days",
        de: "Letzte 30 Tage",
    },
    "CoreDashboard:Last7D":{
        en: "Last 7 days",
        de: "Letzte 7 Tage",
    },
    "CoreDashboard:Last14D":{
        en: "Last 14 days",
        de: "Letzte 14 Tage",
    },
    "CoreDashboard:Last24H":{
        en: "Last 24h",
        de: "Letzte 24h",
    },
    "CoreDashboard:SelectSomething":{
        en: "Select something...",
        de: "Wählen ...",
    },
    "CoreDashboard:PickADate":{
        en: "Pick a date...",
        de: "Wähle ein Datum...",
    },
    "CoreTable:NoResults":{
        en: "No results",
        de: "Keine Ergebnisse",
    },
    "Core:NotAnyMatchingItem":{
        en: "Not any matching item",
        de: "Kein passender Eintrag",
    },
}

export default SharedCorePageContentI18n
export type SharedCorePageContentI18nType = typeof SharedCorePageContentI18n
