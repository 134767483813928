import { api } from '@shared/index'
import React from 'react'
import FunctionEditor from '../../../../../app/pages/Orgs/Monitor/MonitorFunctions/FunctionEditor'
import DefaultNavigation from '@shared/components/page/navigation/DefaultNavigation'
import { Page } from '@shared/components/page/Page'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'

const EditFunction = ({
  params,
}: {
  params: { functionId: string; orgAliasId: string }
}) => {
  return (
    <Page>
      <DefaultNavigation />
      <ManageEditor functionId={params.functionId} orgAliasId={params.orgAliasId} />
    </Page>
  )
}

const ManageEditor = ({
  functionId,
  orgAliasId,
}: {
  functionId: string
  orgAliasId: string
}) => {
  const selectedFunction = api.function.getFunctionById.useLiveData({
    id: functionId, orgAliasId: orgAliasId
  })
  if (selectedFunction.loading) {
    return <LoadingIcon />
  }
  return (
    <FunctionEditor
      functionId={functionId}
      functionCode={selectedFunction.data.function}
      functionUserConfigSchema={selectedFunction.data.userConfigSchema || {}}
      functionGeneralConfigSchema={
        selectedFunction.data.configSchema || {}
      }
      functionRuleConfig={generateExampleFromSchema(
        selectedFunction.data.configSchema
      )}
      name={selectedFunction.data.name}
      playground={selectedFunction.data.ruleCheckerPlayground}
    />
  )
}

ManageEditor.displayName = 'Main'

function generateExampleFromSchema(schema: object): string {
  try {
    schema = JSON.parse(JSON.stringify(schema))
  } catch (e) {
    return '{}'
  }
  function generatePropertyExample(propertySchema: object): any {
    if (propertySchema.hasOwnProperty('type')) {
      switch (propertySchema['type']) {
        case 'string':
          return 'ExampleString'
        case 'number':
          return 42
        case 'integer':
          return 42
        case 'boolean':
          return true
        case 'object':
          if (propertySchema.hasOwnProperty('properties')) {
            const objExample: { [key: string]: any } = {}
            for (const key in propertySchema['properties']) {
              if (propertySchema['properties'].hasOwnProperty(key)) {
                objExample[key] = generatePropertyExample(
                  propertySchema['properties'][key]
                )
              }
            }
            return objExample
          } else {
            return {}
          }
        case 'array':
          if (propertySchema.hasOwnProperty('items')) {
            const arrayExample: any[] = []
            for (let i = 0; i < 2; i++) {
              arrayExample.push(
                generatePropertyExample(propertySchema['items'])
              )
            }
            return arrayExample
          } else {
            return []
          }
        default:
          return null
      }
    } else {
      // If 'type' is not specified, return an empty object.
      return {}
    }
  }

  return JSON.stringify(generatePropertyExample(schema))
}
export default {
  '/org/:orgAliasId/monitors/functions/:functionId': {
    page: EditFunction,
  },
}
