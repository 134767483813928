const ProjectsPageContentI18n = {
    "Projects:Loading": {
        "en": "Loading",
        "de": "Laden"
    },
    "Projects:NoProjects": {
        "en": "No projects",
        "de": "Keine Projekte"
    },
    "Projects:NoDevice": {
        "en": { 
            "idle": "There are no projects in an idle state.",
            "alert": "There are no projects in an alert state.",
            "alarm": "There are no projects in an alarm state.",
            "all": "There are no projects."
        },
        "de": { 
            "idle": "Es gibt keine Projekte im Ruhezustand.",
            "alert": "Es gibt keine Projekte im Alarmzustand.",
            "alarm": "Es gibt keine Projekte im Alarmzustand.",
            "all": "Es gibt keine Projekte."
        }
    },
    "Projects:All": {
        "en": "All",
        "de": "Alle"
    },
    "Projects:Idle": {
        "en": "Idle",
        "de": "Ruhezustand"
    },
    "Projects:Alert": {
        "en": "Alert",
        "de": "Warnung"
    },
    "Projects:Alarm": {
        "en": "Alarm",
        "de": "Alarm"
    },
}

export default ProjectsPageContentI18n
export type ProjectsPageContentI18nType = typeof ProjectsPageContentI18n
