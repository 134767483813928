import {
  useToast,
} from '@colombalink/basedui'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'
import DefaultNavigation from '@shared/components/page/navigation/DefaultNavigation'
import { useEffect, useState } from 'react'
import { Page } from '@shared/components/page/Page'
import { api } from '@shared/index'
import { useLocation } from 'wouter'
import { useTable } from '@shared/components/core/table/Table'
import i18n from './i18n'
import { useT } from '@app/i18n'
import { WithI18n } from '@shared/i18n/withI18n'


type PageProps = { params: { orgAliasId: string } }
export type PageParams = PageProps['params']

const ProjectQuotaPage = ({ params }: { params: { orgAliasId: string, projectAliasId: string } }) => {
  return (
    <Page>
      <DefaultNavigation />
      <Main orgAliasId={params.orgAliasId} />
    </Page>
  )
}

const Main = ({ orgAliasId }: { orgAliasId: string }) => {
  return (
    <WithI18n i18n={i18n}>
      <ManageQuotas orgAliasId={orgAliasId} />
    </WithI18n>
  )
}

Main.displayName = 'Main'

export default ProjectQuotaPage

const ManageQuotas = ({ orgAliasId }: { orgAliasId: string }) => {

  return (
    <div className="flex flex-col">
      <QuotaTable />
    </div>
  )
}

ManageQuotas.displayName = 'Main'

function QuotaTable() {
  const t = useT()
  const org = api.project.quotas.getAll.useLiveData();
  const [, setLocation] = useLocation()
  const toast = useToast()
  const [tableData, setTableData] = useState([])

  // only Org owners should have this power
  const quota = api.project.quotas.setQuota.useMutation()

  const [table, TableComponent] = useTable({
    header: [
      { label: t["Quota:Name"].get(), key: 'name' },
      { label: t["Quota:Count"].get(), key: 'count' },
      { label: t["Quota:Limit"].get(), key: 'limit', isEditable: true },
    ],
    data: tableData,
    onUpdatedCell(rowIndex, columnId, value) {
      //console.log('onUpdatedCell', rowIndex, columnId, value)
      if (columnId === "limit") {
        quota.mutateAsync({ limit: parseInt(value) })
      }
    },
  })


  useEffect(() => {
    if (org.data?.quotas) {
      console.log(org.data?.quotas)
      setTableData(
        org.data.quotas.map((quota) => {
          return {
            ...quota,
            count: quota.count.toString(),
            limit: quota.limit.toString(),
            customAttribute: {
            },
          }
        })
      )
    }
  }, [org.data?.quotas])

  if (org.error) {
    throw org.error
  }

  if (org.loading) {
    return <>{t["Quota:Loading"].get()} <LoadingIcon /></>
  }
  return (
    <>
      {TableComponent}
    </>
  )
}