import { createLogWriter } from '@roarr/browser-log-writer';
import {
    Roarr as log,
} from 'roarr';

// Set the log level based on the environment
const logLevel = import.meta.env.VITE_LOG_LEVEL || 'debug';
localStorage.setItem('ROARR_LOG', logLevel);

globalThis.ROARR = globalThis.ROARR ?? {};
globalThis.ROARR.write = createLogWriter({
    styleOutput: true,
});

export { log }