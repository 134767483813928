const SettingsApiTokensPageContentI18n = {

    "SettingsApiTokens:ApiTokens": {
        en: "API Tokens",
        de: "API Tokens",
    },
    "SettingsApiTokens:Create": {
        en: "Create",
        de: "Erstellen",
    },
    "SettingsApiTokens:Name": {
        en: "Name",
        de: "Name",
    },
    "SettingsApiTokens:Actions": {
        en: "Actions",
        de: "Aktionen",
    },
    "SettingsApiTokens:Loading": {
        en: "Loading...",
        de: "Laden...",
    },
    "SettingsApiTokens:DeleteApiToken": {
        en: "Delete API Token",
        de: "API Token löschen",
    },
    "SettingsApiTokens:DeleteApiTokenConfirmation": {
        en: "Are you sure you want to delete this API token:  ",
        de: "Möchten Sie diesen API Token wirklich löschen:  ",
    },
    "SettingsApiTokens:Delete": {
        en: "Delete",
        de: "Löschen",
    },
    "SettingsApiTokens:Cancel": {
        en: "Cancel",
        de: "Abbrechen",
    },
    "SettingsApiTokens:TokenDeleted": {
        en: "Token deleted",
        de: "Token gelöscht",
    },
    "SettingsApiTokens:TokenDeletedMessage": {
        en: "The API token has been deleted.",
        de: "Der API Token wurde gelöscht.",
    },
    "SettingsApiTokens:NewToken": {
        en: "New Token",
        de: "Neuer Token",
    },
    "SettingsApiTokens:Copy": {
        en: "Copy (Clipboard)",
        de: "Kopieren (Zwischenablage)",
    },
    "SettingsApiTokens:TokenCopied": {
        en: "Token copied",
        de: "Token kopiert",
    },
    "SettingsApiTokens:CreateApiToken": {
        en: "Create API Token",
        de: "API Token erstellen",
    },
    "SettingsApiTokens:TokenName": {
        en: "Token Name",
        de: "Token Name",
    },
    "SettingsApiTokens:CreatedApiToken": {
        en: "Created API Token",
        de: "API Token erstellt",
    },
    "SettingsApiTokens:CreatedSuccessfully": {
        en: " created successfully.",
        de: " erfolgreich erstellt.",
    },
    "SettingsApiTokens:FailedToCreateApiToken": {
        en: "Failed to create API token",
        de: "API Token konnte nicht erstellt werden",
    },
    "SettingsApiTokens:Save": {
        en: "Save",
        de: "Speichern",
    },








}

export default SettingsApiTokensPageContentI18n
export type SettingsApiTokensPageContentI18nType = typeof SettingsApiTokensPageContentI18n
