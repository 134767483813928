import { useEffect } from 'react'
import { Dialog, Input, Text, Toast, useToast } from '@colombalink/basedui'
import { api } from '@shared/index'
import { PageParams } from '../../../../app/pages/Orgs/Things/Page'
import { useLocation } from 'wouter'
import { useRouteParams } from '@shared/router'
import { ZodInputValidator } from '@shared/zod/InputValidator'
import { addThingsParams } from '@server/app'
import React from 'react'
import processString from '@app/pages/tools/convertName'
import { WithI18n } from '@shared/i18n/withI18n'
import i18n from '../i18n'
import { useT } from '@app/i18n'
import { log } from '@shared/logger'

export const _AddGenericThingDialog = () => {
  const t = useT()
  const [, setLocation] = useLocation()
  const { orgAliasId, projectAliasId } = useRouteParams<PageParams>()
  const [createThingArgs, setCreateThingParams] = React.useState({ name: '', alias: '', projectAliasId })
  const addDeviceToProjectMutation = api.project.addThingToProject.useMutation()
  const toast = useToast()
  
  useEffect(() => {
    log.debug(createThingArgs, "createThingArgs")
    //console.log("createThingArgs", ZodInputValidator(addThingsParams, createThingArgs, "name"))
  }, [createThingArgs.name])

  return (
    <Dialog label= {t["SThing:AddAGenericThing"].get()}>
      <div>
        <Input 
           type='text'
           description={t["SThing:Name"].get()}
           value={createThingArgs.name}
           pattern="true"
           error={(value) => ZodInputValidator(addThingsParams, createThingArgs, "name")}
           onChange={(name: string) => {
            setCreateThingParams({ ...createThingArgs, name, alias: processString(name) })
          }}
        />

        <Dialog.Buttons>

          <Dialog.Cancel displayShortcut={false}>{t["SThing:Close"].get()}</Dialog.Cancel>
          <Dialog.Confirm displayShortcut={false} onConfirm={async () => {
            try {
              await addDeviceToProjectMutation.mutateAsync(createThingArgs)
              toast.add(
                <Toast
                  label={t["SThing:AddedThing"].get()}
                  type="success"
                  description={
                    <Text>
                      Thing <b>{createThingArgs.name}</b> {t["SThing:CreatedSuccessfully"].get()} <b>{projectAliasId}</b>
                    </Text>
                  }
                />
              )
            } catch (e) {
              toast.add(
                <Toast
                  label= {t["SThing:FailedToCreateThing"].get()}
                  type="error"
                  description={
                    <Text style={{whiteSpace: 'normal'}}>
                      {e.message}
                    </Text>
                  }
                />
              )
              throw e
            }
          
          }}>{t["SThing:Add"].get()}</Dialog.Confirm>
        </Dialog.Buttons>
      </div>
    </Dialog >
  )
}

export const AddGenericThingDialog = (props) => (
  <WithI18n i18n={i18n}>
      <_AddGenericThingDialog {...props} />
  </WithI18n>
)