import React from 'react'
import { Dialog, Input, Spacer, Text, Toast, useToast } from '@colombalink/basedui'
import { ZodInputValidator } from '@shared/zod/InputValidator'
import { api } from '@shared/index'

import { useT } from '@app/i18n'
import { inviteMemberParams } from '@server/app'

const InviteMemberToOrgDialog = ({orgAliasId}: {orgAliasId}) => {
  const t = useT()
  const inviteMember = api.organization.inviteMemberFromOrganization.useMutation()
  const [inviteMemberArgs, setInviteMemberArgs] = React.useState({ email: '', orgAliasId})
  const toast = useToast()

  return (
    <Dialog label={t['Member:InviteMember'].get()}
        style={{ width: '800px' }} // increase width to fit the Invite Explanation Text
    >

      <div>
        <Text>
          {t['Member:InviteText'].get()}
        </Text>
        <Spacer/>
        <Input
          description="Email"
          value={inviteMemberArgs.email}
          pattern="true"
          error={(value) => ZodInputValidator(inviteMemberParams, inviteMemberArgs, "email")}
          onChange={(email: string) => {
            setInviteMemberArgs({ ...inviteMemberArgs, email })
          }
          }
        />
        <Dialog.Buttons>
          <Dialog.Cancel displayShortcut={false}>{t['Member:Close'].get()}</Dialog.Cancel>
          <Dialog.Confirm displayShortcut={false} onConfirm={async () => {

            try {
              await inviteMember.mutateAsync(inviteMemberArgs)
              toast.add(
                <Toast
                  label={t['Member:EmailSent'].get()}
                  type="success"
                  description={
                    <Text>
                      Email <b>{inviteMemberArgs.email}</b> {t['Member:EmailSentSuccessfully'].get()}
                    </Text>
                  }
                />
              )
            } catch (e) {
              toast.add(
                <Toast
                  label={t['Member:InviteFailed'].get()}
                  type="error"
                  description={
                    <Text style={{whiteSpace: 'normal'}}>
                      {e.message}
                    </Text>
                  }
                />
              )
              throw e
            }
          }}>{t['Member:Save'].get()}</Dialog.Confirm>
        </Dialog.Buttons>
      </div>
    </Dialog>
  )
}




export default InviteMemberToOrgDialog

