import {  Text, useDialog, GraphIcon } from '@colombalink/basedui'
import { api } from '@shared/index'
import { useMemo, useRef } from 'react'
import { Unit } from '@server/shared'
import { getUnitLabel } from '@shared/utils/units'
import ReactECharts, { EChartsOption } from 'echarts-for-react'
import HistogramDialog from '@shared/components/core/dashboard/HistogramDialog'
import { useProjectDashboardContext } from '@app/pages/Orgs/Project/DashboardContext'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'

export const StatsCardHistogram = ({
  interval,
  className,
  propertyKey,
  thingId,
  thingName,
  thingAlias,
}: {
  thingId: string
  interval: { label: string; value: '-24h' | '-7d' | '-30d' }
  className?: string
  propertyKey: string
  thingName: string
  thingAlias?: string
}) => {
  // Event data for the chart
  const parentDiv = useRef()
  const { open } = useDialog()

  const { useThingProperty } = useProjectDashboardContext()

  const property = useThingProperty(thingId, propertyKey).getProperty()
  const { unit } = property


  const { loading: isLoadingStats, data: rawData } =
    api.event.getStringEventStatsById.useLiveData({
      thingId,
      interval: interval.value,
      influxField: propertyKey,
    })

  const data = useMemo(() => {
    if (!rawData) return null
    return Object.keys(rawData).map((key) => ({
      value: rawData[key],
      label: key,
    }))
  }, [rawData])
  if (!data) return <LoadingIcon />

  const containerMultiplier = Math.floor((data?.length || 0) / 5) + 1

  const option: EChartsOption = {
    grid: {
      top: 0,
      bottom: 0,
    },
    xAxis: {
      type: 'value',
      position: 'top',
      splitLine: {
        lineStyle: {
          type: 'dashed',
        },
      },
    },
    yAxis: {
      type: 'category',
      axisLine: { show: false },
      axisLabel: { show: false },
      axisTick: { show: false },
      splitLine: { show: false },
    },
    series: [
      {
        type: 'bar',
        itemStyle: {
          color: '#e2e1f5',
        },
        label: {
          show: true,
          formatter: function ({ data }) {
            return `${data.label}: ${data.value} `
          },
        },
        data,
      },
    ],
  }

  return (
    <div ref={parentDiv} className={className} style={{ minWidth: '200px' }}>
      <div className="relative h-full w-full" style={{ minHeight: '180px' }}>
        <div className="absolute z-5 p-6 h-full w-full">
          <div className="flex flex-col h-full">
            <div className="flex flex-row justify-between ">
              <div>
                <h3 className="text-lg font-medium text-gray-900">
                  {property.label.en}
                </h3>
                <br />
                <Text size="18px" weight={600} />
                <h4 className="text-sm font-medium text-gray-500">
                  {interval.label}
                </h4>
              </div>
              <GraphIcon
                onClick={async () =>
                  open(
                    <HistogramDialog
                      thingId={thingId}
                      influxField={propertyKey}
                      thingName={thingName}
                    />
                  )
                }
                className="h4 w-4 cursor-pointer"
              />
            </div>
            {isLoadingStats ? (
              <LoadingIcon />
            ) : (
              <div style={{ height: '100%', overflowY: 'scroll' }}>
                <ReactECharts
                  option={option}
                  style={{ height: 70 * containerMultiplier + 'px' }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
