import { StatsCardMinMaxMean } from '@shared/components/core/dashboard/StatsCardMinMaxMean'
import { StatsCardHistogram } from '@shared/components/core/dashboard/StatsCardHistogram'
import { useProjectDashboardContext } from '@app/pages/Orgs/Project/DashboardContext'
import { WithI18n } from '@shared/i18n/withI18n'
import i18n from '../i18n'
import { useT } from '@app/i18n'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'

const _StatsCard = ({
  property: propertyKey,
  thingId,
  thingName,
  thingAlias,
}: {
  thingId: string
  property: string
  thingName: string
  thingAlias?: string
}) => {

  const t = useT()
  const { useThingProperty } = useProjectDashboardContext()
  const property = useThingProperty(thingId, propertyKey).getProperty()

  if (!property) return (<div className='h-[180px] flex justify-center items-center'><LoadingIcon /></div>)

  if (property.primitiveDataType === 'string') {
    return (
      <StatsCardHistogram
        thingId={thingId}
        interval={{ label: t["CoreDashboard:Last24H"].get()  +':', value: '-24h' }}
        propertyKey={propertyKey}
        thingName={thingName}
        thingAlias={thingAlias}
      />
    )
  } else if (property.primitiveDataType === 'boolean') {
    // not supported
    return null
  }

  return (
    <StatsCardMinMaxMean
      thingId={thingId}
      interval={{ label: t["CoreDashboard:Last24H"].get()  +':', value: '-24h' }}
      propertyKey={propertyKey}
      thingName={thingName}
      thingAlias={thingAlias}
    />
  )
}

export const StatsCard = (props) => (
  <WithI18n i18n={i18n}>
    <_StatsCard {...props} />
  </WithI18n>
)
