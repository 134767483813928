import { FC } from 'react'
import { OptionMenu } from '@shared/components/core/EditEntityMenu'
import { api } from '@shared/index'
import {
  ContextItem,
  DeleteIcon,
  EditIcon,
  Text,
  Toast,
  useDialog,
  useToast,
} from '@colombalink/basedui'
import { useLocation } from 'wouter'
import { useT } from '@app/i18n'
import EditMonitor from '@app/pages/Orgs/Thing/Fragments/Monitors/Dialogs/EditDialog'
import { EditMonitorRegularUserDialog } from '@app/pages/Orgs/Thing/Fragments/Monitors/Dialogs/AddDialogRegular'

type RowMenuProps = {
  monitor: ReturnType<
    typeof api.monitor.getAllAlarmsFromThing.useLiveData
  >['data'][0]
}

export const RowMenu = (props: RowMenuProps) => {
  const CategoryMenu: FC<{}> = ({}) => {
    const t = useT()
    const { open } = useDialog()
    const [, setLocation] = useLocation()
    const toast = useToast()

    const removeAlarm = api.monitor.removeAlarm.useMutation()
    return (
      <>
        <ContextItem
          onClick={() => {
            open(<EditMonitor monitorId={props.monitor['id']} />)
          }}
          icon={EditIcon}
        >
          {t['ThingMonitors:EditPro'].get()}
        </ContextItem>

        <ContextItem
          onClick={() => {
            open(<EditMonitorRegularUserDialog monitorId={props.monitor['id']} />)
          }}
          icon={EditIcon}
        >
          {t['ThingMonitors:Edit'].get()}
        </ContextItem>

        <ContextItem
          onClick={async () => {
            await removeAlarm.mutateAsync({ id: props.monitor['id'] })

            toast.add(
              <Toast
                label={t['ThingMonitors:RemovedMonitor'].get()}
                type="success"
                description={
                  <Text> {t['ThingMonitors:RemovedSuccessfully'].get()} </Text>
                }
              />
            )
          }}
          icon={DeleteIcon}
        >
          {t['ThingMonitors:Remove'].get()}
        </ContextItem>
      </>
    )
  }

  return <OptionMenu menu={CategoryMenu} style={{ width: '150px' }} />
}
