
import { useRouteParams } from "@shared/router"
import { PageParams } from "../../Page.owner"
import { useEffect, useState } from "react"
import {ChartContext, useChartContext} from "@shared/components/core/charts/context"
import { SimpleContainer } from "@shared/components/core/charts/SimpleContainer"
import { Legend } from "@shared/components/core/charts/Legend"
import { ThingTimeSeriesChart } from "@shared/components/core/charts/ThingTimeseriesChart"
import {ThingContext, useThingContext} from "../Latest/context"

export const TrendsFragment = () => {
    const { thingAliasId } = useRouteParams<PageParams>()

    return (
        <div className="h-full max-w-5xl">
            <ThingContext thingAliasId={thingAliasId}>
                <ChartContext>
                    <ThingChartController/>
                    <SimpleContainer>
                        <Legend/>
                        <ThingTimeSeriesChart></ThingTimeSeriesChart>
                    </SimpleContainer>
                </ChartContext>
            </ThingContext>
        </div>
    )
}

const ThingChartController = () => {
    const thingCtx = useThingContext()
    const chartCtx = useChartContext()
    // introduce a debounce time of 500ms

    const [isDebouncing, setIsDebouncing] = useState(false)


    useEffect(() => {
        if (!thingCtx.thing.data) return
        if (isDebouncing) {
            setIsDebouncing(!isDebouncing)
        }
        
        setTimeout(() => {
            setIsDebouncing(true)
        }, 500)
        // we got a new event lets update the toDate filter
        chartCtx.useToDate[1](new Date().getTime())

    },[thingCtx.thing.data])



    useEffect(() => {
        if (thingCtx.properties) {
            chartCtx.useProperties[1](thingCtx.properties);
        }
    }, [thingCtx.properties])


    useEffect(() => {

      const properties = thingCtx.properties
      // console.log("setting properties", properties)
      // console.log("xx", ctx.useProperties[0])
      const keys = Object.keys(properties)
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i]
        if (!properties[key]) continue
        properties[key].visible =  true
        if (chartCtx.useVisibleFields[0].size === 0) {
          chartCtx.useVisibleFields[1](pre => new Map(pre).set(key, true));
        }
      }
      chartCtx.useProperties[1]({ keys, values: properties })
      // console.log("setting properties", properties)
    }, [thingCtx.properties])

    return <></>
}