import { useDialog } from "@shared/components/core/useDialog"
import { Role } from "@server/shared"
import { api } from "@shared/index"
import { useRouteParams } from "@shared/router"
import { NoItemGuidance } from "@shared/components/page/generic/NoItemGuidance"
import { useEffect, useState } from "react"
import { LoadingIcon } from '@shared/components/core/LoadingIcon'
import { CustomizableButton } from "@shared/components/core/CustomizableButton"
import CreateOrganizationDialog from "./CreateDialog"
import { WithI18n } from "@shared/i18n/withI18n"
import i18n from "./i18n"
import { useT } from "@app/i18n"

const _UserLandingFragment = ({ children }) => {
    const t = useT()
    const createOrganization = useDialog(<CreateOrganizationDialog />)
    const { orgAliasId } = useRouteParams<{ orgAliasId: string }>()
    const roles = api.user.getRoles.useLiveData()
    const [hasOrgs, setHasOrgs] = useState<boolean>(null)
    
    useEffect(() => {
        if (!roles.data?.asArray) {
            return
        }
        if(roles.data.asArray.length === 0){
            setHasOrgs(false)
            return
        }
        setHasOrgs(true)
    }, [roles.data?.asArray, orgAliasId])


    if (roles.error) {
        throw roles.error
    }


    if (roles.loading || hasOrgs === null) {
        return <LoadingIcon />
    }


    if (hasOrgs) {
        return children
    }

    if (!hasOrgs) {
        return (
            <NoItemGuidance>

                {
                    t["SUser:NotPart"].get()
                }
                <CustomizableButton
                    onClick={async () => {
                        await createOrganization.open()
                    }}
                >
                    {t["SUser:Create"].get()}
                </CustomizableButton>
            </NoItemGuidance>
        )
    }

    return (
        <NoItemGuidance
        >
            {
                t["SUser:NoProjects"].get()
            }
        </NoItemGuidance>
    )

}

export const UserLandingFragment = (props) => (
    <WithI18n i18n={i18n}>
        <_UserLandingFragment {...props} />
    </WithI18n>
)