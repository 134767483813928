const SharedProjectPageContentI18n = {

    "SProject:NoProjectsFound": {
        "en": "No projects found",
        "de": "Keine Projekte gefunden"
    },
    "SProject:CreateYourFirstProject": {
        "en": "Create your first project",
        "de": "Erstelle dein erstes Projekt"
    },
    "SProject:CreateProject": {
        "en": "Create project",
        "de": "Projekt erstellen"
    },
    "SProject:PleaseContact": {
        "en": "Please contact the project owner to create and invite you to a project.",
        "de": "Bitte kontaktiere den Projekteigentümer, um dich zu einem Projekt einzuladen."
    },
    "SProject:Project": {
        "en": "Project",
        "de": "Projekt"
    },
    "SProject:Name": {
        "en": "Name",
        "de": "Name"
    },
    "SProject:ProjectUrlPath": {
        "en": "Project URL path: ",
        "de": "Projekt URL Pfad: "
    },
    "SProject:AddedProject": {
        "en": "Added project",
        "de": "Projekt hinzugefügt"
    },
    "SProject:CreatedSuccessfully": {
        "en": "Created successfully",
        "de": "Erfolgreich erstellt"
    },
    "SProject:FailedToCreateProject": {
        "en": "Failed to create project",
        "de": "Projekt konnte nicht erstellt werden"
    },
    "SProject:Close": {
        "en": "Close",
        "de": "Schliessen"
    },
    "SProject:Save": {
        "en": "Save",
        "de": "Speichern"
    },
    "SProject:EditProject": {
        "en": "Edit project",
        "de": "Projekt bearbeiten"
    },
    "SProject:EditingTheOrg": {
        "en": "Editing the organization name will change the URL",
        "de": "Das Bearbeiten des Organisationsnamens ändert die URL"
    },
    "SProject:PleaseRetypePreviousProjectName": {
        "en": "Please retype the previous project name: ",
        "de": "Bitte den vorherigen Projektnamen erneut eingeben: "
    },
    "SProject:RetypeProjectName": {
        "en": "Retype project name",
        "de": "Projektnamen erneut eingeben"
    },
    "SProject:UpdatedProject": {
        "en": "Updated project ",
        "de": "Projekt "
    },
    "SProject:FailedToUpdateProject": {
        "en": "Failed to update project",
        "de": "Projekt konnte nicht aktualisiert werden"
    },
    "SProject:Successfully": {
        "en": "successfully",
        "de": "Erfolgreich aktualisiert"
    },
    "SProject:NoViewsFound": {
        "en": "No views found",
        "de": "Keine Views gefunden"
    },
    "SProject:CreateYourFirstView": {
        "en": "Create your first view",
        "de": "Erstelle deine erste View"
    },
    "SProject:CreateView": {
        "en": "Create view",
        "de": "View erstellen"
    },
    "SProject:View": {
        "en": "View",
        "de": "View"
    },
    "SProject:ViewName": {
        "en": "View name",
        "de": "View Name"
    },
    "SProject:EnterViewName": {
        "en": "Enter view name",
        "de": "View Name eingeben"
    },
    "SProjcet:PleaseContact": {
        "en": "Please contact the project owner to create a view.",
        "de": "Bitte kontaktiere den Projektinhaber, um eine Ansicht zu erstellen."
    },
    "SProject:AddedView": {
        "en": "Added view",
        "de": "Ansicht hinzugefügt"
    },
    "SProject:CreateViewSuccessfully": {
        "en": "Create view successfully",
        "de": "Ansicht erfolgreich erstellt"
    },
    "SProject:CreatedView": {
        "en": "Create view ",
        "de": "Ansicht erstellt"
    },
    "SProject:FailedToCreateView": {
        "en": "Failed to create view",
        "de": "Ansicht konnte nicht erstellt werden"
    },
    "SProject:EditView": {
        "en": "Edit view",
        "de": "Ansicht bearbeiten"
    },
    "SProject:NoConfiguredDevices": {
        "en": "No configured devices",
        "de": "Keine konfigurierten Geräte"
    },
    "SProject:PleaseConfigureD": {
        "en": "Please configure a device first before creating the view",
        "de": "Bitte konfiguriere zuerst ein Gerät, bevor du die Ansicht erstellst"
    },
    "SProject:SelectDevices": {
        "en": "Select Devices",
        "de": "Geräte auswählen"
    },
    "SProject:UpdatedView": {
        "en": "Updated view",
        "de": "Ansicht aktualisiert"
    },
    "SProject:FailedToUpdateView": {
        "en": "Failed to update view",
        "de": "Ansicht konnte nicht aktualisiert werden"
    },
    "SProject:UpdatedSuccessfully": {
        "en": " updated successfully",
        "de": " erfolgreich aktualisiert"
    },





}

export default SharedProjectPageContentI18n
export type SharedProjectPageContentI18nType = typeof SharedProjectPageContentI18n
