import { api } from '@shared/index'
import { useLink } from '../routes'
import {
  NavigationItem,
} from '@shared/components/page/navigation/context'
import {
  InfoIcon,
  NewspaperIcon,
  PcCaseIcon,
  RadioTower,
  SliceIcon,
  UserPlus2Icon,
} from 'lucide-react'
import { Role, RoleEnum } from '@server/shared'


const deviceConfigureItem = ({ orgAliasId, projectAliasId, thingAliasId }, role: Role, t) => {
  if (RoleEnum[role] < RoleEnum.OWNER) return
  return ({
    configure: {
      name: t['Navigation:Configure'].get(),
      hidden: true,
      link: useLink(
        '/org/:orgAliasId/project/:projectAliasId/thing/:thingAliasId/configure',
        {
          orgAliasId,
          projectAliasId,
          thingAliasId,
        }
      ),
    },
  })

}
const deviceMonitorsItem = ({ orgAliasId, projectAliasId, thingAliasId }, t) => ({
  monitors: {
    name: t['Navigation:Monitors'].get(),
    hidden: true,
    link: useLink(
      '/org/:orgAliasId/project/:projectAliasId/thing/:thingAliasId/monitors',
      {
        orgAliasId,
        projectAliasId,
        thingAliasId,
      }
    ),
  },
})

const devicesItem = ({ orgAliasId, projectAliasId, things }, role: Role, t) => {
  if (!role) return;

  let deviceLink
  if (RoleEnum[role] < RoleEnum.OWNER)
    deviceLink = null
  else
    deviceLink = useLink('/org/:orgAliasId/project/:projectAliasId/things', {
      orgAliasId,
      projectAliasId,
    })
  const deviceItem = {
    name: t['Navigation:Devices'].get(),
    icon: <RadioTower />,
    link: deviceLink,
    subItems: {},
  }

  for (const thing of things) {

    const thingLink = useLink(
      '/org/:orgAliasId/project/:projectAliasId/thing/:thingAliasId',
      {
        orgAliasId,
        projectAliasId,
        thingAliasId: thing.alias,
      }
    )

    deviceItem.subItems[thing.alias] = {
      name: thing.name,
      link: thingLink,
      subItems: {
        ...deviceConfigureItem({ orgAliasId, projectAliasId, thingAliasId: thing.alias }, role, t),
        ...deviceMonitorsItem({ orgAliasId, projectAliasId, thingAliasId: thing.alias }, t),
      },
    }
  }
  return { devices: deviceItem }
}

const members = ({ orgAliasId, projectAliasId }, t) => ({
  members: {
    name: t['Navigation:Members'].get(),
    link: useLink('/org/:orgAliasId/project/:projectAliasId/members', {
      orgAliasId,
      projectAliasId,
    }),
    subItems: {},
    icon: <UserPlus2Icon />,
  },
})

const auditLog = ({ orgAliasId, projectAliasId }, t) => ({
  auditLog: {
    name: t['Navigation:AuditLog'].get(),
    link: useLink('/org/:orgAliasId/project/:projectAliasId/auditLog', {
      orgAliasId,
      projectAliasId
    }),
    subItems: {},
    icon: <InfoIcon />,
  },
});

const quota = ({ orgAliasId, projectAliasId }, t) => ({
  quota: {
    name: t['Navigation:Quota'].get(),
    link: useLink('/org/:orgAliasId/project/:projectAliasId/quota', {
      orgAliasId: orgAliasId,
      projectAliasId: projectAliasId
    }),
    subItems: {},
    icon: <SliceIcon />,
  },
});

// navigation.subItems[org.alias].subItems.projects.subItems['Edit'] = {
//   name: 'Edit',
//   hidden: true,
//   link: useLink('/org/:orgAliasId/projects/editor', {
//     orgAliasId: org.alias
//   }),
//   subItems: {},
// }

const projectsEditorItem = ({ orgAliasId }, t) => ({
  editor: {
    name: t['Navigation:Editor'].get(),
    hidden: true,
    link: useLink('/org/:orgAliasId/projects/editor', {
      orgAliasId,
    }),
    subItems: {},
  },
})

export function projects(
  navigation: NavigationItem,
  org: ReturnType<typeof api.user.getUserNav.useLiveData>['data']['organizations'][0],
  roles: ReturnType<typeof api.user.getRoles.useLiveData>['data'],
  orgAliasId: string,
  t
) {

  const projects = {
    name: t['Navigation:Projects'].get(),
    link: useLink('/org/:orgAliasId/projects', { orgAliasId }),
    parent: navigation,
    icon: <NewspaperIcon />,
    subItems: {},
  }

  // if we have no projects we need to add the editor to navigation subitems
  if (org?.projects.length === 0) {
    projects.subItems = {
      ...projectsEditorItem({ orgAliasId }, t)
    }
  }
  else {
    projects.subItems = org?.projects.map(p => {

      const projectAliasId = p.alias
      // console.log('roles................: ', roles, projectAliasId)
      if (!roles[projectAliasId]) return null;

      const projectItem: NavigationItem = {
        name: p.name,
        link: useLink('/org/:orgAliasId/project/:projectAliasId', {
          orgAliasId,
          projectAliasId,
        }),
        parent: projects,
        active: false,
        icon: <PcCaseIcon />,
        subItems: {
          ...devicesItem({ orgAliasId, projectAliasId, things: p.things }, roles[projectAliasId], t),
          ...members({ orgAliasId, projectAliasId }, t),
          ...auditLog({ orgAliasId, projectAliasId }, t),
          ...quota({ orgAliasId, projectAliasId }, t)
        },
      }
      return projectItem
    })
    .filter(p=> !!p)

    // we need to make sure that the project edit still exists 
    // otherwise we get some weird nav based on proj
    projects.subItems["editor"] = {
      name: t['Navigation:Editor'].get(),
      hidden: true,
      link: useLink('/org/:orgAliasId/projects/editor', {
        orgAliasId,
      }),
      subItems: {},
    }
  }
  return { projects }
}
