import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'
import { api } from '@shared/index'
import { Id } from '@server/shared'
import { useT } from '@app/i18n'

export type ExplorerView = {
  selections: {
    thingId: string
    thingProperties: {
      key: string
      label: string
      color: string
    }[]
  }[]
}

function useProvideContext() {
  const views = api.project.getExplorerViews.useLiveData()
  const [viewKeys, setViewKeys] = useState<string[]>([])
  const [viewsValues, setViewsValues] = useState<Record<Id, typeof views.data[0]>>({})
  const [timeFrame, setTimeFrame] = useState<'daily' | 'monthly' | 'yearly' | 'live'>('daily')
  const useOpenAccordionItems = useState<string[]>([])

  useEffect(() => {
    if (views.data) {
      const keys = []
      const values = {}
      for (let i = 0; i < views.data.length; i++) {
        const view = views.data[i]
        keys.push(view.id)
        values[view.id] = view
      }
      setViewKeys(keys)
      setViewsValues(values)
    }

  }, [views.data])

  
  return ({
    loading: views.loading,
    error: views.error,
    views: {
      keys: viewKeys,
      values: viewsValues
    },
    filters: {
      timeFrame: {
        value: timeFrame,
        set: setTimeFrame
      }
    },
    useOpenAccordionItems,
  })
}

type ContextType = ReturnType<typeof useProvideContext>
const Context = createContext<ContextType | undefined>(undefined)


type Props = {
  projectAliasId: string
} & PropsWithChildren

const ProjectExplorerContext: React.FC<Props> = ({ children }) => {
  const t = useT()
  const value = useProvideContext()

  // wrap the error and loading better
  if(value.error){
    <Context.Provider value={value}>{value.error}</Context.Provider>
  }

  if (value.loading) {
    return <Context.Provider value={value}>{children}
      <div>{t['Project:Loading'].get()} </div>
    </Context.Provider>
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}

const useProjectExplorerContext = (): ContextType => {
  const t = useT()
  const context = useContext(Context)
  if (!context) {
    //TODO: add multilingual support for error 
    throw new Error(t['Project:NoContext'].get())
  }
  return context
}

export { useProjectExplorerContext, ProjectExplorerContext }
