import { useTypedTranslation } from "@shared/i18n/useTypedTranslation";
import { HomePageContentI18nType } from "./pages/User/Home/i18n";
import { OrganizationsPageContentI18nType } from "./pages/User/Organizations/i18n";
import { ProfilePageContentI18nType } from "./pages/User/Profile/i18n";
import { ProfileChangePasswordPageContentI18nType } from "./pages/User/ProfileChangePassword/i18n";
import { LoginPageContentI18nType } from "./pages/Login/i18n";
import { AuditLogPageContentI18nType } from "./pages/Orgs/AuditLog/i18n";
import { OrgHomePageContentI18nType } from "./pages/Orgs/Home/i18n";
import { OrgIntegrationsPageContentI18nType } from "./pages/Orgs/Integrations/i18n";
import { OrgMembersPageContentI18nType } from "./pages/Orgs/Members/i18n";
import { OrgMonitorPageContentI18nType } from "./pages/Orgs/Monitor/i18n";
import { ProjectPageContentI18nType } from "./pages/Orgs/Project/i18n";
import { ProjectAuditLogPageContentI18nType } from "./pages/Orgs/ProjectAuditLog/i18n";
import { ProjectDevicesPageContentI18nType } from "./pages/Orgs/Things/i18n";
import { ProjectQuotaPageContentI18nType } from "./pages/Orgs/ProjectQuota/i18n";
import { ProjectsPageContentI18nType } from "./pages/Orgs/Projects/i18n";
import { ProjectsEditorPageContentI18nType } from "./pages/Orgs/ProjectsEditor/i18n";
import { ProjectsMembersPageContentI18nType } from "./pages/Orgs/ProjectsMembers/i18n";
import { SettingsApiTokensPageContentI18nType } from "./pages/Orgs/SettingsApiTokens/i18n";
import { SettingsQuotaPageContentI18nType } from "./pages/Orgs/SettingsQuota/i18n";
import { ThingPageContentI18nType } from "./pages/Orgs/Thing/i18n";
import { ThingConfigurePageContentI18nType } from "./pages/Orgs/ThingConfigure/i18n";
import { ThingMonitorsPageContentI18nType } from "./pages/Orgs/Thing/Fragments/Monitors/i18n";
import { SharedCorePageContentI18nType } from "@shared/components/core/i18n";
import { SharedErrorsPageContentI18nType } from "@shared/components/errors/i18n";
import { SharedMonitorPageContentI18nType } from "@shared/components/monitor/i18n";
import { SharedProjectPageContentI18nType } from "@shared/components/project/i18n";
import { SharedThingPageContentI18nType } from "@shared/components/thing/i18n";
import { SharedUserPageContentI18nType } from "@shared/components/user/i18n";
import { UserSettingsPageContentI18nType } from "./pages/User/Settings/i18n";
import { NavigationContentI18nType } from "./nav/i18n";
import { ForgotContentI18nType } from "./pages/Login/Forgot/i18n";
import { ResetContentI18nType } from "./pages/Login/Reset/i18n";
import { SharedPagePageContentI18nType } from "@shared/components/page/i18n";



// TODO: we add the type whenever we add it to new section
export type I18nType = HomePageContentI18nType & 
                       OrganizationsPageContentI18nType & 
                       ProfilePageContentI18nType & 
                       ProfileChangePasswordPageContentI18nType & 
                       UserSettingsPageContentI18nType &
                       LoginPageContentI18nType & 
                       ForgotContentI18nType &
                       ResetContentI18nType &
                       AuditLogPageContentI18nType & 
                       OrgHomePageContentI18nType & 
                       OrgIntegrationsPageContentI18nType & 
                       OrgMembersPageContentI18nType & 
                       OrgMonitorPageContentI18nType &
                       ProjectPageContentI18nType &
                       ProjectAuditLogPageContentI18nType &
                       ProjectDevicesPageContentI18nType &
                       ProjectQuotaPageContentI18nType &
                       ProjectsPageContentI18nType &
                       ProjectsEditorPageContentI18nType &
                       ProjectsMembersPageContentI18nType &
                       SettingsApiTokensPageContentI18nType &
                       SettingsQuotaPageContentI18nType &
                       ThingPageContentI18nType &
                       ThingConfigurePageContentI18nType &
                       ThingMonitorsPageContentI18nType &
                       SharedCorePageContentI18nType &
                       SharedErrorsPageContentI18nType &
                       SharedMonitorPageContentI18nType &
                       SharedPagePageContentI18nType &
                       SharedProjectPageContentI18nType &
                       SharedThingPageContentI18nType &
                       SharedUserPageContentI18nType &
                       NavigationContentI18nType 

                       
export const useT = () => useTypedTranslation<I18nType>()

