import {
  ArrowRightIcon,
  ContextItem,
  ExpandRightIcon,

  useContextMenu,
  UserIcon,
} from '@colombalink/basedui'
import { api } from '@shared/index'
import { useNavigator } from '@shared/components/page/navigation/context'
import Logo from '@shared/assets/log.svg'
import { Link, useLocation } from 'wouter'
import React, { useEffect, useState } from 'react'
import './arrowContainer.css'
import { ColumnViewer, hasNextColumn } from './ColumnViewer'
import { useLink } from '../../../../app/routes'
import { usePageContext } from '../context'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'
import { useClientsContext, useIdentityClient } from '@shared/client/context'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Drawer = ({ index }) => {
  return (
    <>
      <div className="sm:block md:hidden">
        <MobileView />
      </div>
      <div className="hidden sm:hidden md:block">
        <ColumnViewer index={index} />
      </div>
    </>
  )
}
export default function DefaultNavigation() {
  const context = useNavigator()
  const [index, setIndex] = useState()

  const { navigation } = usePageContext()
  useEffect(() => {
    navigation.overlay.setNavigationOverlay(<Drawer index={index} />)
  }, [])

  return (
    <>
      <ToolBar setIndex={setIndex} />
    </>
  )
}

DefaultNavigation.displayName = 'DefaultNavigation'

const ToolBar = ({ setIndex }: { setIndex?: any }) => {
  return (
    <>
      <div className="relative  h-16">
        <div className="absolute m-4 flex justify-center left-0 right-0">
          <div className="left-1/2 right-1/2">
            <Logo className="w-44 h-10" />
          </div>
        </div>

        <div className="absolute right-0 m-4 flex">
          <button
            className="w-6 h-6 flex bg-blue-100 rounded-full justify-center items-center"
            onClick={useContextMenu(UserMenu, {}, { placement: 'left' })}
          >
            <UserIcon />
          </button>
        </div>
      </div>
      <div className="w-full h-10 overflow-hidden border-t border-gray-300 shadow">
        <NavBreadcrumbs setIndex={setIndex} />
      </div>

      {/* Status Bar */}
    </>
  )
}

const MobileView = () => {
  const { state, setCurrentDrawerItem } = useNavigator()

  return (
    <div className="lg:hidden">
      {/*{state.currentDrawerItem?.parent && <BackButton />}*/}
      <div className="flex flex-col space-y-2 justify-between m-4">
        <ChildItems />
      </div>
    </div>
  )
}

const BackButton = () => {
  const { state, setCurrentDrawerItem } = useNavigator()

  return (
    <div
      onClick={() => {
        if (state.currentDrawerItem?.parent) {
          setCurrentDrawerItem(state.currentDrawerItem.parent)
        }
      }}
      className="flex flex-row items-center cursor-pointer"
    >
      <div className="p-3 transform rotate-180 transition rounded duration-500 ease-in-out">
        <ArrowRightIcon className="h-5 w-5" />
      </div>
    </div>
  )
}

export const ChildItems = () => {
  const { state, setCurrentDrawerItem, setNavigationVisibility } =
    useNavigator()

  if (!state.currentDrawerItem) return <LoadingIcon />

  if (!hasNextColumn(state.currentDrawerItem)) {
    setCurrentDrawerItem(state.currentDrawerItem.parent)
  }

  const SubItem = ({ item }) => {
    let className = 'mx-0.5 p-3 text-base flex'
    className += item.link ? ' cursor-pointer underline italic' : ''

    return (
      <div className="flex flex-row justify-between items-center">
        <div className={className}>
          <div className="flex flex-row items-center mx-5">{item?.icon}</div>
          {item.link ? (
            <Link
              className='className="w-48 overflow-hidden whitespace-nowrap text-ellipsis p-1"'
              onClick={() => setNavigationVisibility(false)}
              href={item.link}
            >
              {item.name}
            </Link>
          ) : (
            <div className="w-48 overflow-hidden whitespace-nowrap text-ellipsis p-1">
              {item.name}
            </div>
          )}
        </div>
        <div
          onClick={() => setCurrentDrawerItem(item)}
          className="cursor-pointer"
        >
          {Object.keys(item.subItems || {}).length > 0 &&
            hasNextColumn(item) && <ExpandRightIcon />}
        </div>
      </div>
    )
  }

  if (
    state.currentDrawerItem?.subItems &&
    Object.keys(state.currentDrawerItem.subItems).length > 0
  ) {
    return (
      <div>
        {Object.values(state.currentDrawerItem.subItems)
          .filter((subItem) => !subItem.hidden)
          .map((subItem, i) => (
            <SubItem key={i} item={subItem} />
          ))}
      </div>
    )
  } else {
    return (
      <div>
        {state.currentDrawerItem.link &&
          hasNextColumn(state.currentDrawerItem) && (
            <Link
              className="mx-0.5 p-3 text-base cursor-pointer underline italic"
              onClick={() => setNavigationVisibility(false)}
              href={state.currentDrawerItem.link}
            >
              {state.currentDrawerItem.name}
            </Link>
          )}
      </div>
    )
  }
}

// ////////////////////////////////////7
// User Menu
// ////////////////////////////////////7
export const UserMenu = () => {
  const { clients } = useClientsContext()
  const user = api.user.getUserNav.useLiveData()
  const useUserNotifications =
    api.globalNotification.getUserNotifications.useLiveData({
      userId: user?.data?.id,
    })
  const unSeenNotificationCount =
    useUserNotifications?.data?.notifications.filter((n) => !n.seen)?.length
  const [, setLocation] = useLocation()

  return (
    <>
      <ContextItem
        icon={UserIcon}
        onClick={() =>
          setLocation(
            useLink('/user/:userAliasId/profile', {
              userAliasId: user.data.alias,
            })
          )
        }
      >
        {user?.data?.name}
      </ContextItem>
      {/* Set location does not work properly here. we just set the path prefix for now... /dashboard/owner/ */}

      {/* <ContextItem
        onClick={() =>
          setLocation(
            useLink('/user/:userAliasId/notifications', {
              userAliasId: user.data.alias,
            })
          )
        }
        icon={EmailIcon}
      >
        <div className="flex">
          {unSeenNotificationCount === 0
            ? 'Notifications'
            : `Notifications (${unSeenNotificationCount})`}
        </div>
      </ContextItem> */}

      <ContextItem
        onClick={async () => {
          await clients.getClient('identity').call('logout')
        }}
      >
        Logout
      </ContextItem>
    </>
  )
}

// ////////////////////////////////////
// Navigation Breadcrumbs
// ////////////////////////////////////

const MenuButton = () => {
  const { state, setPath, setNavigationVisibility } = useNavigator()
  return (
    <div className="flex flex-row items-center cursor-pointer">
      <button
        className="h-6 w-6 m-4"
        onClick={() => {
          setPath(state.activeNavPath)
          setNavigationVisibility(!state.isVisible)
        }}
      >
        <svg
          className="h-6 w-6 text-gray-500"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d={'M4 6h16M4 12h16M4 18h16'}
          />
        </svg>
      </button>
    </div>
  )
}

const CloseButton = () => {
  const { state, setPath, setNavigationVisibility } = useNavigator()
  return (
    <div className="flex flex-row items-center cursor-pointer">
      <button
        className="h-6 w-6 m-4"
        onClick={() => {
          setPath(state.activeNavPath)
          setNavigationVisibility(!state.isVisible)
        }}
      >
        <svg
          className="h-6 w-6 text-gray-500"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d={'M6 18L18 6M6 6l12 12'}
          />
        </svg>
      </button>
    </div>
  )
}

const NavBreadcrumbs = ({
  setIndex,
}: {
  setIndex: (index: number) => void
}) => {
  const { state, setPath, setNavigationVisibility } = useNavigator()
  const path = state.activeNavPath
  const [visibleLinks, setVisibleLinks] = React.useState([])

  const { navigation } = usePageContext()

  useEffect(() => {
    navigation.overlay.setIsOpen(state.isVisible)
  }, [state.isVisible])

  useEffect(() => {
    const showPath = state.isVisible ? state.activeDrawerPath : path

    const handleResize = () => {
      if (showPath.length === 0) {
        return
      }
      let currentDivWidth = 0
      let numVisibleLinks = 0

      for (let i = showPath.length - 1; i >= 0; i--) {
        const item = showPath[i]

        if (!item?.name) continue
        currentDivWidth += item.name.length * 7 + 100

        if (currentDivWidth > window.innerWidth) {
          break
        }
        numVisibleLinks++
      }

      setVisibleLinks(showPath.slice(showPath.length - numVisibleLinks))
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Initial check
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [path, state.isVisible, state.activeDrawerPath])

  const setShowPath = (path, item) => {
    setIndex(path.findIndex((navItem) => navItem.name === item.name))
    setPath(path)
    setNavigationVisibility(true)
  }

  return (
    <div className="w-full h-full flex flex-row items-center whitespace-nowrap">
      {
        <div className="absolute right-0">
          {state.isVisible && <CloseButton />}
        </div>
      }
      {state.isVisible ? <BackButton /> : <MenuButton />}

      <div className="flex flex-row ml-0 h-full items-center">
        {visibleLinks.map((item, i) => {
          if (!item.name) return null
          let className =
            'flex flex-row relative items-center last:pr-2 whitespace-nowrap text-lg mr-2 ml-2 cursor-pointer '
          className =
            visibleLinks.length - 1 === i
              ? className + 'text-gray-700 font-bold'
              : className + 'text-gray-400 font-semibold'
          return (
            <div className={className} key={i}>
              {item.link ? (
                <Link
                  className={className}
                  onClick={() => setNavigationVisibility(false)}
                  href={item.link}
                >
                  {item.name}
                </Link>
              ) : (
                <div
                  className={className}
                  onClick={() => setShowPath(path, item)}
                  key={i}
                >
                  {item.name}
                </div>
              )}
              {i < visibleLinks.length - 1 && (
                <div className="arrow-container" />
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}
