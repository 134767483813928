import { AnyRouter } from '@trpc/server'
import { createRecursiveProxy } from '@trpc/server/shared'
import { createRootHooks } from '@shared/trpc/shared/createHooksInternal'

/**
 * We treat `undefined` as an input the same as omitting an `input`
 * https://github.com/trpc/trpc/issues/2290
 */
export function getQueryKey(
  path: string,
  input: unknown
): [string] | [string, unknown] {
  if (path.length) return input === undefined ? [path] : [path, input]
  return [] as unknown as [string]
}

/**
 * Create proxy for decorating procedures
 * @internal
 */
export function createReactProxyDecoration<TRouter extends AnyRouter>(
  name: string,
  hooks: ReturnType<typeof createRootHooks>
) {
  return createRecursiveProxy((opts) => {
    const args = opts.args
    const pathCopy = [name, ...opts.path]

    const [input, ...rest] = args
    const lastArg = pathCopy.pop()!
    const path = pathCopy.join('.')
    const queryKey = getQueryKey(path, input)
    // console.log('args', args);
    // console.log('name', name);
    // console.log('path', path);
    // console.log('pathCopy', pathCopy);
    // console.log(hooks);
    // console.log('lastArg', lastArg);
    //  console.log('queryKey', queryKey);
    return (hooks as any)[lastArg](queryKey, ...rest)
  })
}
