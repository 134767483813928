import { AddIcon, useToast } from '@colombalink/basedui'
import DefaultNavigation from '@shared/components/page/navigation/DefaultNavigation'
import React, { useEffect } from 'react'
import { DynamicButtonRow } from '@shared/components/core/DynamicButtonRow'
import { RowMenu } from './RowMenu'
import { Page } from '@shared/components/page/Page'
import { api } from '@shared/index'
import { useLocation } from 'wouter'
import { useTable } from '@shared/components/core/table/Table'
import AddNotificationGroupDialog from '@shared/components/monitor/group/dialogs/AddDialog'
import { MonitorMenu } from '@app/pages/Orgs/Monitor/shared/Menu'
import { GroupLandingFragment } from '@shared/components/monitor/group/LandingFragment'
import { WithI18n } from '@shared/i18n/withI18n'
import i18n from '../i18n'
import { useT } from '@app/i18n'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'

type PageProps = { params: { orgAliasId: string } }
export type PageParams = PageProps['params']

export const MonitorNotificationGroupOwnerPage = () => {
  return (
    <Page className="pl-4">
      <DefaultNavigation />
      <Main />
    </Page>
  )
}

const Main = () => {
  return (
    <WithI18n i18n={i18n}>
      <ManageNotificationGroup />
    </WithI18n>
  )
}
Main.displayName = 'Main'

MonitorNotificationGroupOwnerPage.displayName = 'OwnerPage'

const ManageNotificationGroup = () => {
  const t = useT()
  const groups = api.organization.monitor.notification.group.getAll.useLiveData()  
  return (
    <div className="flex h-full">
      <MonitorMenu page={'notificationGroups'} />

      <div className="flex flex-col overflow-auto w-full mx-4 mt-4 ">
        <GroupLandingFragment>
          <>
            <DynamicButtonRow
              buttons={[
                {
                  icon: <AddIcon />,
                  dialogComponent: <AddNotificationGroupDialog />,
                  label: t['Monitor:Add'].get() ,
                  style: { cursor: 'pointer' },
                },
              ]}
            />
            <NotificationGroupTable groups={groups} />
          </>
          </GroupLandingFragment>
      </div>
    </div>
  )
}
//ManageNotificationGroup.displayName = 'Main'

function NotificationGroupTable({ groups }) {
  const t = useT()
  const [, setLocation] = useLocation()
  const removeOrganization =
    api.organization.monitor.notification.group.delete.useMutation()
  const toast = useToast()
  const [tableData, setTableData] = React.useState([])

  const [table, TableComponent] = useTable({
    header: [
      { label: t['Monitor:Name'].get(), key: 'name' },
      { label: t['Monitor:Description'].get(), key: 'description' },
      { label: t['Monitor:NotificationGroupType'].get(), key: 'notificationGroupType' },
      { label: t['Monitor:Value'].get(), key: 'values' },
      { label: t['Monitor:Action'].get(), key: 'action' },
],
    data: tableData,
  })

  useEffect(() => {
    if (groups.data) {
      setTableData(
        groups.data.map((group) => {
          return {
            ...group,
            action: <RowMenu group={group} isOwner={true}/>,
            customAttribute: {},
            values: group.values.join(', '),
          }
        })
      )
    }
  }, [groups.data])

  if (groups.loading) {
    return (
      <>
        {t['Monitor:Loading'].get()} <LoadingIcon />
      </>
    )
  }
  return <>{TableComponent}</>
}
