import { useEffect, useState } from 'react'
import { api, createTrpcReactClient } from '@shared/index'
import { useLocation, useRouter } from 'wouter'
import { useClientsContext } from '@shared/client/context'
import { log } from '@shared/logger'
import { useAuthState } from '@shared/client/useAuthState'
import { OperationScope } from '@shared/basedLink'

export const WithTrpcProvider = ({ children }) => {
  const { clients } = useClientsContext()
  const scope = getScope(useRouter(), useAuthState('app'), useLocation()[0])
  const [trpcClient, setClient] = useState(createTrpcReactClient(clients.getClient('app'), "trpc-default", {scope}))
  return <api.Provider scope={scope} client={trpcClient} setClient={setClient} >{children}</api.Provider>
}

export const SetTrcpClientOptions = ({ children }) => {
  const { clients } = useClientsContext()
  const context = api.useContext()
  const authState = useAuthState('app')
  const router = useRouter()
  const [location] = useLocation()
  useEffect(() => {
    const scope = getScope(router, authState, location)
    log.debug("Update trpc client with userId" + scope?.userId)
    context.setClient(createTrpcReactClient(clients.getClient('app'), "trpc-default", {scope}))
    context.scope.set(scope)
  }, [
    location,
    authState,
    authState.userId
  ])
  return <>{children}</>
}


function getScope(router, authState, location) {
  const options = { orgAliasId: undefined, projectAliasId: undefined, userId: undefined }

  const maybeOrgId = router.matcher('/org/:orgAliasId/:rest*', location)
  if (maybeOrgId[1] && maybeOrgId[1].orgAliasId) {
    options.orgAliasId = `org/${maybeOrgId[1].orgAliasId}`
  }
  const maybeProjectId = router.matcher('/org/:orgAliasId/project/:projectAliasId/:rest*', location)
  if (maybeProjectId[1] && maybeProjectId[1].projectAliasId) {
    options.projectAliasId = `org/${maybeProjectId[1].orgAliasId}/project/${maybeProjectId[1].projectAliasId}`
  }

  options.userId = authState.userId

  return options as OperationScope
}