import {
  LoadingIcon,
  Text,
} from '@colombalink/basedui'
import { api } from '@shared/index'
import { useEffect, useRef, useState } from 'react'
import { Area, AreaChart, YAxis } from 'recharts'
import { StatsDetailsTable } from '@shared/components/core/util/StatsDetailsTable'
import { useProjectDashboardContext } from '@app/pages/Orgs/Project/DashboardContext'
import { statusColors } from '@styles/colors'
import { hsvToRgb } from '@shared/utils/colors/hsvToRgb'
import { asRgbString } from '@shared/utils/colors/asRgbString'

export const StatsCardMinMaxMean = ({
  interval,
  className,
  propertyKey,
  thingId,
  thingName,
  thingAlias,
}: {
  thingId: string
  interval: { label: string; value: '-24h' | '-7d' | '-30d' }
  className?: string
  propertyKey: string
  thingName: string
  thingAlias?: string
}) => {

  const { useThingProperty } = useProjectDashboardContext()

  const status = useThingProperty(thingId, propertyKey).getStatus()
  const property = useThingProperty(thingId, propertyKey).getProperty()
  const { idealRange, unit } = property

  // Statitics data
  const { loading: isLoadingStats, data: statsData } =
    api.event.getEventStatsById.useLiveData({
      thingId: thingId,
      interval: interval.value,
      influxFields: [propertyKey],
    })
  // Event data for the chart
  const parentDiv = useRef()
  const [chartDimensions, setChartDimensions] = useState({
    width: 0,
    height: 0,
  })

  // Handle the initial dimensions
  useEffect(() => {
    if (!parentDiv.current) return
    setChartDimensions(parentDiv.current.getBoundingClientRect())
  }, [parentDiv.current])

  // Handle the dimensions on resize
  useEffect(() => {
    const handleResize = () => {
      if (!parentDiv.current) return
      setChartDimensions(parentDiv.current.getBoundingClientRect())
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const getEvents =
    interval.value === '-30d'
      ? api.event.getLast30dEvents
      : interval.value === '-7d'
        ? api.event.getLast7dEvents
        : api.event.getLast24hEvents

  const chartData = getEvents.useData({
    thingId: thingId,
    influxFields: [propertyKey],
    limit: 6,
  })

  const { data: eventsData } = chartData

  const statusColor = statusColors[status]?.hsv
  const statusColorRgb = asRgbString(hsvToRgb(statusColor[0], statusColor[1], statusColor[2] - 20))
  const statusColorLightRgb = asRgbString(hsvToRgb(statusColor[0], statusColor[1], statusColor[2] + 20))
  const ultraLight = asRgbString(hsvToRgb(statusColor[0], 5, 95))


  return (
    <div ref={parentDiv} className={className} style={{ minWidth: '200px', minHeight: '180px' }}>
      <div className="relative h-full w-full" style={{ minHeight: '180px' }}>
        <div className="absolute z-10 p-6 h-full w-full">
          <div className="flex flex-col h-full">
            <div className="flex flex-row justify-between ">
              <div>
                <h3 className="text-lg font-medium text-gray-900">
                  {property.label.en} 
                </h3>
                <br />
                <Text size="18px" weight={600} />
                <h4 className="text-sm font-medium text-gray-500">
                  {interval.label}
                </h4>
              </div>
              {/* <GraphIcon
                onClick={async () =>
                  open(
                    <GraphViewDialog
                      thingId={thingId}
                      influxFields={[property]}
                      thingName={thingName}
                    />
                  )
                }
                className="h4 w-4 cursor-pointer"
              /> */}
            </div>
            {isLoadingStats ? (
              <LoadingIcon />
            ) : (
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <StatsDetailsTable
                  unit={unit}
                  statsData={statsData}
                  property={propertyKey}
                />
              </div>
            )}
          </div>
        </div>

        <div className="absolute z-0">
          <AreaChart
            width={chartDimensions.width}
            height={chartDimensions.height}
            data={eventsData}
            margin={{ top: 20, right: 0, left: 0, bottom: 0 }}
          >
            <YAxis domain={[idealRange?.min || 0, idealRange?.max || 100]} hide />
            <defs>
              <linearGradient id={`color${status}`} x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={statusColorRgb} stopOpacity={0.3} />
                <stop offset="95%" stopColor={statusColorRgb} stopOpacity={0} />
              </linearGradient>
            </defs>
            <Area
              type="monotone"
              dataKey={propertyKey}
              stroke={statusColorRgb}
              fillOpacity={1}
              fill={`url(#color${status})`}
            />
          </AreaChart>
        </div>
      </div>
    </div>
  )
}
