
import { useEffect, useState } from 'react'
import { AddIcon, Button, Input, Spacer, Tab, Tabs, Text, Toast, useToast} from '@colombalink/basedui'
import { api } from '@shared/index'
import { z } from 'zod'
import { useTable } from '@shared/components/core/table/Table'
import { useT } from '@app/i18n'

const NOTIFICATION_GROUP = "notification_user_quota"

export const EmailSelection = ({ values, onChange }: { values: string[], onChange: (value: string[]) => void }) => {
    const t = useT()
    return (
      <div className='mt-4'>
        <Tabs >
        <Tab label={t['Monitor:AddCustomEmail'].get()}>
            <Spacer />
            <CustomEmail
              values={values}
              onChange={onChange}
            ></CustomEmail>
          </Tab>
        
          <Tab label={t['Monitor:UserSearch'].get()}>
            <SearchUser
              values={values}
              onChange={onChange}
            ></SearchUser>
          </Tab>
  
        </Tabs>
  
        <EmailTable
          values={values}
          onChange={onChange}
        />
      </div>
    )
  }

  const CustomEmail = ({ values, onChange }: { values: string[], onChange: (value: string[]) => void }) => {
    const t = useT()
    const [email, setEmail] = useState<string>("")
    const [isValid, setIsValid] = useState<boolean>(false)
    const org = api.organization.quotas.get.useLiveData({ quotaType: NOTIFICATION_GROUP});

    const toast = useToast()
  
    return (
      <div>
        <Text style={{ paddingLeft: '5px' }}>{t["Monitor:EnterEmail"].get()}</Text>
        <div className="flex flex-row justify-between align-middle items-center">
          <Input
            value={email}
            style={{ width: '90%' }}
            placeholder={t['Monitor:EnterEmail'].get()}
            type="email"
            onChange={(email) => {
              setEmail(email)
              try {
                z.string().email().parse(email);
                setIsValid(true)
              } catch (e) {
                setIsValid(false)
              }
  
            }}
            error={(email) => {
              try {
                z.string().email().parse(email);
                return undefined
              } catch (e) {
                return t['Monitor:PleaseEnterAValidEmail'].get()
              }
            }}
          />
          <Button
            keyboardShortcut='Shift+Enter'
            disabled={!isValid}
            onClick={() => {
              const isNew = values.find((value) => value === email) === undefined
              // TODO: use the quote of the max number of notification users 
              if (isNew && values.length < org.data.quotas.limit ) {
                values.push(email)
                onChange([...values])
                setEmail("")
              } else {
                setEmail("")
                toast.add(
                  // todo: add translation
                  <Toast
                    label={`max ${org.data.quotas.limit} `+t['Monitor:MaxReached'].get()}
                    type="error"
                  />
                )
              }
            }}>
            <AddIcon className="cursor-pointer" />
          </Button>
        </div>
      </div>
    )
  
  }

  const EmailTable = ({ values, onChange }: { values: string[], onChange: (values: string[]) => void }) => {
    const [tableData, setTableData] = useState([])
    const t = useT()
    const [table, TableComponent] = useTable({
      header: [
        { label: t['Monitor:Email'].get(), key: 'email' },
        { label: t['Monitor:Action'].get(), key: 'action' },
      ],
      data: tableData,
    })
  
    useEffect(() => {
      // TODO: this is where we would need to decrement the count
      if (values) {
        setTableData(
          values.map((value) => {
            return {
              email: value,
              action: (<Button onClick={() => {
                const newValues = values.filter((v) => v !== value)
                onChange(newValues)
              }}>{t['Monitor:Remove'].get()}</Button>),
              customAttribute: {},
            }
          })
        )
      }
    }, [values])
  
  
    return TableComponent
  }


const SearchUser = ({ values, onChange }: { values: string[], onChange: (value: string[]) => void }) => {
    const t = useT()
    const users = api.user.getAllOrgUsers.useLiveData()
    const org = api.organization.quotas.get.useLiveData({ quotaType: NOTIFICATION_GROUP});
    const [email, setEmail] = useState<string>("")
    const [isValid, setIsValid] = useState<boolean>(false)
    const [search, setSearch] = useState<string>("")
  
    const [options, setOptions] = useState<User[]>([])
    const [topMatches, setTopMatches] = useState<User[]>([])

    const toast = useToast()
  
    useEffect(() => {
      if(search.length < 3) {
        setTopMatches([])
        return 
      }
      if (users.data) {
        setTopMatches(findTopMatches(search, options))
      }
    }, [search, options])
  
  
    useEffect(() => {
      if (users.data) {
        setOptions(users.data.filter((u) => !values.includes(u.email)))
      }
    }, [values, users.data])
  
    return (
      <div>
        <div className="flex flex-col justify-between align-middle items-center mt-4">
          <Input
            value={email}
            style={{ width: '90%' }}
            placeholder={t['Monitor:SearchForUser'].get()}
            onChange={setSearch}
          />
  
          <div className="p-4 w-full cursor-pointer">
            <ul className="space-y-1">
              {topMatches.map(user => (
                <li
                  onClick={() => {
                    const isNew = values.find((value) => value === user.email) === undefined
                    if (isNew && values.length < org.data.quotas.limit) {
                      values.push(user.email)
                      onChange([...values])
                      setEmail("")
                    } else {
                      setEmail("")
                      toast.add(
                        <Toast
                          label={`max ${org.data.quotas.limit} `+t['Monitor:MaxReached'].get()}
                          type="error"
                        />
                      )
                    }
                  }}
                  key={user.id}
                  className="p-4 border rounded-md shadow-md transition-transform transform hover:scale-105"
                  style={{
                    boxShadow: '0px 0px 15px #0000001f, 0px 10px 10px #0000000f'
                  }}
                >
                  <p className="font-bold text-xl">{user.name}</p>
                  <p className="text-gray-600">{user.email}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    )
  
  }

  const levenshteinDistance = (s: string, t: string): number => {
    if (!s.length) return t.length;
    if (!t.length) return s.length;
    const arr: number[][] = [];
    for (let i = 0; i <= t.length; i++) {
      arr[i] = [i];
      for (let j = 1; j <= s.length; j++) {
        arr[i][j] =
          i === 0
            ? j
            : Math.min(
              arr[i - 1][j] + 1,
              arr[i][j - 1] + 1,
              arr[i - 1][j - 1] + (s[j - 1] === t[i - 1] ? 0 : 1)
            );
      }
    }
    return arr[t.length][s.length];
  };
  
  type User = {
    id: string;
    name: string;
    email: string;
  };
  
  const findTopMatches = (
    input: string,
    list: User[],
    topN: number = 5
  ): User[] => {
    const distances = list.map(user => {
      // Calculate the distance for both name and email, and use the minimum
      const nameDistance = levenshteinDistance(input, user.name);
      const emailDistance = levenshteinDistance(input, user.email);
      return {
        user,
        distance: Math.min(nameDistance, emailDistance)
      };
    });
  
    // Sort by distance
    distances.sort((a, b) => a.distance - b.distance);
  
    // Retrieve the topN closest matches
    const topMatches = distances.slice(0, topN).map(d => d.user);
  
    // Filter the top matches to only keep those containing the input string
    const filteredMatches = topMatches.filter(user =>
      user.name.includes(input) || user.email.includes(input)
    );
  
    return filteredMatches;
  };