function processString(inputString) {
    // Convert to lowercase
    let processedString = inputString.toLowerCase();
  
    // Replace spaces with "-"
    processedString = processedString.replace(/\s+/g, '-');
  
    // Remove special characters except "-"
    processedString = processedString.replace(/[^a-z0-9-]/g, '');
  
    return processedString;
  }

export default processString