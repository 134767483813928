import { DefaultProcedure } from '@server/app/trpc/router'
import { z } from 'zod'
import { incrementQuotaCount } from '@server/app/trpc/monidas/routers/organization/quotas/modifyCount'

export const createNotificationGroupParams = z.object({
        name: z.string({ required_error: 'Name is required' }),
        description: z.string({ required_error: 'Description is required' }),
        notificationGroupType: z.string(),
        values: z.array(z.string()),
      })

export const createNotificationGroupProcedure = (procedure: DefaultProcedure) =>
  procedure
    .input(createNotificationGroupParams)
    .mutation(async ({ ctx, input }) => {
      await incrementQuotaCount(ctx, input.values.length)      
      const res = await ctx.based.db.default.set({
        name: input.name,
        description: input.description,
        notificationGroupType: input.notificationGroupType,
        values: input.values,
        type: 'notificationGroup',
        parents: [ctx.org.id],
      })
      ctx.logger.debug('procedure::createNotificationGroup result:', res)
      // now we need to count the number of unique values and increase the count 
    })
