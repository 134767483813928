import EditNotificaitonTemplate from "@shared/components/monitor/template/dialogs/EditDialog"
import { FC } from "react"
import { OptionMenu } from "@shared/components/core/EditEntityMenu"
import { api } from "@shared/index"
import { useDialog, useToast, ContextItem, Toast, Text, EditIcon, DeleteIcon } from "@colombalink/basedui"
import { useT } from "@app/i18n"

type RowMenuProps = {
  template: ReturnType<typeof api.organization.monitor.notification.template.getAll.useLiveData>['data'][0]
} 

export const RowMenu = (props: RowMenuProps) => {

  const CategoryMenu: FC<{}> = ({ }) => {
    const t = useT()
    const { open } = useDialog()
    const toast = useToast()

    const deleteTemplate = api.organization.monitor.notification.template.delete.useMutation()

    return (
      <>
        <ContextItem
          onClick={() => {
            open(<EditNotificaitonTemplate template={props.template} />)
          }}
          icon={EditIcon}
        >
          {t['Monitor:Edit'].get()}
        </ContextItem>
        <ContextItem
          onClick={async () => {
            
            await deleteTemplate.mutateAsync({ templateId: props.template.id })

            toast.add(
              <Toast
                label={t['Monitor:RemovedTemplate'].get()}
                type="success"
                description={
                  <Text>
                    {t['Monitor:RemovedSuccess'].get()}
                  </Text>
                }
              />
            )
          }}
          icon={DeleteIcon}
        >
          {t['Monitor:Remove'].get()}
        </ContextItem>
      </>
    )
  }

  return <OptionMenu menu={CategoryMenu} style={{ width: '150px' }} />
}