import { PageRoleSwitcher } from "@shared/components/page/PageRoleSwitcher"
import {OwnerPage} from "./Page.owner"
import {SupervisorPage} from "./Page.supervisor" 

export type PageProps = {
  params: { orgAliasId: string; projectAliasId: string; thingAliasId: string }
}
export type PageParams = PageProps['params']

const DefaultPage = (props) => (<SupervisorPage {...props}/>) 
DefaultPage.displayName = 'DefaultPage'

export default (props: PageProps) => {
  return (
    <PageRoleSwitcher>
      <OwnerPage {...props} />
      <DefaultPage  {...props} />
    </PageRoleSwitcher>
  )
} 