const ProjectsMembersPageContentI18n = {

    "ProjectsMembers:OrganizationOwners": {
        en: "Organization Owners:",
        de: "Organisationseigentümer:"
    },
    "ProjectsMembers:AllProjectMembers": {
        en: "Project Members:",
        de: "Projektmitglieder:"
    },
    "ProjectsMembers:Add": {
        en: "Add",
        de: "Hinzufügen"
    },
    "ProjectsMembers:Name": {
        en: "Name",
        de: "Name"
    },
    "ProjectsMembers:Role": {
        en: "Role",
        de: "Rolle"
    },
    "ProjectsMembers:Loading": {
        en: "Loading",
        de: "Laden"
    },
    "ProjectsMembers:Edit": {
        en: "Edit",
        de: "Bearbeiten"
    },
    "ProjectsMembers:Remove": {
        en: "Remove",
        de: "Entfernen"
    },
    "ProjectsMembers:RemovedMember": {
        en: "Removed member",
        de: "Mitglied entfernt"
    },
    "ProjectsMembers:RemovedSuccessfully": {
        en: "Removed successfully",
        de: "Erfolgreich entfernt"
    },
    "ProjectsMembers:FailedToRemove": {
        en: "Failed to remove Member",
        de: "Mitglied konnte nicht entfernt werden"
    },
    "ProjectsMembers:InviteMember": {
        en: "Invite Member",
        de: "Mitglied einladen"
    },
    "ProjectsMembers:Cancel": {
        en: "Cancel",
        de: "Abbrechen"
    },
    "ProjectsMembers:Action": {
        en: "Action",
        de: "Aktion"
    },
    "ProjectsMembers:LoadingMembers": {
        en: "Loading Members",
        de: "Mitglieder werden geladen"
    },
    "ProjectsMembers:MemberAdded": {
        en: "Member added",
        de: "Mitglied hinzugefügt"
    },
    "ProjectsMembers:Member": {
        en: "Member",
        de: "Mitglied"
    },
    "ProjectsMembers:AddedToProject": {
        en: "added to project",
        de: "zu Projekt hinzugefügt"
    },
    "ProjectsMembers:FailedToAddMember": {
        en: "Failed to add member",
        de: "Mitglied konnte nicht hinzugefügt werden"
    },
    "ProjectsMembers:Error": {
        en: "Error",
        de: "Fehler"
    },
    "ProjectsMembers:TypeMemberNameHere": {
        en: "Type member name here",
        de: "Geben Sie hier den Mitgliedsnamen ein"
    },
    "ProjectsMembers:EditMember": {
        en: "Edit Member",
        de: "Mitglied bearbeiten"
    },
    "ProjectsMembers:Save": {
        en: "Save",
        de: "Speichern"
    },
    "ProjectsMembers:MemberName": {
        en: "Member Name",
        de: "Mitgliedsname"
    },
    "ProjectsMembers:Attention": {
        en: "Attention: Changing the role here will change the role project-wide.",
        de: "Achtung: Die Änderung der Rolle hier wird die Rolle projektweit ändern."
    },
    "ProjectsMembers:RoleUpdated": {
        en: "Role updated",
        de: "Rolle aktualisiert"
    },
    "ProjectsMembers:FailedToUpdateRole": {
        en: "Failed to update role",
        de: "Rolle konnte nicht aktualisiert werden"
    },
    "ProjectsMembers:SetRoleTo": {
        en: "Set role successfully to: ",
        de: "Rolle erfolgreich geändert auf: "
    },





}

export default ProjectsMembersPageContentI18n
export type ProjectsMembersPageContentI18nType = typeof ProjectsMembersPageContentI18n
