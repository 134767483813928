import { Button, MoreIcon, Style, useContextMenu } from "@colombalink/basedui"

export const OptionMenu = ({ menu, style }: {menu: Parameters<typeof useContextMenu>[0], style?: Style } ) => {
    const openMenu = useContextMenu(menu, {}, { position: 'left', style: { width: '100px', cursor: 'pointer', ...style } })
    return <div className='w-full flex justify-end p-4'><Button
        onClick={openMenu}
        ghost
        icon={<MoreIcon />}
    />
  </div>
}