import { Error } from '@shared/components/errors/Error'
import ErrorBoundary from './ErrorBoundary';
import { useEffect, useRef } from 'react';
import BaseError from './ErrorTemplate.svg'
import DefaultNavigation from '@shared/components/page/navigation/DefaultNavigation'
import { WithI18n } from '@shared/i18n/withI18n';
import i18n from './i18n';
import { useT } from '@app/i18n';


function ErrorPage({ message, code }) {

  const t = useT()

  const ref = useRef(null)

  useEffect(() => {
    if (ref.current) {
      ref.current.getElementById("errorCode").innerHTML = code || ''
    }
  }, [code, message])
  return (
    <div className="min-h-100 flex items-center justify-center">
      <div className="bg-white w-full">
        <h2 className="text-xl font-bold text-center mb-4">

          Oops!
          {
            code === '404' ? t["Errors:PageNotFound"].get() : t["Errors:SorrySomethingWentWrong"].get()
          }

        </h2>
        <BaseError ref={ref} className="w-full"></BaseError>
        {
          code === '404' ?
            <p className="text-center text-txt mb-4"> {t["Errors:ThePageYouAreLookingForDoesNotExist"].get()} </p>
            : <p className="text-center text-txt mb-4">{message}</p>
        }

      </div>
    </div>
  )
}

function DefaultErrorPage(message: string, code: string) {
  return (
    <div className="w-screen h-screen z-10 absolute bg-white">
      <div
        className="border-b-1 border-gray-300 shadow "
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
        }}
      >
        <div className="border-b-1 border-gray-300 shadow">
          <DefaultNavigation />
        </div>
        <div className="w-screen h-[2px]" />
        <div className="flex overflow-auto h-full">
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div className={`w-full h-full p-4`}>
              <ErrorPage message={message} code={code} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default (props) => (
  <WithI18n i18n={i18n}>
    <DefaultErrorPage {...props} />
  </WithI18n>
)
