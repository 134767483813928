const ProjectsEditorPageContentI18n = {


    "ProjectsEditor:Create" : {
        en: "Create",
        de: "Erstellen",
    },
    "ProjectsEditor:Edit" : {
        en: "Edit",
        de: "Bearbeiten",
    },
    "ProjectsEditor:Save" : {
        en: "Save",
        de: "Speichern",
    },
    "ProjectsEditor:Cancel" : {
        en: "Cancel",
        de: "Abbrechen",
    },
    "ProjectsEditor:Name" : {
        en: "Name",
        de: "Name",
    },
    "ProjectsEditor:Loading" : {
        en: "Loading",
        de: "Laden",
    },
    "ProjectsEditor:DeleteProject" : {
        en: "Delete Project",
        de: "Projekt löschen",
    },
    "ProjectsEditor:DeleteProjectConfirmation" : {
        en: "Are you sure you want to delete the project: ",
        de: "Möchten Sie das folgende Projekt wirklich löschen: ",
    },
    "ProjetsEditor:Delete" : {
        en: "Delete",
        de: "Löschen",
    },
    "ProjectsEditor:DeleteProjectSuccess" : {
        en: "Project deleted successfully",
        de: "Projekt erfolgreich gelöscht",
    },
    "ProjectsEditor:ProjectDeleted" : {
        en: "Project deleted",
        de: "Projekt gelöscht",
    },



}

export default ProjectsEditorPageContentI18n
export type ProjectsEditorPageContentI18nType = typeof ProjectsEditorPageContentI18n
