import { FC, useState } from "react"
import { OptionMenu } from "@shared/components/core/EditEntityMenu"
import { api } from "@shared/index"
import { useDialog, useToast, ContextItem, Toast, Text, EditIcon, DeleteIcon } from "@colombalink/basedui"
import { useLocation } from "wouter"
import EditIntegration from "./EditDialog"
import { useT } from "@app/i18n"

export const RowMenu = ({ integration }: { integration: { id: string, name: string, url: string, token: string, type: string } }) => {

  const CategoryMenu: FC<{}> = ({ }) => {
    const t = useT()
    const { open } = useDialog()
    const [, setLocation] = useLocation()
    const toast = useToast()
    const [hidden, setHidden] = useState('hidden')

    const deleteIntegration = api.integrations.delete.useMutation()

    return (
      <>
        <ContextItem
          onClick={() => {
            open(<EditIntegration integration={integration} />)
          }}
          icon={EditIcon}
        >
          {t['OrgIntegration:Edit'].get()}
        </ContextItem>
        <ContextItem
          onClick={async () => {

            await deleteIntegration.mutateAsync({ integrationId: integration.id })

            toast.add(
              <Toast
                label={t['OrgIntegration:DeletedIntegration'].get()}
                type="success"
                description={
                  <Text>
                    {t['OrgIntegration:DeletedIntegrationText'].get()}
                  </Text>
                }
              />
            )
          }}
          icon={DeleteIcon}
        >
          {t['OrgIntegration:Delete'].get()}
        </ContextItem>
      </>
    )
  }

  return <OptionMenu menu={CategoryMenu} style={{ width: '120px' }} />
}