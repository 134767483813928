import { FC, useState } from "react"
import { OptionMenu } from "@shared/components/core/EditEntityMenu"
import { api } from "@shared/index"
import { useDialog, useToast, ContextItem, Toast, Text, EyeIcon, EditIcon, DeleteIcon } from "@colombalink/basedui"
import { useLocation } from "wouter"
import ConfirmationDialog from "@shared/components/core/ConfirmationDialog"
import { useT } from "@app/i18n"

export const RowMenu = ({ apiToken }: { apiToken: { id: string, name: string } }) => {

  const CategoryMenu: FC<{}> = ({ }) => {
    const t = useT()
    const { open } = useDialog()
    const [, setLocation] = useLocation()
    const toast = useToast()
    const [hidden, setHidden] = useState('hidden')

    const deleteApiToken = api.organization.apiTokens.deleteById.useMutation()

    return (
      <>
        <ContextItem
          onClick={async () => {

            open(<ConfirmationDialog
              label={t['SettingsApiTokens:DeleteApiToken'].get()} 
              message={ t["SettingsApiTokens:DeleteApiTokenConfirmation"].get()  + apiToken.name + "?"}
              confirm={t["SettingsApiTokens:Delete"].get()}
              onConfirm={async () => {
                await deleteApiToken.mutateAsync({ apiTokenId: apiToken.id })
                toast.add(
                  <Toast
                    label={t['SettingsApiTokens:TokenDeleted'].get()}
                    type="success"
                    description={
                      <Text>
                        {t['SettingsApiTokens:TokenDeletedMessage'].get()}
                      </Text>
                    }
                  />
                )

              }}
            ></ConfirmationDialog>)
          }}
          icon={DeleteIcon}
        >
          {t['SettingsApiTokens:Delete'].get()}
        </ContextItem>
      </>
    )
  }

  return <OptionMenu menu={CategoryMenu} style={{ width: '150px' }} />
}