import { Checkbox} from '@colombalink/basedui'
import React, { DOMAttributes, useEffect } from 'react'
import {
  Index,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from './table'
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { cn } from '@shared/utils/tw'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'
// import {
//   Index,
//   Index as BaseTable,
//   TableBody,
//   TableCell,
//   TableHead,
// } from '@shared/components/core/table'
// import {
//   flexRender,
//   getCoreRowModel,
//   useReactTable,
// } from '@tanstack/react-table'
// function TableHeader({ header }: { header: { label: string; key: string }[] }) {
//   return (
//     <thead className="">
//       <tr>
//         {header?.map((item, index) => (
//           <th
//             key={index}
//             scope="col"
//             className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
//           >
//             {item?.label}
//           </th>
//         ))}
//       </tr>
//     </thead>
//   )
// }

/**
 * @description This component is used to render a table
 * Usage:
 *
 ```tsx
 <Table
 header={[
              { label: 'Name', key: 'user.name' },
              { label: 'Email', key: 'user.email' },
              { label: 'Friend', key: 'friend' },
              { label: 'Actions', key: 'actions' },
            ]}
 data={[
              {
                user: { name: 'John', email: 'john@a.com' },
                friend: 'Bob',
                actions: (
                  <Button onClick={() => console.log('John')}>
                    Console log...
                  </Button>
                ),
                customAttribute: {
                  row: {
                       className: isSelected ? 'bg-blue-500 text-white' : '',
                       onClick: () => console.log("row on click event"),
                  },
                  friend: {
                    className: 'bg-red-500',
                    style: { color: 'white' },
                    onClick: () =>
                      console.log('invoke friend from custom attribute'),
                  },
                  'user.name': {
                    className: 'bg-green-500',
                  },
                },
              },
              {
                user: { name: 'Alice', email: 'alice@a.com' },
                friend: 'Bob',
                actions: (
                  <Button onClick={() => console.log('Alice')}>
                    Console log...
                  </Button>
                ),
              },
            ]}
 />
 ```
 */
export const OldCompatTable = ({
  header,
  data,
  loading,
  className,
}: {
  loading?: boolean
  header: { label: string; key: string; language?: 'en' | 'de' }[]
  data: any[]
  className?: string
}) => {
  const [rowSelection, setRowSelection] = React.useState({})

  const columns = React.useMemo(
    () => [
      {
        id: 'select',
        cell: ({ row }) => (
          <Checkbox
            value={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        ),
      },
      ...header.map((item) => ({
        accessorKey: item.key,
        header: item.label,
        cell: (row) => {
          const cellContent = row.getValue(item.key)

          if (item.language && cellContent?.[item.language]) {
            return cellContent[item.language]
          }

          return cellContent
        },
      })),
    ],
    [header]
  )

  const table = useReactTable({
    data,
    columns,
    state: { rowSelection },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <div className={cn(className)}>
      <Index className="max-h-80">
        <TableHeader
          className="sticky top-0 z-50 bg-white"
          style={{
            boxShadow: '0px 4px 0px rgb(239, 239, 239)',
          }}
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id} className="font-bold">
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody className="border-t-4 ">
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => {
              return (
                <TableRow
                  className="bg-white"
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  {...row?.original?.customAttribute?.row}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                      {...row?.original?.customAttribute?.[cell.column.id]}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              )
            })
          ) : (
            <TableRow>
              {/* <TableCell colSpan={columns.length} className="h-24 text-center"> */}
              {/*  No results. */}
              {/* </TableCell> */}
            </TableRow>
          )}
        </TableBody>
      </Index>
    </div>
  )
}
// export type TableRow = Parameters<typeof TableRow>[0]['row']
//
// function getCellContent(
//   row: any,
//   header: { label: string; key: string; language?: 'en' | 'de' }
// ) {
//   let cellContent = ''
//
//   if (header.key.split('.').length > 1) {
//     const keys = header.key.split('.')
//     cellContent = row[keys[0]]
//     for (let i = 1; i < keys.length; i++) {
//       cellContent = cellContent?.[keys[i]]
//     }
//   } else {
//     cellContent = row[header.key]
//   }
//
//   if (header.language && cellContent?.[header.language]) {
//     cellContent = cellContent?.[header.language]
//   }
//
//   return cellContent
// }
//
// function TableRow({
//   headers,
//   row,
//   rowNumber,
// }: {
//   headers: { label: string; key: string; language?: 'en' | 'de' }[]
//   row: any
//   customAttribute?: { [key: string]: DOMAttributes<HTMLTableDataCellElement> }
//   rowNumber: number
// }) {
//   return (
//     <tr className={rowNumber % 2 === 0 ? undefined : ''}>
//       {headers?.map((header, index) => {
//         const cellContent = getCellContent(row, header)
//         return (
//           <td
//             key={index}
//             className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
//             {...row.customAttribute?.[header.key]}
//           >
//             {cellContent}
//           </td>
//         )
//       })}
//     </tr>
//   )
// }
//
// const loadingRows = () => {
//   return (
//     <tr>
//       <td className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
//         <LoadingIcon />
//       </td>
//     </tr>
//   )
// }
