import {
  Dialog,
  Input,
  
  MultiSelect,
  Select,
  Spacer,
  Text,
  Toast,
  useToast,
} from '@colombalink/basedui'
import React from 'react'
import { api } from '@shared/index'
import { ZodInputValidator } from '@shared/zod/InputValidator'
import { createActionParams } from '@server/app'
import { WithI18n } from '@shared/i18n/withI18n'
import i18n from '../../i18n'
import { useT } from '@app/i18n'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'

type EditProps = {
  action: ReturnType<
    typeof api.organization.monitor.action.getAll.useLiveData
  >['data'][0]
  
}


const EditAction = (props: EditProps) => {
  const t = useT()
  const updateAction = api.organization.monitor.action.update.useMutation()
  const [updateActionArgs, setUpdateActionArgs] = React.useState({
    ...props.action,
    notificationGroups: props.action.notificationGroups.map(i => i.id),
    notificationTemplate: props.action.notificationTemplate.id
  })


    const notificationGroups =
      api.organization.monitor.notification.group.getAll.useLiveData()
    const notificationTemplates =
      api.organization.monitor.notification.template.getAll.useLiveData()
  const toast = useToast()

  if (!updateActionArgs || !notificationGroups.data || !notificationTemplates.data) {
    return <LoadingIcon />
  }
  const handleOnChange = (key, value) => {
    setUpdateActionArgs((prev) => ({
      ...prev,
      [key]: value,
    }))
  }

  const triggers = ['idle', 'alert', 'alarm']
  const triggerOptions= [t["MonitorAction:Idle"].get(), t["MonitorAction:Alert"].get(), t["MonitorAction:Alarm"].get()]


  return (
    <Dialog label={t["MonitorAction:EditAction"].get()}>
      <div className="flex flex-col overflow-y-auto">
        <Input
          value={updateActionArgs.name}
          label={t["MonitorAction:Name"].get()}
          placeholder={t["MonitorAction:EnterNameHere"].get()}
          type="text"
          pattern="true"
          error={(value) =>
            ZodInputValidator(createActionParams, updateActionArgs, 'name')
          }
          onChange={(name) => handleOnChange('name', name)}
        />
        <Spacer />
        <Select
          label= {t["MonitorAction:SelectTrigger"].get()}
          value={updateActionArgs.trigger}
          options={triggerOptions}
          onChange={(trigger) => handleOnChange('trigger', triggers[(triggerOptions.indexOf(trigger.toString()))])}
 
        />
        <Spacer />
        <Select
          label= {t["MonitorAction:SelectTriggerType"].get()}
          value={updateActionArgs.triggerType}
          options={['always', 'once']}
          onChange={(triggerType) => handleOnChange('triggerType', triggerType)}
        />
        <Spacer />
        <MultiSelect
          values={updateActionArgs.notificationGroups}
          label={t["MonitorAction:SelectGroups"].get()}
          options={notificationGroups.data.map(i => ({value: i.id, label: i.name}))}
          filterable="create"
          onChange={(groups) => handleOnChange('notificationGroups', groups)}
        />
        <Spacer />
        <Select
          value={updateActionArgs.notificationTemplate}
          label={t["MonitorAction:SelectTemplate"].get()}
          options={notificationTemplates.data?.map((item) => ({
            value: item.id, label: item.name
          }))}
          onChange={(notificationTemplate) =>
            handleOnChange('notificationTemplate', notificationTemplate)
          }
        />

        <Dialog.Buttons>
          <Dialog.Cancel displayShortcut={false}>{t["MonitorAction:Close"].get()}</Dialog.Cancel>
          <Dialog.Confirm
            displayShortcut={false}
            onConfirm={async () => {
              try {
                await updateAction.mutateAsync(updateActionArgs)
                toast.add(
                  <Toast
                    label={t["MonitorAction:UpdatedAction"].get()}
                    type="success"
                    description={
                      <Text>
                        {t["MonitorAction:ActionEditedSuccessfully"].get()}
                      </Text>
                    }
                  />
                )
              } catch (e) {
                toast.add(
                  <Toast
                    label={t["MonitorAction:FailedToEditAction"].get()}
                    type="error"
                    description={
                      <Text style={{ whiteSpace: 'normal' }}>{e.message}</Text>
                    }
                  />
                )
                throw e
              }
            }}
          >
            {t["MonitorAction:Save"].get()}
          </Dialog.Confirm>
        </Dialog.Buttons>
      </div>
    </Dialog>
  )
}

export default (props)=> (
  <WithI18n i18n={i18n}>
    <EditAction {...props}  />
  </WithI18n>
)