import {  useState, useEffect } from 'react'
import { BasedClient } from '@based/client'
import { useClientsContext } from './context'

export const useConnected = () => {
  const client: BasedClient = useClientsContext().clients.getClient('identity') 
  const [connected, setConnected] = useState(client.connected)

  useEffect(() => {
    if (client) {
      setConnected(client.connected)
      const listener = () => {
        setConnected(client.connected)
      }
      client.on('disconnect', listener)
      client.on('reconnect', listener)
      client.on('connect', listener)
      return () => {
        client.off('disconnect', listener)
        client.off('reconnect', listener)
        client.off('connect', listener)
      }
    }
  }, [client])

  return { connected }
}
