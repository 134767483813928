const env = import.meta.env

console.log('env: ', env)
export const config = env.VITE_MONIDAS_DEPLOYMENT === 'test' ? loadTestServerConfig() : {
  deployment: env.VITE_MONIDAS_DEPLOYMENT,
  services: {
    hub: {
      url: {
        ws: env.VITE_MONIDAS_HUB_URL_WS,
        http: env.VITE_MONIDAS_HUB_URL_HTTP
      }
    },
    app: {
      url: {
        ws: env.VITE_MONIDAS_APP_URL_WS,
        http: env.VITE_MONIDAS_APP_URL_HTTP
      }
    },
    identity: {
      url: {
        ws: env.VITE_MONIDAS_IDENTITY_URL_WS,
        http: env.VITE_MONIDAS_IDENTITY_URL_HTTP
      }
    }
  }
}


function loadTestServerConfig() {
  const defaultConfig = {
    deployment: env.VITE_MONIDAS_DEPLOYMENT,
    services: {
      hub: {
        url: {
          ws: env.VITE_MONIDAS_HUB_URL_WS,
          http: env.VITE_MONIDAS_HUB_URL_HTTP
        }
      },
      app: {
        url: {
          ws: env.VITE_MONIDAS_APP_URL_WS,
          http: env.VITE_MONIDAS_APP_URL_HTTP
        }
      },
      identity: {
        url: {
          ws: env.VITE_MONIDAS_IDENTITY_URL_WS,
          http: env.VITE_MONIDAS_IDENTITY_URL_HTTP
        }
      }
    }
  }

  try {
    const testConf = JSON.parse(localStorage.getItem('@test-server-config'))
    return {
      ...defaultConfig,
      ...testConf
    }
  } catch (e) {
    console.error('Failed to load test server config', e)
  }

  return defaultConfig
}
