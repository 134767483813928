import { api } from '@shared/index'
import { useChartContext } from './context'
import { useProjectExplorerContext } from '@app/pages/Orgs/Project/ExplorerFragment/ExplorerContext'
import SingleSeriesLineChart from './SingleSeriesLineChart'
import { WithI18n } from '@shared/i18n/withI18n'
import i18n from '../i18n'
import { useT } from '@app/i18n'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'
import { useEffect, useState } from "react";

const _ExplorerTimeSeriesChart = ({ viewId }) => {
  const firstLoad = useState(true)
  const seriesData = useState([])
  const t = useT()
  const { views } = useProjectExplorerContext()
  const view = views.values[viewId]
  const settings = view.selections[0].settings
  const chartContext = useChartContext()
  // Initially set the default date
  const [fromDate] = chartContext.useFromDate
  const [toDate] = chartContext.useToDate

  const [properties] = chartContext.useProperties
  const visibleFields = chartContext.useVisibleFields[0]

  const visibleNames: Map<string, string> = Object.entries(properties.values)
    .reduce((map, [key, value]) => {
      if (visibleFields.get(key) === true) {
        map.set(key, value.name);
      }
      return map;
    }, new Map<string, string>());


  const { data, loading } = api.event.getEventsByExplorerView.useData({
    fromDate,
    toDate,
    viewId,
    things: view.selections.map((selection) => ({
      influxFields: Array.from(visibleNames.values()),
      thingId: selection.thingId,
    })),
  })

  useEffect(() => {
    if(!data) return 
    seriesData[1](data)
  }, [data])

  if (firstLoad[0]) {
    if (loading) {
      return <LoadingIcon />
    }
    if (!data || data[0].data.length === 0) return <div>{t["CoreCharts:SorryNoData"].get()} </div>

    if ([...visibleFields.values()].filter(value => value).length === 0) {
      return (
        <div style={{ minHeight: '300px', width: '100%' }}>
          {
            <div style={{ marginTop: '50px' }}>
              {t["CoreCharts:NoPropertiesSelected"].get()} </div>
          }
        </div>
      )
    }
    
    firstLoad[1](false) 
    return 
  }


  return (
    <div style={{ minHeight: '300px', width: '100%' }}>
      <SingleSeriesLineChart
          seriesData={seriesData[0]}
          showHorizontal={settings?.chart?.grid?.showHorizontal}
          showVertical={settings?.chart?.grid?.showVertical}
          colorRgba={settings?.chart?.grid?.colorRgba}
      />
    </div>
  )
}

export const ExplorerTimeSeriesChart = (props) => (
  <WithI18n i18n={i18n}>
    <_ExplorerTimeSeriesChart {...props} />
  </WithI18n>
)

