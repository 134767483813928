import { FC } from 'react'
import { OptionMenu } from '@shared/components/core/EditEntityMenu'
import {
  ContextItem,
  EditIcon,
  useDialog,
  useToast,
} from '@colombalink/basedui'
import { useLocation } from 'wouter'
import EditUnit from '@app/pages/Orgs/Thing/Fragments/Units/EditDialog'
import { useT } from '@app/i18n'

type RowMenuProps = {
  unit: {
    id: string
    label: { en?: string; de?: string }
    symbol: string
  }
}

export const RowMenu = (props: RowMenuProps) => {
  const CategoryMenu: FC<{}> = ({}) => {
    const t = useT()
    const { open } = useDialog()
    const [, setLocation] = useLocation()
    const toast = useToast()

    const unit = {
      id: props.unit.id,
      label: props.unit.label.en,
      symbol: props.unit.symbol,
    }

    return (
      <>
        <ContextItem
          onClick={() => {
            open(<EditUnit unit={unit} />)
          }}
          icon={EditIcon}
        >
          {t['Thing:Edit'].get()}
        </ContextItem>
      </>
    )
  }

  return <OptionMenu menu={CategoryMenu} style={{ width: '150px' }} />
}
