import DefaultNavigation from '@shared/components/page/navigation/DefaultNavigation'
import React, { ChangeEvent, useEffect } from 'react'
import { api } from '@shared/index'
import { Page } from '@shared/components/page/Page'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'

import {
  Button,
  useToast,
  Toast,
  DeleteIcon,
  Text,
  useDialog,
  AddIcon,
  Input,
  Dialog,
  useCopyToClipboard,
  Spacer,
  Accordion,
  AccordionItem,
  Label,
  CheckIcon,
  
} from '@colombalink/basedui'
import {
  OldCompatTable,
} from '@shared/components/core/OldCompatTable'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@shared/components/core/shadcn-ui/Tabs'

const GlobalSettings = () => {
  return (
    <Page>
      <DefaultNavigation />
      <ManageSettings />
    </Page>
  )
}

export default GlobalSettings

const ManageSettings = () => {
  return (
    <div className="flex flex-col p-4">
      <div className="min-w-full align-middle">
        <Tabs defaultValue="integrations" className="w-full">
          <TabsList>
            <TabsTrigger value="integrations">Integrations</TabsTrigger>
          </TabsList>
          <TabsContent value="integrations">
            <ManageIntegrations />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  )
}
ManageSettings.displayName = 'Main'
/// //////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// # Manage Integration
/// //////////////////////////////////////////////////////////////////////////////////////////////////////////////


function InfluxDbAccordionItem() {
  const setChirpIntegration =
    api.settings.integrations.influxdb.set.useMutation()
  const useInfluxdbIngegration =
    api.settings.integrations.influxdb.get.useLiveData()
  const [showSetTokenDialog, setShowSetTokenDialog] = React.useState(false)
  const [chripstackUrl, setInfluxDbUrl] = React.useState(
    useInfluxdbIngegration.data?.url
  )
  const [chripstackToken, setInfluxDbToken] = React.useState<
    string | undefined
  >(undefined)

  useEffect(() => {
    setInfluxDbUrl(useInfluxdbIngegration.data?.url)
  }, [useInfluxdbIngegration.data?.url])

  useEffect(() => {
    if (useInfluxdbIngegration.loading) return
    if (!useInfluxdbIngegration.data?.hasToken) {
      setShowSetTokenDialog(true)
    }
  }, [useInfluxdbIngegration.data?.hasToken])

  const toast = useToast()
  const isLoading = useInfluxdbIngegration.loading
  return (
    <AccordionItem label="InfluxDb">
      {isLoading ? (
        <LoadingIcon />
      ) : (
        <>
          <Input
            label={<div className="text-gray-600">URL</div>}
            description={
              <div className="text-gray-600">
                Please provide a valid URL that points to the InfluxDb server.
              </div>
            }
            value={
              useInfluxdbIngegration.data?.url
                ? useInfluxdbIngegration.data?.url
                : ''
            }
            indent
            onChange={(value) => setInfluxDbUrl(value)}
          />
          <Spacer />
          {showSetTokenDialog ? (
            <>
              <Input
                label="Token"
                description="Please provide a valid API token to get access to the InfluxDb server."
                indent
                onChange={(value) => setInfluxDbToken(value)}
              />
              <Spacer />
              {useInfluxdbIngegration.data?.hasToken && (
                <Button
                  onClick={() => {
                    setShowSetTokenDialog(false)
                    setInfluxDbToken(undefined)
                  }}
                >
                  Keep old token
                </Button>
              )}
            </>
          ) : (
            <Label
              label="Token"
              labelColor="green"
              description="Token has been set."
              descriptionColor="grey"
              icon={<CheckIcon />}
              iconColor="green"
            >
              <Spacer />
              <Button
                onClick={() => {
                  setShowSetTokenDialog(true)
                }}
              >
                Update Token
              </Button>
            </Label>
          )}

          <Spacer />
          <div className="flex justify-end">
            <Button
              onClick={async () => {
                try {
                  await setChirpIntegration.mutateAsync({
                    url: chripstackUrl,
                    token: chripstackToken,
                  })
                  setShowSetTokenDialog(false)
                  toast.add(
                    <Toast
                      label="Success:"
                      type="success"
                      description={<Text>Updated InfluxDb Integration.</Text>}
                    />
                  )
                } catch (e) {
                  toast.add(
                    <Toast
                      label="Error:"
                      type="error"
                      description={<Text>Sorry, something went wrong.</Text>}
                    />
                  )
                }
              }}
            >
              {' '}
              Save
            </Button>
          </div>
        </>
      )}
    </AccordionItem>
  )
}

const ManageIntegrations = () => {
  return (
    <Accordion>
      <InfluxDbAccordionItem />
      <EmailAccordionItem />
      <OpenAiAccordionItem />
    </Accordion>
  )
}

/// //////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// # EmailAccordionItem
/// //////////////////////////////////////////////////////////////////////////////////////////////////////////////
function EmailAccordionItem() {
  const setEmailSettings = api.settings.integrations.email.set.useMutation()
  const useEmailSettings = api.settings.integrations.email.get.useLiveData()
  const [settings, setSettings] = React.useState({
    host: '',
    port: 0,
    credentials: {
      user: '',
      password: '',
    },
    senderAlias: '',
  })
  useEffect(() => {
    if (!useEmailSettings.data) return
    setSettings(useEmailSettings.data)
  }, [useEmailSettings.data])
  const toast = useToast()
  const isLoading = useEmailSettings.loading
  return (
    <AccordionItem label="Email">
      {isLoading ? (
        <LoadingIcon />
      ) : (
        <>
          <Input
            label={<div className="text-gray-600">Host</div>}
            description={
              <div className="text-gray-600">
                Please provide a host i.e.: smtp.example.com
              </div>
            }
            value={settings.host}
            indent
            onChange={(host: string) => {
              settings.host = host
              setSettings({ ...settings })
            }}
          />
          <Spacer />

          <Input
            label={<div className="text-gray-600">Port</div>}
            description={
              <div className="text-gray-600">
                Please provide a port number i.e.: 587
              </div>
            }
            value={settings.port}
            indent
            onChange={(port: string) => {
              settings.port = Number.parseInt(port)
              setSettings({ ...settings })
            }}
          />
          <Spacer />
          <Input
            label={<div className="text-gray-600">Email Address</div>}
            description={
              <div className="text-gray-600">
                Please provide a email address to authenticate with the server.
              </div>
            }
            value={settings.credentials.user}
            indent
            onChange={(user: string) => {
              settings.credentials.user = user
              setSettings({ ...settings })
            }}
          />
          <Spacer />
          <Input
            label={<div className="text-gray-600">Sender Email Address</div>}
            description={
              <div className="text-gray-600">
                Please provide a email address that is used as a sender.
              </div>
            }
            value={settings.senderAlias}
            indent
            onChange={(user: string) => {
              settings.senderAlias = user
              setSettings({ ...settings })
            }}
          />
          <Spacer />
          <Input
            label={<div className="text-gray-600">Password</div>}
            description={
              <div className="text-gray-600">
                Please provide a password to authenticate with the server.
              </div>
            }
            value={settings.credentials.password}
            type="password"
            indent
            onChange={(password: string) => {
              settings.credentials.password = password
              setSettings({ ...settings })
            }}
          />
          <div className="flex justify-end">
            <Button
              onClick={async () => {
                try {
                  // console.log('Email Settings:  ', settings)
                  await setEmailSettings.mutateAsync(settings)
                  toast.add(
                    <Toast
                      label="Success:"
                      type="success"
                      description={<Text>Updated Email Integration.</Text>}
                    />
                  )
                } catch (e) {
                  console.log(e)
                  toast.add(
                    <Toast
                      label="Error:"
                      type="error"
                      description={<Text>Sorry, something went wrong.</Text>}
                    />
                  )
                }
              }}
            >
              {' '}
              Save
            </Button>
          </div>
        </>
      )}
    </AccordionItem>
  )
}

/// //////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// # OpenAI AccordionItem
/// //////////////////////////////////////////////////////////////////////////////////////////////////////////////
function OpenAiAccordionItem() {
  const setOpenAiCredentials =
    api.settings.integrations.openAi.set.useMutation()
  const useOpenAiCredentials =
    api.settings.integrations.openAi.get.useLiveData()
  const [settings, setSettings] = React.useState({
    orgId: '',
    token: '',
  })
  useEffect(() => {
    if (!useOpenAiCredentials.data) return
    // console.log(useOpenAiCredentials.data)
    setSettings(useOpenAiCredentials.data)
  }, [useOpenAiCredentials.data])
  const toast = useToast()
  const isLoading = useOpenAiCredentials.loading
  return (
    <AccordionItem label="Open AI">
      {isLoading ? (
        <LoadingIcon />
      ) : (
        <>
          <Input
            label={<div className="text-gray-600">OpenAI orgId</div>}
            description={
              <div className="text-gray-600">
                Please provide a orgId to authenticate with the server.
              </div>
            }
            value={settings.orgId}
            indent
            onChange={(orgId: string) => {
              settings.orgId = orgId
              setSettings({ ...settings })
            }}
          />
          <Spacer />
          <Input
            label={<div className="text-gray-600">OpenAi API token</div>}
            description={
              <div className="text-gray-600">
                Please provide a OpenAI API token.
              </div>
            }
            defaultValue={settings.token}
            // todo: fix based ui if type is password value is not setk
            value={settings.token}
            indent
            type="password"
            onChange={(token: string | object) => {
              // todo: fix based ui... should always be string
              if (typeof token === 'object') {
                token = (token as ChangeEvent).target.value
              }
              settings.token = token
              setSettings({ ...settings })
            }}
          />
          <Spacer />
          <div className="flex justify-end">
            <Button
              onClick={async () => {
                try {
                  // console.log(settings)
                  await setOpenAiCredentials.mutateAsync(settings)
                  toast.add(
                    <Toast
                      label="Success:"
                      type="success"
                      description={<Text>Updated OpenAI Integration.</Text>}
                    />
                  )
                } catch (e) {
                  console.log(e)
                  toast.add(
                    <Toast
                      label="Error:"
                      type="error"
                      description={<Text>Sorry, something went wrong.</Text>}
                    />
                  )
                }
              }}
            >
              {' '}
              Save
            </Button>
          </div>
        </>
      )}
    </AccordionItem>
  )
}
