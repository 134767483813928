import React, { useEffect } from 'react'
import { AddIcon, Button, Dialog, Input, SearchIcon, Spacer, Text, Toast, useToast } from '@colombalink/basedui'
import { api } from '@shared/index'
import { OldCompatTable } from '@shared/components/core/OldCompatTable'
import { Role, getRoleLabel } from '@server/shared'
import { useT } from '@app/i18n'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'

const AddMemberToProjectDialog = ({ orgAliasId, projectAliasId }: { orgAliasId, projectAliasId }) => {
  const t = useT()
  
  return (
    <Dialog label={t["ProjectsMembers:InviteMember"].get()}>
      <div>
        

       <MemberSelectorTable orgAliasId={orgAliasId}  projectAliasId={projectAliasId}/> 
        
        
        
        <Dialog.Buttons>
          <Dialog.Cancel displayShortcut={false}>{t["ProjectsMembers:Cancel"].get()}</Dialog.Cancel>
        </Dialog.Buttons> 
      </div>
    </Dialog>
  )
}




export default AddMemberToProjectDialog




function MemberSelectorTable({ orgAliasId, projectAliasId }: { orgAliasId: string, projectAliasId: string }) {
  const t = useT()
  // todo: add paginated search (it is ok for now)
  const org = api.organization.getOrganizationById.withMembers.useLiveData({
    orgAliasId,
  })
  const project = api.project.getById.withMembers.useLiveData({ orgAliasId, projectAliasId});


  const addMemberToProject =
    api.project.addMemberToProject.useMutation()
  const [search, setSearch] = React.useState('')
  const toast = useToast()
  const [tableData, setTableData] = React.useState([])
  const tableHeader = [
    { label: t["ProjectsMembers:Name"].get(), key: 'user.name' },
    { label: t["ProjectsMembers:Role"].get(), key: 'roleLabel' },
    { label: t["ProjectsMembers:Action"].get(), key: 'action' },
  ]

  useEffect(() => {
    if (!org.data?.members || !project.data?.members) {
      return
    }

    const tableRows = org.data.members
      .filter(
        (orgMember) => orgMember.role !== 'OWNER' 
        && !project.data.members.find((projectMember) => projectMember.user.id === orgMember.user.id)
      )
      .map((member) => {
        return {
          ...member,
          roleLabel: getRoleLabel(member.role as Role, 'en'),
          action: (
            <Button
              onClick={async () => {
                try {
                  await addMemberToProject.mutateAsync({
                    projectAliasId, 
                    memberToRoleId: member.id, 
                  })
                  toast.add(
                    <Toast
                      label={t["ProjectsMembers:MemberAdded"].get()}
                      type="success"
                      description={
                        <Text>
                          {t["ProjectsMembers:Member"].get()} <b>{member?.user?.name}</b> {t["ProjectsMembers:AddedToProject"].get()}
                        </Text>
                      }
                    />
                  )
                } catch (e) {
                  toast.add(
                    <Toast
                      label={t["ProjectsMembers:Error"].get()}
                      type="error"
                      description= {t["ProjectsMembers:FailedToAddMember"].get()}
                    />
                  )
                }
              }}
            >
              <AddIcon />
            </Button>
          ),
          customAttribute: {
            action: {},
          },
        }
      })

    if (search === '') {
      setTableData(tableRows.slice(0, 10))
    } else {
      setTableData(
        tableRows.filter((member) =>
          member.user.name.toLowerCase().startsWith(search.toLowerCase())
        )
      )
    }
  }, [org.data?.members, project.data?.members, search])

  if (org.loading) {
    return (
      <div>
        <LoadingIcon /> {t["ProjectsMembers:LoadingMembers"].get()}
      </div>
    )
  }

  return (
    <div>
      <Input
        icon={SearchIcon}
        placeholder={t["ProjectsMembers:TypeMemberNameHere"].get()}
        onChange={setSearch}
      />
      {tableData.length > 0 && (
        <div>
          <Spacer space="15px" />
          <OldCompatTable
            data={tableData}
            loading={org.loading}
            header={tableHeader}
          />
        </div>
      )}
    </div>
  )
}



