import { LoadingIcon } from '@shared/components/core/LoadingIcon'
import { useProjectDashboardContext } from "../../DashboardContext"
import { NoThingFoundRedirect } from "./NoThingsFoundRedirect"


export const AsyncStateHandler = ({children}) => {
    const {
        things,
        useSelectedThingsIds: [selectedThingsIds],
    } = useProjectDashboardContext()

    if (things.loading) {
        return <LoadingIcon />
    }

    if (!things.array) {
        return <NoThingFoundRedirect /> 
    }

    if(things.error) {
        throw things.error
    } 

    return  children
}