
const ResetPageContentI18n = {

    'Reset:NewPassword': {
        en: 'New Password',
        de: 'Neues Passwort',
    },
    'Reset:PasswordNotMatch': {
        en: 'Passwords do not match',
        de: 'Passwörter stimmen nicht überein',
    },
    'Reset:ResetPassword': {
        en: 'Reset Password',
        de: 'Passwort zurücksetzen',
    },
    'Reset:ConfirmPassword': {
        en: 'Confirm new Password',
        de: 'Neues Passwort bestätigen',
    },
    'Reset:Save': {
        en: 'Save',
        de: 'Speichern',
    },
    'Reset:PasswordChangedSuccessfully': {
        en: 'Password changed successfully',
        de: 'Passwort erfolgreich geändert',
    },
    'Reset:PasswordChanged': {
        en: 'Password changed',
        de: 'Passwort geändert',
    },
}


export default ResetPageContentI18n
export type ResetContentI18nType = typeof ResetPageContentI18n