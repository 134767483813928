import DefaultNavigation from '@shared/components/page/navigation/DefaultNavigation'
import { Button, Input, Spacer, useGlobalObjectState } from '@colombalink/basedui'
import { Page } from '@shared/components/page/Page'
import { api } from '@shared/index'
import { useLink } from '../../../routes'
import { useLocation } from 'wouter'
import { useState } from 'react'
import { Title } from '@shared/components/core/Title'
import i18n from './i18n'
import { WithI18n } from '@shared/i18n/withI18n'
import { useT } from '@app/i18n'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'

export default function ProfilePage() {
  return (
    <Page className='p-4' >
      <DefaultNavigation />
      <Main />
    </Page>
  )
}

const Main = () => (
  <WithI18n i18n={i18n}>
    <ProfileMain />
  </WithI18n>
)
Main.displayName = 'Main'

const ProfileMain = () => {
  const t = useT()
  const [, setLocation] = useLocation()
  const user = api.user.getUserById.useLiveData()
  if (user.loading) {
    return <LoadingIcon />
  }
  return (<>
    <Title title={t['Profile:AccountDetails'].get()} />
    <ProfileCircle name={user.data.name} />
    <div className="flex flex-col justify-around mb-4">

      <UserNameInput alias={user.data.alias} name={user.data.name} firstName={user.data.firstName} lastName={user.data.lastName} />

      <Spacer />

      <UserEmailInput alias={user.data.alias} email={user.data.email} />

    </div>

    <Title title="Password" />
    <Button onClick={() => setLocation(useLink("/user/:userAliasId/profile/change/password", { userAliasId: user.data.alias }))}>
      {t['Profile:ResetPassword'].get()}
    </Button>
    <Spacer/>
  </>
  )
}

ProfileMain.displayName = 'ProfileMain'

const ProfileCircle = ({ name }: { name: string }) => {
  return (
    <div className="p-2 flex w-full  justify-center">
      <div className="flex h-48 w-48 bg-red-100 rounded-full justify-center items-center">
        <div className="text-red-700 font-medium text-7xl">
          {name[0]}
        </div>
      </div>
    </div>
  )
}

const UserNameInput = ({ name, firstName, lastName, alias }: { name, firstName, lastName, alias }) => {
  const t = useT()
  const [editedName, setEditedName] = useState(name)
  const [editedFirstName, setEditedFirstName] = useState(firstName)
  const [editedLastName, setEditedLastName] = useState(lastName)
  const changeUserDetailsMutation = api.user.updateUserProfile.useMutation()
  const [showUserNameInfo, setShowUserNameInfo] = useState(false)
  const [showInfo, setShowInfo] = useState(false)

  if (editedName === name && editedFirstName === firstName && editedLastName === lastName) {

    return <div>
      <div className="flex flex-col"
        onMouseEnter={() => { setShowUserNameInfo(true) }}
        onMouseLeave={() => { setShowUserNameInfo(false) }}
      >
        <Input
          disabled
          description={showUserNameInfo ? t['Profile:NameNotChange'].get() : null}
          label={t['Profile:Name'].get()}
          type="text"
          value={editedName}
          onChange={(name) => {
            setEditedName(name)
          }}
        />
      </div>

      <Input
        label={t['Profile:FirstName'].get()}
        type="text"
        value={editedFirstName}
        onChange={(firstName) => {
          setEditedFirstName(firstName)
        }}
      />

      <Input
        label={t['Profile:LastName'].get()}
        type="text"
        value={editedLastName}
        onChange={(lastName) => {
          setEditedLastName(lastName)
        }}
      />

    </div >
  }

  return <div>
    <div className="m-2">
      <Input
        label={t['Profile:Name'].get()}
        type="text"
        value={editedName}
        onChange={(name) => {
          setEditedName(name)
        }}
      />
    </div>

    <div className="m-2">
      <Input
        label={t['Profile:FirstName'].get()}
        type="text"
        value={editedFirstName}
        onChange={(firstName) => {
          setEditedFirstName(firstName)
        }}
      />
    </div>

    <div className="m-2">
      <Input
        label={t['Profile:LastName'].get()}
        type="text"
        value={editedLastName}
        onChange={(lastName) => {
          setEditedLastName(lastName)
        }}
      />
    </div>
    <Spacer />
    <div className="mx-3">
      <Button
        onClick={async () => {
          try {
            await changeUserDetailsMutation.mutateAsync({
              userAliasId: alias,
              name: editedName,
              firstName: editedFirstName,
              lastName: editedLastName,
            })
          } catch (e) {
            // todo show toast
            throw e
          }
        }}
      >Update profile settings</Button>
    </div >
  </div>
}


const UserEmailInput = ({ email, alias }: { email, alias }) => {
  const t = useT()
  const [showInfo, setShowInfo] = useState(false)

  return (
    <div className="flex flex-col"
      onMouseEnter={() => { setShowInfo(true) }}
      onMouseLeave={() => { setShowInfo(false) }}
    >
      <Input
        disabled
        description={showInfo ? t['Profile:EmailNotChange'].get() : null}
        label={t['Profile:Email'].get()}
        value={email}
        type="email"
        name="email"
      />
    </div>
  )

}