import { useNavigator } from "@shared/components/page/navigation/context"
import { api } from "@shared/index"
import { useEffect } from "react"
import { setUserNavigationSubItems } from "./setUserNav"
import { setOrgNavigationSubItems } from "./setOrgNav"
import { setDocsNavigation } from "./setDocsNav"
import { useT } from '@app/i18n';
import { useTranslation } from "react-i18next"

// export const useAliasResolver = () => {
//   const navdata = api.user.getUserNav.useLiveData()

//    const aliasToTargetMap = useMemo(() => {
//       const map =  new Map<string, RoleEnum>()
//       if(!navdata?.data) {
//         return map 
//       }
//       console.log('update: ', navdata.data)
//       for (const org of navdata.data.organizations) {
//         map.set(org.alias, org.members.role as RoleEnum)
//         for (const project of org.projects) {
//           console.log('ali ............', project.alias)
//           map.set(project.alias, project.members.role as RoleEnum)
//         }
//       }
//       return map
//    }, [navdata.data])

//    return aliasToTargetMap
// }

// type Org =  ReturnType<typeof api.user.getUserNav.useLiveData>['data']['organizations'][number]
// type Project =  ReturnType<typeof api.user.getUserNav.useLiveData>['data']['organizations'][number]['projects'][number]

// export const useAliasToProjectMap = () => {
//   return useAliasResolver() as Map<string, Project>
// }

// export const useAliasToOrgMap = () => {
//   return useAliasResolver() as Map<string, Org>
// }




export const useRoleBasedNavigation = () => {
  const navigationState = useNavigator().state
  const setNavigation = useNavigator().setNavigation
  const navdata = api.user.getUserNav.useLiveData()
  const roles = api.user.getRoles.useLiveData()
  const t = useT()
  const [, trans] = useTranslation()

  useEffect(() => {
    if (navdata.loading || roles.loading) {
      return
    }

    const navigation = navigationState.navigation
    if (navigation.subItems?.user.subItems) {
      // Save the user subItem
      const userSubItem = navigation.subItems.user.subItems;
      // Clear all subItems
      for (const key in navigation.subItems) {
        if (key !== 'user') {
          delete navigation.subItems[key];
        }
      }
      // Restore the user subItem
      navigation.subItems.user.subItems = userSubItem;
    }


    // console.log("NAVIGATION: ", navigation)
    // TODO: there exsits for subitem '' the old projects is not removed
    setUserNavigationSubItems(navigation, navdata.data, t)
    setOrgNavigationSubItems(navigation, navdata.data, roles.data, t)
    setDocsNavigation(navigation, t)
    setNavigation({ ...navigation })
    // console.log('navi', navigation)
  }, [navdata.data, navdata?.data?.organizations, roles.data, trans.language])
}

export const UserNavigationContentLoader = () => {
  useRoleBasedNavigation()

  return null
}