const ProjectQuotaPageContentI18n = {

"Quota:Name": {
    "en": "Name",
    "de": "Name",
},
"Quota:Limit": {
    "en": "Limit",
    "de": "Limit",
},
"Quota:Count": {
    "en": "Count",
    "de": "Anzahl",
},
"Quota:Loading": {
    "en": "Loading",
    "de": "Laden",
},

    
}

export default ProjectQuotaPageContentI18n
export type ProjectQuotaPageContentI18nType = typeof ProjectQuotaPageContentI18n
