import {
  AddIcon,
  Spacer,
  useToast,
} from '@colombalink/basedui'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'
import DefaultNavigation from '@shared/components/page/navigation/DefaultNavigation'
import { useEffect, useState } from 'react'
import { DynamicButtonRow } from '@shared/components/core/DynamicButtonRow'
import { Page } from '@shared/components/page/Page'
import { Title } from '@shared/components/core/Title'
import { api } from '@shared/index'
import { useLocation } from 'wouter'
import { useTable } from '@shared/components/core/table/Table'
import { Role, getRoleLabel } from '@server/shared'
import { useRouteParams } from '@shared/router'
import { usePageRole } from '@shared/components/page/usePageRole'
import { WithI18n } from '@shared/i18n/withI18n'
import i18n from './i18n'
import { useT } from '@app/i18n'

type PageProps = { params: { orgAliasId: string } }
export type PageParams = PageProps['params']

const SettingsQuotaPage = ({ params }: { params: { orgAliasId: string } }) => {
  return (
    <Page>
      <DefaultNavigation />
      <Main orgAliasId={params.orgAliasId} />
    </Page>
  )
}

const Main = ({ orgAliasId }: { orgAliasId: string }) => {
  return (
    <WithI18n i18n={i18n}>
      <ManageQuotas orgAliasId={orgAliasId} /> 
    </WithI18n>
  )
}

Main.displayName = 'Main'

export default SettingsQuotaPage

const ManageQuotas = ({ orgAliasId }: { orgAliasId: string }) => {

  return (
    <div className="flex flex-col">
      <QuotaTable />
    </div>
  )
}

function QuotaTable() {
  const t = useT()
  const org = api.organization.quotas.getAll.useLiveData();
  const [, setLocation] = useLocation()
  const toast = useToast()
  const [tableData, setTableData] = useState([])
  const role = usePageRole()
  // only Org owners should have this power
  const quota = api.organization.quotas.setQuota.useMutation()
  //console.log("quotas:", quota)

  const [table, TableComponent] = useTable({
    header: [
      { label: t["SettingsQuota:Name"].get(), key: 'name' },
      { label: t["SettingsQuota:Count"].get(), key: 'count' },
      { label: t["SettingsQuota:Limit"].get(), key: 'limit', isEditable: role.isOwner },
    ],
    data: tableData,
    onUpdatedCell(rowIndex, columnId, value) {
      //console.log('onUpdatedCell', rowIndex, columnId, value)
      if (columnId === "limit") {
        quota.mutateAsync({ limit: parseInt(value), quotaType:  tableData[rowIndex]['quotaType']})
      }
    },
  })


  useEffect(() => {
    if (org.data?.quotas) {
      //console.log(org.data?.quotas)
      setTableData(
        org.data.quotas.map((quota) => {
          return {
            ...quota,
            count: quota.count.toString(),
            limit: quota.limit.toString(),
            customAttribute: {
            },
          }
        })
      )
    }
  }, [org.data?.quotas])

  if (org.loading) {
    return <>{t["SettingsQuota:Loading"].get()} <LoadingIcon /></>
  }
  return (
    <>
      {TableComponent}
    </>
  )
}

ManageQuotas.displayName = 'Main'