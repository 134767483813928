import {
  AccordionContent,
  AccordionItem as BaseAccordionItem,
  AccordionTrigger,
} from '@shared/components/core/shadcn-ui/Accordion'
import { statusColors } from '@styles/colors'
import { cn } from '@shared/utils/tw'
import { useLocation } from 'wouter'

export const AccordionItem = ({ title, children, statusStats, id, alias }: {
  id,
  title,
  children?: React.ReactNode,
  statusStats?: {
    alerts: number,
    alarms: number,
    idles: number
  },
  alias: string
}) => {
  let status = 'default';

  if(statusStats.idles > 0) {
    status = 'idle';
  }

  if (statusStats.alerts > 0) {
    status = 'alert';
  }
  if (statusStats.alarms > 0) {
    status = 'alarm';
  }

  if(statusStats.alarms + statusStats.alerts + statusStats.idles === 0){
    status = 'default'
  }

  const [,setLocation] = useLocation()
  return (
      <BaseAccordionItem value={id}>
        <AccordionTrigger
            headerClassName={cn("border-l-8", statusColors[status]?.border)}>
          <div className="flex flex-col w-full min-h-20 justify-between md:flex-row md:flex-wrap md:items-center">
            {/* Title */}
            <h3 className="max-w-xl text-txt scroll-m-20 text-2xl font-semibold tracking-tight text-left flex-grow">
              <a className="hover:underline" onClick={(e) => {
                setLocation(alias)
                e.preventDefault()
              }}>
                {title}
              </a>
            </h3>

            {/* Status Stats Display */}
            {statusStats && (
                <div className="flex space-x-2 mr-4 md:h-auto">
                  <div className="flex space-x-2">
                    {statusStats.idles > 0 && (
                        <span
                            className={cn("border-l-8 h-10 justify-center items-center", statusColors['idle']?.border, "w-12 text-center flex items-center")}>
                            {statusStats.idles}
                        </span>
                    )}
                    {statusStats.alerts > 0 && (
                        <span
                            className={cn("border-l-8 h-10 justify-center items-center", statusColors['alert']?.border, "w-12 text-center flex items-center")}>
                            {statusStats.alerts}
                        </span>
                    )}
                    {statusStats.alarms > 0 && (
                        <span
                            className={cn("border-l-8 h-10 justify-center items-center", statusColors['alarm']?.border, "w-12 text-center flex items-center")}>
                            {statusStats.alarms}
                        </span>
                    )}
                  </div>
                </div>
            )}
          </div>
        </AccordionTrigger>
        <AccordionContent>
          {children}
        </AccordionContent>
      </BaseAccordionItem>
  )
}