import FadeInBox from '../shared/FadeInBox'
import MainContainer from '../shared/MainContainer'
import { Button, useToast } from '@colombalink/basedui';
import { useLocation } from 'wouter';
import { useState, useEffect } from 'react';
import { useLocalStorageContext } from '@shared/utils/react/LocalStorage/context';
import { LoadingIcon } from '@shared/components/core/LoadingIcon';
import { useIdentityClient } from '@shared/client/context';
import { useAuthState } from '@shared/client/useAuthState';

type Status = 'validating' | 'valid' | 'invalid'

export default ({ params }: { params: { token } }) => {
    const client = useIdentityClient()
    const [status, setStatus] = useState<Status>('validating')
    const [orgName, setOrgName] = useState("")
    const [userId, setUserId] = useState<string>('');
    const authState = useAuthState()
    const [, setLocation] = useLocation()

    const {
        value: globalInvitation,
        setValue: setGlobalInvitation,
    } = useLocalStorageContext('invitation', params.token, !!params.token)
    const toast = useToast()

    useEffect(() => {
       //  console.log('validating invitation')
        client.call('login/validate/invitation', { token: params.token })
            .then((invitation) => {
                // console.log(invitation)
                setOrgName(invitation?.organization?.name)
                setUserId(invitation.id)
                setGlobalInvitation(params.token);
                setStatus('valid')

            })
            .catch((e) => {
                console.log(e)
                setStatus('invalid')
            })
    }, [params.token])

    useEffect(() => {
        if (authState && authState?.userId) {
        }
    }, [authState])
   

    if (authState.userId) {
        setLocation('/invitation/join')
    }

    if (status === 'validating') {
        return (
            <MainContainer>
                <FadeInBox>
                    <p className='w-full text-center'>We are validating your invitation.</p>
                    <p className='w-full flex justify-center mt-4'><LoadingIcon></LoadingIcon></p>
                </FadeInBox>
            </MainContainer>
        )
    }

    if (status === 'invalid') {
        return (
            <MainContainer>
                <FadeInBox>
                    <p className='w-full text-center'>Sorry, this invitation is invalid.</p>
                </FadeInBox>
            </MainContainer>
        )

    }

    if (status === 'valid') {

    return (
        <MainContainer>
            <FadeInBox>
                <div>
                    <p className='w-full text-center'>You have been invited to join the organization: </p>
                    <p className='w-full text-center'><b>{orgName}</b></p>
                    <p className='w-full text-center my-4'>Please sign in or register to continue with invitation process. </p>
                </div>
                <Button
                    large
                    fill
                    style={{
                        marginBottom: 24,
                        height: 48,
                    }}
                    textAlign="center"
                    onClick={() => setLocation(`/invitation/login`)}
                >
                    Sign in
                </Button>
                <Button
                    large
                    fill
                    style={{
                        marginBottom: 24,
                        height: 48,
                    }}
                    textAlign="center"
                    onClick={() => {
                        setLocation(`/invitation/register`)

                    }}
                >
                    Register
                </Button>
            </FadeInBox>
        </MainContainer>
    );
}
}