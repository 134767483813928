const SharedThingPageContentI18n = {

    "SThing:NoDevicesFound": {
        "en": "No devices found",
        "de": "Keine Geräte gefunden"
    },
    "SThing:AddFirst": {
        "en": "Add your first device or generic thing.",
        "de": "Füge dein erstes Gerät oder generisches Thing hinzu."
    },
    "SThing:CreateDevice": {
        "en": "Create device",
        "de": "Gerät erstellen"
    },
    "SThing:CreateGenericThing": {
        "en": "Create generic thing",
        "de": "Generisches Thing erstellen"
    },
    "SThing:NoPlease": {
        "en": `No devices found.
        Please contact the project owner to add new devices.`,
        "de": `Keine Geräte gefunden.
        Bitte kontaktiere den Projektinhaber um neue Geräte hinzuzufügen.`
    },
    "SThing:TheThing": {
        "en": `The thing`,
        "de": `Das Thing`
    },
    "SThing:HasNotBeenConfigured": {
        "en": `has not been configured yet.`,
        "de": `wurde noch nicht konfiguriert.`
    },
    "SThing:ConfigureIt": {
        "en": `Configure it`,
        "de": `Konfiguriere es`
    },
    "SThing:CannotConfigureDevice": {
        "en": `Cannot configure device.
        Please contact the project owner to configure device.`,
        "de": `Gerät kann nicht konfiguriert werden.
        Bitte kontaktiere den Projektinhaber um das Gerät zu konfigurieren.`
    },
    "SThing:AddAChirpstackThing": {
        "en": `Add a ChirpStack thing`,
        "de": `Füge ein ChirpStack Thing hinzu`
    },
    "SThing:NoIntegrationsFound": {
        "en": `No integrations found`,
        "de": `Keine Integrationen gefunden`
    },
    "SThing:AddIntegration": {
        "en": `Add integration`,
        "de": `Integration hinzufügen`
    },
    "SThing:SelectIntegration": {
        "en": `Select integration`,
        "de": `Integration auswählen`
    },
    "SThing:SelectATenant": {
        "en": `Select a tenant`,
        "de": `Mandant auswählen`
    },
    "SThing:Loading": {
        "en": `Loading`,
        "de": `Laden`
    },
    "SThing:SelectAnApplication": {
        "en": `Select an application`,
        "de": `Anwendung auswählen`
    },
    "SThing:DeviceEUI": {
        "en": `Device EUI`,
        "de": `Geräte EUI`
    },
    "SThing:DeviceName": {
        "en": `Device name`,
        "de": `Gerätename`
    },
    "SThing:Action": {
        "en": `Action`,
        "de": `Aktion`
    },
    "SThing:AddedThing": {
        "en": `Added thing`,
        "de": `Thing hinzugefügt`
    },
    "SThing:AddedToProject": {
        "en": `added to project`,
        "de": `hinzugefügt zu Projekt`
    },
    "SThing:FailedToAddThing": {
        "en": `Failed to add thing`,
        "de": `Thing konnte nicht hinzugefügt werden`
    },
    "SThing:AddAGenericThing": {
        "en": `Add a generic thing`,
        "de": `Füge ein generisches Thing hinzu`
    },
    "SThing:CreatedSuccessfully": {
        "en": `created successfully`,
        "de": `erfolgreich erstellt`
    },
    "SThing:FailedToCreateThing": {
        "en": `Failed to create thing`,
        "de": `Thing konnte nicht erstellt werden`
    },
    "SThing:Close": {
        "en": `Close`,
        "de": `Schliessen`
    },
    "SThing:Add": {
        "en": `Add`,
        "de": `Hinzufügen`
    },
    "SThing:NoPropertiesFoundInThisDevice": {
        "en": `No properties found in this device`,
        "de": `Keine Eigenschaften in diesem Gerät gefunden`
    },
    "SThing:NoDataFoundForThisDevice": {
        "en": `No data found for this device`,
        "de": `Keine Daten für dieses Gerät gefunden`
    },
    "SThing:Name": {
        "en": `Name`,
        "de": `Name`
    },




}

export default SharedThingPageContentI18n
export type SharedThingPageContentI18nType = typeof SharedThingPageContentI18n
