import DefaultNavigation from '@shared/components/page/navigation/DefaultNavigation'
import { Page } from '@shared/components/page/Page'
import { Title } from '@shared/components/core/Title'
import { ProjectsTable } from './shared/ProjectsTable'
import { ProjectLandingFragment } from '@shared/components/project/LandingFragment'
import { WithI18n } from '@shared/i18n/withI18n'
import i18n from './i18n'

export const SupervisorPage = () => {
  return (
    <Page>
      <DefaultNavigation/>
      <Main/>
    </Page>
  )
}
SupervisorPage.displayName = 'SupervisorPage'

const Main = () => {
  return (
    <WithI18n i18n={i18n}>
      <ManageOrganizationProjectsMain  />
    </WithI18n>
  )
}

Main.displayName = 'Main'

const ManageOrganizationProjectsMain = () => {

  return (
    <ProjectLandingFragment>
      <div className="flex flex-col">
        <Title title="All Projects" />
        <ProjectsTable/>
      </div>
    </ProjectLandingFragment>
  )
}

ManageOrganizationProjectsMain.displayName = 'ManageOrganizationProjectsMain'