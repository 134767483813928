import {
  AddIcon,
  Button, Checkbox, ColorPicker,
  DeleteIcon,
  Dialog,
  Text,
  Toast,
  useDialog,
  useToast,
} from '@colombalink/basedui'
import { useProjectDashboardContext } from '../DashboardContext'
import { useEffect, useState } from 'react'
import { useProjectExplorerContext } from './ExplorerContext'
import { CreateNewView } from '@shared/components/project/explorer/dialogs/AddViewDialog'
import {
  AccordionItem,
  AccordionContent,
  AccordionTrigger,
  Accordion,
} from '@shared/components/core/shadcn-ui/Accordion'
import {ChartContext, useChartContext} from '@shared/components/core/charts/context'
import { SimpleContainer } from '@shared/components/core/charts/SimpleContainer'
import { FixedRangeSelector } from '@shared/components/core/charts/FixedRangeSelector'
import { ExplorerTimeSeriesChart } from '@shared/components/core/charts/ExplorerTimeseriesChart'
import { ThingsLegend } from '@shared/components/core/charts/ThingsLegend'
import { FragmentContext, useFragmentContext } from './FragmentContext'
import { EditIcon, LayoutDashboard } from 'lucide-react'
import { ButtonRowContainer } from '@shared/components/core/DynamicButtonRow/ButtonRowContainer'
import { api } from '@shared/index'
import { EditViewDialog } from '@shared/components/project/explorer/dialogs/EditViewDialog'
import { DynamicButtonRow } from '@shared/components/core/DynamicButtonRow'
import { usePageRole } from '@shared/components/page/usePageRole'
import { ProjectExplorerLandingFragment } from '@shared/components/project/explorer/LandingFragment'
import { Legend } from '@shared/components/core/charts/Legend'
import {useViewThingContext, ViewThingContext} from './MultiThingContex'
import { useT } from '@app/i18n'
import {useThingContext} from "@app/pages/Orgs/Thing/Fragments/Latest/context";
import {AsyncStateHandler} from "@app/pages/Orgs/Project/ExplorerFragment/shared/AsyncStateHandler";
import {MainContainer} from "@app/pages/Orgs/Project/ExplorerFragment/shared/MainContainer";
import {FragmentContainer} from "@app/pages/Orgs/Project/ExplorerFragment/shared/FragmentContainer";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "@shared/components/core/shadcn-ui/Tabs";

export const ExplorerFragment = () => {
  return (
      <AsyncStateHandler>
        <FragmentContainer>
          <FragmentContext>
            <MainContainer>
              <ToolPanel/>
              <ProjectExplorerLandingFragment>
                <ExplorerViewEditor/>
                <ChartContext>
                  <ExplorerView/>
                </ChartContext>
              </ProjectExplorerLandingFragment>
            </MainContainer>
          </FragmentContext>
        </FragmentContainer>
      </AsyncStateHandler>
  )
}

const ToolPanel = () => {
  const {
    useEditMode: [editMode, setEditMode],
  } = useFragmentContext()

  const { things } = useProjectDashboardContext()

  const role = usePageRole()
  const t = useT()
  if (!role.isOwner) return <></>
  return (
    <ButtonRowContainer>
      <div className="mt-4 mb-4">
        {!editMode ? (
          <Button
            icon={<EditIcon style={{ height: '16px', width: '16px' }} />}
            onClick={() => setEditMode(!editMode)}
          />
        ) : (
          <div className="flex flex-row">
            <DynamicButtonRow
              buttons={[
                {
                  icon: <AddIcon />,
                  dialogComponent: <CreateNewView things={things} />,
                  label: t['Project:CreateNewView'].get(),
                  style: { cursor: 'pointer', marginRight: '10px' },
                },
              ]}
            />
             <div className="my-4 pr-4">
              <Button
                icon={
                  <LayoutDashboard style={{ width: '16px', height: '16px' }} />
                }
                onClick={() => setEditMode(!editMode)}
              />
            </div>
          </div>
        )}
      </div>
    </ButtonRowContainer>
  )
}

const ExplorerView = () => {
  if (useFragmentContext().useEditMode[0]) return <></>

  const { views, useOpenAccordionItems: [openAccordionItems, setOpenAccordionItems]} = useProjectExplorerContext()

  return (
    <Accordion
      type="multiple"
      value={openAccordionItems}
      onValueChange={setOpenAccordionItems}
    >
      {views?.keys.map((viewId) => (
          <ViewThingContext key={viewId} viewId={viewId}>
            <AccordionItem key={viewId} value={viewId}>
              <AccordionTrigger
                  onClick={() => {
                    if (openAccordionItems.includes(viewId)) {
                      setOpenAccordionItems(
                          openAccordionItems.filter((item) => item !== viewId)
                      )
                    } else {
                      setOpenAccordionItems([...openAccordionItems, viewId])
                    }
                  }}
              >
                <div className="flex flex-col sm:flex-row flex-wrap items-center w-full space-x-4 space-y-4 ">
                  <h3 className="ml-4 text-txt scroll-m-20 text-2xl font-semibold tracking-tight">
                    {views.values[viewId].name}
                  </h3>
                </div>
              </AccordionTrigger>
              <AccordionContent>
                <ViewContent viewId={viewId}/>
              </AccordionContent>
            </AccordionItem>
          </ViewThingContext>
      ))}
    </Accordion>
  )
}

const ViewContent = ({ viewId }) => {
  const { filters: { timeFrame } } = useProjectExplorerContext()

  return (
      <>
        <ViewChartController timeFrame={timeFrame}/>
        <SimpleContainer>
          <ThingsLegend>
            <FixedRangeSelector
              customKey={timeFrame.value}
            />
          </ThingsLegend>
          <ExplorerTimeSeriesChart
            viewId={viewId}
          ></ExplorerTimeSeriesChart>
        </SimpleContainer>
      </>
  )
}

const ExplorerViewEditor = () => {
  const {
    useEditMode: [editMode],
  } = useFragmentContext()

  const toast = useToast()
  const { open } = useDialog()
  const { views, useOpenAccordionItems: [openAccordionItems, setOpenAccordionItems] } = useProjectExplorerContext()
  const removeView = api.project.deleteExplorerView.useMutation()
  const t = useT()

  if (!editMode) return <></>
  return (
    <Accordion
      type="multiple"
      value={openAccordionItems}
      onValueChange={setOpenAccordionItems}
    >
      {views?.keys?.map((viewId) => (
        <AccordionItem key={viewId} value={viewId}>
          <AccordionTrigger
            onClick={() => {
              if (openAccordionItems.includes(viewId)) {
                setOpenAccordionItems(
                  openAccordionItems.filter((item) => item !== viewId)
                )
              } else {
                setOpenAccordionItems([...openAccordionItems, viewId])
              }
            }}
          >
            <div className="flex flex-col sm:flex-row flex-wrap items-center w-full pr-2">
              <div className="cursor-pointer h-4 w-4">
                <DeleteIcon
                  onClick={async () => {
                    open(
                      <Dialog label={t['Project:Proceed'].get()}>
                        <Dialog.Buttons>
                          <Dialog.Cancel>{t['Project:No'].get()}</Dialog.Cancel>
                          <Dialog.Confirm
                            onConfirm={async () => {
                              await removeView.mutateAsync({ viewId })
                              toast.add(
                                <Toast
                                  label={t['Project:RemovedView'].get()}
                                  type="success"
                                  description={
                                    <Text>{t['Project:RemovedSuccess'].get()}</Text>
                                  }
                                />
                              )
                            }}
                          >
                            {t['Project:Yes'].get()}
                          </Dialog.Confirm>
                        </Dialog.Buttons>
                      </Dialog>
                    )
                  }}
                />
              </div>
              <h3 className="ml-4 text-txt scroll-m-20 text-2xl font-semibold tracking-tight">
                {views.values[viewId].name}
              </h3>
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <Tabs defaultValue="view" className="w-full">
              <TabsList>
                <TabsTrigger value="view">{t['Project:View'].get()}</TabsTrigger>
                <TabsTrigger value="settings">{t['Project:Settings'].get()}</TabsTrigger>
              </TabsList>
              <TabsContent value="view">
                <EditView viewId={viewId} />
              </TabsContent>
              <TabsContent value="settings">
                <Settings viewId={viewId}/>
              </TabsContent>
            </Tabs>
          </AccordionContent>
        </AccordionItem>
      ))}
    </Accordion>
  )
}

const Settings = ({viewId}: { viewId: string }) => {

  const t = useT()
  const dialog = useDialog()
  const {views} = useProjectExplorerContext()
  const view = views.values[viewId]
  const toast = useToast()
  const [selections, setSelections] = useState(
      view.selections.map((selection) => ({
        thingId: selection.thingId,
        properties: selection.thingProperties,
        settings: selection.settings
      }))
  )

  const editView = api.project.editExplorerView.useMutation()

  const onSettingsChange = (thingId, property, value) => {
    setSelections((prevSelections) => {
      return prevSelections.map((selection) => {
        if (selection.thingId === thingId) {
          let settings = selection.settings || {
            chart: {
              grid: {
                showHorizontal: false,
                showVertical: false,
                colorRgba: 'rgba(1,1,1,1)'
              }
            }
          }
          settings.chart.grid[property] = value
          return {
            ...selection,
            settings
          }
        }
        return selection
      })
    })
  }
  const openColorPicker = (thingId, currentColor) => {
    dialog.open(
        <Dialog label={t['Project:SelectColor'].get()}>
          <ColorPicker
              value={currentColor}
              onChange={(color) =>
                  onSettingsChange(thingId, 'colorRgba', color)
              }
          />
          <Dialog.Buttons border>
            <Dialog.Cancel>{t['ProjectClose'].get()}</Dialog.Cancel>
            <Dialog.Confirm>{t['Project:Confirm'].get()}</Dialog.Confirm>
          </Dialog.Buttons>
        </Dialog>
    )
  }
  return (
      <>
        <Accordion type="single" collapsible defaultValue="chart">
          <AccordionItem value="chart" key={viewId}>
            <AccordionTrigger>{t['Project:Chart'].get()}</AccordionTrigger>
            <AccordionContent>
              {selections.map((selection) =>
                  <div key={selection.thingId}>
                    <div className='m-6 space-y-3'>
                      <Checkbox
                          onChange={(isSelected) => onSettingsChange(selection.thingId, 'showHorizontal', isSelected)}
                          value={selection?.settings?.chart?.grid?.showHorizontal || false}
                          label={t['Project:HorizontalGrid'].get()}/>
                      <Checkbox
                          onChange={(isSelected) => onSettingsChange(selection.thingId, 'showVertical', isSelected)}
                          value={selection?.settings?.chart?.grid?.showVertical || false}
                          label={t['Project:VerticalGrid'].get()}/>

                      <div className="flex flex-row items-center">
                        <div
                            className="mr-3 w-6 h-6 rounded-full border border-gray-300"
                            style={{
                              backgroundColor: selection?.settings?.chart?.grid?.colorRgba || 'rgba(1,1,1,1)'
                            }}
                            onClick={() =>
                                openColorPicker(
                                    selection.thingId,
                                    selection?.settings?.chart?.grid?.colorRgba || 'rgba(1,1,1,1)'
                                )
                            }
                        />
                        <Text>{t['Project:GridLineColor'].get()}</Text>
                      </div>
                    </div>
                    <Dialog.Buttons>
                      <Dialog.Confirm onConfirm={async () => {
                        const viewConfig = {
                          id: viewId,
                          name: view.name,
                          selections: selections.map((selection) => ({
                            thingId: selection.thingId,
                            thingProperties: selection.properties.map((prop) => ({
                              key: prop.key,
                              color: prop.color,
                            })),
                            settings: selection.settings
                          })),
                        }

                        try {
                          await editView.mutateAsync(viewConfig)
                          toast.add(
                              <Toast
                                  label={t['SProject:UpdatedView'].get()}
                                  type="success"
                                  description={
                                    <Text>
                                      View <b>{viewConfig.name}</b> {t['SProject:UpdatedSuccessfully'].get()}
                                    </Text>
                                  }
                              />
                          )
                        } catch (e) {
                          toast.add(
                              <Toast
                                  label={t['SProject:FailedToUpdateView'].get()}
                                  type="error"
                                  description={
                                    <Text style={{whiteSpace: 'normal'}}>{e.message}</Text>
                                  }
                              />
                          )
                          throw e
                        }

                      }}>{t['SProject:Save'].get()}</Dialog.Confirm>
                    </Dialog.Buttons>
                  </div>
              )}
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </>
  )
}

const EditView = ({ viewId }) => {
  return <EditViewDialog viewId={viewId} />
}

const ViewChartController = ({timeFrame}: {timeFrame: {value: string}}) => {
  const chartCtx = useChartContext()
  const viewCtx = useViewThingContext()
  const projectDashboardCtx = useProjectDashboardContext()

  const [isDebouncing, setIsDebouncing] = useState(false)

  useEffect(() => {
    if (!projectDashboardCtx.things) return
    if (isDebouncing) {
      setIsDebouncing(!isDebouncing)
    }

    setTimeout(() => {
      setIsDebouncing(true)
    }, 500)
    // we got a new event lets update the toDate filter
    chartCtx.useToDate[1](new Date().getTime())

  },[projectDashboardCtx.filters.things.options])

  
  // useEffect(() => {
  //   if (timeFrame.value === 'live') {
  //     chartCtx.useToDate[1](new Date().getTime())
  //   }
  // }, [projectDashboardCtx.things]);

  useEffect(() => {
    const properties = viewCtx.properties
    const keys = Object.keys(properties)
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      if (!properties[key]) continue
      properties[key].visible = true
      if (chartCtx.useVisibleFields[0].size === 0) {
        chartCtx.useVisibleFields[1](pre => new Map(pre).set(key, true));
      }
    }
    chartCtx.useProperties[1]({ keys, values: properties })

  }, [viewCtx.properties])


  return <></>
}
