import { api } from '@shared/index'
import routes, { useLink } from '../routes'
import {
  NavigationItem,
  useNavigator,
} from '@shared/components/page/navigation/context'
import { useEffect } from 'react'
import {
  AlarmCheckIcon,
  Building2Icon,
  BuildingIcon,
  CodesandboxIcon,
  FunctionSquareIcon,
  HandMetalIcon,
  InfoIcon,
  LayoutTemplateIcon,
  MonitorCheckIcon,
  NewspaperIcon,
  PcCaseIcon,
  RadioTower,
  SettingsIcon,
  SliceIcon,
  StickyNoteIcon,
  UngroupIcon,
  User2Icon,
  UserCircleIcon,
  UserPlus2Icon,
  UserPlusIcon,
  ZapIcon,
} from 'lucide-react'
import { projects } from './getProjectNav'
import { Role, RoleEnum } from '@server/shared'



//// ORG
//////////////////////////////////////////////////////////


const orgMembers = ({ orgAliasId }, t) => ({
  members: {
    name: t['Navigation:Members'].get(),
    link: useLink('/org/:orgAliasId/members', { orgAliasId }),
    subItems: {},
    icon: <UserPlus2Icon />,
  },
})



const auditLog = ({ orgAliasId }, t) => ({
  auditlog: {
    name: t['Navigation:AuditLog'].get(),
    link: useLink('/org/:orgAliasId/auditLog', { orgAliasId }),
    subItems: {},
    icon: <InfoIcon />,
  },
});

const integrations = ({ orgAliasId }, role: Role, t) => {
  if (!role) return;
  if (RoleEnum[role] < RoleEnum.OWNER) return;
  return ({
    integrations: {
      name: t['Navigation:Integrations'].get(),
      link: useLink('/org/:orgAliasId/integrations', { orgAliasId }),
      subItems: {},
      icon: <CodesandboxIcon />,
    },
  });
}

//// ORG/SETTINGS
//////////////////////////////////////////////////////////

const quota = ({ orgAliasId }, t) => ({
  quota: {
    name: t['Navigation:Quota'].get(),
    link: useLink('/org/:orgAliasId/settings/quota', { orgAliasId }),
    subItems: {},
    icon: <SliceIcon />,
  },
})

const apiTokens = ({ orgAliasId }, role: Role, t) => {
  if (!role) return;
  if (RoleEnum[role] < RoleEnum.OWNER) return;
  return ({
    apiTokens: {
      name: t['Navigation:ApiTokens'].get(),
      link: useLink('/org/:orgAliasId/settings/api/tokens', { orgAliasId }),
      subItems: {},
      icon: <ZapIcon />,
    },
  });
}

const settings = ({ orgAliasId }, role: Role, t) => ({
  settings: {
    name: t['Navigation:Settings'].get(),
    link: '',
    icon: <SettingsIcon />,
    subItems: {
      ...quota({ orgAliasId }, t),
      ...apiTokens(orgAliasId = { orgAliasId }, role, t),
    },
  },
});

//// ORG/MONITORS
//////////////////////////////////////////////////////////

const functions = ({ orgAliasId }, role: Role, t) => {
  if (!role) return;
  if (RoleEnum[role] < RoleEnum.OWNER) return;
  return ({
    functions: {
      name: t['Navigation:Functions'].get(),
      link: useLink('/org/:orgAliasId/monitors/functions', { orgAliasId }),
      subItems: {},
      icon: <FunctionSquareIcon />,
    },
  });
}

const action = ({ orgAliasId }, role: Role, t) => {
  if (!role) return;
  if (RoleEnum[role] < RoleEnum.OWNER) return;
  return ({
    action: {
      name: t['Navigation:Actions'].get(),
      //link: routes['/cms/global/system/actions'].path,
      link: useLink('/org/:orgAliasId/monitors/actions', { orgAliasId }),
      subItems: {},
      icon: <HandMetalIcon />,
    },
  });
}

const notification = ({ orgAliasId }, role: Role, t) => {
  if (!role) return;
  return ({
    notification: {
      name: t['Navigation:Notifications'].get(),
      icon: <NewspaperIcon />,
      subItems: {
        ...notificationTemplate({ orgAliasId }, role, t),
        ...notificationGroup({ orgAliasId }, t),
      },
    },
  })
}


const notificationTemplate = ({ orgAliasId }, role: Role, t) => {
  if (!role) return;
  if (RoleEnum[role] < RoleEnum.OWNER) return;
  return ({
    notificationTemplate: {
      name: t['Navigation:Templates'].get(),
      link: useLink('/org/:orgAliasId/monitors/notifications/templates', {
        orgAliasId,
      }),
      subItems: {},
      icon: <LayoutTemplateIcon />,
    },
  })
}

const notificationGroup = ({ orgAliasId }, t) => ({
  notificationGroup: {
    name: t['Navigation:Groups'].get(),
    link: useLink('/org/:orgAliasId/monitors/notifications/groups', {
      orgAliasId,
    }),
    subItems: {},
    icon: <UngroupIcon />,
  },

})


const monitors = ({ orgAliasId }, role: Role, t) => ({
  monitors: {
    name: t['Navigation:Monitors'].get(),
    icon: <MonitorCheckIcon />,
    link: undefined,
    subItems: {
      ...functions({ orgAliasId }, role, t),
      ...action({ orgAliasId }, role, t),
      ...notification({ orgAliasId }, role, t),
    },
  },
})



export function setOrgNavigationSubItems(
  navigation: NavigationItem,
  user: ReturnType<typeof api.user.getUserNav.useLiveData>['data'],
  roles: ReturnType<typeof api.user.getRoles.useLiveData>['data'],
  t
) {

  for (const org of user?.organizations) {
    const orgAliasId = org.alias;
    const orgItem = navigation.subItems[orgAliasId] = {
      name: org.name,
      link: useLink('/org/:orgAliasId', { orgAliasId }),
      parent: navigation,
      icon: <BuildingIcon />,
      active: false,
      subItems: {},
    }
    // console.log('roles............xx', roles) 
    orgItem.subItems = {
      ...projects(orgItem, org, roles, orgAliasId, t),
      ...orgMembers({ orgAliasId }, t),
      ...monitors({ orgAliasId }, roles[orgAliasId], t),
      ...auditLog({ orgAliasId }, t),
      ...integrations({ orgAliasId }, roles[orgAliasId], t),
      ...settings({ orgAliasId }, roles[orgAliasId], t),
    }

  }
}

