import { FC } from 'react'
import { OptionMenu } from '@shared/components/core/EditEntityMenu'
import { api } from '@shared/index'
import {
  ContextItem,
  DeleteIcon,
  EditIcon,
  Text,
  Toast,
  useDialog,
  useToast,
} from '@colombalink/basedui'
import { useLocation } from 'wouter'
import { useLink } from '../../../../routes'
import EditFunction from '@shared/components/monitor/function/dialogs/EditDialog'
import { useT } from '@app/i18n'

type RowMenuProps = {
  orgAliasId: string
  functionId: string
}

export const RowMenu = (props: RowMenuProps) => {
  const t = useT()
  const CategoryMenu: FC<{}> = ({}) => {
    const [, setLocation] = useLocation()
    const toast = useToast()

    const deleteFunction = api.function.deleteFunction.useMutation()

    return (
      <>
        <ContextItem
          onClick={() => {
            setLocation(
              useLink('/org/:orgAliasId/monitors/functions/:functionId', {
                orgAliasId: props.orgAliasId,
                functionId: props.functionId,
              })
            )
          }}
          icon={EditIcon}
        >
          {t['Monitor:Edit'].get()}
        </ContextItem>
        <ContextItem
          onClick={async () => {
            await deleteFunction.mutateAsync({ id: props.functionId })

            toast.add(
              <Toast
                label={t['Monitor:RemovedTemplate'].get()}
                type="success"
                description={<Text>{t['Monitor:RemovedSuccess'].get()}</Text>}
              />
            )
          }}
          icon={DeleteIcon}
        >
          {t['Monitor:Remove'].get()}
        </ContextItem>
      </>
    )
  }

  return <OptionMenu menu={CategoryMenu} style={{ width: '150px' }} />
}
