import { Select } from '@colombalink/basedui';
import { Page } from '@shared/components/page/Page';
import DefaultNavigation from '@shared/components/page/navigation/DefaultNavigation';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';
import { log } from '@shared/logger';

function DocPageSwitcher() {
  const [, { language }] = useTranslation();
  const [pages, setPages] = useState();
  const [location] = useLocation();
  const role = 'supervisor'


  log.debug("location:  " + location)

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  useEffect(() => {
    import('@colombalink/monidas-user-doc')
      .then((m) => setPages(m.default))
      .catch((e) => {
        setError(e);
      })
      .finally(() => {
        setIsLoading(false);
      })

  }, [])

  //console.log('i18n', language);
  const currentPage = location.replace('/docs', role)


  let localizationCode = 'enUS';

  if(language === 'de-CH' || language === 'de' || language === 'de-DE'){
    localizationCode = 'deCH';
  }
  //console.log('currentPage', currentPage);
  if (isLoading) return <div>Loading...</div>

  const keys = Object.keys(pages);
  //console.log('keys', keys);
  return (
    <div>
      {currentPage && pages[currentPage] && pages[currentPage][localizationCode]}
    </div>
  );
}


DocPageSwitcher.displayName = 'DocPageMain';


const DocApp = () => {
  return (
    <Page className='p-4'>
      <DefaultNavigation />
      <DocPageSwitcher />
    </Page>
  )
}


export default {
  '/docs/:rest*': {
    page: DocApp,
  }
}