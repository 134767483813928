import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useAppClient } from "@shared/client/context";
import { useAuthState } from "@shared/client/useAuthState";


export const WithI18LanguageSwitcher = () => {
  const client = useAppClient() 
  const { i18n: i } = useTranslation();
  const userId = useAuthState('app').userId 
  useEffect(() => {
    const unsub = client.query("app:user:getPreference", {userId}).subscribe(
      (preference) => {
        // console.log("...........", preference)
        i.changeLanguage(preference.language)
      },
      (error) => {
        // en as default for now
        i.changeLanguage('en')
      }
    )
    return () => {
      unsub()
    }
  },[])

  return <></>
}

