import { Code, Dialog, Text, Toast, useToast } from '@colombalink/basedui'
import React from 'react'
import { api } from '@shared/index'
import trpcErrorHandler from '@shared/utils/trpcErrorHandler'
import { WithI18n } from '@shared/i18n/withI18n'
import i18n from '../../i18n'
import { useT } from '@app/i18n'

const AddEventDialog = ({ functionId, playground }) => {
  const t = useT()
  return (
    <Dialog label={t["MonitorFunction:AddEvents"].get()}>
      <DialogContent functionId={functionId} playground={playground} />
    </Dialog>
  )
}

export default (props)=> (
  <WithI18n i18n={i18n}>
    <AddEventDialog {...props}  />
  </WithI18n>
)
const DialogContent = ({ functionId, playground }) => {
  return <CreateEventForm functionId={functionId} playground={playground} />
}

const CreateEventForm = ({
  functionId,
  playground,
}: {
  functionId: string
  playground: { id: string; events: any[] }
}) => {
  const t = useT()
  const [code, setCode] = React.useState(JSON.stringify({ object: { T: 1 } }))
  const createPlayGroundEvent = api.function.createPlayGroundEvent.useMutation()
  const toast = useToast()
  const handleSubmit = async () => {
    const playGroundEvent = {
      id: playground?.id,
      events: playground?.events,
      functionId: functionId,
    }

    if (playground) {
      playGroundEvent.events.push(code)
    } else {
      playGroundEvent.events = []
      playGroundEvent.id = ''
      playGroundEvent.events.push(code)
    }

    try {
      await createPlayGroundEvent.mutateAsync(playGroundEvent)
      toast.add(
        <Toast
          label={t["MonitorFunction:AddedEvent"].get()}
          type="success"
          description={<Text>{t["MonitorFunction:EventCreatedSuccessfully"].get()}</Text>}
        />
      )
    } catch (e) {
      const trpcErrorObject = trpcErrorHandler(e.message)
      trpcErrorObject.map(
        (e: {
          code: string
          message: string
          path: string[]
          validation: string
        }) => {
          toast.add(
            <Toast label={t["MonitorFunction:FailedToAddEvent"].get()} type="error" description={e.message} />
          )
        }
      )
      throw e
    }
  }
  return (
    <div>
      <div className="flex flex-col">
        <Text
          style={{
            paddingLeft: '5px',
            paddingBottom: '15px',
            paddingTop: '10px',
            color: 'gray',
          }}
        >
          {t["MonitorFunction:AddEventHere"].get()}
        </Text>
        <Code value={code} onChange={(event) => setCode(event)} />
      </div>
      <Dialog.Buttons>
        <Dialog.Cancel>{t["MonitorFunction:Close"].get()}</Dialog.Cancel>
        <Dialog.Confirm onConfirm={handleSubmit}>Save</Dialog.Confirm>
      </Dialog.Buttons>
    </div>
  )
}
