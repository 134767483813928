import { useRouteParams } from "@shared/router"
import { Button, Input, Menu, ScrollArea, Spacer } from "@colombalink/basedui"
import { Page } from "@shared/components/page/Page"
import DefaultNavigation from "@shared/components/page/navigation/DefaultNavigation"
import { useLocation } from "wouter"
import { useLink } from "@app/routes"
import { Fragment, useEffect, useState } from "react"
import { api } from "@shared/index"
import { PageContext, usePageContext } from "./context"
import { useTable } from "@shared/components/core/table/Table"
import { PageParams } from "./Page"
import { PayloadSelectionFragment } from "./fragments/PayloadSelection"
import { IdentifiersSelectionFragment } from "./fragments/IdentifiersSelection"
import { TagsSelectionFragment } from "./fragments/TagsSelection"
import { ValueSelectionFragment } from "./fragments/ValueSelection"
import { WithI18n } from "@shared/i18n/withI18n"
import i18n from "./i18n"
import { useT } from "@app/i18n"
import { LoadingIcon } from '@shared/components/core/LoadingIcon'

export const OwnerPage = () => {
  return (
    <PageContext>
      <Page
        className='pl-4 pr-4'
      >
        <DefaultNavigation />
        <Main />
      </Page>
    </PageContext>
  )
}

const Main = () => {
  return (
    <WithI18n i18n={i18n}>
      <ConfigureDeviceMain  />
    </WithI18n>
  )
}

Main.displayName = 'Main'

OwnerPage.displayName = 'OwnerPage'


const ConfigureDeviceMain = () => {
  const t = useT()
  const { thingAliasId, orgAliasId, projectAliasId } = useRouteParams<PageParams>()
  const [location, setLocation] = useLocation()
  const [active, setActive] = useState('step-1')
  const thing = api.thing.get.useLiveData({ thingAliasId })
  const {
    useStep: [step, setStep],
    useThingType: [, setThingType],
    useValidJSON: [validJSON],
  } = usePageContext()

  useEffect(() => {
    // first check if there is a hash
    if (!location.includes('#')) {
      setLocation(`${location}#step-1`)
      return
    }
    const hash = location.split('#')[1]
    setActive(hash)
  }, [location])

  useEffect(() => {
    setLocation(`${location.split('#')[0]}#step-${step}`)
    setActive(`step-${step}`)
  }, [step])


  useEffect(() => {
    if (thing?.data?.detail?.type) {
      switch (thing?.data?.detail?.type) {
        case 'chirpstackDevice':
          setThingType('chirp')
          break
        case 'genericThingDetail':
          setThingType('generic')
          break
        default:
          setThingType('unknown')
      }
    }

  }, [thing.data])

  if (thing.loading) {
    return <div>{t["ThingConfigure:Loading"].get()}</div>
  }

  if (!thing.data) {
    return <div>{t["ThingConfigure:Sorry"].get()}</div>
  }

  if (thing.data.isConfigured) {
    setTimeout(() => {
      setLocation(useLink('/org/:orgAliasId/project/:projectAliasId/thing/:thingAliasId', { orgAliasId, projectAliasId, thingAliasId }))
    }, 3000)

    return <div className="mt-4">
      {t["ThingConfigure:TheThing"].get()} <b>{thing.data.name}</b> {t["ThingConfigure:AlreadyConfigured"].get()}
      <Spacer />
      <Button onClick={
        () => {
          setLocation(useLink('/org/:orgAliasId/project/:projectAliasId/thing/:thingAliasId', { orgAliasId, projectAliasId, thingAliasId }))
        }

      }>{t["ThingConfigure:GoToThing"].get()} </Button>
    </div>
  }

  return (
    <div className='h-full flex'>
      <Menu
        style={{
          padding: '10px 14px 0px 0px',
          width: '140px'
        }}

        active={active}
        onChange={page => {
          if (page === 'Steps') {
            return
          }
          const switchStep = parseInt(page.split('-')[1])
          if (switchStep > step) {
            return
          }
          const newLocation = location.split('#')[0] + `#${page}`
          setActive(page)
          setLocation(newLocation)
          setStep(switchStep)
        }}
        data={{
          Steps: {
            'step-1': '1) '+t["ThingConfigure:Payload"].get(),
            'step-2': '2) '+t["ThingConfigure:Identifiers"].get(),
            'step-3': '3) '+t["ThingConfigure:Tags"].get(),
            'step-4': '4) '+t["ThingConfigure:Values"].get(),
            'step-5': '5) '+t["ThingConfigure:PropertiesDefinition"].get(),
          },
        }}
      >

        <div className="mt-4 flex justify-end">
          {step < 6 && validJSON ? (
            <Button
              keyboardShortcut="Enter"
              onClick={() => {
                setStep(step + 1);
              }}
            >
              {t["ThingConfigure:Next"].get()}
            </Button>
          ) : null}
        </div>
      </Menu>

      <div className='ml-4 mt-4 w-full overflow-scroll'>
        <FragmentSwitch active={active} >
          <PayloadSelectionFragment key={'step-1'} />
          <IdentifiersSelectionFragment key={'step-2'} />
          <TagsSelectionFragment key={'step-3'} />
          <ValueSelectionFragment key={'step-4'} />
          <AllUnitsAlreadyDefinitionFragment key={'step-5'} >
            <PropertiesDefinitionFragment />
          </AllUnitsAlreadyDefinitionFragment>
        </FragmentSwitch>

      </div>
    </div>
  )
}

ConfigureDeviceMain.displayName = 'ConfigureDeviceMain'





const AllUnitsAlreadyDefinitionFragment = ({ children }) => {
  const t = useT()
  const { thingAliasId, orgAliasId, projectAliasId } = useRouteParams<PageParams>()
  const {
    useFlatPaths: [flatPaths],
    useTags: [tags],
    useValues: [values],
    useIdentifiers: [identifiers],
    useExistingPopertyPaths: [, setExistingPopertyPaths],
    useThingType: [thingType],
  } = usePageContext()

  const setDevicePayloadSchema = api.thing.setPayloadSchema.useMutation()
  let selectedPropertyPaths = tags.concat(values)

  const existingPropertyPaths = api.payloadProperty.getIfExists.useData({
    selectedPropertyPaths: tags.concat(values).concat(Object.values(identifiers)),
  })

  useEffect(() => {
    if (existingPropertyPaths.data) {
      setExistingPopertyPaths(existingPropertyPaths.data)
    }
  }, [existingPropertyPaths.data])

  if (existingPropertyPaths.loading) {
    return <LoadingIcon></LoadingIcon>
  }
  //console.log('x', existingPropertyPaths.data)

  if (thingType === 'chirp') {
    selectedPropertyPaths = selectedPropertyPaths.concat(Object.values(identifiers))
  }
  //console.log('selectedPropertyPaths', selectedPropertyPaths)
  //console.log('existingPopertyPaths', existingPropertyPaths)

  if (selectedPropertyPaths.length === existingPropertyPaths.data.length) {
    // todo show just button
    console.log('existingPropertyPaths........', existingPropertyPaths)
    console.log('todo: show button')
    return (
      <div>
        <div className="max-w-3xl">
          <p>
            {t["ThingConfigure:AllUnitsAlreadyDefined"].get()}
            <br/><br/>
            {t["ThingConfigure:AllUnitsAlreadyDefined2"].get()}
          </p>
          <Spacer />
        </div>

        <Button
          onClick={async () => {
            // todo: properly set the schema
            await setDevicePayloadSchema.mutateAsync({
              thingAliasId,
              orgAliasId: orgAliasId,
              properties: existingPropertyPaths.data,
              propertyPaths: existingPropertyPaths.data.map((p) => ({ path: p.name, unitUri: p.unit.uri })),
              identifiers: thingType === 'chirp' ? Object.values(identifiers) : [],
              values: values,
              tags: tags,
            })
          }}
        >
          {t["ThingConfigure:Save"].get()}
        </Button>

      </div>
    )
  }

  else {
    return children
  }


}



const PropertiesDefinitionFragment = () => {
  const t = useT()
  const defaultPayloadDescription = t["ThingConfigure:DefaultPayloadDescription"].get()
  const [payloadDescription, setPayloadDescription] = useState(defaultPayloadDescription)
  const [payloadDescription1, setPayloadDescription1] = useState(null)
  const { thingAliasId, orgAliasId, projectAliasId } = useRouteParams<PageParams>()
  const {
    useSampleEvent: [sampleEvent],
    useFlatPaths: [flatPaths],
    useStep: [, setStep],
    useTags: [tags],
    useValues: [values],
    useIdentifiers: [identifiers],
    useExistingPopertyPaths: [existingPopertyPaths, setExistingPopertyPaths],
    useThingType: [thingType],
  } = usePageContext()
  const [hasCompletedGen, setHasCompletedGen] = useState(false)


  const setDevicePayloadSchema = api.thing.setPayloadSchema.useMutation()
  let selectedPropertyPaths = tags.concat(values)
  if (thingType === 'chirp') {
    selectedPropertyPaths = selectedPropertyPaths.concat(Object.values(identifiers))
  }

  const helper = api.monica.thingIntegrationHelper.useLiveData({
    selectedPropertyPaths,
    sampleEvent: JSON.parse(sampleEvent),
    orgAliasId: orgAliasId,
    payloadDescription: payloadDescription1
  })
  console.log('helper', helper)
  const [isProcessing, setIsProcessing] = useState(helper?.data?.isProcessing === undefined || helper?.data?.isProcessing)

  const [tableData, setTableData] = useState([])
  const [table, TableComponent] = useTable({
    header: [
      { label: t["ThingConfigure:PropertyPath"].get(), key: 'prop.name' },
      { label: t["ThingConfigure:Label"].get(), key: 'prop.label.en', isEditable: true },
      { label: t["ThingConfigure:UnitLabel"].get() , key: 'prop.unit.label.en', isEditable: true },
      { label: t["ThingConfigure:UnitAbbreviation"].get(), key: 'prop.unit.abbreviation', isEditable: true },
      { label: t["ThingConfigure:UnitSymbol"].get(), key: 'prop.unit.symbol', isEditable: true },
      { label: t["ThingConfigure:PrimitiveDataType"].get(), key: 'prop.primitiveDataType' },
      { label: t["ThingConfigure:UnitURI"].get(), key: 'prop.unit.uri', isEditable: true },
    ],
    data: tableData,
    onUpdatedCell(rowIndex, columnId, value) {
      console.log('onUpdatedCell', rowIndex, columnId, value)
    },
  })

  useEffect(() => {
    console.log('useEffect helper?.data?.state')
    if (!helper?.data?.state) {
      setIsProcessing(false)
      return
    }


    const tableData = Object.values(helper?.data?.state).map((prop) => {
      return {
        prop,
        customAttribute: {},
      }
    })
    console.log('tableData', tableData)

    console.log('existingPopertyPaths', existingPopertyPaths)
    const filteredTableData = tableData.filter((item) => {
      // Check if the unit.propertyPath is not present in existingPopertyPaths's 'name' attribute
       return !existingPopertyPaths.some((otherItem) => otherItem.name === item.prop.name);
     });
     console.log('filteredTableData', filteredTableData)

    setTableData(filteredTableData)
    setIsProcessing(helper?.data?.isProcessing)
  }, [helper])

  console.log('i.....', identifiers)

  return (
    <div>
      <div className="max-w-3xl">
        <p>
          {t["ThingConfigure:Monica"].get()}
        </p>
        <Spacer />
        <Input
          type="multiline"
          value={payloadDescription}
          onChange={setPayloadDescription}
        />
      </div>
      <Button
        style={{ marginTop: '8px' }}
        disabled={isProcessing}
        onClick={() => {
          setPayloadDescription1(payloadDescription)
          setHasCompletedGen(true)
        }}
      >
        {!isProcessing && (<>{t["ThingConfigure:GenerateUnits"].get()}</>)}
        {isProcessing && (<> {t["ThingConfigure:GeneratingUnits"].get()} <LoadingIcon style={{ marginLeft: "4px" }} /></>)}
      </Button>

      <div className="w-full max-h-[500px] overflow-y-scroll">
        <ScrollArea>{TableComponent}</ScrollArea>
      </div>

      <Button
        disabled={isProcessing || !tableData || !hasCompletedGen}
        onClick={async () => {
          // todo: properly set the schema

          const _identifiers: string[] = thingType === 'chirp' ? Object.values(identifiers) : []
          const all = values.concat(tags) as string[]
          _identifiers.forEach((i) => all.includes(i) ? null : all.push(i))
          const propertyPaths = all.map((path) => ({
            path,
            unitUri: helper?.data?.state[path]?.unit?.uri
              || existingPopertyPaths.find((payloadProperty) => payloadProperty.name === path)?.unit?.uri
          }))

          console.log('propertyPaths', propertyPaths)
          await setDevicePayloadSchema.mutateAsync({
            thingAliasId,
            orgAliasId: orgAliasId,
            properties: Object.values(helper?.data?.state),
            propertyPaths,
            identifiers: _identifiers,
            values,
            tags,
          })
        }}
      >
        {t["ThingConfigure:Save"].get()}
      </Button>

    </div>
    // <div className="grid grid-cols-2 w-full h-100">
    //   <div className="w-full max-h-[500px] overflow-y-scroll">
    //     <ScrollArea>{TableComponent}</ScrollArea>
    //   </div>
    //   <div className="min-w-[400px] flex flex-col h-full overflow-hidden mr-4">
    //     <div className="flex flex-1 flex-col overflow-y-scroll h-[400px] m-4">
    //       <div className="w-full flex justify-end mb-4">
    //       </div>

    //       <Input
    //         placeholder={
    //           isProcessing ? 'Monica is processing...' : 'Type a message...'
    //         }
    //         type="text"
    //         value={input}
    //         onChange={(value) => {
    //           setInput(value)
    //         }}
    //       />
    //       <Spacer />
    //       {!isProcessing && (
    //         <Button
    //           disabled={isProcessing || !tableData}
    //           onClick={async () => {
    //           }}
    //         >
    //           Update
    //         </Button>
    //       )}
    //       {isProcessing && <LoadingIcon style={{ marginBottom: '4px' }} />}
    //     </div>
    //   </div>
    // </div>
  )
}




const FragmentSwitch = ({ active, children }: { active: string, children }) => {
  const t = useT()
  let ActiveFragment = null


  if (!(children instanceof Array)) {
    children = [children]
  }
  for (let child of children) {
    if (child.key === active) {
      ActiveFragment = child
      break
    }
  }
  if (!ActiveFragment) {
    ActiveFragment = <>{t["ThingConfigure:SorryPageNotFound"].get()} </>
  }
  return (
    <Fragment>
      {ActiveFragment}
    </Fragment>
  )
}