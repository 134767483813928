import { Unit } from '@server/shared'
import { useTooltip } from '@colombalink/basedui'

export const StatsDetailsTable = ({
  unit,
  statsData,
  property,
}: {
  unit: Unit | null
  statsData: any
  property: string
}) => {
  let min: { value?: any; time?: string }
  let max: { value?: any; time?: string }
  let mean: { value: number }
  if (statsData != null && Object.keys(statsData).length) {
    min = statsData[property].min
    max = statsData[property].max
    mean = statsData[property].mean
  } else {
    min = { value: 0, time: '' }
    max = { value: 0, time: '' }
    mean = { value: 0 }
  }
  const minValue = min.value.toFixed(2)
  const maxValue = max.value.toFixed(2)
  const meanValue = mean.value.toFixed(2)

  const minTooltip = useTooltip(new Date(min.time).toLocaleString(), 'right')
  const maxTooltip = useTooltip(new Date(max.time).toLocaleString(), 'right')
  return (
    <table>
      <tbody>
        <tr>
          <td className="text-txt">Max:</td>
          <td className="text-txt" {...maxTooltip}>
            {' '}
            {maxValue} {unit?.symbol || ''}{' '}
          </td>
        </tr>
        <tr>
          <td className="text-txt">Mean:</td>
          <td className="text-txt">
            {' '}
            {meanValue} {unit?.symbol || ''}{' '}
          </td>
        </tr>
        <tr>
          <td className="text-txt">Min:</td>
          <td className="text-txt" {...minTooltip}>
            {' '}
            {minValue} {unit?.symbol || ''}{' '}
          </td>
        </tr>
      </tbody>
    </table>
  )
}
