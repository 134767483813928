import {
  AddIcon,
  useToast,
} from '@colombalink/basedui'
import DefaultNavigation from '@shared/components/page/navigation/DefaultNavigation'
import React, { useEffect } from 'react'
import { DynamicButtonRow } from '@shared/components/core/DynamicButtonRow'
import { RowMenu } from './RowMenu'
import { Page } from '@shared/components/page/Page'
import { Row } from '@influxdata/influxdb-client'
import { Title } from '@shared/components/core/Title'
import { api } from '@shared/index'
import { useLink } from '../../../routes'
import { useLocation } from 'wouter'
import { useTable } from '@shared/components/core/table/Table'
import InviteMemberToOrgDialog from './AddDialog'
import { Role, getRoleLabel } from '@server/shared'
import { useRouteParams } from '@shared/router'
import { WithI18n } from '@shared/i18n/withI18n'
import i18n from './i18n'
import { useT } from '@app/i18n'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'
import { InvitationRowMenu } from './InvitationRowMenu'
import { useTranslation } from 'react-i18next'

type PageProps = { params: { orgAliasId: string } }
export type PageParams = PageProps['params']

const OrganisationsMembersPage = (props: PageProps) => {
  return (
    <Page>
      <DefaultNavigation />
      <Main />
    </Page>
  )
}

const Main = () => {

  return (
    <WithI18n i18n={i18n}>
      <ManageOrganizationsMembers />
    </WithI18n>
  )
}
Main.displayName = 'Main'

export default OrganisationsMembersPage

const ManageOrganizationsMembers = () => {
  const t = useT()
  const { orgAliasId } = useRouteParams<PageParams>()
  return (
    <div className="flex flex-col">
      <DynamicButtonRow buttons={[
        {
          icon: <AddIcon />,
          dialogComponent: <InviteMemberToOrgDialog orgAliasId={orgAliasId} />,
          label: t['Member:Invite'].get(),
          style: { cursor: 'pointer' }
        }
      ]
      } />

      <OrganizationTable />
      <OpenInvitationsTable />
    </div>
  )
}

ManageOrganizationsMembers.displayName = 'ManageOrganizationsMembersMain'

function OrganizationTable() {
  const t = useT()
  const { orgAliasId } = useRouteParams<PageParams>()
  const org = api.organization.getOrganizationById.withMembers.useLiveData({ orgAliasId: orgAliasId });
  const [tableData, setTableData] = React.useState([])


  const [table, TableComponent] = useTable({
    header: [
      { label: t['Member:Name'].get(), key: 'user.name' },
      { label: t['Member:Role'].get(), key: 'roleLabel' },
      { label: '', key: 'action' },
    ],
    data: tableData,
  })


  useEffect(() => {
    if (org.data?.members) {
      //console.log(org.data?.members)
      setTableData(
        org.data.members.map((member) => {
          return {
            ...member,
            roleLabel: getRoleLabel(member.role as Role, 'en'),
            action: (<RowMenu member={member} />),
            customAttribute: {
            },
          }
        })
      )
    }
  }, [org.data?.members])

  if (org.loading) {
    return <>{t['Member:Loading'].get()} <LoadingIcon /></>
  }
  return (
    <>
      {TableComponent}
    </>
  )
}


function OpenInvitationsTable() {
  const t = useT()
  const [, trans] = useTranslation()
  const { orgAliasId } = useRouteParams<PageParams>()
  const org = api.organization.getOrganizationById.withMembers.useLiveData({ orgAliasId: orgAliasId });
  const [tableData, setTableData] = React.useState([])


  const [table, TableComponent] = useTable({
    header: [
      { label: t["Member:InvitationEmail"].get() , key: 'email' },
      { label: t["Member:InvitationExpirationDate"].get() , key: 'expiresIn' },
      { label: '', key: 'action' },
    ],
    data: tableData,
  })

  useEffect(() => {
    if (org.data?.members) {
      //console.log(org.data?.members)
      setTableData(
        org.data.memberInvitations.map((memberInvitation) => {
          const expiresIn = new Date(memberInvitation.createdAt + 24 * 60 * 60 * 1000)

          return {
            ...memberInvitation,
            expiresIn: t['intlDateTime'].get({
              val: expiresIn,
              formatParams: {
                val: { dateStyle: 'short', timeStyle: 'short' },
              },
            }),
            action: (<InvitationRowMenu invitationId={memberInvitation.id} />),
            customAttribute: {
            },
          }
        })
      )
    }
  }, [org.data?.members, trans.language])

  if (org.data?.memberInvitations.length === 0) {
    return <></>
  }

  if (org.loading) {
    return <>{t['Member:Loading'].get()} <LoadingIcon /></>
  }
  return (
    <>
      <h1 className='ml-4'>Open invitations</h1>
      {TableComponent}
    </>
  )
}