import {useProjectDashboardContext} from "@app/pages/Orgs/Project/DashboardContext";
import {LoadingIcon} from "@shared/components/core/LoadingIcon";
import {NoThingFoundRedirect} from "@app/pages/Orgs/Project/ExplorerFragment/shared/NoThingsFoundRedirect";

export const AsyncStateHandler = ({children}) => {
    const { things } = useProjectDashboardContext()

    if (things.loading) {
        return <LoadingIcon />
    }

    if (!things.array) {
        return <NoThingFoundRedirect />
    }

    if(things.error) {
        throw things.error
    }

    return children
}