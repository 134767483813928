import { LoadingIcon } from '@shared/components/core/LoadingIcon'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useProjectExplorerContext } from './ExplorerContext'
import { useProjectDashboardContext } from '../DashboardContext'


function useProvideContext({ viewId }: { viewId: string }) {

    const [properties, setProperties] = React.useState({})
    const [things, setThings] = React.useState({})
    const [propertyKeys, setPropertyKeys] = React.useState<string[]>([])
    const { views } = useProjectExplorerContext()
    const dashboardCtx = useProjectDashboardContext()
    const view = views.values[viewId]
    const didSetProperties = useState(false)
    const useTimeFilters = useState<{daily: Date, monthly: number, yearly: number}>({
        daily: new Date(),
        monthly: new Date().getMonth(),
        yearly: new Date().getFullYear()
    })

    useEffect(() => {
        const things = {}
        const allProperties = {}
        for (const selection of view.selections) {
            const properties = {}
            for (const property of selection.thingProperties) {
                const prop = dashboardCtx.useThingProperty(selection.thingId, property.key)
                properties[`${selection.thingId}_${property.key}`] = prop.getProperty()
                allProperties[`${selection.thingId}_${property.key}`] = prop.getProperty()
            }
            things[selection.thingId] = {
                label: dashboardCtx.things.map[selection.thingId].name,
                properties
            } 
        }
       
        setThings(things)
        setProperties(allProperties)
        didSetProperties[1](true)
    }, [view])


    useEffect(() => {
        setPropertyKeys(Object.keys(properties))
    }, [properties])

    return {
        loading: !didSetProperties[0],
        things,
        properties,
        propertyKeys,
        view,
        useTimeFilters
        // loading: thing.loading || thingWithLastEvent.loading,
        // thing,
        // lastEvent: thingWithLastEvent.data?.lastEvent,
        // useViewThingProperty:  (property: string) => ({
        //   getStatus: () => getViewThingPropertyStatus(thing.data, property),
        //   getProperty: () => properties[property], 
        // })
    }
}

type ContextType = ReturnType<typeof useProvideContext>
const Context = createContext<ContextType | undefined>(undefined)


type Props = {
    viewId: string
}

const ViewThingContext: React.FC<Props> = ({ children, viewId }) => {
    const value = useProvideContext({ viewId })

    if (value.loading) {
        return <LoadingIcon />
    }

    return <Context.Provider value={value}>{children}</Context.Provider>
}

const useViewThingContext = (): ContextType => {
    const context = useContext(Context)
    if (!context) {
        //TODO: make this error in multilingual 
        throw new Error('useViewThingContext must be used within a ViewThingContext')
    }
    return context
}



export { useViewThingContext, ViewThingContext }
