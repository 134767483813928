import {
  Dialog,
  Input,
  MultiSelect,
  Select,
  Spacer,
  Text,
  Toast,
  useToast,
} from '@colombalink/basedui'
import React from 'react'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'
import { api } from '@shared/index'
import { useT } from '@app/i18n'

type EditProps = {
  unit: {
    id: string
    label: string
    symbol: string
  }
}

const EditUnit = (props: EditProps) => {
  const t = useT()
  const [updateUnit, setUpdateUnit] = React.useState({
    ...props.unit,
  })
  const updateUnitMutation = api.unit.updateUnit.useMutation()
  const toast = useToast()
  const handleOnChange = (key, value) => {
    setUpdateUnit((prev) => ({
      ...prev,
      [key]: value,
    }))
  }

  const handleSubmit = async () => {
    try {
      await updateUnitMutation.mutateAsync(updateUnit)
      toast.add(
        <Toast
          label= {t['Thing:UpdatedUnit'].get()}
          type="success"
          description={
            <Text>
              {t["Thing:Unit"].get()} <b>{updateUnit?.label}</b> {t["Thing:UpdatedSuccessfully"].get()}
            </Text>
          }
        />
      )
    } catch (e) {
      toast.add(
        <Toast
          label= {t["Thing:FailedToUpdateUnit"].get()}
          type="error"
          description={
            <Text style={{ whiteSpace: 'normal' }}>{e.message}</Text>
          }
        />
      )
      throw e
    }
  }

  return (
    <Dialog label={t["Thing:EditUnit"].get()}>
      <div>
        <Input
          label={t["Thing:Label"].get()}
          value={updateUnit.label}
          type="text"
          onChange={(label) => handleOnChange('label', label)}
        />
        <Spacer space={20} />
        <Input
          label={t["Thing:Symbol"].get()}
          value={updateUnit?.symbol}
          type="text"
          onChange={(symbol) => handleOnChange('symbol', symbol)}
        />
       
      </div>
      <Dialog.Buttons>
        <Dialog.Cancel displayShortcut={false}>{t["Thing:Close"].get()}</Dialog.Cancel>
        <Dialog.Confirm onConfirm={handleSubmit} displayShortcut={false}>
          {t["Thing:Save"].get()}
        </Dialog.Confirm>
      </Dialog.Buttons>
    </Dialog>
  )
}

export default EditUnit
