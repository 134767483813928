import React, { useEffect, useState } from 'react'
import {
  Dialog,
  Input,
  MultiSelect,
  Spacer,
  Text,
  Toast,
  useToast,
} from '@colombalink/basedui'
import { api } from '@shared/index'
import PropertySelection from '@app/pages/Orgs/Project/ExplorerFragment/PropertySelection'
import { WithI18n } from '@shared/i18n/withI18n'
import i18n from '../../i18n'
import { useT } from '@app/i18n'

export const _CreateNewView = ({ things }) => {
  return <CreateViewForm things={things} />
}

export const CreateNewView = (props) => (
  <WithI18n i18n={i18n}>
      <_CreateNewView {...props} />
  </WithI18n>
)

const CreateViewForm = ({ things }) => {
  const t = useT()
  const toast = useToast()
  const [viewName, setViewName] = useState('')
  const [selectedThingsIds, setSelectedThingsIds] = useState<string[]>([])
  const thingsOptions = things.array
  .filter(thing => thing.isConfigured === true)
  .map((thing) => ({
    label: thing.name,
    value: thing.id,
  }))

  if(thingsOptions.length === 0){
    toast.add(
      <Toast
        label={t["SProject:NoConfiguredDevices"].get()}
        type="error"
        description={
          <Text style={{ whiteSpace: 'normal' }}> {t["SProject:PleaseConfigureD"].get()} </Text>
        }
      />
    )
  }

  const addView = api.project.addExplorerViewToProject.useMutation()

  const [selections, setSelections] = useState([])

  // Update the selections state when selectedThingsIds changes
  useEffect(() => {
    setSelections(
      selectedThingsIds.map((thingId) => ({
        thingId,
        properties:
          selections.find((s) => s.thingId === thingId)?.properties || [],
      }))
    )
  }, [selectedThingsIds, things])

  const handlePropertySelectionChange = (
    thingId,
    propertyKey,
    isEdited,
    color
  ) => {
    setSelections((selections) => {
      const selection = selections.find((s) => s.thingId === thingId)
      const property = selection.properties.find((p) => p.key === propertyKey)

      if (property) {
        property.color = color
      } else {
        selection.properties.push({
          key: propertyKey,
          color,
        })
      }

      return [...selections]
    })
  }

  return (
    <Dialog label={t["SProject:CreateView"].get()}>
      <div className="flex flex-col m-4">
        <Input
          value={viewName}
          label= {t["SProject:ViewName"].get()}
          type="text"
          placeholder={t["SProject:EnterViewName"].get()}
          onChange={(name) => setViewName(name)}
        />
        <Spacer />
        <MultiSelect
          label={t["SProject:SelectDevices"].get()}
          values={selectedThingsIds}
          options={thingsOptions}
          onChange={(ids: string[]) => setSelectedThingsIds(ids)}
        />
        {selections.map((selection) => {
          const thing = things.map[selection.thingId]
          return (
            <PropertySelection
              key={selection.thingId}
              thingId={selection.thingId}
              thingName={thing?.name}
              influxFields={thing?.payloadSchema?.influxFields || []}
              selectedProperties={selection.properties}
              onPropertySelectionChange={handlePropertySelectionChange}
            />
          )
        })}
      </div>
      <Dialog.Buttons>
        <Dialog.Cancel>{t["SProject:Close"].get()}</Dialog.Cancel>
        <Dialog.Confirm
          onConfirm={async () => {
            const viewConfig = {
              name: viewName,
              selections: selections.map((selection) => ({
                thingId: selection.thingId,
                thingProperties: selection.properties.map((prop) => ({
                  key: prop.key,
                  color: prop.color,
                })),
              })),
            }

            try {
              await addView.mutateAsync(viewConfig)
              toast.add(
                <Toast
                  label={t["SProject:CreatedView"].get()}
                  type="success"
                  description={
                    <Text>
                      View <b>{viewConfig.name}</b> {t["SProject:CreatedSuccessfully"].get()}
                    </Text>
                  }
                />
              )
            } catch (e) {
              toast.add(
                <Toast
                  label={t["SProject:FailedToCreateView"].get()}
                  type="error"
                  description={
                    <Text style={{ whiteSpace: 'normal' }}>{e.message}</Text>
                  }
                />
              )
              throw e
            }
          }}
        >
          {t["SProject:Save"].get()}
        </Dialog.Confirm>
      </Dialog.Buttons>
    </Dialog>
  )
}
