const ProjectDevicesPageContentI18n = {

    "Devices:AddGeneric": {
        "en": "Add Generic",
        "de": "Generisches Gerät hinzufügen",
    },
    "Devices:Add": {
        "en": "Add",
        "de": "Hinzufügen",
    },
    "Devices:Edit": {
        "en": "Edit",
        "de": "Bearbeiten",
    },
    "Devices:Delete": {
        "en": "Delete",
        "de": "Löschen",
    },
    "Devices:Configure": {
        "en": "Configure",
        "de": "Konfigurieren",
    },
    "Devices:OpenInChirpstack": {
        "en": "Open in Chirpstack",
        "de": "In Chirpstack öffnen",
    },
    "Devices:DeletedSuccessfully": {
        "en": "Deleted successfully",
        "de": "Erfolgreich gelöscht",
    },
    "Devices:DeletedDevice": {
        "en": "Deleted device",
        "de": "Gerät gelöscht",
    },
    "Devices:Name": {
        "en": "Name",
        "de": "Name",
    },
    "Devices:IsConfigured": {
        "en": "Is Configured",
        "de": "Konfiguriert",
    },
    "Devices:Loading": {
        "en": "Loading",
        "de": "Laden",
    },
    "Devices:Save": {
        "en": "Save",
        "de": "Speichern",
    },
    "Devices:Cancel": {
        "en": "Cancel",
        "de": "Abbrechen",
    },
    "Devices:CreatedDevice": {
        "en": "Created Device",
        "de": "Gerät erstellt",
    },
    "Devices:Device": {
        "en": "Device",
        "de": "Gerät",
    },
    "Devices:CreatedSuccessfully": {
        "en": "created successfully",
        "de": "erfolgreich erstellt",
    },
    "Devices:FailedToCreate": {
        "en": "Failed to create Device",
        "de": "Fehler beim Erstellen des Geräts",
    },
    "Devices:EditDevice": {
        "en": "Edit Device",
        "de": "Gerät bearbeiten",
    },

    
}

export default ProjectDevicesPageContentI18n
export type ProjectDevicesPageContentI18nType = typeof ProjectDevicesPageContentI18n
