import { PageRoleSwitcher } from "@shared/components/page/PageRoleSwitcher"
import { MonitorNotificationGroupOwnerPage } from "./Page.owner"
import { MonitorNotificationGroupSupervisorPage } from "./Page.supervisor"
import DefaultErrorPage from "@shared/components/errors/DefaultErrorPage"


type PageProps = { params: { orgAliasId: string, projectAliasId: string, thingAliasId: string } }
export type PageParams = PageProps['params']

export default (props: PageProps) => {
  return (
    <PageRoleSwitcher>
      <MonitorNotificationGroupOwnerPage />
      <MonitorNotificationGroupSupervisorPage />
    </PageRoleSwitcher>
  )
}