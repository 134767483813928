import AddNotificationTemplateDialog from '@shared/components/monitor/template/dialogs/AddDialog'
import DefaultNavigation from '@shared/components/page/navigation/DefaultNavigation'
import React, { useEffect } from 'react'
import { AddIcon, useToast } from '@colombalink/basedui'
import { DynamicButtonRow } from '@shared/components/core/DynamicButtonRow'
import { MonitorMenu } from '@app/pages/Orgs/Monitor/shared/Menu'
import { Page } from '@shared/components/page/Page'
import { RowMenu } from './RowMenu'
import { api } from '@shared/index'
import { useLocation } from 'wouter'
import { useTable } from '@shared/components/core/table/Table'
import { CenteredContainerOld } from '@shared/components/core/CenteredContainerOld'
import { TemplateLandingFragment } from '@shared/components/monitor/template/LandingFragment'
import { WithI18n } from '@shared/i18n/withI18n'
import i18n from '../i18n'
import { useT } from '@app/i18n'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'

type PageProps = { params: { orgAliasId: string } }
export type PageParams = PageProps['params']

const MonitorNotificationTemplatePage = (props: PageProps) => {
  return (
    <Page className="pl-4">
      <DefaultNavigation />
      <Main />
    </Page>
  )
}

const Main = () => {
  return (
    <WithI18n i18n={i18n}>
      <ManageNotificationTemplate />
    </WithI18n>
  )
}
Main.displayName = 'Main'

export default MonitorNotificationTemplatePage

const ManageNotificationTemplate = () => {
  const t = useT()
  const templates = api.organization.monitor.notification.template.getAll.useLiveData()
  const button = {
    icon: <AddIcon />,
    dialogComponent: <AddNotificationTemplateDialog />,
    label: t['Monitor:Add'].get(),
    style: { cursor: 'pointer' },
  }
  return (
    <div className="flex h-full">
      <MonitorMenu page={'notificationTemplates'} />

      <div className="flex flex-col overflow-auto w-full mx-4 mt-4 ">
        <TemplateLandingFragment>
          <>
            <DynamicButtonRow
              buttons={[
                {
                  icon: <AddIcon />,
                  dialogComponent: <AddNotificationTemplateDialog />,
                  label: t['Monitor:Add'].get(),
                  style: { cursor: 'pointer' },
                },
              ]}
            />
            <NotificationTemplateTable templates={templates} />
          </>
        </TemplateLandingFragment>
      </div>
    </div>
  )
}

ManageNotificationTemplate.displayName = 'Main'

function NotificationTemplateTable({ templates }) {
  const t = useT()
  const [, setLocation] = useLocation()
  const removeOrganization =
    api.organization.monitor.notification.template.delete.useMutation()
  const toast = useToast()
  const [tableData, setTableData] = React.useState([])

  const [table, TableComponent] = useTable({
    header: [
      { label: t['Monitor:Name'].get(), key: 'name' },
      { label: t['Monitor:Title'].get(), key: 'title' },
      { label: t['Monitor:Message'].get(), key: 'message' },
      { label: t['Monitor:Actions'].get(), key: 'action' },
    ],
    data: tableData,
  })

  useEffect(() => {
    if (templates.data) {
      setTableData(
        templates?.data?.map((template) => {
          return {
            ...template,
            action: <RowMenu template={template} />,
            customAttribute: {},
          }
        })
      )
    }
  }, [templates.data])

  if (templates.loading) {
    return (
      <>
        {t['Monitor:Loading'].get()} <LoadingIcon />
      </>
    )
  }
  return <>{TableComponent}</>
}
