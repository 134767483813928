const SettingsQuotaPageContentI18n = {


    "SettingsQuota:Name": {
        "en": "Name",
        "de": "Name",    
    },
    "SettingsQuota:Count": {
        "en": "Count",
        "de": "Anzahl",    
    },
    "SettingsQuota:Limit": {
        "en": "Limit",
        "de": "Limit",    
    },
    "SettingsQuota:Loading": {
        "en": "Loading",
        "de": "Laden",    
    },
}

export default SettingsQuotaPageContentI18n
export type SettingsQuotaPageContentI18nType = typeof SettingsQuotaPageContentI18n
