import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'
import * as echarts from 'echarts/core'
import { EChartsOption } from 'echarts'
import { Property } from '@server/shared'
import { useViewThingContext } from '@app/pages/Orgs/Project/ExplorerFragment/MultiThingContex'
import { toDate } from 'date-fns'
import SingleSeriesLineChart from './SingleSeriesLineChart'

function  useProvideContext({
  interval: {
    from,
    to,
  }
}: {
  interval: {
    from: number
    to: number
  }
}) {
  const useChart = useState<null | echarts.ECharts>(null)
  const options = useState<EChartsOption>(null)
  const seriesData = useState<any[]>([])
  const useProperties = useState<{ keys: string[], values: { [key: string]: Property } }>({ keys: [], values: {} })

  // console.log("useProperties", useProperties)

  const useFromDate = useState<number>(from)
  const useToDate = useState<number>(to)
  const useHighlightedField = useState<string>()
  const useVisibleFields = useState<Map<string, boolean>>(new Map())
  const [chartInstance] = useState(
  ) 

  const useZoom = useState<{start: number, end: number}>({start: 0, end: 100,})

  useEffect(() => {
    console.log("update tooooooo")
  },[useToDate[0]])
  return {
    useZoom,
    useChart,
    options,
    seriesData,
    useProperties,
    useFromDate,
    useToDate,
    useHighlightedField,
    useVisibleFields,
    chartInstance
  }
}


type ContextType = ReturnType<typeof useProvideContext>
const Context = createContext<ContextType | undefined>(undefined)

type Props = {
  interval?: {
    from: number
    to: number
  }
} & PropsWithChildren

const ChartContext: React.FC<Props> = ({ children, interval }) => {

  const now = new Date().getTime()
  const from = now - 1000 * 60 * 60 * 24
  const to = now
  interval = interval || { from, to }

  const value = useProvideContext({ interval })

  return <Context.Provider value={value}>{children}</Context.Provider>
}

const useChartContext = (): ContextType => {
  const context = useContext(Context)
  if (!context) {
    throw new Error('useChartContext must be used within a ChartContext')
  }
  return context
}

export { useChartContext, ChartContext }
