import FadeInBox from '../shared/FadeInBox'
import MainContainer from '../shared/MainContainer'

import { Button, Input, LockIcon, Spacer, Tab, Tabs, Text, Toast, useToast } from '@colombalink/basedui';
import { useState, useRef, useEffect } from 'react';
import { useLocation } from 'wouter';
import { WithI18n } from '@shared/i18n/withI18n';
import i18n from './i18n';
import { useT } from '@app/i18n';
import { ZodInputValidator } from '@shared/zod/InputValidator';
import { changePasswordParams } from '@server/app/trpc/monidas/routers/user/changePassword';
import { LoadingIcon } from '@shared/components/core/LoadingIcon';
import { useIdentityClient } from '@shared/client/context';



export default (params) => (
    <WithI18n i18n={i18n}>
        <MainContainer>
            <FadeInBox>
                <TokenValidator {...params} >
                    <ResetPage {...params} />
                </TokenValidator>
            </FadeInBox>
        </MainContainer>
    </WithI18n>
)


const TokenValidator = ({children, params}) => {
    // console.log(params)
    const [isValidating, setIsValidating] = useState(true)
    const [isInvalid, setIsInvalid] = useState(false)
    const client = useIdentityClient()

    useEffect(() => {
        client.call('login:password:validateResetToken', params)
        .then(() => setIsValidating(false))
        .catch(() => setIsInvalid(true))
    }, [])

    if(isInvalid) {
        return <div>Token is invalid.</div>
    }

    if (isValidating) {
        return <div>Validating Token <LoadingIcon></LoadingIcon> </div>
    }
    
    return <>{children}</> 
}


const ResetPage = ({ params }: { params: { token } }) => {
    // console.log("token", params.token)
    const client = useIdentityClient()
    const t = useT()
    const toast = useToast()
    const [, setLocation] = useLocation()

    const [changePasswordArgs, setChangePasswordArgs] = useState({
        newPassword: '',
        newConfirmed: '',
    })

    const [passwordMatch, setPasswordMatch] = useState(false);
    const [validPassword, setValidPassword] = useState(false);

    useEffect(() => {
        const doPasswordsMatch = changePasswordArgs.newPassword === changePasswordArgs.newConfirmed;
        const validationResult = ZodInputValidator(changePasswordParams, changePasswordArgs).length == 1;

        setPasswordMatch(doPasswordsMatch);
        setValidPassword(validationResult && doPasswordsMatch);
    }, [changePasswordArgs.newPassword, changePasswordArgs.newConfirmed]);


    return (
        <>
            <Input
                label={t['Reset:NewPassword'].get()}
                type='password'
                pattern="true"
                value={changePasswordArgs.newPassword}
                error={(value) => ZodInputValidator(changePasswordParams, changePasswordArgs, 'newPassword')}
                onChange={(newPassword) => {
                    setChangePasswordArgs({ ...changePasswordArgs, newPassword })
                }}
            />

            <Input
                label={t['Reset:ConfirmPassword'].get()}
                type='password'
                value={changePasswordArgs.newConfirmed}
                onChange={(newConfirmed) => setChangePasswordArgs({ ...changePasswordArgs, newConfirmed })}
            />
            <div>
                {!passwordMatch && (
                    <p className="text-red-500">{t['Reset:PasswordNotMatch'].get()}</p> // TODO: equal stylings like the other errors
                )}
            </div>
            <Spacer />

            <Button
                large
                fill
                style={{
                    marginBottom: 24,
                    height: 48,
                }}
                textAlign="center"
                keyboardShortcut="Enter"
                disabled={!validPassword}
                onClick={async () => {
                    if (passwordMatch) {
                        try {
                        await client.call('login:password:reset', {
                            token: params.token,
                            newPassword: changePasswordArgs.newPassword,
                        })
                        setLocation('/login') 
                        toast.add(
                            <Toast
                                label={t['Reset:PasswordChanged'].get()}
                                type="success"
                                description={
                                    <Text style={{ whiteSpace: 'normal' }}>
                                        {t['Reset:PasswordChangedSuccessfully'].get()}
                                    </Text>
                                }
                            />
                        )
                        }catch (e) {
                            toast.add(
                                <Toast
                                    label={t['Reset:PasswordChanged'].get()}
                                    type="error"
                                    description={
                                        <Text style={{ whiteSpace: 'normal' }}>
                                            {e.message}
                                        </Text>
                                    }
                                />
                            )
                        }
                        //LOGIN
                    }
                }}
            >
                {t['Profil:Save'].get()}
            </Button>
        </>
    )
}
