import { format, Locale } from 'date-fns'
import { enUS, de } from 'date-fns/locale'

import { Calendar as CalendarIcon } from 'lucide-react'
import { cn } from '@shared/utils/tw'
import { Calendar } from './Calender'
import { Popover, PopoverContent, PopoverTrigger } from './Popover'
import { Button } from './Button'
import { WithI18n } from "@shared/i18n/withI18n"
import i18n from "../i18n"
import { useT } from "@app/i18n"
import { useTranslation } from 'react-i18next'


const locales: { [key: string]: Locale } = {
  en: enUS,
  "en-US": enUS,
  de: de,
  "de-CH": de,
  "de-DE": de,

}

function _DatePicker(
  {
    date,
    setDate
  }:
    {
      date: Date | null
      setDate: (date: Date) => void
    }
) {
  const t = useT()
  const { i18n } = useTranslation()
  const currentLanguage = locales[i18n.language]


  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={'outline'}
          className={cn(
            'w-[280px] justify-start text-left font-normal hover:bg-background-hover',
            !date && 'text-muted-foreground'
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? t['intlDateTime'].get({
            val: new Date(new Date(date).getTime()),
            formatParams: {
              val: { year:'numeric', month: 'long', day: 'numeric' },
            }
          }) : <span>{t["CoreDashboard:PickADate"].get()}</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0 bg-">
        <Calendar
          mode="single"
          selected={date}
          locale={currentLanguage}
          onSelect={(value) =>
            value
              ? setDate(new Date(new Date(value).setHours(5, 30, 0, 0)))
              : new Date()
          }
          initialFocus
        />
      </PopoverContent>
    </Popover>
  )
}

export const DatePicker = (props) => (
  <WithI18n i18n={i18n}>
    <_DatePicker {...props} />
  </WithI18n>
)
