import { DefaultProcedure } from '@server/app/trpc/router'
import { z } from 'zod'

export const updateNotificationTemplateParams = z.object({
        id: z.string(),
        name: z.string(),
        title: z.string({
          required_error: 'Title is required',
        }),
        message: z.string(),
      })

export const updateProcedure = (
  procedure: DefaultProcedure
) =>
  procedure
    .input(updateNotificationTemplateParams)
    .mutation(async ({ ctx: { based, logger }, input }) => {
      const res = await based.db.default.set({
        $merge: true,
        $id: input.id,
        name: input.name,
        title: input.title,
        message: input.message,
        type: 'notificationTemplate',
      })
      logger.debug('procedure::updateNotificationTemplate result', res)
    })
