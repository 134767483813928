import { useEffect, useRef, useState } from 'react'
import { AddIcon, Button, Dialog, Select, Spacer, Text, Toast, useToast } from '@colombalink/basedui'
import { api } from '@shared/index'
import { OldCompatTable } from '@shared/components/core/OldCompatTable'
import { PageParams } from '../../../../app/pages/Orgs/Things/Page'
import { useRouteParams } from '../../../router'
import { useLink } from '../../../../app/routes'
import { useLocation } from 'wouter'
import { TableRow } from '@shared/components/core/table'
import { WithI18n } from '@shared/i18n/withI18n'
import i18n from '../i18n'
import { useT } from '@app/i18n'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'
import { log } from '@shared/logger'

const _AddDeviceDialog = () => {
  const t = useT()
  const [, setLocation] = useLocation()
  const { orgAliasId } = useRouteParams<PageParams>()
  //todo: we should not get the token here ... add new api call to just get names and ids
  const integrations = api.integrations.getAllByOrgIdForProject.useLiveData()
  const [selectedIntegration, setSelectedIntegration] = useState<string>()
  const [hasSelectedTenant, setHasSelectedTenant] = useState<{
    id: string
    name: string
  }>()
  const [hasSelectedApplication, setHasSelectedApplication] = useState<{
    id: string
    name: string
  }>()
  const { projectAliasId } = useRouteParams<PageParams>()

  const ref = useRef(null)

  return (
    <Dialog label= {t["SThing:AddAChirpstackThing"].get()}>
      <div>

        {integrations.loading && <LoadingIcon />}

        {integrations.error && <div>{integrations.error.message}</div>}

        {
          integrations.data?.length < 1 && <div>{t["SThing:NoIntegrationsFound"].get()} <Spacer /> <Button
            onClick={() => {

              // todo: update dialog to close on escape
              document.dispatchEvent(new KeyboardEvent('keydown', {
                key: 'Escape',
                keyCode: 27,
                code: 'Escape',
                which: 27,
                shiftKey: false,
                ctrlKey: false,
                metaKey: false
              }));
              setLocation(useLink('/org/:orgAliasId/integrations', { orgAliasId }))
            }}
          >{t["SThing:AddIntegration"].get()}</Button> </div>
        }

        {integrations?.data?.length > 0 && (
          <Select
            placeholder={t["SThing:SelectIntegration"].get()}
            options={integrations.data?.map((i) => ({ label: i.name, value: i.id })) || []}
            onChange={(id: string) => {
              setSelectedIntegration(id)
            }}
          ></Select>
        )
        }

        {selectedIntegration && (<TenantSelector setSelectedTenant={setHasSelectedTenant} />)}
        {
          selectedIntegration && hasSelectedTenant && (
            <ApplicationSelector
              tenantId={hasSelectedTenant.id}
              setSelectedApplication={setHasSelectedApplication}
            />
          )
        }
        {
          selectedIntegration && hasSelectedTenant && hasSelectedApplication && (
            <DeviceSelectorTable
              integrationId={selectedIntegration}
              projectAliasId={projectAliasId}
              tenantId={hasSelectedTenant.id}
              applicationId={hasSelectedApplication.id}
            />
          )
        }
        <Dialog.Buttons>
          <Dialog.Cancel displayShortcut={false}>Close</Dialog.Cancel>

        </Dialog.Buttons>
      </div>
    </Dialog >
  )
}



function TenantSelector({
  setSelectedTenant,
}: {
  setSelectedTenant: (tenant: { id: string; name: string }) => void
}) {
  const t = useT()
  const tenants = api.integrations.chirpstack.getTenants.useData()

  if (tenants.error) {
    //console.log(tenants.error)
    log.error( "tenants.error")

    return <div>{tenants.error.message}</div>
  }

  if (tenants.loading) {
    return <div>{t['SThing:Loading'].get()} </div>
  }


  return (
    <div className="flex flex-col sm:flex-row sm:justify-between">
      <div className="m-1 w-full">
        <Select
          placeholder={t["SThing:SelectATenant"].get()}
          options={tenants?.data.map((t) => t.name)}
          onChange={(tenant) => {
            const selectedTenant = tenants.data.find((t) => t.name === tenant)
            setSelectedTenant(selectedTenant)
          }}
        />
      </div>
    </div>
  )
}



function ApplicationSelector({
  tenantId,
  setSelectedApplication,
}: {
  setSelectedApplication: (app: { id: string; name: string }) => void
  tenantId: string
}) {
  const t = useT()
  const useApplications = api.integrations.chirpstack.getApplicationsByTenant.useData({
    tenantId: tenantId.toString(),
    limit: 100,
  })
  if (useApplications.loading) {
    return <div>{t["SThing:Loading"].get()}</div>
  }
  if (useApplications.error) {
    return <div>{useApplications.error.message}</div>
  }
  const applications = useApplications.data

  return (
    <div className="flex flex-col sm:flex-row sm:justify-between">
      <div className="m-1 w-full">
        <Select
          options={applications.map((app) => app.name)}
          placeholder={t["SThing:SelectAnApplication"].get()}
          onChange={(tenant) => {
            const selectedTenant = applications.find((t) => t.name === tenant)
            setSelectedApplication(selectedTenant)
          }}
        />
      </div>
    </div>
  )
}

function DeviceSelectorTable({
  applicationId,
  projectAliasId,
  tenantId,
  integrationId,
}: {
  projectAliasId: string
  tenantId: string
  applicationId: string
  integrationId: string
}) {
  const t = useT()
  const things = api.thing.getAll.useLiveData()
  const chirpDevices = api.integrations.chirpstack.getDevicesByApplication.useData({
    applicationId,
    limit: 100,
  })
  const addDeviceToProjectMutation =
    api.project.addDeviceToProject.useMutation()
  const toast = useToast()
  const tableHeader = [
    { label: t["SThing:DeviceEUI"].get(), key: 'Eui' },
    { label: t["SThing:DeviceName"].get(), key: 'name' },
    { label: t["SThing:Action"].get(), key: 'action' },
  ]
  const [tableData, setTableData] = useState<TableRow[]>([])
  useEffect(() => {
    if (things.data && chirpDevices.data) {
      setTableData(
        chirpDevices.data
          .filter(
            (device) =>
              !things.data.find(
                (thing) => thing.detail.eui === device?.Eui
              )
          )
          .map((chirpstackDevice) => {
            return {
              ...chirpstackDevice,
              action: (
                <Button
                  onClick={async () => {
                    try {
                      await addDeviceToProjectMutation.mutateAsync({
                        projectAliasId,
                        eui: chirpstackDevice?.Eui,
                        // TODO: we need to check if this name is even valid?
                        deviceName: chirpstackDevice?.name,
                        tenantId: tenantId,
                        appId: applicationId,
                        integrationId
                      })
                      toast.add(
                        <Toast
                          label={t["SThing:AddedThing"].get()}
                          type="success"
                          description={
                            <Text>
                              Thing <b>{chirpstackDevice.name}</b> {t["SThing:AddedToProject"].get()}
                            </Text>
                          }
                        />
                      )
                    } catch (e) {
                      toast.add(
                        <Toast
                          label="Error"
                          type="error"
                          description={t["SThing:FailedToAddThing"].get()}
                        />
                      )
                    }
                  }}
                >
                  <AddIcon />
                </Button>
              ),
              customAttribute: {
                action: {},
              },
            }
          })
      )
    }
  }, [things, chirpDevices])

  if (chirpDevices.loading && things.loading) {
    return (
      <div>
        {' '}
        <LoadingIcon /> Loading things...
      </div>
    )
  }

  if (tableData.length > 0) {
    return (
      <OldCompatTable
        data={tableData}
        loading={things.loading}
        header={tableHeader}
      />
    )
  }

  if (chirpDevices.data?.length > 0) {
    return <div>All things are already added to this project.</div>
  }
  return <div>No things found. Please check the Chirpstack.</div>
}

export const AddDeviceDialog = (props) => (
  <WithI18n i18n={i18n}>
      <_AddDeviceDialog {...props} />
  </WithI18n>
)