import React, { useEffect } from 'react'
import { RowMenu } from './RowMenu'
import { api } from '@shared/index'
import { useLocation } from 'wouter'
import { useTable } from '@shared/components/core/table/Table'
import { useLink } from '../../../routes'
import { useRouteParams } from '../../../../shared/router'
import { PageParams } from './Page'
import { Row } from '@tanstack/react-table'
import { useT } from '@app/i18n'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'


export function DevicesTable() {
  const t = useT()
  const { orgAliasId, projectAliasId } = useRouteParams<PageParams>()
  
  const things = api.thing.getAll.useLiveData();
  const [, setLocation] = useLocation()
  const [tableData, setTableData] = React.useState([])


  const [table, TableComponent] = useTable({
    header: [
      { label: t['Devices:Name'].get(), key: 'name' },
      { label: t['Devices:IsConfigured'].get(), key: 'isConfigured' },
      { label: 'EUI', key: 'detail.eui' },
      { label: '', key: 'action' },
    ],
    data: tableData,
    setData: setTableData
  })

  useEffect(() => {
    if (things.data) {
      setTableData(
        things.data.map((thing) => {
          return {
            ...thing,
            isConfigured: thing.isConfigured ? 'Yes' : 'No',
            action: (<RowMenu  thing={thing} />),
            customAttribute: {
              row: {
                onClick: () => setLocation(useLink('/org/:orgAliasId/project/:projectAliasId/thing/:thingAliasId', { orgAliasId, projectAliasId, thingAliasId: thing.alias })),
                className: 'cursor-pointer',
              },
            },
          }
        })
      )
    }
  }, [things.data])

  if (things.error) {
    throw things.error
  }

  if (things.loading) {
    return <>{t['Devices:Loading'].get()} <LoadingIcon /></>
  }
  return (
    <>
      {TableComponent}
    </>
  )
}

