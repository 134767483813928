import { getValueOfGenericProperty } from '@server/app'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'
import { api } from '@shared/index'
import React, {createContext, useContext, useEffect} from 'react'


function useProvideContext({ thingAliasId }: { thingAliasId: string }) {
  const [properties, setProperties] = React.useState(null)
  const [propertyKeys, setPropertyKeys] = React.useState<string[]>(null)
  const usePropertiesColor = React.useState<Map<string, number[]>>(new Map())

  const thing = api.thing.getWithOptions.useLiveData({
    thingAliasId,
    fields: {
      id: true,
      name: true,
      lastEvent: {
        id: true,
        value: true
      },
      payloadSchema: {
        id: true,
        properties: {
          $list: {},
          name: true,
          label: true,
          idealRange: {
            min: true,
            max: true,
            tolerance: true,
          },
          primitiveDataType: true,
          unit: {
            label: true,
            symbol: true,
          }
        },
        influxFields: {
          $list: {},
          name: true,
          primitiveDataType: true,
          unit: {
            label: true,
            symbol: true,
          }
        }
      },
    }
  })

  const thingWithLastEvent = api.thing.getWithOptions.useLiveData({
    thingAliasId,
    fields: {
      id: true,
      name: true,
      lastEvent: {
        id: true,
        value: true
      },
    }
  })


  useEffect(() => {
    if (thing.data) {
      console.log("setting properties....", properties)
      const properties_l = thing.data.payloadSchema.properties
      .filter(p => ["number", "int", "float"].includes(p.primitiveDataType))
      .sort((a, b) => a?.label?.en?.localeCompare(b?.label?.en)) // sort by label
      .reduce((acc, property) => {
        acc[property.name] = property
        return acc
      }, {})
      console.log("setting properties", properties_l)
      setProperties(properties_l)
    }
  }, [thing.data])

  useEffect(() => {
    if(!properties) return
    setPropertyKeys(Object.keys(properties))
  }, [properties])

  return {
    loading: 
      thing.loading 
      || thingWithLastEvent.loading
      || !properties 
      || !propertyKeys,
    thing,
    lastEvent: thingWithLastEvent.data?.lastEvent,
    properties,
    propertyKeys,
    usePropertiesColor,
    useThingProperty:  (property: string) => ({
      getStatus: () => getThingPropertyStatus(thing.data, property),
      getProperty: () => properties[property], 
    })
  }
}

type ContextType = ReturnType<typeof useProvideContext>
const Context = createContext<ContextType | undefined>(undefined)


type Props = {
  thingAliasId: string
}

const ThingContext: React.FC<Props> = ({ children, thingAliasId }) => {
  const value = useProvideContext({ thingAliasId })

  if(value.loading) {
    return <LoadingIcon/>
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}

const useThingContext = (): ContextType => {
  const context = useContext(Context)
  if (!context) {
    throw new Error('usePageContext must be used within a PageContextProvider')
  }
  return context
}


const getThingPropertyStatus = (thing, propertyName) => {
    const property = thing.payloadSchema.properties.find(prop => prop.name === propertyName);
  
    if (!property) {
      throw new Error(`Property "${propertyName}" not found in payloadSchema.`);
    }

    if (!thing.payloadSchema || !thing.lastEvent || !property.idealRange) {
      return 'default';
    }
    
    const value =  getValueOfGenericProperty(thing.lastEvent.value, propertyName);
    const idealRange = property.idealRange;
  
    if (value !== undefined) {

      const t = idealRange.tolerance || 0
      const min = idealRange.min
      const max = idealRange.max

      if(value < min - t) {
        return 'alarm'
      }
      if(value <= min + t) {
        return 'alert'
      }
      if (value <= max - t) {
        return 'idle'
      }
      if (value <= max + t) {
        return 'alert'
      }
      return 'alarm'
  }
  return 'default';
}
export { useThingContext, ThingContext }
