import {
  Dialog,
  Input,
  MultiSelect,
  Select,
  Spacer,
  Text,
  Toast,
  useToast,
} from '@colombalink/basedui'
import React from 'react'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'
import { api } from '@shared/index'
import { useT } from '@app/i18n'

type EditProps = {
  property: {
    label?: { en: string; de?: string }
    idealRange?: {
      min?: number
      max?: number
      tolerance?: number
    }
    id: string
  }
}

const EditProperty = (props: EditProps) => {
  // console.log('EditProperty props...:', props)
  const t = useT()
  const [updateProperty, setUpdateProperty] = React.useState({
    propertyId: props.property.id,
    label: props.property.label,
    min: props.property.idealRange.min,
    max: props.property.idealRange.max,
    tolerance: props.property.idealRange.tolerance,
  })
  const updatePropertyMutation = api.thing.updatePayloadPropertiesWithProperties.useMutation()
  const toast = useToast()
  const handleOnChange = (key, value) => {
    if (key === "min" || key === "max" || key === "tolerance") {
      value = parseFloat(value)
    }
    if (key === "label") {
      value = { en: value }
    }
    setUpdateProperty((prev) => ({
      ...prev,
      [key]: value,
    }))
  }

  const handleSubmit = async () => {
    try {
      //console.log("UPDATE PROPERTY:", updateProperty)
      await updatePropertyMutation.mutateAsync(updateProperty)
      toast.add(
        <Toast
          label={t['Thing:UpdatedProperty'].get()}
          type="success"
          description={
            <Text>
              {t["Thing:Property"].get()} <b>{updateProperty?.label.en}</b> {t["Thing:UpdatedSuccessfully"].get()}
            </Text>
          }
        />
      )
      window.location.reload();
    } catch (e) {
      toast.add(
        <Toast
          label={t["Thing:FailedToUpdateProperty"].get()}
          type="error"
          description={
            <Text style={{ whiteSpace: 'normal' }}>{e.message}</Text>
          }
        />
      )
      throw e
    }
  }

  return (
    <Dialog label={t["Thing:EditProperty"].get()}>
      <div>
        <Input
          label={t["Thing:Label"].get()}
          value={updateProperty.label.en}
          type="text"
          onChange={(label) => handleOnChange('label', label)}
        />
        <Spacer space={20} />
        <Input
          label={t["Thing:Min"].get()}
          value={updateProperty?.min}
          type="float"
          onChange={(min) => handleOnChange('min', min)}
        />
        <Spacer space={20} />
        <Input
          label={t["Thing:Max"].get()}
          value={updateProperty?.max}
          type="float"
          onChange={(max) => handleOnChange('max', max)}
        />
        <Spacer space={20} />
        <Input
          label={t["Thing:Tolerance"].get()}
          value={updateProperty?.tolerance}
          type="float"
          onChange={(tolerance) => handleOnChange('tolerance', tolerance)}
        />

      </div>
      <Dialog.Buttons>
        <Dialog.Cancel displayShortcut={false}>{t["Thing:Close"].get()}</Dialog.Cancel>
        <Dialog.Confirm onConfirm={handleSubmit} displayShortcut={false}>
          {t["Thing:Save"].get()}
        </Dialog.Confirm>
      </Dialog.Buttons>
    </Dialog>
  )
}

export default EditProperty
