import { DefaultProcedure } from '@server/app/trpc/router'
import { z } from 'zod'

export const createActionParams = z.object({
  name: z.string({
    required_error: 'Name is required',
  }),
  trigger: z.string(),
  triggerType: z.string(),
  groups: z.array(z.string()),
  notificationTemplate: z.string(),
})

export const createProcedure = (procedure: DefaultProcedure) =>
  procedure.input(createActionParams).mutation(async ({ ctx, input }) => {
    const res = await ctx.based.db.default.set({
      name: input.name,
      trigger: input.trigger,
      triggerType: input.triggerType,
      notificationGroups: input.groups,
      notificationTemplate: input.notificationTemplate,
      type: 'action',
      parents: [ctx.org.id],
    })

    for (const groupId of input.groups){

      await ctx.based.db.default.set({
        $id: groupId,
        parents: {
          $add: res.id,
        },
      });
    }

    await ctx.based.db.default.set({
      $id: input.notificationTemplate,
      parents: {
        $add: res.id,
      },
    });

    ctx.logger.debug('procedure::createAction result:', res)
  })
