import { useEffect, useState } from 'react'
import { LineGraphDataInput } from '@colombalink/basedui/dist/components/LineGraph/types'
import { api } from '@shared/index'
import SingleSeriesLineChart from '@shared/components/core/charts/SingleSeriesLineChart'
import { hsvToRgb } from '@shared/utils/colors/hsvToRgb'
import { useChartContext } from './context'
import { useThingContext } from '@app/pages/Orgs/Thing/Fragments/Latest/context'
import { WithI18n } from '@shared/i18n/withI18n'
import i18n from '../i18n'
import { useT } from '@app/i18n'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'
import { Toggle } from '@colombalink/basedui'
import { findAllByTestId } from '@testing-library/react'

export const __ThingTimeSeriesChart = () => {
  const thingCtx = useThingContext() 
  
  if(thingCtx.loading || !thingCtx.thing.data) return <LoadingIcon />

  return (<_ThingTimeSeriesChart/>)
}

export const _ThingTimeSeriesChart = () => {
  const t = useT()
  const [data, setData] = useState<LineGraphDataInput>(null)
  const chartContext = useChartContext()
  const [fromDate,] = chartContext.useFromDate
  const [toDate, setToDate] = chartContext.useToDate
  const [properties,] = chartContext.useProperties
  const visibleFields = chartContext.useVisibleFields[0]
  const thingCtx = useThingContext()
  const thing = thingCtx.thing.data
  const propertyKeys = thingCtx.propertyKeys

  const [loading, setLoading] = useState(true)
  const [toggle, setToggle] = useState(false);
  
  // console.log("from date", fromDate)  
  // console.log("to date", toDate)
  const events =
    api.event.getEventsByIdAndDateFilter.useData({
      thingAliasId: thing.alias,
      fromDate,
      toDate,
      influxFields: thingCtx.propertyKeys,
      toggle: toggle
    })


  //console.log("data", events?.data?.length)
  useEffect(() => {
    if (events.loading || !events.data || !properties) {
      // we need this check because otherwise the Graph component will render with 2 different hook execution orders
      // and throw an error... maybe something to fix later in the ui lib...
      return
    }    

    const data = []
    for (let i = 0; i < propertyKeys.length; i++) {
      for (let j = 0; j < events.data.length; j++) {
        const event = events.data[j]
        const propKey = propertyKeys[i]

        if (!data[i]) {
          data[i] = {
            data: [],
            color: {
              rgb: hsvToRgb((360 / propertyKeys.length) * i, 80, 80),
            },
            key: propKey,
          }
        }
        data[i].data.push([new Date(event?._time), event[propKey], { propKey }])
      }
    }
    thingCtx.usePropertiesColor[1](data.reduce((colorsMap, item) => {
      colorsMap.set(item.key, item.color.rgb);
      return colorsMap;
    }, new Map()));
    setData(data)
    setLoading(false)
  }, [events.data])


  if (loading || thingCtx.loading) {
    return <LoadingIcon />
  }

  const visibleCount = [...visibleFields.values()].filter(value => value).length;
  console.log(`Number of objects with visible set to true: ${visibleCount}`);

  if(visibleCount === 0){
    return (
      <div style={{ minHeight: '300px', width: '100%' }}>
        {
          <div style={{ marginTop: '50px' }}>
            {t["CoreCharts:NoPropertiesSelected"].get()} </div>
        }
      </div>
    )
  }

  return (
    <div style={{ minHeight: '300px', width: '100%' }}>
      {data && data.length === 0
        ?
        <div style={{ marginTop: '50px' }}>
          {t["CoreCharts:NoDataAvailable"].get()} </div>
        : data ?
          (
            // <LineGraph data={data} label={label} xFormat="date" />
            <div>
              <div className='flex justify-end m-1'>
              <Toggle label='Aggregate' onChange={
                (e) => {
                  setToggle(e)
                }
              }></Toggle>
              </div>
              <SingleSeriesLineChart seriesData={data} />
            </div>
          ) : (
            <LoadingIcon />
          )}
    </div>
  )
}

export const ThingTimeSeriesChart = () => (
  <WithI18n i18n={i18n}>
    < __ThingTimeSeriesChart />
  </WithI18n>
)

