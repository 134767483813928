import { useDialog } from "@shared/components/core/useDialog"
import { Role } from "@server/shared"
import { api } from "@shared/index"
import { useRouteParams } from "@shared/router"
import { NoItemGuidance } from "@shared/components/page/generic/NoItemGuidance"
import { useEffect, useState } from "react"
import { AddIcon} from "@colombalink/basedui"
import { CustomizableButton } from "@shared/components/core/CustomizableButton"
import { useLocation, useRouter } from "wouter"
import { useLink } from "@app/routes"
import { WithI18n } from "@shared/i18n/withI18n"
import i18n from "../i18n"
import { useT } from "@app/i18n"
import { LoadingIcon } from '@shared/components/core/LoadingIcon'

type PageProps = { params: { orgAliasId: string, projectAliasId: string, thingAliasId: string } }
export type PageParams = PageProps['params']

const _ThingConfigureLandingFragment = ({ children }) => {
    const t = useT()
    const { thingAliasId, orgAliasId, projectAliasId } = useRouteParams<PageParams>()
    const roles = api.user.getRoles.useLiveData()
    const thing = api.thing.get.useLiveData({ thingAliasId })
    const [, setLocation] = useLocation()

    useEffect(() => {
        if (!roles.data?.asArray) {
            return
        }
        if (!thing.data) {
            return
        }
       roles.data.asArray.some((role) => {
            if (Array.isArray(role) && role[0].startsWith(projectAliasId) &&
                Array.isArray(thing.data) && thing.data.isConfigured
            ){
                return true
            }
        })

    }, [roles.data?.asArray, thing.data, projectAliasId])



    if (roles.error) {
        throw roles.error
    }


    if (roles.loading || thing.loading) {
        return <LoadingIcon />
    }


    if (thing.data?.isConfigured) {
        return children
    }

    const projectRole = roles.data[projectAliasId] as Role
    if (projectRole === "OWNER") {
        return (
            <NoItemGuidance>
                 <div>
                    {t["SThing:TheThing"].get()}
                    {" "}<b>{thing.data?.name}</b>{" "}
                    {t["SThing:HasNotBeenConfigured"].get()}
                </div>
                <CustomizableButton
                    icon={<AddIcon />}
                    onClick={
                        () => {
                          setLocation(useLink('/org/:orgAliasId/project/:projectAliasId/thing/:thingAliasId/configure', { orgAliasId, projectAliasId, thingAliasId }))
                        }
                    }> {t["SThing:ConfigureIt"].get()}
                </CustomizableButton>
            </NoItemGuidance>
        )
    }

    return (
        <NoItemGuidance
        >
            {
                t["SThing:CannotConfigureDevice"].get()
            }
        </NoItemGuidance>
    )

}

export const ThingConfigureLandingFragment = (props) => (
    <WithI18n i18n={i18n}>
        <_ThingConfigureLandingFragment {...props} />
    </WithI18n>
)