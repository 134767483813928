import React, { useEffect, useState } from 'react'
import {
  Index,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from './'
import {
  Cell,
  flexRender,
  getCoreRowModel,
  useReactTable,
  Table,
  getSortedRowModel,
  SortingState,
} from '@tanstack/react-table'
import { cn } from '@shared/utils/tw'
import { setValueOfGenericProperty } from '@server/app'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'

// This could be a new component or just a part of the existing cell rendering logic
const EditableCell = ({
  headerKey, // we have an extra header key becuase transtack changes our prop path from dot notation to underscore notation
  isEditable,
  cell,
  updateMyData, // This is a function that you'll need to define to update the main data array
}) => {
  const [value, setValue] = useState(cell.getValue() || "")
  const [isEditing, setIsEditing] = useState(false)
  
  if (value instanceof Object) {
    // cases where we pass a react component 
    return value
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      updateMyData(cell.row.index, headerKey, value)
      setIsEditing(false)
    } else if (event.key === 'Escape') {
      setValue(cell.getValue())
      setIsEditing(false)
    }
  }

  const additionalAttributes = cell?.row?.original?.customAttribute?.[headerKey]

  return isEditable && isEditing ? (
    <input
      className="p-4 w-full"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onKeyDown={handleKeyDown}
      onBlur={() => {
        updateMyData(cell.row.index, headerKey, value)
        setIsEditing(false)
      }}
      {...additionalAttributes}
    />
  ) : (
    <div
      style={{ cursor: isEditable ? 'text' : 'default' }}
      className="p-4"
      onDoubleClick={() => setIsEditing(true)}
      {...additionalAttributes}
    >
      {value}
    </div>
  )
}

/**
 * @description This component is used to render a table
 * Usage:
 *
 ```tsx
 <Table
 header={[
              { label: 'Name', key: 'user.name' },
              { label: 'Email', key: 'user.email' },
              { label: 'Friend', key: 'friend' },
              { label: 'Actions', key: 'actions' },
            ]}
 data={[
              {
                user: { name: 'John', email: 'john@a.com' },
                friend: 'Bob',
                actions: (
                  <Button onClick={() => console.log('John')}>
                    Console log...
                  </Button>
                ),
                customAttribute: {
                  row: {
                       className: isSelected ? 'bg-blue-500 text-white' : '',
                       onClick: () => console.log("row on click event"),
                  },
                  friend: {
                    className: 'bg-red-500',
                    style: { color: 'white' },
                    onClick: () =>
                      console.log('invoke friend from custom attribute'),
                  },
                  'user.name': {
                    className: 'bg-green-500',
                  },
                },
              },
              {
                user: { name: 'Alice', email: 'alice@a.com' },
                friend: 'Bob',
                actions: (
                  <Button onClick={() => console.log('Alice')}>
                    Console log...
                  </Button>
                ),
              },
            ]}
 />
 ```
 */
export const useTable = ({
  header,
  data,
  setData,
  loading,
  className,
  onUpdatedCell,
}: {
  loading?: boolean
  header: {
    label: string
    key: string
    isEditable?: boolean
  }[]
  // todo: pass useData [data, setData] = useData()
  data: any[]
  setData?: (data) => void
  className?: string
  onUpdatedCell?: (rowIndex, columnId, value) => void
}): [Table<any>, JSX.Element ] => {
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({})
  // Pass this function down to your table component

  const [sorting, setSorting] = useState<SortingState>([])

  useEffect(() => {
    data.forEach((row, index) => {
      setSorting   // todo: document this
      if (row.isSelectedDefault) {
        rowSelection[index] = true
      }
    })
    setRowSelection({ ...rowSelection })
  }, [data])

  const columns = React.useMemo(
    () => [
      ...header.map((item) => {
        return {
          accessorKey: item.key,
          header: item.label,
          cell: (cell) => {
            if (cell.cell.getValue() instanceof Function) {
              return cell.cell.getValue()(cell)
            }
            else {
              return <EditableCell

                headerKey={item.key} isEditable={item.isEditable} cell={cell} updateMyData={updateTableData} />
            }
          }
        }
      }),
    ],
    [header]
  )

  const table = useReactTable({
    data,
    columns,
    state: { rowSelection, sorting },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),

    onSortingChange: setSorting,    
    getSortedRowModel: getSortedRowModel(),
  })


  // You'll need to create a function to let you update the data
  // This could be a simple function that takes an index, columnId, and value
  // and then updates the correct entry in your data array.
  const updateTableData = (rowIndex, columnKey, value) => {
    onUpdatedCell?.(rowIndex, columnKey, value)
    setValueOfGenericProperty(data[rowIndex], columnKey, value)
    if (setData) {
      setData([...data])
    }
  }


  const tableComponent = (
    <div className={cn(className)}>
      <Index className="max-h-80">
        <TableHeader
          className="sticky top-0 z-50 bg-white"
          style={{
            boxShadow: '0px 4px 0px rgb(239, 239, 239)',
          }}
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id} className="font-bold">
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody className="border-t-4 ">
          {loading && (
            <div className="m-4">
              <LoadingIcon />
            </div>
          )}
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => {
              return (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  {...row?.original?.customAttribute?.row}
                  onClick={() => {
                    row?.original?.customAttribute?.row?.onClick?.({ row })
                  }}
                  className={cn(
                    'bg-white',
                    row?.original?.customAttribute?.row?.className
                  )}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              )
            })
          ) : (
            <TableRow>
              {/* <TableCell colSpan={columns.length} className="h-24 text-center"> */}
              {/*  No results. */}
              {/* </TableCell> */}
            </TableRow>
          )}
        </TableBody>
      </Index>
    </div>
  )
  return [table, tableComponent ]
}
// export type TableRow = Parameters<typeof TableRow>[0]['row']
//
// function getCellContent(
//   row: any,
//   header: { label: string; key: string; language?: 'en' | 'de' }
// ) {
//   let cellContent = ''
//
//   if (header.key.split('.').length > 1) {
//     const keys = header.key.split('.')
//     cellContent = row[keys[0]]
//     for (let i = 1; i < keys.length; i++) {
//       cellContent = cellContent?.[keys[i]]
//     }
//   } else {
//     cellContent = row[header.key]
//   }
//
//   if (header.language && cellContent?.[header.language]) {
//     cellContent = cellContent?.[header.language]
//   }
//
//   return cellContent
// }
//
// function TableRow({
//   headers,
//   row,
//   rowNumber,
// }: {
//   headers: { label: string; key: string; language?: 'en' | 'de' }[]
//   row: any
//   customAttribute?: { [key: string]: DOMAttributes<HTMLTableDataCellElement> }
//   rowNumber: number
// }) {
//   return (
//     <tr className={rowNumber % 2 === 0 ? undefined : ''}>
//       {headers?.map((header, index) => {
//         const cellContent = getCellContent(row, header)
//         return (
//           <td
//             key={index}
//             className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
//             {...row.customAttribute?.[header.key]}
//           >
//             {cellContent}
//           </td>
//         )
//       })}
//     </tr>
//   )
// }
//
// const loadingRows = () => {
//   return (
//     <tr>
//       <td className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
//         <LoadingIcon />
//       </td>
//     </tr>
//   )
// }
