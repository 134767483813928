
const LoginPageContentI18n = {

    'Login:Email': {
        en: 'Email',
        de: 'Email',
    },
    'Login:Password': {
        en: 'Password',
        de: 'Passwort',
    },
    'Login:EmailPlaceholder': {
        en: 'Enter your email address',
        de: 'Geben Sie Ihre Email-Adresse ein',

    },
    'Login:PasswordPlaceholder': {
        en: 'Enter your password',
        de: 'Geben Sie Ihr Passwort ein',
    },
    'Login:ForgotPassword': {
        en: 'Forgot Password?',
        de: 'Passwort vergessen?',
    },
    'Login:Login': {
        en: 'Login',
        de: 'Anmelden',
    },
    'Login:Signin': {
        en: 'Sign in',
        de: 'Anmelden',
    },
    'Login:FailedToLogin': {
        en: 'Failed to login',
        de: 'Anmeldung fehlgeschlagen',
    },
    'Login:FailedToLoginInvalidCredentials': {
        en: 'Invalid credentials',
        de: 'Ungültige Zugangsdaten',
    },
    'Login:LoginBeforeJoining': {
        en: 'It seems you are not logged in, please try to login before trying to join an organization.',
        de: 'Es scheint, dass Sie nicht angemeldet sind. Bitte melden Sie sich an, bevor Sie einer Organisation beitreten.',
    },
    'Login:InvitedToOrganization': {
        en: 'You have been invited to:',
        de: 'Sie wurden eingeladen zu:',
    },
    'Login:Join': {
        en: 'Join Organization',
        de: 'Organisation Beitreten',
    },
    'Login:Joined': {
        en: 'You have joined:',
        de: 'Sie sind beigetreten:',
    },
    'Login:SuccessfullyJoinedOrganization': {
        en: 'You have successfully joined:',
        de: 'Sie sind erfolgreich beigetreten:',
    },
    'Login:FailedToJoinOrganization': {
        en: 'Failed to join:',
        de: 'Beitritt fehlgeschlagen:',
    },
    'Login:Username': {
        en: 'Username',
        de: 'Benutzername',
    },
    'Login:UsernamePlaceholder': {
        en: 'Enter your username',
        de: 'Geben Sie Ihren Benutzernamen ein',
    },
    'Login:LastName': {
        en: 'Last Name',
        de: 'Nachname',
    },
    'Login:LastNamePlaceholder': {
        en: 'Enter your name',
        de: 'Geben Sie Ihren Namen ein',
    },
    'Login:FirstName': {
        en: 'First Name',
        de: 'Vorname',
    },
    'Login:FirstNamePlaceholder': {
        en: 'Enter your first name',
        de: 'Geben Sie Ihren Vornamen ein',
    },
    'Login:Register': {
        en: 'Register',
        de: 'Registrieren',
    },
    'Login:RegistrationFailed': {
        en: 'Registration failed',
        de: 'Registrierung fehlgeschlagen',
    },

}


export default LoginPageContentI18n
export type LoginPageContentI18nType = typeof LoginPageContentI18n
