import { FC } from 'react'
import { OptionMenu } from '@shared/components/core/EditEntityMenu'
import {
  ContextItem,
  EditIcon,
  useDialog,
  useToast,
} from '@colombalink/basedui'
import { useLocation } from 'wouter'
import EditProperty from '@app/pages/Orgs/Thing/Fragments/Properties/EditDialog'
import { useT } from '@app/i18n'

type RowMenuProps = {
  property: {
    label?: { en: string; de?: string }
    idealRange?: {
      min?: number
      max?: number
      tolerance?: number
    }
    id: string
  }
}

export const RowMenu = (props: RowMenuProps) => {
  const property = props.property

  const CategoryMenu: FC<{}> = ({ }) => {
    const t = useT()
    const { open } = useDialog()
    const [, setLocation] = useLocation()
    const toast = useToast()

    //console.log("PROPERTY:", props)

    property.label = property.label || { en: '' }
    property.idealRange = property.idealRange || { min: 0, max: 0, tolerance: 0 }
    
    return (
      <>
        <ContextItem
          onClick={() => {
            open(<EditProperty property={property} />)
          }}
          icon={EditIcon}
        >
          {t['Thing:Edit'].get()}
        </ContextItem>
      </>
    )
  }

  return <OptionMenu menu={CategoryMenu} style={{ width: '150px' }} />
}
