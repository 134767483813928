const ThingConfigurePageContentI18n = {

    "ThingConfigure:PleaseContact": {
        "en": "Please contact your Project Owner to configure this device.",
        "de": "Bitte kontaktieren Sie Ihren Projektinhaber, um dieses Gerät zu konfigurieren."
    },
    "ThingConfigure:Loading": {
        "en": "Loading...",
        "de": "Laden..."
    },
    "ThingConfigure:Sorry": {
        "en": "Sorry. We could not find the thing you are looking for.",
        "de": "Sorry. Wir konnten das gesuchte Thing nicht finden."
    },
    "ThingConfigure:TheThing": {
        "en": "The Thing",
        "de": "Das Thing"
    },
    "ThingConfigure:AlreadyConfigured": {
        "en": "is already configured. We will redirect you to the thing page in a few seconds.",
        "de": "ist bereits konfiguriert. Wir werden Sie in wenigen Sekunden zur Thing-Seite weiterleiten."
    },
    "ThingConfigure:GoToThing": {
        "en": "Go to Thing",
        "de": "Zum Thing"
    },
    "ThingConfigure:Configure": {
        "en": "Configure",
        "de": "Konfigurieren"
    },
    "ThingConfigure:Payload": {
        "en": "Payload",
        "de": "Payload"
    },
    "ThingConfigure:Identifiers": {
        "en": "Identifiers",
        "de": "Identifikatoren"
    },
    "ThingConfigure:Tags": {
        "en": "Tags",
        "de": "Tags"
    },
    "ThingConfigure:Values": {
        "en": "Values",
        "de": "Werte"
    },
    "ThingConfigure:PropertiesDefinition": {
        "en": "Properties Definition",
        "de": "Eigenschaften Definition"
    },
    "ThingConfigure:Next": {
        "en": "Next",
        "de": "Weiter"
    },
    "ThingConfigure:AllUnitsAlreadyDefined": {
        "en": "All units are already defined within this organization or you did not select any.",
        "de": "Alle Einheiten sind bereits innerhalb dieser Organisation definiert oder Sie haben keine ausgewählt."
    },
    "ThingConfigure:AllUnitsAlreadyDefined2": {
        "en": "Please click on the button below to continue.",
        "de": "Bitte klicken Sie auf den unten stehenden Button, um fortzufahren."
    },
    "ThingConfigure:Save": {
        "en": "Save",
        "de": "Speichern"
    },
    "ThingConfigure:PropertyPath": {
        "en": "Property Path",
        "de": "Eigenschaftspfad"
    },
    "ThingConfigure:Label": {
        "en": "Label",
        "de": "Bezeichnung"
    },
    "ThingConfigure:UnitLabel": {
        "en": "Unit Label",
        "de": "Einheit Bezeichnung"
    },
    "ThingConfigure:UnitAbbreviation": {
        "en": "Unit abbreviation",
        "de": "Einheit Abkürzung"
    },
    "ThingConfigure:UnitSymbol": {
        "en": "Unit symbol",
        "de": "Einheit Symbol"
    },
    "ThingConfigure:PrimitiveDataType": {
        "en": "Primitive Data Type",
        "de": "Primitiver Datentyp"
    },
    "ThingConfigure:UnitURI": {
        "en": "Unit URI",
        "de": "Einheit URI"
    },
    "ThingConfigure:DefaultPayloadDescription": {
        "en": `The payload is a LoRaWAN payload. 
        It contains data from a temperature sensor and a humidity sensor.
        
        We have the following naming convention for the payload:
        
        Sens_ext = Sensor external
        Sens_int = Sensor internal
        `,
        "de": `Das Payload ist ein LoRaWAN Payload.
        Es enthält Daten von einem Temperatursensor und einem Feuchtigkeitssensor.

        Wir haben folgende Namenskonvention für das Payload:

        Sens_ext = Sensor extern
        Sens_int = Sensor intern
        `
    },

    "ThingConfigure:Monica" : {
        "en": ` Monica our AI will help you to define the payload schema for your things.
        You can improve the generation by describing the payload. Feel free to extend
        the description with any information that you think is relevant.`,
        "de": ` Monica unsere KI hilft Ihnen, das Payload-Schema für Ihre Dinge zu definieren.
        Sie können die Generierung verbessern, indem Sie das Payload beschreiben. Fühlen Sie sich frei,
        die Beschreibung um alle Informationen zu erweitern, die Sie für relevant halten.`
    },

    "ThingConfigure:GenerateUnits": {
        "en": "Generate Units",
        "de": "Einheiten generieren"
    },
    "ThingConfigure:GeneratingUnits": {
        "en": "Generating units...",
        "de": "Einheiten generieren..."
    },
    "ThingConfigure:SorryPageNotFound": {
        "en": "Sorry, we could not find the page you are looking for.",
        "de": "Entschuldigen Sie, wir konnten die gesuchte Seite nicht finden."
    },
    "ThingConfigure:DeviceIdentifier": {
        "en": "Device Identifier: ",
        "de": "Geräte Identifikator: "
    },
    "ThingConfigure:SelectIdentifier": {
        "en": "Select the identifiers that will be used to identify the thing in the platform.",
        "de": "Wählen Sie die Identifikatoren aus, die zur Identifizierung des Objekts in der Plattform verwendet werden sollen."
    },
    "ThingConfigure:SkipStep": {
        "en": "You can skip this step, because generic things are identified by their randomly generated UUID.",
        "de": "Sie können diesen Schritt überspringen, da generische Things anhand ihrer zufällig generierten UUID identifiziert werden."
    },
    "ThingConfigure:PayloadIsChirpstack": {
        "en" : `The event payload is a Chirpstack event. The identifiers are already set by the platform.
        You can continue to the next step`,
        "de" : `Das Event Payload ist ein Chirpstack Event. Die Identifikatoren sind bereits von der Plattform gesetzt.
        Sie können zum nächsten Schritt übergehen`
    },
    "ThingConfigure:TenantIdentifier": {
        "en": "Tenant Identifier",
        "de": "Mandanten Identifikator"
    },
    "ThingConfigure:ApplicationIdentifier": {
        "en": "Application Identifier",
        "de": "Anwendungs Identifikator"
    },
    "ThingConfigure:PayloadDefinition": {
        "en": "Payload Definition: ",
        "de": "Payload Definition: "
    },
    "ThingConfigure:WeDidNotReceive": {
        "en": "We did not receive any data for this thing yet.",
        "de": "Wir haben noch keine Daten für dieses Thing erhalten."
    },
    "ThingConfigure:PleaseUse": {
        "en": "Please use the following text box to provide us with a sample payload.",
        "de": "Bitte verwenden Sie das folgende Textfeld, um uns ein Beispiel-Payload zur Verfügung zu stellen."
    },
    "ThingConfigure:OtherwiseYouCan": {
        "en": "Otherwise you can also wait until the fist event is send to Monidas.",
        "de": "Andernfalls können Sie auch warten, bis das erste Ereignis an Monidas gesendet wird."
    },
    "ThingConfigure:ForGenericThing": {
        "en": "For generic thing, you need to specify the JSON payload.",
        "de": "Für generische Things müssen Sie das JSON-Payload angeben."
    },
    "ThingConfigure:PayloadSample": {
        "en": "Payload Sample",
        "de": "Payload Beispiel"
    },
    "ThingConfigure:NotValidJSON": {
        "en": "Not a valid JSON",
        "de": "Kein gültiges JSON"
    },
    "ThingConfigure:Selected": {
        "en": "Selected",
        "de": "Ausgewählt"
    },
    "ThingConfigure:TagsSelection": {
        "en": "Tags Selection: ",
        "de": "Tags Auswahl: "
    },
    "ThingConfigure:SelectProps":{
        "en": "Now you can select the properties that will be used as tags. ",
        "de": "Jetzt können Sie die Eigenschaften auswählen, die als Tags verwendet werden. "
    },
    "ThingConfigure:TagsAre": {
        "en": "Tags are used to filter and group things in the platform.",
        "de": "Tags werden verwendet, um Things in der Plattform zu filtern und zu gruppieren."
    },
    "ThingConfigure:ValuesSelection": {
        "en": "Values Selection: ",
        "de": "Werte Auswahl: "
    },
    "ThingConfigure:SelectValues": {
        "en": "Now you can select the properties that will be used as measurements. ",
        "de": "Jetzt können Sie die Eigenschaften auswählen, die als Messwerte verwendet werden. "
    },
    "ThingConfigure:MeasurementsAre": {
        "en": "Measurements are used to create dashboards and alerts in the platform.",
        "de": "Messwerte werden verwendet, um Dashboards und Überwachungsfunktionen in der Plattform zu erstellen."
    },

}

export default ThingConfigurePageContentI18n
export type ThingConfigurePageContentI18nType = typeof ThingConfigurePageContentI18n
