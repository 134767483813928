import React, { useMemo } from "react"


const select = (children: React.ReactNode, match) => {
    return useMemo(() => {
        if(!children) return <></>
        
        const { type, value } = match
        const childrenMap: Record<string, React.ReactChild> = {}
        if (children instanceof Array) {
            for (let child of children) {
                const name = child?.type?.name || ''
                if (type === 'endWith' && name.endsWith(value)) {
                    return child
                } else if (type === 'startWith' && name.startsWith(value)) {
                    return child
                } else if (type === 'equal' && name === value) {
                    return child
                }
            }
        } else {
            const name = children?.type?.name
            if (type === 'endWith' && name.endsWith(value)) {
                return children
            } else if (type === 'startWith' && name.startsWith(value)) {
                return children
            } else if (type === 'equal' && name === value) {
                return children
            }

            return children
        }
        return <></>
    }, [children])
}

export const selectChild = (children: React.ReactNode) => {
    if(!children) return <></>
    return {
        endsWith: (value: string) => {
            return select(children, { type: 'endWith', value })
        },
        startsWith: (value: string) => {
            return select(children, { type: 'startWith', value })
        },
        equal: (value: string) => {
            return select(children, { type: 'equal', value })
        },
    }
}