import {
    AddIcon,
    useToast,
  } from '@colombalink/basedui'
  import DefaultNavigation from '@shared/components/page/navigation/DefaultNavigation'
  import React, { useEffect } from 'react'
  import { DynamicButtonRow } from '@shared/components/core/DynamicButtonRow'
  import { RowMenu } from './RowMenu'
  import { Page } from '@shared/components/page/Page'
  import { Row } from '@influxdata/influxdb-client'
  import { api } from '@shared/index'
  import { useLink } from '../../../routes'
  import { useLocation } from 'wouter'
  import { useTable } from '@shared/components/core/table/Table'
  import CreateOrganizationDialog from '@shared/components/user/CreateDialog'
  import i18n from './i18n'
  import { WithI18n } from '@shared/i18n/withI18n'
  import { useT } from '@app/i18n'
  
  export const AdminPage = () => {
    return (
      <Page>
        <DefaultNavigation />
        <Main />
      </Page>
    )
  }
  
  const Main = () => {
    return (
      <WithI18n i18n={i18n}>
        <ManageOrganizations />
      </WithI18n>
    )
  }

  Main.displayName = 'Main'

  AdminPage.displayName = 'AdminPage'
  
  const ManageOrganizations = () => {
    const t = useT()
  
    return (
      <div className="flex flex-col">
        <DynamicButtonRow buttons={[
          {
            icon: <AddIcon />,
            label: t['UserOrganizations:CreateOrganization'].get(),
            style: { cursor: 'pointer' },
            dialogComponent: <CreateOrganizationDialog />,
          }
        ]
        } />
        <OrganizationTable translator={t} />
      </div>
    )
  }
  
  
  function OrganizationTable({translator:t}) {
    const orgList = api.organization.getAllOrganizations.withAll.useLiveData()
    const [, setLocation] = useLocation()
    const toast = useToast()
    const [tableData, setTableData] = React.useState([])
  
  
    const [table, TableComponent] = useTable({
      header: [
        { label: t['UserOrganizations:Name'].get(), key: 'name' },
        { label: '', key: 'action' },
      ],
      data: tableData,
    })
  
    useEffect(() => {
      if (orgList.data) {
        setTableData(
          orgList.data.map((organization) => {
            return {
              ...organization,
              action: (<RowMenu orgAliasId={organization.alias} isAmin={true} name={organization.name} />),
              customAttribute: {
                row: {
                  onClick: ({ row }: { row: Row }) => setLocation(useLink(`/org/:orgAliasId`, { orgAliasId: organization.alias})),
                  className: 'cursor-pointer',
                },
              },
            }
          })
        )
      }
    }, [orgList])
  
    return (
      <>
        {TableComponent}
      </>
    )
  }
  
  