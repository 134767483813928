import { Page } from '@shared/components/page/Page'
import DefaultNavigation from '@shared/components/page/navigation/DefaultNavigation'
import React, { useEffect } from 'react'
import { Title } from '@shared/components/core/Title'
import { DynamicButtonRow } from '@shared/components/core/DynamicButtonRow'
import { AddIcon } from '@colombalink/basedui'
import AddFunctionDialog from '@shared/components/monitor/function/dialogs/AddDialog'
import { api } from '@shared/index'
import { useTable } from '@shared/components/core/table/Table'
import { RowMenu } from './RowMenu'
import { useRouteParams } from '@shared/router'
import { MonitorMenu } from '@app/pages/Orgs/Monitor/shared/Menu'
import AddNotificationTemplateDialog from '@app/pages/Orgs/Monitor/MonitorNotificationTemplate/AddDialog'
import { CenteredContainerOld } from '@shared/components/core/CenteredContainerOld'
import { FunctionLandingFragment } from '@shared/components/monitor/function/LandingFragment'
import { WithI18n } from '@shared/i18n/withI18n'
import i18n from '../i18n'
import { useT } from '@app/i18n'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'

type PageProps = { params: { orgAliasId: string } }
export type PageParams = PageProps['params']

const MonitorFunctionsPage = (props: PageProps) => {
  return (
    <Page className="pl-4">
      <DefaultNavigation />
      <Main />
    </Page>
  )
}

const Main = () => {
  return (
    <WithI18n i18n={i18n}>
      <ManageFunctions />
    </WithI18n>
  )
}
Main.displayName = 'Main'

export default MonitorFunctionsPage

const ManageFunctions = () => {
  const t= useT()
  const functionsSubscription = api.function.getFunctionsForOrg.useLiveData()
  return (

      <div className="flex h-full">
        <MonitorMenu page={'functions'} />
        <div className="flex flex-col overflow-auto w-full mx-4 mt-4 ">
            <FunctionLandingFragment>
            <>
              <DynamicButtonRow
                buttons={[
                  {
                    icon: <AddIcon />,
                    dialogComponent: <AddFunctionDialog />,
                    label: t['Monitor:Add'].get(),
                    style: { cursor: 'pointer' },
                  },
                ]}
              />
              <FunctionsTable functionsSubscription={functionsSubscription} />
            </>
            </FunctionLandingFragment>
        </div>
      </div>

  )
}

//ManageFunctions.displayName = 'Main'

function FunctionsTable({ functionsSubscription }) {
  const t = useT()
  const { orgAliasId } = useRouteParams<PageParams>()

  const [tableData, setTableData] = React.useState([])

  const [table, TableComponent] = useTable({
    header: [
      { label: t['Monitor:Name'].get(), key: 'name', isEditable: false },
      { label: t['Monitor:Actions'].get(), key: 'action' },
    ],
    data: tableData,
    onUpdatedCell: (row, cId, value) => {
      //console.log(row, cId, value)
    },
  })

  useEffect(() => {
    if (functionsSubscription.data) {
      setTableData(
        functionsSubscription.data.map((selectedFunction) => {
          return {
            ...selectedFunction,
            action: (
              <RowMenu
                orgAliasId={orgAliasId}
                functionId={selectedFunction.id}
              />
            ),
            customAttribute: {},
          }
        })
      )
    }
  }, [functionsSubscription.data])

  if (functionsSubscription.error) {
    throw functionsSubscription.error
  }

  if (functionsSubscription.loading) {
    return (
      <>
        {t['Monitor:Loading'].get()} <LoadingIcon />
      </>
    )
  }
  return <>{TableComponent}</>
}
