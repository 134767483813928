import { api } from '@shared/index'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { Option } from "@colombalink/basedui"
import { useT } from '@app/i18n'
import { getValueOfGenericProperty } from '@server/app'

type Thing = ReturnType<typeof api.thing.getAllWithOptions.useLiveData>['data'][0]

type Properties = {
  [key: string]: {
    key: string
    label: string // property name
    symbol: string // unit symbol
    min?: number
    max?: number
    tolerance?: number
    primitiveDataType: string
  }
}

type ThingId = string
type Status = 'idle' | 'alert' | 'alarm'| 'no-monitors'

function useProvideContext({ projectAliasId }: { projectAliasId: string }) {
  const t = useT()

  const statusOptions = [
    { label: t['Project:All'].get(), value: 'all' },
    { label: t['Project:Idle'].get(), value: 'idle' },
    { label: t['Project:Alert'].get(), value: 'alert' },
    { label: t['Project:Alarm'].get(), value: 'alarm' },
    { label: t['Project:NoMonitor'].get(), value: 'no-monitors' }
  ]

  const useThingsMap = useState<{ [key: ThingId]: Thing }>({})
  const useThingToPropKey = useState<{ [key: string]: string[] }>({})
  const useSelectedThingsIds = useState<ThingId[]>([])
  const useStatusFilter = useState<Status | 'all'>('all')
  const useOpenAccordionItems = useState<ThingId[]>([])

  const things = api.thing.getAllWithOptions.useLiveData({
    projectAliasId,
    fields: {
      id: true,
      isConfigured: true,
      name: true,
      lastEvent: {
        id: true,
        value: true,
        updatedAt: true
      },
      payloadSchema: {
        id: true,
        properties: {
          $list: {},
          name: true,
          label: true,
          idealRange: {
            min: true,
            max: true,
            tolerance: true,
          },
          primitiveDataType: true,
          unit: {
            label: true,
            symbol: true,
          }
        },
        influxFields: {
          $list: {},
          name: true,
          label: true,
          primitiveDataType: true,
          unit: {
            label: true,
            symbol: true,
          }
        }
      },
      monitors: {
        $list: {},
        status: true,
        id: true,
        activeState: true,
      }
    }
  })

  const thingsWithLastEvent = api.thing.getAllWithOptions.useLiveData({
    projectAliasId,
    fields: {
      id: true,
      name: true,
      lastEvent: {
        id: true,
        value: true
      },
    }
  })

  useEffect(() => {
    if (things.data) {

      // sort payloadSchema.influxFields by lable to display in order.
      things.data.forEach(item => {
          if (item.payloadSchema && item.payloadSchema.influxFields) {
              item.payloadSchema.influxFields.sort((a, b) => a?.label?.en?.localeCompare(b?.label?.en));
          }

      });

      const properties: Properties = {}
      const thingsToPropertyKeys: { [key: string]: string[] } = {}
      for (const thing of things.data) {
        if (!thing.payloadSchema) {
          continue
        }
        const allProperties = {}
        for (const property of thing.payloadSchema.properties) {
          allProperties[property.name] = property
        }


        for (const field of thing.payloadSchema.influxFields) {
          if (!allProperties[field.name]) {
            continue
          }
          properties[field.name] = {
            ...allProperties[field.name],
            // for ui compatibility
            ...allProperties[field.name].unit,
            label: allProperties[field.name].label,
          }
          // depending on the role we could restrict the access to the unit
          if (!thingsToPropertyKeys[thing.id]) {
            thingsToPropertyKeys[thing.id] = []
          }
          thingsToPropertyKeys[thing.id].push(field.name)
        }
      }

      //console.log('properties', properties)

      // useAllUnits[1](properties)
      useThingToPropKey[1](thingsToPropertyKeys)
    }
  }, [things.data])


  useEffect(() => {
    if (!things.data) {
      useThingsMap[1]({})
      return
    }
    const map = {}
    const ids = []
    for (const thing of things.data) {
      map[thing.id] = thing
      ids.push(thing.id)
    }
    useThingsMap[1](map)

  }, [things.data])


  const [thingsOptions, setThingsOptions] = useState<Option[]>([{ label: t['Project:All'].get(), value: 'all' }])
  const [selectedThing, setSelectedThing] = useState<string>('all')
  useEffect(() => {
    if (!things.data) {
      return
    }
    const options: Option[] = [{ label: t['Project:All'].get(), value: 'all' }]
    for (let thing of things.data) {
      
      //   console.log('thing', thing)
      options.push({
        label: thing.name,
        value: thing.id
      })
    }
    if (selectedThing === 'all') {
      useSelectedThingsIds[1](things.data.map(t => t.id))
    }
    setThingsOptions(options)
  }, [things.data])


  useEffect(() => {
    // console.log('selectedThing', things.data)
    if (!things.data) {
      useSelectedThingsIds[1]([])
      return
    }
    const filterStatus = useStatusFilter[0]

    //console.log('currentStatus', filterStatus)
    if (selectedThing === 'all' && filterStatus === 'all') {
      useSelectedThingsIds[1](things.data.map(t => t.id))
      return
    }

    if (selectedThing === 'all') {
      const newSelection = []
      for (const thing of things.data) {
        if (thing.status === filterStatus) {
          newSelection.push(thing.id)
        }
      }
      useSelectedThingsIds[1](newSelection)
      return
    }
    //   console.log('selectedThing', selectedThing)
    const thing = useThingsMap[0][selectedThing]
    if (!thing) {
      useSelectedThingsIds[1]([])
      return
    }

    const newSelection = []
    if (thing.status === filterStatus || filterStatus === 'all') {
      newSelection.push(thing.id)
    }
    useSelectedThingsIds[1](newSelection)

  }, [selectedThing, useStatusFilter[0], things.data])

  // console.log(things)
  return {
    projectAliasId,
    things: {
      array: things.data,
      loading: things.loading && thingsWithLastEvent.loading,
      error: things.error,
      map: useThingsMap[0],
      withLastEvent: thingsWithLastEvent.data,
    },
    filters: {
      things: {
        options: thingsOptions,
        selectedOption: selectedThing,
        setSelectedOption: (option: string) => setSelectedThing(option),
      },
      status: {
        options: statusOptions,
        selected: useStatusFilter[0], setSelected: (status: string) => useStatusFilter[1](status as Status),
      }

    },
    useSelectedThingsIds,
    useThingToUnitsKey: useThingToPropKey,
    useOpenAccordionItems,
    useThingProperty: (thingId: string, property: string) => ({
      getStatus: () => getThingPropertyStatus(useThingsMap[0][thingId], property),
      getProperty: () => useThingsMap[0][thingId].payloadSchema.properties.find(prop => prop.name === property)
    })
  }
}

type ContextType = ReturnType<typeof useProvideContext>
const Context = createContext<ContextType | undefined>(undefined)


type Props = {
  projectAliasId: string
}

const ProjectDashboardContext: React.FC<Props> = ({ children, projectAliasId }) => {
  const value = useProvideContext({ projectAliasId })

  return <Context.Provider value={value}>{children}</Context.Provider>
}

const useProjectDashboardContext = (): ContextType => {
  const context = useContext(Context)
  if (!context) {
    //TODO: make the error a bit cleaner will user see this 
    throw new Error('usePageContext must be used within a PageContextProvider')
  }
  return context
}


const getThingPropertyStatus = (thing, propertyName) => {
  const property = thing.payloadSchema.properties.find(prop => prop.name === propertyName);

  if (!property) {
    throw new Error(`Property "${propertyName}" not found in payloadSchema.`);
  }

  if (!thing.payloadSchema || !thing.lastEvent || !property.idealRange) {
    return 'no-monitors';
  }

  const value = getValueOfGenericProperty(thing.lastEvent.value, propertyName)
  const idealRange = property.idealRange;

  if (value !== undefined) {

    const t = idealRange.tolerance || 0
    const min = idealRange.min
    const max = idealRange.max

    if (value < min - t) {
      return 'alarm'
    }
    if (value <= min + t) {
      return 'alert'
    }
    if (value <= max - t) {
      return 'idle'
    }
    if (value <= max + t) {
      return 'alert'
    }
    return 'alarm'
  }
  return 'no-monitors';
}

export { useProjectDashboardContext, ProjectDashboardContext }
