import {
  useToast,
} from '@colombalink/basedui'
import React, { useEffect } from 'react'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'
import { Row } from '@influxdata/influxdb-client'
import { RowMenu } from './RowMenu'
import { api } from '@shared/index'
import { useLocation } from 'wouter'
import { useTable } from '@shared/components/core/table/Table'
import { useLink } from '../../../../routes'
import { useRouteParams } from '../../../../../shared/router'
import { PageParams } from '../Page'
import { useT } from '@app/i18n'


export function ProjectsTable() {
  const t = useT()
  const { orgAliasId } = useRouteParams<PageParams>()
  const projects = api.project.getAll.useLiveData({ orgAliasId });
  const [, setLocation] = useLocation()
  const removeOrganization = api.organization.deleteOrganization.useMutation()
  const toast = useToast()
  const [tableData, setTableData] = React.useState([])


  const [table, TableComponent] = useTable({
    header: [
      { label: t["ProjectsEditor:Name"].get(), key: 'name' },
      { label: '', key: 'action' },
    ],
    data: tableData,
  })


  useEffect(() => {
    if (projects.data) {
      setTableData(
        projects.data.map((project) => {
          return {
            ...project,
            action: (<RowMenu projectAliasId={project.alias} name={project.name} />),
            customAttribute: {
              row: {
                onClick: ({ row }: { row: Row }) => setLocation(useLink('/org/:orgAliasId/project/:projectAliasId', { orgAliasId, projectAliasId: project.alias })),
                className: 'cursor-pointer',
              },
            },
          }
        })
      )
    }
  }, [projects.data])


  if (projects.error) {
    throw projects.error
  }

  if (projects.loading) {
    return <>{t["ProjectsEditor:Loading"].get()} <LoadingIcon /></>
  }
  return (
    <>
      {TableComponent}
    </>
  )
}

