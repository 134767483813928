import { DefaultProcedure } from '@server/app/trpc/router'
import { z } from 'zod'
import { incrementQuotaCount } from '@server/app/trpc/monidas/routers/organization/quotas/modifyCount'
import { decrementQuotaCount } from '@server/app/trpc/monidas/routers/organization/quotas/modifyCount'

export const updateNotificationGroupParams = z.object({
  id: z.string(),
  name: z.string({ required_error: 'Name is required' }),
  description: z.string({ required_error: 'Description is required' }),
  notificationGroupType: z.string(),
  values: z.array(z.string()),
})

export const updateNotificationGroupProcedure = (procedure: DefaultProcedure) =>
  procedure
    .input(updateNotificationGroupParams)
    .mutation(async ({ ctx, input }) => {
      const notificationGroup = await ctx.based.db.default.get({
        values: true,
        $id: input.id,
      })
      await decrementQuotaCount(ctx, notificationGroup.values.length)

      const res = await ctx.based.db.default.set({
        $merge: true,
        $id: input.id,
        name: input.name,
        description: input.description,
        notificationGroupType: input.notificationGroupType,
        values: input.values,
        type: 'notificationGroup',
      })
      ctx.logger.debug('procedure::updateNotificationGroup result:', res)
      await incrementQuotaCount(ctx, input.values.length)
    })
