import { useRouteParams } from '../../../../shared/router'
import { Menu } from '@colombalink/basedui'
import { Page } from '@shared/components/page/Page'
import DefaultNavigation from '@shared/components/page/navigation/DefaultNavigation'
import { useLocation } from 'wouter'
import { useLink } from '../../../routes'
import { Fragment, useEffect, useState } from 'react'
import { api } from '@shared/index'
import { Title } from '@shared/components/core/Title'
import { UnitsFragment } from './Fragments/Units'
import { AlarmsFragment } from './Fragments/Alarms'
import PropertiesFragment from './Fragments/Properties'
import { ThingConfigureLandingFragment } from '@shared/components/thing/configure/LandingFragment'
import { LastEventFragment } from './Fragments/Latest'
import { TrendsFragment } from './Fragments/Trends'
import { WithI18n } from '@shared/i18n/withI18n'
import i18n from './i18n'
import { useT } from '@app/i18n'
import { MonitorsFragment } from '@app/pages/Orgs/Thing/Fragments/Monitors'
import { PageProps } from './Page'


export const OwnerPage =  (props: PageProps) => {
  return (
    <Page className="">
      <DefaultNavigation />
      <Main />
    </Page>
  )
}

OwnerPage.displayName = 'OwnerPage'

const Main = () => {
  return (
    <WithI18n i18n={i18n}>
      <ManageOrganizationProjectsMain />
    </WithI18n>
  )
}

Main.displayName = 'Main'

const ManageOrganizationProjectsMain = () => {
  const t = useT()
  const { thingAliasId, orgAliasId, projectAliasId } =
    useRouteParams<PageParams>()
  const [location, setLocation] = useLocation()
  const [active, setActive] = useState('status')
  const thing = api.thing.get.useLiveData({ thingAliasId })

  useEffect(() => {
    if (window.location.hash.length < 1) {
      setLocation(`${location}#status`)
      return
    }
    setActive(window.location.hash.replace('#', ''))
  }, [location])

  if (thing.error) {
    throw thing.error
  }

  if (thing.loading) {
    return <div>{t['Thing:Loading'].get()}</div>
  }

  if (!thing.data) {
    return <div> {t['Thing:DeviceNotFound'].get()} </div>
  }

  // if (!thing.data.isConfigured) {
  //   return <div className="mt-4">
  //     The thing <b>{thing.data.name}</b> has not been configured yet.
  //     <Spacer />
  //     <Button onClick={
  //       () => {
  //         setLocation(useLink('/org/:orgAliasId/project/:projectAliasId/thing/:thingAliasId/configure', { orgAliasId, projectAliasId, thingAliasId }))
  //       }

  //     }>Configure</Button>
  //   </div>
  // }

  return (
    <ThingConfigureLandingFragment>
      <div className="h-full flex">
        <Menu
          style={{
            padding: '1em',
            width: '140px',
          }}
          collapse
          active={active}
          onChange={(page) => {
            if (['Info', 'Configuration'].includes(page)) {
              return
            }
            const newLocation = location.split('#')[0] + `#${page}`
            setActive(page)
            setLocation(newLocation)
          }}
          data={{
            Info: {
              general: t['Thing:General'].get(),
              status: t['Thing:Status'].get(),
              trends: t['Thing:Trends'].get(),
              alarms: t['Thing:Alarms'].get(),
              // alams: 'Monitor logs',
            },
            Configuration: {
              monitors: t['Thing:Monitors'].get(),
              units: t['Thing:Units'].get(),
              properties: t['Thing:Properties'].get(),
              sources: t['Thing:DataSources'].get(),
            },
          }}
        />

        <div className="w-full overflow-y-scroll">
          <FragmentSwitch active={active}>
            <GeneralFragment key={'general'} />
            <LastEventFragment key={'status'} />
            <TrendsFragment key={'trends'} />
            <AlarmsFragment key={'alarms'} />
            <MonitorsFragment key={'monitors'} />
            <DataSourcesFragment key={'sources'} />
            <UnitsFragment key={'units'} />
            <PropertiesFragment key={'properties'} />
          </FragmentSwitch>
        </div>
      </div>
    </ThingConfigureLandingFragment>
  )
}

ManageOrganizationProjectsMain.displayName = 'ManageOrganizationProjectsMain'

const DataSourcesFragment = () => {
  const t = useT()
  const { orgAliasId, projectAliasId, thingAliasId } =
    useRouteParams<PageParams>()
  const thing = api.thing.get.useLiveData({ thingAliasId })
  const alias = thing.data?.alias
  const uuid = thing.data?.uuid

  const [, setLocation] = useLocation()

  return (
    <div className="p-4">
      <Title>{t['Thing:DataSources'].get()}</Title>

      <h2>{t['Thing:Authorization'].get()}</h2>
      <p>{t['Thing:ProvideApiToken'].get()}</p>
      <p>
        {t['Thing:GenerateApiToken'].get()}{' '}
        <a
          onClick={() => {
            setLocation(
              useLink('/org/:orgAliasId/settings/api/tokens', { orgAliasId })
            )
          }}
        >
          {t['Thing:Settings'].get()}
        </a>{' '}
        {t['Thing:Page'].get()}
      </p>
      {t['Thing:JsonAuthorization'].get()}
      <h2>Http</h2>
      <p>{t['Thing:SendData'].get()}</p>
      <p>{t['Thing:SendDataExample'].get()} </p>
      <pre style={{ userSelect: 'text' }}>
        <code>
          {`curl -X POST https://api.monidas.com/datahub/events/push`}
          {` -H  'json-authorization: {"token": "eyJhbGciOiJSUzI1......"}'`}
          {` -H 'x-monidas-alias: ${alias}`}
          {` -H 'Content-Type: application/json' -d '{"temperature": 23.4}'`}
        </code>
      </pre>

      <h2>{t['Thing:WithUUID'].get()}</h2>
      <pre style={{ userSelect: 'text' }}>
        <code>
          {`curl -X POST https://api.monidas.com/datahub/events/push`}
          {` -H 'x-monidas-uuid: ${uuid}`}
          {` -H  'json-authorization: {"token": "eyJhbGciOiJSUzI1......"}'`}
          {` -H 'Content-Type: application/json' -d '{"temperature": 23.4}'`}
        </code>
      </pre>
    </div>
  )
}

const GeneralFragment = () => {
  const t = useT()
  const { orgAliasId, projectAliasId, thingAliasId } =
    useRouteParams<PageParams>()
  const thing = api.thing.get.useLiveData({ thingAliasId })
  const alias = thing.data?.alias
  const uuid = thing.data?.uuid

  const infos = [
    {
      label: 'Alias',
      value: alias,
    },
    {
      label: 'UUID',
      value: uuid,
    },
  ]
  const qrUuid = thing.data?.aliases?.find((alias) =>
    alias.startsWith('lora-uuid')
  )

  if (qrUuid) {
    // console.log('', qrUuid)
    infos.push({
      label: 'Lora UUID',
      value: qrUuid.split('/')[1],
    })

    infos.push({
      label: 'QR URL',
      value: `https://app.monidas.com/#/info/id/${qrUuid.split('/')[1]}`,
    })
  }

  return (
    <div>
      <Title>{t['Thing:GeneralInformation'].get()}</Title>
      {infos.map((info, index) => {
        return (
          <div key={index} className="flex mb-2">
            <div className="w-32 font-bold">{info.label}: </div>
            <div
              className="flex-1"
              style={{
                userSelect: 'text',
              }}
            >
              {info.value}
            </div>
          </div>
        )
      })}
    </div>
  )
}

const FragmentSwitch = ({ active, children }: { active: string; children }) => {
  const t = useT()
  let ActiveFragment = null
  for (let child of children) {
    if (child.key === active) {
      ActiveFragment = child
      break
    }
  }
  if (!ActiveFragment) {
    ActiveFragment = <>{t['Thing:SorryPageNotFound'].get()}</>
  }
  return <Fragment>{ActiveFragment}</Fragment>
}
