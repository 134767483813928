import { ActionsMultiSelect, ActiveStateSelect, DataInput, LoadingHandler, NameInput, RuleConfigInput, RuleFunctionSelect, StatusMessageTemplateSelect, StatusSelect } from "./FormElements"
import { Dialog, Spacer, Toast, useToast, Text } from "@colombalink/basedui"
import { MonitorsBaseContext, useMonitorsBaseContext } from "./BaseContext"
import { api } from "@shared/index"
import { useRouteParams } from "@shared/router"
import { PageParams } from "../index"
import { useT } from "@app/i18n"

const AddAlarmDialog = () => {
  const t = useT();
  return (
    <MonitorsBaseContext>
      <Dialog label={t["ThingMonitors:CreateNewMonitor"].get()} >
        <div className="flex flex-col">
          <LoadingHandler>
            <NameInput />
            <Spacer />
            <StatusSelect />
            <Spacer />
            <ActiveStateSelect />
            <Spacer />
            <RuleFunctionSelect />
            <Spacer />
            <RuleConfigInput />
            <Spacer />
            <ActionsMultiSelect />
            <Spacer />
            <StatusMessageTemplateSelect />
            <Spacer />
            <DataInput />
            <Spacer />
          </LoadingHandler>
        </div>
          <Dialog.Buttons border>
            <Dialog.Cancel>{t["ThingMonitors:Close"].get()}</Dialog.Cancel>
            <ConfirmCreateButton />
          </Dialog.Buttons>
      </Dialog>
    </MonitorsBaseContext >
  )
}



export default AddAlarmDialog



export const ConfirmCreateButton = () => {
  const t = useT();
  const { formData: {
    useName: [name],
    useStatus: [status],
    useStatusMessageTemplate: [statusMessageTemplate],
    useActiveState: [activeState],
    useRuleFunction: [ruleFunction],
    useRuleConfig: [ruleConfig],
    useActions: [actions],
    useData: [data],
  } } = useMonitorsBaseContext();
 
  const createMonitor = api.monitor.setAlarm.useMutation();
  const { thingAliasId } = useRouteParams<PageParams>();
  const toast = useToast();
  const handleConfirm = async () => {
    try {
      await createMonitor.mutateAsync({
        thingAliasId,
        name,
        status,
        statusMessageTemplate,
        activeState,
        ruleFunction,
        ruleConfig: JSON.parse(ruleConfig),
        actions,
        data: JSON.parse(data),
      });
      toast.add(<Toast label={t["ThingMonitors:CreatedMonitor"].get()} type="success" description={<Text> {t["ThingMonitors:Monitor"].get()} <b>{name}</b> {t["ThingMonitors:CreatedSuccessfully"].get()}</Text>} />);
    } catch (e) {
      toast.add(<Toast label={t["ThingMonitors:FailedToCreateMonitor"].get()} type="error" description={<Text style={{ whiteSpace: 'normal' }}>{e.message}</Text>} />);
      throw e;
    }
  };

  return (
    <Dialog.Confirm onConfirm={handleConfirm}>
      {t["ThingMonitors:Confirm"].get()}
    </Dialog.Confirm>
  );
};