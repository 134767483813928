import React from 'react'

export const SimpleContainer = ({
  className,
  style,
  children,
}: {
  className?: string
  style?: React.CSSProperties
  children: React.ReactNode[]|React.ReactNode
}) => {
  return (
    <div className="w-full h-full flex">
      {children} 
    </div >
  )
}

