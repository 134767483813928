
const OrgMonitorPageContentI18n = {

    'Monitor:Actions': {
        en: 'Actions',
        de: 'Aktionen',
    },
    'Monitor:Functions': {
        en: 'Functions',
        de: 'Funktionen',
    },
    'Monitor:Templates': {
        en: 'Templates',
        de: 'Vorlagen',
    },
    'Monitor:Groups': {
        en: 'Groups',
        de: 'Gruppen',
    },
    'Monitor:Users': {
        en: 'Users',
        de: 'Benutzer',
    },
    'Monitor:Add': {
        en: 'Add',
        de: 'Hinzufügen',
    },
    'Monitor:Name': {
        en: 'Name',
        de: 'Name',
    },
    'Monitor:Trigger': {
        en: 'Trigger',
        de: 'Auslöser',
    },
    'Monitor:TriggerTyoe': {
        en: 'Trigger Type',
        de: 'Auslöser Typ',
    },
    'Monitor:NotificationTemplate': {
        en: 'Notification Template',
        de: 'Benachrichtigungsvorlage',
    },
    'Monitor:NotificationGroup': {
        en: 'Notification Group',
        de: 'Benachrichtigungsgruppe',
    },
    'Monitor:Loading': {
        en: 'Loading',
        de: 'Laden',
    },
    'Monitor:Edit': {
        en: 'Edit',
        de: 'Bearbeiten',
    },
    'Monitor:RemovedAction': {
        en: 'Removed Action',
        de: 'Aktion entfernt',
    },
    'Monitor:RemovedSuccess': {
        en: 'Removed Successfully',
        de: 'Erfolgreich entfernt',
    },
    'Monitor:RemovedFunction': {
        en: 'Removed Function',
        de: 'Funktion entfernt',
    },
    'Monitor:RemovedTemplate': {
        en: 'Removed Template',
        de: 'Vorlage entfernt',
    },
    'Monitor:Remove': {
        en: 'Remove',
        de: 'Entfernen',
    },
    'Monitor:ActionRemoved': {
        en: 'Action Removed',
        de: 'Aktion entfernt',
    },
    'Monitor:UpdatedPlayground': {
        en: 'Updated Playground',
        de: 'Spielplatz aktualisiert',
    },
    'Monitor:UpdatedSuccess': {
        en: 'Updated Successfully',
        de: 'Erfolgreich aktualisiert',
    },
    'Monitor:Playground': {
        en: 'Playground',
        de: 'Spielplatz',
    },
    'Monitor:Save': {
        en: 'Save',
        de: 'Speichern',
    },
    'Monitor:Cancel': {
        en: 'Cancel',
        de: 'Abbrechen',
    },
    'Monitor:UpdatedFunction': {
        en: 'Updated Function',
        de: 'Funktion aktualisiert',
    },
    'Monitor:Function': {
        en: 'Function',
        de: 'Funktion',
    },
    'Monitor:PleaseAddEventBeforeProceeding': {
        en: 'Please add event before proceeding',
        de: 'Bitte fügen Sie ein Ereignis hinzu, bevor Sie fortfahren',
    },
    'Monitor:Editor': {
        en: 'Editor',
        de: 'Editor',
    },
    'Monitor:AddEvents': {
        en: 'Add Events',
        de: 'Ereignisse hinzufügen',
    },
    'Monitor:RuleConfigValues': {
        en: 'Rule Config Values',
        de: 'Regelkonfigurationswerte',
    },
    'Monitor:RuleConfigValuesInfoTitle': {
        en: 'Information',
        de: 'Informationen',
    },
    'Monitor:RuleConfigValuesInfoText': {
        en: 'Pay attention to the event structure/names.',
        de: 'Achten Sie auf die Ereignisstruktur/-namen.',
    },
    'Monitor:InitialValues': {
        en: 'Initial Values',
        de: 'Anfangswerte',
    },
    'Monitor:Event': {
        en: 'Event ',
        de: 'Ereignis ',
    },
    'Monitor:InvalidJson': {
        en: 'Invalid JSON',
        de: 'Ungültiges JSON',
    },
    'Monitor:Result': {
        en: 'Result',
        de: 'Ergebnis',
    },
    'Monitor:General': {
        en: 'General',
        de: 'Allgemein',
    },
    'Monitor:User': {
        en: 'User',
        de: 'Benutzer',
    },
    'Monitor:TestOutput': {
        en: 'Test Output:',
        de: 'Testausgabe:',
    },
    'Monitor:Run': {
        en: 'Run',
        de: 'Starten',
    },
    'Monitor:Description': {
        en: 'Description',
        de: 'Beschreibung',
    },
    'Monitor:NotificationGroupType': {
        en: 'Notification Group Type',
        de: 'Benachrichtigungsgruppentyp',
    },
    'Monitor:Value': {
        en: 'Value',
        de: 'Wert',
    },
    'Monitor:Action': {
        en: 'Action',
        de: 'Aktion',
    },
    'Monitor:RemovedGroup': {
        en: 'Removed Group',
        de: 'Gruppe entfernt',
    },
    'Monitor:AddCustomEmail': {
        en: 'Add Custom Email',
        de: 'Benutzerdefinierte E-Mail hinzufügen',
    },
    'Monitor:UserSearch': {
        en: 'User Search',
        de: 'Benutzersuche',
    },
    'Monitor:EnterEmail': {
        en: 'Enter Email',
        de: 'E-Mail eingeben',
    },
    'Monitor:EnterEmailHere': {
        en: 'Enter Email Here',
        de: 'E-Mail hier eingeben',
    },
    'Monitor:PleaseEnterAValidEmail': {
        en: 'Please enter a valid email',
        de: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
    },
    'Monitor:Email': {
        en: 'Email',
        de: 'E-Mail',
    },
    'Monitor:SearchForUser': {
        en: 'Search for User name or email',
        de: 'Suche nach Benutzername oder E-Mail',
    },
    'Monitor:Title': {
        en: 'Title',
        de: 'Titel',
    },
    'Monitor:Message': {
        en: 'Message',
        de: 'Nachricht',
    },
    'Monitor:MaxReached': {
        en: 'notificated users limit reached for Group.',
        de: 'Benachrichtigende Benutzer für Gruppenlimit erreicht.',
    },

}



export default OrgMonitorPageContentI18n
export type OrgMonitorPageContentI18nType = typeof OrgMonitorPageContentI18n
