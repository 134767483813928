import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react'
import ErrorBoundary from '../errors/ErrorBoundary'
import { PageContext, usePageContext } from './context'

export type PageProps = {
  navigation?: ReactElement
  sidePane?: ReactElement
  main?: ReactElement
  children?: ReactElement[]
  className?: string
}

export const Page: FunctionComponent<PageProps> = ({
  className = '',
  children,
}) => {
  return (
    <PageContext components={children}>
      <PageWithContext className={className} />
    </PageContext>
  )
}

const PageWithContext = ({ className = '' }) => {
  const { main, navigation } = usePageContext()

  const [mainVisible, setMainVisible] = useState(false)

  useEffect(() => {
    // Assuming you have a way to detect when 'main' changes
    // Trigger the fade-in effect
    setMainVisible(false) // hide before change
    setTimeout(() => setMainVisible(true), 50) // then show with transition
  }, [main]) // Dependency array with 'main'

  return (
    <div className="w-screen h-screen z-10 absolute bg-white">
      <div
        className="border-b-1 border-gray-300 shadow "
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
        }}
      >
        <div className="border-b-1 border-gray-300 shadow">
          {navigation.component}
        </div>
        <div className="w-screen h-[2px]" />
        <div className="flex overflow-auto h-full">
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              className={`w-full h-full min-w-[200px] fade-in ${
                mainVisible ? 'visible' : ''
              } ${className}`}
            >
              <ErrorBoundary>{main}</ErrorBoundary>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
