import { useEffect, useState } from "react"

export default ({ children }) => {
    const [fadeIn, setFade] = useState(false)
    useEffect(() => {
        const t = setTimeout(() => setFade(true), 300)
        return () => {
            clearTimeout(t)
        }
    }, [])

    return <div className={`flex flex-col p-6 md:p-8 w-full max-w-[456px] transition-opacity duration-1000 ${fadeIn ? 'opacity-100' : 'opacity-0'}`}>
        {children}
    </div>
}