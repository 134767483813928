import { useDialog } from "@shared/components/core/useDialog"
import AddFunctionDialog from "./dialogs/AddDialog"
import { Role } from "@server/shared"
import { api } from "@shared/index"
import { useRouteParams } from "@shared/router"
import { NoItemGuidance } from "@shared/components/page/generic/NoItemGuidance"
import { useEffect, useState } from "react"
import { AddIcon} from "@colombalink/basedui"
import { CustomizableButton } from "@shared/components/core/CustomizableButton"
import { WithI18n } from "@shared/i18n/withI18n"
import i18n from "../i18n"
import { useT } from "@app/i18n"
import { LoadingIcon } from '@shared/components/core/LoadingIcon'

const _FunctionLandingFragment = ({ children }) => {
    const t = useT()
    const createFunctionDialog = useDialog(<AddFunctionDialog />)
    const { orgAliasId } = useRouteParams<{ orgAliasId: string }>()
    const roles = api.user.getRoles.useLiveData()
    const functionsSubscription = api.function.getFunctionsForOrg.useLiveData()
    const [hasFunctions, setHasFunctions] = useState<boolean>(null)

    useEffect(() => {
        if (!roles.data?.asArray) {
            return
        }
        if (!functionsSubscription.data) {
            return
        }
        const hasFunctions = roles.data.asArray.some((role) => {
            if (Array.isArray(role)
                && role[0].startsWith(orgAliasId)
                && Array.isArray(functionsSubscription.data) && functionsSubscription.data.length > 0
            ) {
                return true
            }

        })

        setHasFunctions(hasFunctions)

    }, [roles.data?.asArray, functionsSubscription.data, orgAliasId])



    if (roles.error) {
        throw roles.error
    }


    if (roles.loading || hasFunctions === null) {
        return <LoadingIcon />
    }


    if (hasFunctions) {
        return children
    }

    const orgRole = roles.data[orgAliasId] as Role
    if (orgRole === "OWNER") {
        return (
            <NoItemGuidance>

                {
                    t["MonitorFunction:NoFunctionsFound"].get()
                }
                <CustomizableButton
                    icon={<AddIcon />}
                    onClick={async () => {
                        await createFunctionDialog.open()
                    }}
                >
                    {
                        t["MonitorFunction:CreateFunction"].get()
                    }
                </CustomizableButton>
            </NoItemGuidance>
        )
    }

    return (
        <NoItemGuidance
        >
            {
                t["MonitorFunction:NoFunctionsFound"].get()
            }
            {
                t["MonitorFunction:PleaseContactTheOwner"].get()
            }
        </NoItemGuidance>
    )

}

export const FunctionLandingFragment = (props) => (
    <WithI18n i18n={i18n}>
        <_FunctionLandingFragment {...props} />
    </WithI18n>
)