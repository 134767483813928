import { Button, Input, LockIcon, Spacer, Tab, Tabs, Text, Toast, useToast } from '@colombalink/basedui';
import { useState, useRef, useEffect } from 'react';
import { email as isEmail } from '@saulx/validators'
import { useLocation } from 'wouter';
import MainContainer from './shared/MainContainer';
import FadeInBox from './shared/FadeInBox';
import { WithI18n } from '@shared/i18n/withI18n';
import i18n from './i18n';
import { useT } from '@app/i18n';
import { useIdentityClient } from '@shared/client/context';




export default () => (
  <WithI18n i18n={i18n}>
    <LoginPage />
  </WithI18n>
)



 const LoginPage= () =>{
  const client = useIdentityClient()


  const t = useT()
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const passwordRef = useRef<HTMLInputElement>(null);
  const [, setLocation] = useLocation()

  const toast = useToast()

  return (
    <MainContainer>
      <FadeInBox>
        <Input
          large
          label={t['Login:Email'].get()}
          value={email}
          type="email"
          placeholder={t['Login:EmailPlaceholder'].get()}
          onChange={(str) => setEmail(str)}
        />
        <Spacer />
        <Input
          label={t['Login:Password'].get()}
          large
          inputRef={passwordRef}
          icon={LockIcon}
          type="password"
          onChange={(password) => {
            setPassword(password)
          }}
          style={{ marginBottom: 12 }}
        />
        <div className='text-justify end' style={{ marginBottom: 24 }}>
          <a className="text-sailorblue-main"
            onClick={() => {
              setLocation("login/forgot")
            }}
          >{t['Login:ForgotPassword'].get()}</a>
        </div>

        <Button
          large
          fill
          style={{
            marginBottom: 24,
            height: 48,
          }}
          textAlign="center"
          keyboardShortcut="Enter"
          disabled={!isEmail(email) || password.length < 3}
          onClick={async () => {
            try {
              await client.call("login", { password, email });
              setLocation("/") 
            } catch(e){
              toast.add(
                <Toast
                  label={t['Login:FailedToLogin'].get()}
                  type="error"
                  description={
                    <Text style={{ whiteSpace: 'normal' }}>
                      {t['Login:FailedToLoginInvalidCredentials'].get()}
                    </Text>
                  }
                />)
              throw e 
            }
          }}
        >
          {t['Login:Login'].get()}
        </Button>
      </FadeInBox>

    </MainContainer>
  );
};




