const ThingPageContentI18n = {


    "Thing:Name": {
        "en": "Name",
        "de": "Name",    
    },
    "Thing:Loading": {
        "en": "Loading",
        "de": "Laden",    
    },
    "Thing:DeviceNotFound": {
        "en": "Device not found",
        "de": "Gerät nicht gefunden",    
    },
    "Thing:Authorization": {
        "en": "Authorization",
        "de": "Berechtigung",    
    },
    "Thing:DataSources": {
        "en": "Data Sources",
        "de": "Datenquellen",    
    },
    "Thing:ProvideApiToken": {
        "en": "You need to provide a valid api token to be able to push data to Monidas.",
        "de": "Sie müssen einen gültigen API-Token angeben, um Daten an Monidas senden zu können.",
    },
    "Thing:GenerateApiToken": {
        "en": "You can generate a token in the",
        "de": "Sie können einen API-Token generieren unter",
    },
    "Thing:Settings": {
        "en": "settings",
        "de": "Einstellungen",
    },
    "Thing:Page": {
        "en": "page",
        "de": "erstellen",
    },
    "Thing:JsonAuthorization": {
        "en": "json-authorization",
        "de": "json-authorization",
    },
    "Thing:SendData": {
        "en" : "You can send data to Monidas using HTTP requests.",
        "de" : "Sie können Daten an Monidas über HTTP-Anfragen senden.",
    },
    "Thing:SendDataExample": {
        "en" : "The following example shows how to send a event with the curl command.",
        "de" : "Das folgende Beispiel zeigt, wie Sie mit dem curl-Befehl ein Ereignis senden.",
    },
    "Thing:WithUUID": {
        "en" : "With UUID",
        "de" : "Mit UUID",
    },
    "Thing:GeneralInformation": {
        "en" : "General Information",
        "de" : "Allgemeine Informationen",
    },
    "Thing:SorryPageNotFound": {
        "en" : "Sorry,We did not find this page.",
        "de" : "Sorry, wir haben diese Seite nicht gefunden.",
    },
    "Thing:All": {
        "en" : "All",
        "de" : "Alle",
    },
    "Thing:Idle": {
        "en" : "Idle",
        "de" : "Ruhezustand",
    },
    "Thing:Alert": {
        "en" : "Alert",
        "de" : "Warnung",
    },
    "Thing:Alarm": {
        "en" : "Alarm",
        "de" : "Alarm",
    },
    "Thing:NoProjectsFound": {
        "en" : "No projects found",
        "de" : "Keine Projekte gefunden",
    },
    "Thing:Edit": {
        "en" : "Edit",
        "de" : "Bearbeiten",
    },
    "Thing:Label": {
        "en" : "Label",
        "de" : "Bezeichnung",
    },
    "Thing:Symbol": {
        "en" : "Symbol",
        "de" : "Symbol",
    },
    "Thing:Actions": {
        "en" : "Actions",
        "de" : "Aktionen",
    },
    "Thing:PrimitiveDataType": {
        "en" : "Primitive Data Type",
        "de" : "Primitiver Datentyp",
    },
    "Thing:PropertyPath": {
        "en" : "Property Path",
        "de" : "Eigenschaftspfad",
    },
    "Thing:Tolerance": {
        "en" : "Tolerance",
        "de" : "Toleranz",
    },
    "Thing:Min": {
        "en" : "Min",
        "de" : "Min",
    },
    "Thing:Max": {
        "en" : "Max",
        "de" : "Max",
    },
    "Thing:MonitorName": {
        "en" : "Monitor Name",
        "de" : "Monitor Name",
    },
    "Thing:StatusMessage": {
        "en" : "Status Message",
        "de" : "Status Nachricht",
    },
    "Thing:Status": {
        "en" : "Status",
        "de" : "Status",
    },
    "Thing:Cancel": {
        "en" : "Cancel",
        "de" : "Quitteren",
    },
    "Thing:Deactivate": {
        "en" : "Deactivate",
        "de" : "Deaktivieren",
    },
    "Thing:NoAlarms": {
        "en" : "No Alarms",
        "de" : "Keine Alarme",
    },
    "Thing:General": {
        "en" : "General",
        "de" : "Allgemein",
    },
    "Thing:Latest": {
        "en" : "Latest",
        "de" : "Letzte",
    },
    "Thing:Trends": {
        "en" : "Trends",
        "de" : "Trends",
    },
    "Thing:Alarms": {
        "en" : "Alarms",
        "de" : "Alarm",
    },
    "Thing:Units": {
        "en" : "Units",
        "de" : "Einheiten",
    },
    "Thing:Properties": {
        "en" : "Properties",
        "de" : "Eigenschaften",
    },
    "Thing:Property": {
        "en" : "Property",
        "de" : "Eigenschaft",
    },
    "Thing:Monitor": {
        "en" : "Monitor",
        "de" : "Monitor",
    },
    "Thing:UpdatedUnit": {
        "en" : "Updated Unit",
        "de" : "Einheit aktualisiert",
    },
    "Thing:UpdatedProperty": {
        "en" : "Updated Property",
        "de" : "Eigenschaft aktualisiert",
    },
    "Thing:Unit": {
        "en" : "Unit ",
        "de" : "Einheit ",
    },
    "Thing:UpdatedSuccessfully": {
        "en" : "Updated Successfully",
        "de" : "Erfolgreich aktualisiert",
    },
    "Thing:FailedToUpdateUnit": {
        "en" : "Failed to update unit",
        "de" : "Aktualisierung der Einheit fehlgeschlagen",
    },
    "Thing:FailedToUpdateProperty": {
        "en" : "Failed to update property",
        "de" : "Aktualisierung der Eigenschaft fehlgeschlagen",
    },
    "Thing:EditUnit": {
        "en" : "Edit Unit",
        "de" : "Einheit bearbeiten",
    },
    "Thing:EditProperty": {
        "en" : "Edit Property",
        "de" : "Eigenschaft bearbeiten",
    },
    "Thing:Save": {
        "en" : "Save",
        "de" : "Speichern",
    },
    "Thing:Close": {
        "en" : "Close",
        "de" : "Schließen",
    },
    "Thing:Monitors": {
        "en" : "Monitors",
        "de" : "Überwachung",
    },

    "ThingMonitors:Active": {
        "en": "Active",
        "de": "Aktiv"
    },
    "ThingMonitors:ActivedSuccsessfully": {
        "en": "Actived successfully",
        "de": "Aktivierung erfolgreich"
    },

    "ThingMonitors:Inactive": {
        "en": "Inactive",
        "de": "Inaktiv"
    },
    
    "ThingMonitors:Activate": {
        "en": "Activate",
        "de": "Aktivieren"
    },
    "ThingMonitors:Deactivate": {
        "en": "Deactivate",
        "de": "Deaktivieren"
    },
    "ThingMonitors:DeactivatedSuccessfully": {
        "en": "Deactivated successfully",
        "de": "Deaktivierung erfolgreich"
    },
    "ThingMonitors:DeactivatedFailed": {
        "en": "Deactivated failed",
        "de": "Deaktivierung fehlgeschlagen"
    },


}

export default ThingPageContentI18n
export type ThingPageContentI18nType = typeof ThingPageContentI18n
