import { useEffect, useMemo } from 'react'
import { Route, Switch, useLocation } from 'wouter'



import { createContext, useContext } from 'react';
import { useNavigator } from '@shared/components/page/navigation/context';

export const RouteParamsContext = createContext();

export const RouteParamsProvider = ({ params, children }) => {
  return (
    <RouteParamsContext.Provider value={params}>
      {children}
    </RouteParamsContext.Provider>
  );
};

export const useRouteParams = <T,>() => {
  const context = useContext<T | undefined>(RouteParamsContext);
  if (context === undefined) {
    throw new Error('useRouteParams must be used within a RouteParamsProvider');
  }
  return context;
};



export const CustomSwitch = ({defaultRoutes}) => {
  const [location] = useLocation();
  const { state: { navigation }, getNavItemByPath } = useNavigator();
  useEffect(() => {
    const navItem = getNavItemByPath(location)
    let title = ''
    let nextItem = navItem
    const titleFragments = []

    while (nextItem) {
      titleFragments.push(nextItem.name)
      nextItem = nextItem.parent
    }

    if (titleFragments.length === 0) {
      document.title = "Monidas"
      return
    }

    if (titleFragments.length === 1) {
      document.title = titleFragments[0]
      return
    }

    title += titleFragments[0] + " ·"
    document.title = titleFragments.slice(1,).reverse()
      .filter(Boolean)
      .reduce((title, fragment) => `${title} ${fragment}`, title);

  }, [location, navigation]);


  const renderedRoutes = useMemo(() => {
    return Object.keys(defaultRoutes).map(
      (path) => {
        const Component = defaultRoutes[path].component || defaultRoutes[path].page
        return <Route key={path} path={path}>
          {(params) => {
            params = setAliases(params)
            return (
                <RouteParamsProvider params={params}>
                  <Component params={params} />
                </RouteParamsProvider>
            )
          }
          }
        </Route>
      }
    )
  }, [defaultRoutes])

  return <Switch>{renderedRoutes}</Switch>
}

/**
 * The router paths are defined as follows: org/:orgAliasId/project/:projectAliasId/thing/:thingAliasId
 * 
 * The standard router matcher will return the following params:
 * i.e for the path org/1/project/2/thing/3
 * {
 *  orgAliasId: 1,
 *  projectAliasId: 2,
 *  thingAliasId: 3
 * }
 * 
 * But the aliases that the server expects are full aliases, i.e:
 * 
 * {
 *  orgAliasId: "org/1",
 *  projectAliasId: "org/1/project/2",
 *  thingAliasId: "org/1/project/2/thing/3"
 * }
 * 
 * @param params 
 */

const setAliases = (params) => {
  const newParams = {}
  for (const key in params) {
    const value = params[key]
    if (params[key] === undefined) {
      newParams[key] = value
      continue
    }

    if (key === 'orgAliasId') {
      newParams[key] = `org/${value}`
    }
    else if (key === 'userAliasId') {
      newParams[key] = `user/${params['userAliasId']}`
    }
    else if (key === 'projectAliasId') {
      newParams[key] = `org/${params['orgAliasId']}/project/${value}`
    }
    else if (key === 'thingAliasId') {
      newParams[key] = `org/${params['orgAliasId']}/project/${params['projectAliasId']}/thing/${value}`
    }
    else {
      newParams[key] = value
    }
  }
  return newParams as any
}

/**
 * The inverse of setAliases
 * @param params 
 */
export const setAliasesId = (params) => {
  const newParams = {}
  for (const key in params) {
    const value = params[key]
    if (params[key] === undefined) {
      newParams[key] = value
      continue
    }
    // todo: use the matcher? to get the orgAliasId
    if (key === 'orgAliasId') {
      newParams[key] = value.split('/')[1]
    } else if (key === 'userAliasId') {
      newParams[key] = value.split('/')[1]
    }
    else if (key === 'projectAliasId') {
      newParams[key] = value.split('/')[3]
    } else if (key === 'thingAliasId') {
      newParams[key] = value.split('/')[5]
    } else {
      newParams[key] = value
    }
  }
  return newParams as any
}