import { useDialog } from "@shared/components/core/useDialog"
import { CreateNewView } from "./dialogs/AddViewDialog"
import { Role } from "@server/shared"
import { api } from "@shared/index"
import { useRouteParams } from "@shared/router"
import { NoItemGuidance } from "@shared/components/page/generic/NoItemGuidance"
import { useEffect, useState } from "react"
import { AddIcon} from "@colombalink/basedui"
import { CustomizableButton } from "@shared/components/core/CustomizableButton"
import { useProjectDashboardContext } from "@app/pages/Orgs/Project/DashboardContext"
import { useProjectExplorerContext } from "@app/pages/Orgs/Project/ExplorerFragment/ExplorerContext"
import { WithI18n } from "@shared/i18n/withI18n"
import i18n from "../i18n"
import { useT } from "@app/i18n"
import { LoadingIcon } from '@shared/components/core/LoadingIcon'
import { Error } from '@shared/components/errors/Error'

const _ProjectExplorerLandingFragment = ({ children }) => {
    const t = useT()
    const { things } = useProjectDashboardContext()
    const createViewDialog = useDialog(<CreateNewView things={things}/>)
    const { projectAliasId } = useRouteParams<{ projectAliasId: string }>()
    const roles = api.user.getRoles.useLiveData()
    const { views } = useProjectExplorerContext()
    const [hasViews, setHasViews] = useState<boolean>(null)

    useEffect(() => {
        if (!roles.data?.asArray) {
            return
        }
        if(!views.keys){
            return
        }
        const hasViews = roles.data.asArray.some((role) => {
            if (Array.isArray(role) && role[0].startsWith(projectAliasId) &&
                views.keys.length > 0
            ) {
                return true
            }

        })

        setHasViews(hasViews)

    }, [roles.data?.asArray, views.data, projectAliasId])



    if (roles.error) {
        throw roles.error
    }


    if (roles.loading && hasViews === null) {
        return <LoadingIcon />
    }


    if (hasViews) {
        return children
    }

    const orgRole = roles.data[projectAliasId] as Role
    if (orgRole === "OWNER") {
        // need to first check if there are configured devices otherwise tell user to configure one
        const hasConfiguredThing = things.array.some(thing => thing.isConfigured);
        if (!hasConfiguredThing){
            return (
                <div className="flex flex-col items-center mt-4">
                    <Error
                    title={t["SProject:NoConfiguredDevices"].get()}
                    code=""
                    message={t["SProject:PleaseConfigureD"].get()}
                    />
                </div>)
        }
        return (
            <NoItemGuidance>

                {
                    t["SProject:NoViewsFound"].get()
                }
                {
                    t["SProject:CreateYourFirstView"].get()
                }
                <CustomizableButton
                    icon={<AddIcon />}
                    onClick={async () => {
                        await createViewDialog.open()
                    }}
                >
                    {
                        t["SProject:CreateView"].get()
                    }
                </CustomizableButton>
            </NoItemGuidance>
        )
    }

    return (
        <NoItemGuidance
        >
            {
                t["SProject:NoViewsFound"].get()
            }
            {
                t["SProject:PleaseContact"].get()
            }
        </NoItemGuidance>
    )

}

export const ProjectExplorerLandingFragment = (props) => (
    <WithI18n i18n={i18n}>
        <_ProjectExplorerLandingFragment {...props} />
    </WithI18n>
)