import { useRouteParams } from '@shared/router'
import { useLink } from '../../../../routes'
import {  Tab, Tabs } from '@colombalink/basedui'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'
import { api } from '@shared/index'
import { useLocation } from 'wouter'
import { useT } from '@app/i18n'
import { useEffect, useState } from 'react'
import { statusColors } from '@styles/colors'
import { Error } from '@shared/components/errors/Error'

type Project = ReturnType<typeof api.organization.getProjectStats.useLiveData>['data']['projects'][0]

export const CardView = () => {
  const t = useT()
  const org = api.organization.getProjectStats.useLiveData()
 
  const [allProjects, setAllProjects] = useState<Project[]>([])
  const [idleProjects, setIdleProjects] = useState<Project[]>([])
  const [alertProjects, setAlertProjects] = useState<Project[]>([])
  const [alarmProjects, setAlarmProjects] = useState<Project[]>([])


  useEffect(() => {
    if (!org.data) {
      return
    }
    const allProjects = []
    const idleProjects = []
    const alertProjects = []
    const alarmProjects = []

    for (const p of org.data.projects) {
      allProjects.push(p)

      if (p?.status?.alarms > 0) {
        alarmProjects.push(p)
      } else if (p?.status?.alerts > 0) {
        alertProjects.push(p)
      } else if (p?.status?.idles > 0) {
        idleProjects.push(p)
      }
    }

    setAlarmProjects(alarmProjects)
    setAlertProjects(alertProjects)
    setIdleProjects(idleProjects)
    setAllProjects(allProjects)

  }, [org.data])


  if (org.error) {
    throw org.error
  }

  if (org.loading) {
    return <div>{t["Projects:Loading"].get()}</div>
  }

  if (org.loading) {
    return <LoadingIcon />
  }


  return (
    <div className="flex flex-col mt-4">
      <div className="min-w-full align-middle">
        <Tabs
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'start',
            justifyContent: 'left',
            overflowX: 'scroll',
            overflowY: 'hidden', 
          }}
          activeTab={0}
        >
          <Tab label={t["Projects:All"].get()} style={{
            display: 'flex',
            alignItems: 'center',
          }}>
            <TabView
              projects={allProjects}
              status="all"
            />
          </Tab>
          <Tab label={t["Projects:Idle"].get()}>
            <TabView
              projects={idleProjects}
              status="idle"
            />
          </Tab>
          <Tab label={t["Projects:Alert"].get()}>
            <TabView
              projects={alertProjects}
              status="alert"
            />
          </Tab>
          <Tab label={t["Projects:Alarm"].get()}>
            <TabView
              projects={alarmProjects}
              status="alarm"
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  )
}

const TabView = ({ projects, status }: { projects: Project[], status: Project['status']['type'] | 'all' }) => {
  const t = useT()
  const { orgAliasId } = useRouteParams<{ orgAliasId: string }>()
  const setLocation = useLocation()[1]
  if (!projects || projects.length === 0) {
    return (<div className="flex flex-col items-center mt-4">
              <Error
                title={t["Projects:NoProjects"].get()}
                code=""
                message={t["Projects:NoDevice." + status ].get()}
              />
            </div>)
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


  return (
    <div className="pt-4 w-full lg:max-w-7xl">
      <ul
        role="list"
        className="p-0 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3"
      >
        {projects.map((project) => {
          return (
            <li
              onClick={() => {
                setLocation(
                  useLink(`/org/:orgAliasId/project/:projectAliasId`, {
                    orgAliasId,
                    projectAliasId: project.alias,
                  })
                )
              }}
              key={project.name}
              className="col-span-1 flex h-24 min-h-full lg:min-w-[320px]  rounded-xl shadow-sm cursor-pointer"
            >
              <div
                className={
                  classNames(statusColors[project?.status?.type || 'no-monitors'].bg, "flex w-3 flex-shrink-0 items-center justify-center rounded-l-xl text-sm font-medium text-white")
                }

              ></div>
              <div className="flex flex-1 items-center justify-between truncate rounded-r-xl border-b border-r border-t border-gray-200 bg-white">
                <div className="flex-1 truncate px-4 py-2 text-sm">
                  <div className="font-medium text-gray-900 hover:text-gray-600">
                    {project.name}
                  </div>
                  <p className="text-gray-500">
                    {project.things.length}{' '}
                    {project.things.length === 1 ? 'Device' : 'Devices'}
                  </p>
                </div>
                <div style={{ height: '103%' }} >
                  <p className="text-gray-500 inline-flex mr-2">
                    Monitors:
                  </p>
                  {[
                    {
                      type: 'idle',
                      count: project?.status?.idles
                    },
                    {
                      type: 'alert',
                      count: project?.status?.alerts
                    },
                    {
                      type: 'alarm',
                      count: project?.status?.alarms
                    }
                  ]
                    .map((status, index) => (
                      <span
                        key={index}
                        className={classNames(
                          statusColors[status.type].bg,
                          index === 2
                            ? 'inline-flex items-center gap-x-1.5 rounded-tr-xl px-2 py-1 text-xs font-medium'
                            : 'inline-flex items-center gap-x-1.5 px-2 py-1 text-xs font-medium'
                        )}
                      >
                        {status.count}
                        {/*{item.statusType}*/}
                      </span>
                    ))}
                </div>
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
