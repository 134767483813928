import React, { useEffect, useMemo, useRef, useState } from 'react'
import ReactECharts, { EChartsInstance } from 'echarts-for-react'
import * as echarts from 'echarts/core'
import PropTypes from 'prop-types'
import { useChartContext } from './context'
import { useT } from '@app/i18n'

const DataZoomButton = ({ chartRef }) => {
  const handleZoomIn = () => {
    chartRef.current.getEchartsInstance().dispatchAction({
      type: 'dataZoom',
      start: 0,
      end: 100,
    })
  }

  const handleZoomOut = () => {
    chartRef.current.getEchartsInstance().dispatchAction({
      type: 'dataZoom',
      start: 0,
      end: 50,
    })
  }

  return (
    <div>
      <button onClick={handleZoomIn}>Zoom In</button>
      <button onClick={handleZoomOut}>Zoom Out</button>
    </div>
  )
}

DataZoomButton.propTypes = {
  chartRef: PropTypes.object.isRequired,
}

const SingleSeriesLineChart = ({
  seriesData,
  showHorizontal,
  showVertical,
  colorRgba
}: {
  seriesData: {
    data: [number | Date, number, { propKey: string }][]
    color: {
      rgb: [number, number, number] | string
    }
    key: string
  }[],
  showHorizontal?: boolean,
  showVertical?: boolean,
  colorRgba?: string
}) => {
  const t = useT()
  const chartRef = useRef(null)
  const chartContext = useChartContext()
  //const units = chartContext.useUnits[0]
  const properties = chartContext.useProperties[0]
  const visibleFields = chartContext.useVisibleFields[0]
  const highlightedField = chartContext.useHighlightedField[0]


  useEffect(() => {
    const chartInstance = chartRef.current.getEchartsInstance() as echarts.ECharts
    return () => {
      // Clean up the chart instance if necessary
      chartInstance.dispose()
    }
  }, [])

  const [, setBrushRange] = useState([])
  const handleBrush = (params) => {
    if (params && params.batch && params.batch.length > 0) {
      const range = params.batch[0].areas[0].coordRange
      setBrushRange(range)
    }
  }
  useEffect(() => {
    const chartInstance = chartRef.current.getEchartsInstance()
    chartInstance.on('brushSelected', handleBrush)

    return () => {
      chartInstance.off('brushSelected', handleBrush)
    }
  }, [])

  useManageSymbolSizeOnZoom(chartRef, seriesData)

  // todo: create one on zoom callback that handles both hooks: mange symbol size and manage zoom and zoom propagation
  const zoom = chartContext.useZoom[0]
  useEffect(() => {
    if (!chartRef.current) return

    const chartInstance = chartRef.current.getEchartsInstance()
    const setZoomOnContext = () => {
      const zoom = chartInstance.getOption().dataZoom[0]
      //console.log('zoom', zoom)
      chartContext.useZoom[1]({ start: zoom.start, end: zoom.end })
    }

    chartInstance.on('dataZoom', setZoomOnContext)
    return () => {
      chartInstance.off('dataZoom', setZoomOnContext)
    }
  }, [])


  // useEffect(() => {
  //   //console.log('properties', properties.values)
  //   const chartInstance = chartRef.current.getEchartsInstance() as echarts.ECharts
  //   if (!chartInstance) return
  //   const visibleFields = properties.keys.filter((key) => properties.values[key].visible)
  //   const hiddenFields = properties.keys.filter((key) => !properties.values[key].visible)
  //
  //   //console.log('visibleFields', visibleFields)
  //   //console.log('hiddenFields', hiddenFields)
  //
  //   for (const name of hiddenFields) {
  //     //  console.log('name', name)
  //     chartInstance.dispatchAction({
  //       type: 'legendUnSelect',
  //       name,
  //     })
  //   }
  //   for (const name of visibleFields) {
  //     chartInstance.dispatchAction({
  //       type: 'legendSelect',
  //       name,
  //     })
  //   }
  //
  // }, [properties.values])

  useEffect(() => {
    const chartInstance = chartRef.current.getEchartsInstance() as echarts.ECharts
    if (!chartInstance) return
    const fields = Array.from(visibleFields.entries())
        .filter(([key, value]) => value)
        .map(([key, value]) => key);
    const hiddenFields = Array.from(visibleFields.entries())
        .filter(([key, value]) => !value)
        .map(([key, value]) => key);

    //console.log('visibleFields', visibleFields)
    //console.log('hiddenFields', hiddenFields)

    for (const name of hiddenFields) {
      //  console.log('name', name)
      chartInstance.dispatchAction({
        type: 'legendUnSelect',
        name,
      })
    }
    for (const name of fields) {
      chartInstance.dispatchAction({
        type: 'legendSelect',
        name,
      })
    }
  }, [visibleFields])

  useEffect(() => {
    const chartInstance = chartRef.current.getEchartsInstance() as echarts.ECharts
    if (!chartInstance) return
    
    // Does not work see: 
    //     https://github.com/apache/echarts/issues/8694
    //     https://github.com/apache/echarts/issues/16545
    // 
    // for (const name of downPlay) {
    //   chartInstance.dispatchAction({
    //     type: 'downplay',
    //     name,
    //   })
    // }
    // chartInstance.dispatchAction({
    //   type: 'highlight',
    //   name: highlightedField,
    // })

    var series = chartInstance.getOption().series;


    for (var i = 0; i < series.length; i++) {
      if (
        !visibleFields.get(highlightedField)
        || !highlightedField 
        || series[i].name === highlightedField) {
        const [r, g, b] = series[i].itemStyle.colorRGB
        series[i].itemStyle.color = `rgba(${r},${g},${b},1)`
      } else {
        const [r, g, b] = series[i].itemStyle.colorRGB
        series[i].itemStyle.color = `rgba(${r},${g},${b},0.0)`
      }
    }
    chartInstance.setOption({ series });
    //chartInstance.setOption([...series]);
    // var clickSeries = series[event.seriesIndex].data;
    // var entry = clickSeries[ev.dataIndex];
    // entry.clicked = !entry.clicked;
    // var any = clickSeries.filter(d => d.clicked).length !== 0;
    // series.map(d => d.itemStyle.opcacity = !any || d.clicked ? 1: 0.3);
    // echart.setOption({series});


  }, [highlightedField, visibleFields])




  const reset = () => {
    chartRef.current.getEchartsInstance().dispatchAction({
      type: 'restore',
    })
  }


  const option = useMemo(() => {
    const series = seriesData.map((dataAndMeta) => {
      const [r, g, b] = dataAndMeta?.color?.rgb || [0, 0, 0]
      
      const options: EChartsInstance = {
        name: dataAndMeta.key,
        data: dataAndMeta.data,
        type: 'line',
        smooth: true,
        animation: false,
        // symbol: 'circle',
        symbolSize: getSymbolSize(seriesData[0].data.length * (zoom.end - zoom.start) / 10),
        sampling: 'average',
        itemStyle: {
          colorRGB: [r, g, b],
          color: `rgba(${r},${g},${b},1)`,
        },
      }
      return options
    })

    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
        },
        formatter: function (params) {
          let tooltipLabel = `${t['intlDateTime'].get({
            val: new Date(new Date(params[0]?.data[0]).getTime()),
            formatParams: {
              val: { dateStyle: 'short', timeStyle: 'short' },
            },
          })}<br /><br />`
          for (const param of params) {

            const propertyKey = param.seriesName
            const property = properties.values[propertyKey]
            tooltipLabel += `${param.marker} ${property.label.en}: ${param.data[1]} ${property?.unit?.symbol || ''}<br />`
          }
          return tooltipLabel
        },
      },

      xAxis: {
        type: 'time',
        splitLine: {
          show: showVertical || false,
          lineStyle: {
            color: colorRgba || 'none', // Set your desired color for the xAxis here
          },
        },
      },
      yAxis: {
        type: 'value',
        axisTick: {
          inside: true,
        },
        splitLine: {
          show: showHorizontal || false,
          lineStyle: {
            color: colorRgba || 'none', // Set your desired color for the xAxis here
          },
        },
        axisLabel: {
          formatter: '{value}\n',
        },
        z: 10,
      },
      legend: {
        show: false,
        orient: 'vertical',
        top: 'left',
        data: seriesData.map((dataAndMeta) => dataAndMeta.key),
      },
      grid: {
        top: 110,
        left: 40,
        right: 15,
        height: 160,
      },

      dataZoom: [
        {
          type: 'inside',
          filterMode: 'none',
          start: zoom.start,
          end: zoom.end,
        },
        {
          type: 'slider',
          filterMode: 'none',
          start: zoom.start,
          end: zoom.end,
        }
      ],

      // emphasis: {
      //   focus: 'series'
      // },
      series,
    }
    return option

  }, [seriesData])

  //console.log('series', seriesData[0].data.length)




  return (
    <div className='min-w-[300px] w-full'>
      <ReactECharts
        ref={chartRef}
        option={option}
        style={{ height: '400px' }}
      />
      <div className="w-full flex justify-center">
        <button onClick={reset}>Zoom Reset</button>
      </div>
    </div>
  )
}

export default SingleSeriesLineChart
function useManageSymbolSizeOnZoom(chartRef: React.MutableRefObject<any>, seriesData: {
  data: [number | Date, number]; color: {
    rgb: [number, number, number]
  }; key: string
}[]) {

  // https://echarts.apache.org/en/api.html#events.datazoom
  const handleZoom = (params: {
    type: 'datazoom',
    // percentage of zoom start position, 0 - 100
    start: number
    // percentage of zoom finish position, 0 - 100
    end: number

    batch: {
      // percentage of zoom start position, 0 - 100
      start: number
      // percentage of zoom finish position, 0 - 100
      end: number
    }[]
  }) => {
    //console.log('params', params)
    let interval = params.end - params.start
    if (params.batch) {
      interval = params.batch[0].end - params.batch[0].start
    }
    const symbolSize = getSymbolSize(seriesData[0].data.length * interval / 10)

    const chartInstance = chartRef.current.getEchartsInstance()

    chartInstance.setOption({
      series: seriesData.map(() => ({ symbolSize })),
    })
  }

  useEffect(() => {
    const chartInstance = chartRef.current.getEchartsInstance()
    chartInstance.on('dataZoom', handleZoom)
    return () => {
      chartInstance.off('dataZoom', handleZoom)
    }
  }, [])
}

function getSymbolSize(seriesLength: number) {
  let symbolSize = 4
  try {
    if (seriesLength > 200) {
      symbolSize = 2
    }

    if (seriesLength > 400) {
      symbolSize = 0
    }
  } catch (e) {

  }
  return symbolSize
}

