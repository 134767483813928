export enum RoleEnum {
    OWNER = 4,
    SUPERVISOR = 3,
    OPERATOR = 2,
    GUEST = 1,
}
export type Role = keyof typeof RoleEnum 
// export const ROLES : Role[] = ['OWNER', 'SUPERVISOR', 'OPERATOR', 'GUEST']
export const ROLES : Role[] = ['OWNER', 'SUPERVISOR']
const ROLE_TO_LABEL : Record<Role, Record<'en' | 'de', string>> = {
    OWNER: {
        en: 'Owner',
        de: 'Owner',
    },
    SUPERVISOR: {
        en: 'Supervisor',
        de: 'Supervisor',
    },
    OPERATOR: {
        en: 'Operator',
        de: 'Operator',
    },
    GUEST: {
        en: 'Guest',
        de: 'Gast',
    },
}

export const getRoleLabel = (role: Role, locale: 'en' | 'de') => {
    try {
        const label = ROLE_TO_LABEL[role][locale]
        if(!label) throw new Error('Unknown role')
        return label
    }catch(e){
        switch(locale){
            case 'en':
                return 'Unknown role'
            case 'de':
                return 'Unbekannte Rolle'
            default:
                return 'Unknown role'
        }
    } 
}