export class ContextBuilder<T> {
  private context: T;

  constructor(initialContext: T = {} as T) {
      this.context = initialContext;
  }

  with<U>(enhancer: (context: T) => U): ContextBuilder<T & U> {
      const enhancedContext = enhancer(this.context);
      return new ContextBuilder({ ...this.context, ...enhancedContext });
  }

  build(): T {
      return this.context;
  }
}