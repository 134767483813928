import { api } from '@shared/index'
import { ContextBuilder } from '@shared/utils/react/ContextBuilder';
import { createContextUtility } from '@shared/utils/react/createContextUtility';
import { useEffect, useState } from 'react';
import { useMonitorsBaseContext } from './BaseContext';


function useProvideContext({ monitorId }: { monitorId: string }) {
  const baseCtx = useMonitorsBaseContext();
  return new ContextBuilder({ baseCtx, monitorId })
    .with(ctx => loadMonitor(ctx, monitorId))
    .with(updateMutation)
    .with((ctx) => {// set updateArgs on monitor.data change
      const monitor = ctx.monitor;
      const formData = ctx.baseCtx.formData
      const [loading, setLoading] = useState(true)
      useEffect(() => {
        //console.log("monitor.data", monitor.data?.actions)
        if (!monitor.loading) {
          formData.useActions[1](monitor.data?.actions.map((a) => a.id))
          formData.useRuleFunction[1](monitor.data?.ruleFunction.id)
          formData.useName[1](monitor.data?.name)
          formData.useStatus[1](monitor.data?.status)
          formData.useActiveState[1](monitor.data?.activeState)
          formData.useRuleConfig[1](stringifySafely(monitor.data?.ruleConfig))
          formData.useData[1](stringifySafely(monitor.data?.data))
          formData.useStatusMessageTemplate[1](monitor.data?.statusMessageTemplate)
          setLoading(false)
        }
      }, [monitor.data])
      return {
        ...ctx,
        loading,
      };
    })
    .with((c) => { // loading
      const loading = c.baseCtx.loading || c.loading
      return {
        ...c,
        loading,
      };
    })
    .build();
}

function loadMonitor<C>(context: C, monitorId: string) {
  const monitor = api.monitor.getAlarmById.useLiveData({ id: monitorId })
  return {
    ...context,
    monitor,
  };
}


function updateMutation<C>(context: C) {
  const updateMonitor = api.monitor.update.useMutation();
  return {
    ...context,
    updateMonitor,
  };
}


export const {
  Provider: EditMonitorsContext,
  useCustomContext: useEditMonitorsContext
} = createContextUtility(useProvideContext);


const stringifySafely = (obj: any) => {
  try {
    return JSON.stringify(obj)
  } catch (e) {
    try {
      return obj.toString()
    }catch(e) {
      return "{}"
    }
  }
}