import { createFlatProxy } from '@trpc/server/shared'
import { createReactProxyDecoration } from '@shared/trpc/shared/decorationProxy'
import { createRootHooks } from '@shared/trpc/shared/createHooksInternal'
import { useMemo } from 'react'

export function createTRPCReact() {
  const hooks = createRootHooks()
  const proxy = createHooksInternalProxy(hooks)
  return proxy as any
}

export function createHooksInternalProxy(hooks: ReturnType<typeof createRootHooks>) {
  return createFlatProxy((key) => {
    if (key === 'useContext') {
      //  console.log('useContext')
      return () => {
        const context = hooks.useContext()
        // create a stable reference of the utils context
        return useMemo(() => {
          return context
        }, [context])
      }
    }

    if (hooks.hasOwnProperty(key)) {
      return (hooks as any)[key]
    }
    return createReactProxyDecoration(key, hooks)
  })
}
