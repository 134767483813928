const ProjectPageContentI18n = {
    'Project:All' : {
        en: 'All',
        de: 'Alle',
    },
    'Project:Idle' : {
        en: 'Idle',
        de: 'Ruhezustand',
    },
    'Project:Alert' : {
        en: 'Alert',
        de: 'Warnung',
    }, 
    'Project:Alarm' : {
        en: 'Alarm',
        de: 'Alarm',
    },
    'Project:Status': {
        en: 'Status',
        de: 'Status',
    },
    'Project:NoMonitor': {
        en: 'Without Monitors',
        de: 'Ohne Überwachung',
    },
    'Project:Trends': {
        en: 'Trends',
        de: 'Trends',
    },
    'Project:Latest': {
        en: 'Latest',
        de: 'Neueste',
    },
    'Project:Live': {
        en: 'Live',
        de: 'Live',
    },
    'Project:Daily': {
        en: 'Daily',
        de: 'Täglich',
    },
    'Project:Monthly': {
        en: 'Monthly',
        de: 'Monatlich',
    },
    'Project:Yearly': {
        en: 'Yearly',
        de: 'Jährlich',
    },
    'Project:View': {
        en: 'View',
        de: 'Sicht',
    },
    'Project:Settings': {
        en: 'Settings',
        de: 'Einstellungen',
    },
    'Project:HorizontalGrid': {
        en: 'Horizontal Grid',
        de: 'Horizontales Gitter',
    },
    'Project:VerticalGrid': {
        en: 'Vertical Grid',
        de: 'Vertikales Gitter',
    },
    'Project:GridLineColor': {
        en: 'Grid line color',
        de: 'Farbe der Gitterlinie',
    },
    'Project:Chart': {
        en: 'Chart',
        de: 'Diagramm',
    },
    'Project:SelectDevice': {
        en: 'Device',
        de: 'Gerät',
    },
    'Project:Device_one': {
        en: "Device",
        de: 'Gerät',
    },
    'Project:Device_other': {
        en: "Devices",
        de: 'Geräte',
    },
    'Project:NoPropertiesFound': {
        en: 'No properties found in this device.',
        de: 'Keine Eigenschaften in diesem Gerät gefunden.',
    },
    'Project:NoDataFound': {
        en: 'No data found for this device.',
        de: 'Für dieses Gerät wurden keine Daten gefunden.',
    },
    'Project:NoDevicesFound': {
        en: 'No devices found in this project.',
        de: 'Keine Geräte in diesem Projekt gefunden.',
    },
    'Project:AddADevice': {
        en: 'Add a device',
        de: 'Ein Gerät hinzufügen',
    },
    'Project:NoDevicesWithSelectedFilters': {
        en: 'With the selected filter options we couldn\'t find any device in this project.',
        de: 'Mit den gewählten Filteroptionen konnten wir kein Gerät in diesem Projekt finden.',
    },
    'Project:PleaseChangeFilters': {
        en: 'Please try to change the filters.',
        de: 'Bitte versuchen Sie, die Filter zu wechseln.',
    },
    'Project:SelectColor': {
        en: 'Select a color',
        de: 'Eine Farbe wählen',
    },
    'Project:Close': {
        en: 'Close',
        de: 'Schliessen',
    },
    'Project:Confirm': {
        en: 'Confirm',
        de: 'Bestätigen',
    },
    'Project:CreateNewView': {
        en: 'Create',
        de: 'Erstellen',
    },
    'Project:Proceed': {
        en: 'Do you want to proceed with this?',
        de: 'Möchten Sie damit fortfahren?',
    },
    'Project:RemovedView': {
        en: 'Removed View',
        de: 'Ansicht entfernt',
    },
    'Project:RemovedSuccess': {
        en: 'Removed successfully',
        de: 'Erfolgreich entfernt',
    },
    'Project:Yes': {
        en: 'Yes',
        de: 'Ja',
    },
    'Project:No': {
        en: 'No',
        de: 'Nein',
    },
    'Project:Loading': {
        en: 'Loading...',
        de: 'Wird geladen...',
    },
    'Project:NoContext': {
        en : 'usePageContext must be used within a PageContextProvider',
        de : 'usePageContext muss innerhalb eines PageContextProvider verwendet werden',
    },
}

export default ProjectPageContentI18n
export type ProjectPageContentI18nType = typeof ProjectPageContentI18n
