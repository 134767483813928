import { useContext, useState, useEffect } from 'react'
import { BasedClient, AuthState } from '@based/client'
import { useClientsContext } from './context'

export const useAuthState = (clientId?: string ): AuthState => {
  const client: BasedClient = useClientsContext().clients.getClient(clientId || 'identity') 
  const [state, setState] = useState<AuthState>(client?.authState || {})

  useEffect(() => {
    if (client) {
      setState(client.authState)
      const listener = () => {
        setState({...client.authState})
      }
      client.on('authstate-change', listener)
      return () => client.off('authstate-change', listener)
    }
  }, [client])

  return state
}
