import React, { useEffect } from 'react'
import { Dialog, Input, Spacer, Text, Toast, useToast } from '@colombalink/basedui'
import { ZodInputValidator } from '@shared/zod/InputValidator'
import { api } from '@shared/index'
import { createProjectParams } from '@server/app'
import { useRouteParams } from '../../../router'
import { PageParams } from '../../../../app/pages/Orgs/ProjectsEditor/Page'
import processString from '@app/pages/tools/convertName'
import { WithI18n } from '@shared/i18n/withI18n'
import i18n from '../i18n'
import { useT } from '@app/i18n'
import { log } from '@shared/logger'

const CreateNewProjectDialog = () => {
  const t = useT()
  const { orgAliasId } = useRouteParams<PageParams>()
  const createProject = api.project.createProject.useMutation()
  const [createProjectArgs, setCreateProjectArgs] = React.useState({ name: '', alias: '', orgAliasId })
  
  useEffect(() => {
    log.debug(createProjectArgs, "createProjectArgs")
    log.debug(ZodInputValidator(createProjectParams, createProjectArgs, "name"), "createOrgArgs ZodInputValidator")
  }, [createProjectArgs.name])
  
  const toast = useToast()
  return (
    <Dialog label={t["SProject:CreateProject"].get()}>
      <div>
        <Input
          type="text"
          description={t["SProject:Name"].get()}
          value={createProjectArgs.name}
          pattern="true"
          error={(value) => ZodInputValidator(createProjectParams, createProjectArgs, "name")}
          onChange={(name: string) => {
            setCreateProjectArgs({ ...createProjectArgs, name, alias: processString(name) })
          }
          }
        />
        </div>
      <div>
        <Text style={{ marginTop: 24 }}>{t["SProject:ProjectUrlPath"].get()} {createProjectArgs.alias}</Text>
      </div>
      <div>
          <Dialog.Buttons>
            <Dialog.Cancel displayShortcut={false}>{t["SProject:Close"].get()} </Dialog.Cancel>
            <Dialog.Confirm displayShortcut={false} onConfirm={async () => {

              try {
                await createProject.mutateAsync(createProjectArgs)
                toast.add(
                  <Toast
                    label={t["SProject:AddedProject"].get()}
                    type="success"
                    description={
                      <Text>
                        {t["SProject:Project"].get()} <b>{createProjectArgs.name}</b> {t["SProject:CreatedSuccessfully"].get()}
                      </Text>
                    }
                  />
                )
              } catch (e) {
                toast.add(
                  <Toast
                    label={t["SProject:FailedToCreateProject"].get()}
                    type="error"
                    description={
                      <Text style={{ whiteSpace: 'normal' }}>
                        {e.message}
                      </Text>
                    }
                  />
                )
                throw e
              }
            }}>{t["SProject:Save"].get()} </Dialog.Confirm>
          </Dialog.Buttons>
      </div>
    </Dialog>
  )
}




export default ()=> (
  <WithI18n i18n={i18n}>
    <CreateNewProjectDialog />
  </WithI18n>
)
