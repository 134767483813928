export function setValueOfGenericProperty(instance, property: string, value: string) {
  try {
    if (!instance || property === '') return undefined;

    const path = property.split('.');
    let current = instance;
  
    for (let i = 0; i < path.length - 1; i++) {
      // Check if the property exists and is an object, otherwise create an object
      if (!current[path[i]] || typeof current[path[i]] !== 'object') {
        current[path[i]] = {}; 
      }
      current = current[path[i]]; // Move to the next level in the path
    }
  
    current[path[path.length - 1]] = value;
  } catch (e) {
    /* WHY?
       We need to catch this error because it happens that some properties are not defined in the payload.

       For us this first happened when adding and removing multiple gateways.
        - rx.0.gateway.name
        - rx.1.gateway.name

      The array positions are hardcoded in the property path, so if not present we will run into an error.
     */
    return undefined
  }
}
