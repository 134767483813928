import { DefaultProcedure } from '@server/app/trpc/router'
import { z } from 'zod'

export const updateIntegrationParams = z.object({
  integrationId: z.string(),
  name: z.string().refine((name) => name.length > 0, { message: 'Name must be at least 1 character long' }),
  url: z.string().url({ message: 'Please enter a valid URL' }),
  token: z.string()
})

export const updateIntegrationProcedure = (
  procedure: DefaultProcedure
) =>
  procedure
    .input(updateIntegrationParams)
    .mutation(async ({ ctx, input }) => {
      ctx.logger.debug('procedure::updateIntegration', input)
      const { integrationId, name, token, url } = input;
      console.log(token)
      await ctx.based.db.default.set({
        $id: integrationId,
        name,
        url,
        token: await ctx.based.encode(token),
      })
    })
