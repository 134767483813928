import { AddIcon, Spacer, useToast } from '@colombalink/basedui'
import DefaultNavigation from '@shared/components/page/navigation/DefaultNavigation'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'
import React, { useEffect } from 'react'
import { DynamicButtonRow } from '@shared/components/core/DynamicButtonRow'
import { RowMenu } from './RowMenu'
import { Page } from '@shared/components/page/Page'
import { Row } from '@influxdata/influxdb-client'
import { Title } from '@shared/components/core/Title'
import { api } from '@shared/index'
import { useLink } from '../../../routes'
import { useLocation } from 'wouter'
import { useTable } from '@shared/components/core/table/Table'
import AddMemberToProjectDialog from './AddDialog'
import { Role, getRoleLabel } from '@server/shared'
import { useRouteParams } from '@shared/router'
import { WithI18n } from '@shared/i18n/withI18n'
import i18n from './i18n'
import { useT } from '@app/i18n'

type PageProps = { params: { orgAliasId: string; projectAliasId: string } }
export type PageParams = PageProps['params']

const ProjectMembersPage = (props: PageProps) => {
  return (
    <Page>
      <DefaultNavigation />
      <Main />
    </Page>
  )
}

const Main = () => {
  return (
    <WithI18n i18n={i18n}>
      <ManageProjectMembers />
    </WithI18n>
  )
}

Main.displayName = 'Main'

export default ProjectMembersPage

const ManageProjectMembers = () => {
  const t = useT()
  const { orgAliasId } = useRouteParams<PageParams>()
  const { projectAliasId } = useRouteParams<PageParams>()

  return (
    <div className="flex flex-col">
      <div className="m-4">
        <Title title={t['ProjectsMembers:OrganizationOwners'].get()} />
      </div>
      <OrgOwnersTable orgAliasId={orgAliasId} />
      <Spacer></Spacer>
      <div className="m-4">
        <Title title={t['ProjectsMembers:AllProjectMembers'].get()} />
      </div>
      <DynamicButtonRow
        buttons={[
          {
            icon: <AddIcon />,
            dialogComponent: (
              <AddMemberToProjectDialog
                orgAliasId={orgAliasId}
                projectAliasId={projectAliasId}
              />
            ),
            label: t['ProjectsMembers:Add'].get(),
            style: { cursor: 'pointer' },
          },
        ]}
      />
      <MemberTable orgAliasId={orgAliasId} projectAliasId={projectAliasId} />
    </div>
  )
}

ManageProjectMembers.displayName = 'ManageProjectMembersMain'

function MemberTable({
  orgAliasId,
  projectAliasId,
}: {
  orgAliasId: string
  projectAliasId: string
}) {
  const t = useT()
  const project = api.project.getById.withMembers.useLiveData({
    orgAliasId,
    projectAliasId,
  })
  const [, setLocation] = useLocation()
  const removeOrganization = api.organization.deleteOrganization.useMutation()
  const toast = useToast()
  const [tableData, setTableData] = React.useState([])

  const [table, TableComponent] = useTable({
    header: [
      { label: t['ProjectsMembers:Name'].get(), key: 'user.name' },
      { label: t['ProjectsMembers:Role'].get(), key: 'roleLabel' },
      { label: '', key: 'action' },
    ],
    data: tableData,
  })

  useEffect(() => {
    if (project.data?.members) {
      //console.log(project.data?.members)
      setTableData(
        project.data.members.map((member) => {
          return {
            ...member,
            roleLabel: getRoleLabel(member.role as Role, 'en'),
            action: <RowMenu member={member} projectAliasId={projectAliasId} />,
            customAttribute: {},
          }
        })
      )
    }
  }, [project.data?.members])

  if(project.error) {
    throw new Error("You are not part of this Project!")
  }

  if (project.loading) {
    return (
      <>
        {t['ProjectsMembers:Loading'].get()} <LoadingIcon />
      </>
    )
  }
  return <>{TableComponent}</>
}

function OrgOwnersTable({ orgAliasId }: { orgAliasId: string }) {
  const t = useT()
  const org = api.organization.getOrganizationById.withMembers.useLiveData({
    orgAliasId,
  })
  const { projectAliasId } = useRouteParams<PageParams>()

  const [, setLocation] = useLocation()
  const removeOrganization = api.organization.deleteOrganization.useMutation()
  const toast = useToast()
  const [tableData, setTableData] = React.useState([])

  const [table, TableComponent] = useTable({
    header: [{ label: t['ProjectsMembers:Name'].get(), key: 'user.name' }],
    data: tableData,
  })

  useEffect(() => {
    if (org.data?.members) {
      setTableData(
        org.data.members
          .filter((member) => member.role === 'OWNER')
          .map((member) => {
            return {
              ...member,
              roleLabel: getRoleLabel(member.role as Role, 'en'),
              action: (
                <RowMenu member={member} projectAliasId={projectAliasId} />
              ),
              customAttribute: {},
            }
          })
      )
    }
  }, [org.data?.members])

  if(org.error) {
    throw new Error("You are not part of this Organization!")
  }

  if (org.loading) {
    return (
      <>
        {t['ProjectsMembers:Loading'].get()} <LoadingIcon />
      </>
    )
  }
  return <>{TableComponent}</>
}
