import { BasedClient } from '@based/client'
import { createTRPCReact } from '@shared/trpc/createTRPCReact'
import { createBasedTrpcClient, basedLink, OperationScope } from './basedLink'
import { createTRPCProxyClient, loggerLink } from '@trpc/client'
import { CreateTRPCReact } from '@server/shared/utils/trpc/types'
import { AppRouterType } from '@server/app/trpc/router'
const env = import.meta.env

export type AppRouter = CreateTRPCReact<AppRouterType, {}> 
export const api = createTRPCReact() as AppRouter

type RequestContext = {
  scope: OperationScope
}

export const createTrpcReactClient =
  (based: BasedClient, trpcEntrypoint, context: RequestContext) => () => {
    

    const links = []
    if (env.VITE_LOG_LEVEL === 'debug') {
      links.push(loggerLink())
    }

    links.push(basedLink({
      client: createBasedTrpcClient({ based, functionName: trpcEntrypoint }),
    }))

    const client = api.createClient({ links })
    client.runtime.context = context
    return client
  }



export const createTrpcBasedClient =
  (based: BasedClient, trpcEntrypoint, context: RequestContext) => () => {

    const links = []
    if (env.VITE_LOG_LEVEL === 'debug') {
      links.push(loggerLink())
    }

    links.push(basedLink({
      client: createBasedTrpcClient({ based, functionName: trpcEntrypoint }),
    }))

    const client = createTRPCProxyClient<AppRouterType>({ links }) 
    client.runtime.context = context || {}

    return client 
  }