const ProjectAuditLogPageContentI18n = {
    'ProjectAuditLog:Time': {
        en: 'Time',
        de: 'Zeit',
    },
    'ProjectAuditLog:LogMessage': {
        en: 'Log Message',
        de: 'Logmeldung',
    },
    'ProjectAuditLog:ActorName': {
        en: 'Actor Name',
        de: 'Akteur Name',
    },
    'ProjectAuditLog:ActorEmail': {
        en: 'Actor Email',
        de: 'Akteur E-Mail',
    },
    'ProjectAuditLog:NoRecordsFound': {
        en: 'No records found for the selected date.',
        de: 'Es wurden keine Datensätze für das ausgewählte Datum gefunden.',
    },
    'ProjectAuditLog:DefaultRetentionPeriod': {
        en: 'The default retention period for audit logs is 30 day.',
        de: 'Die Standardaufbewahrungsfrist für Audit-Protokolle beträgt 30 Tage.',
    },
    'ProjectAuditLog:ContactSupport': {
        en: 'In case you need to access older logs, please contact support.',
        de: 'Falls Sie Zugang zu älteren Protokollen benötigen, wenden Sie sich bitte an den Support.',
    },
    'ProjectAuditLog:ErrorParsingJson': {
        en: 'Error parsing JSON: Invalid JSON format.',
        de: 'Fehler beim Parsen von JSON: Ungültiges JSON-Format.',
    },
    'ProjectAuditLog:UnexpectedError': {
        en: 'Unexpected error.',
        de: 'Unerwarteter Fehler.',
    },
}

export default ProjectAuditLogPageContentI18n
export type ProjectAuditLogPageContentI18nType = typeof ProjectAuditLogPageContentI18n
