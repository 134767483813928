import { Dialog, Input, Text, Toast, useToast, Select, Label } from '@colombalink/basedui'
import React, { useEffect } from 'react'
import { api } from '@shared/index'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'

const ConfirmationDialog = ({label, message, confirm, onConfirm}) => {
  return (
    <Dialog label={label}>
      <div>
        <Text>{message}</Text>

        <Dialog.Buttons>
          <Dialog.Cancel displayShortcut={false}>Close</Dialog.Cancel>
          <Dialog.Confirm displayShortcut={false} onConfirm={onConfirm}>{confirm}</Dialog.Confirm>
        </Dialog.Buttons>
      </div>
    </Dialog>
  )
}



export default ConfirmationDialog;