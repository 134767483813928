import React, { createContext, useContext, FunctionComponent } from 'react';

export function createContextUtility<ContextType, Props>(
    useProvideContext: (props: Props) => ContextType,
    onMissingContext?: () => void 
    ) {
    const Context = createContext<ContextType | undefined>(undefined);

    const Provider: FunctionComponent<Props> = ({ children, ...props }) => {
        const value = useProvideContext(props as Props);
        return <Context.Provider value={value}>{children}</Context.Provider>;
    };

    const useCustomContext = (): ContextType => {
        const context = useContext(Context);
        if (!context) {
            if (onMissingContext) {
                onMissingContext();
            }
            throw new Error('useCustomContext must be used within a Provider');
        }
        return context;
    };

    return { Provider, useCustomContext };
}
