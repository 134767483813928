import { useRouteParams } from "@shared/router"
import { PageParams } from "../../Page.owner"
import { ThingContext } from "./context"
import { LatestThingContainer } from "@shared/components/thing/LatestThingValuesContainer"

export const LastEventFragment = () => {
    const { thingAliasId } = useRouteParams<PageParams>()
    return (
        <div className="max-w-3xl m-4">
            <ThingContext thingAliasId={thingAliasId}>
                <LatestThingContainer thingId={""} />
            </ThingContext>
        </div>
    )

}
