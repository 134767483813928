import FadeInBox from '../shared/FadeInBox'
import MainContainer from '../shared/MainContainer'

import { Button, Input, Spacer, Text, Toast, useToast } from '@colombalink/basedui';
import { useState, useRef, useEffect } from 'react';
import { email as isEmail } from '@saulx/validators'
import { useLocation } from 'wouter';
import { WithI18n } from '@shared/i18n/withI18n';
import i18n from './i18n';
import { useT } from '@app/i18n';
import { useIdentityClient } from '@shared/client/context';


export default () => (
    <WithI18n i18n={i18n}>
        <ForgotPage />
    </WithI18n>
)


const ForgotPage = () => {
    const [sendEmail, setSendEmail] = useState(false)

    return (
        <MainContainer>
            <FadeInBox>
                {!sendEmail && <EmailForm setSendEmail={setSendEmail} />}
                { sendEmail && <ShowSendConfirmation />}
            </FadeInBox>
        </MainContainer>
    )
}

const EmailForm = ({
    setSendEmail
}) => {
    const client = useIdentityClient()
    const t = useT()
    const toast = useToast()

    const [, setLocation] = useLocation()
    const [email, setEmail] = useState<string>('');
    return (
        <>
            <Input
                large
                label={t['Forgot:Email'].get()}
                value={email}
                type="email"
                placeholder={t['Forgot:EmailPlaceholder'].get()}
                onChange={(str) => setEmail(str)}
            />
            <Spacer />

            <Button
                large
                fill
                style={{
                    marginBottom: 24,
                    height: 48,
                }}
                textAlign="center"
                keyboardShortcut="Enter"
                disabled={!isEmail(email)}
                onClick={async () => {
                    try {
                        await client.call("login:password:requestResetToken", { email });
                        setSendEmail(true)
                    } catch (e) {
                        toast.add(
                            <Toast
                                label={t['Forgot:FailedToResetPassword'].get()}
                                type="error"
                                description={
                                    <Text style={{ whiteSpace: 'normal' }}>
                                        {t['Forgot:FailedToResetPasswordInvalidEmail'].get()}
                                    </Text>
                                }
                            />)
                        throw e
                    }
                }}
            >
                {t['Forgot:ResetPassword'].get()}
            </Button>

            <div className='text-justify end' style={{ marginBottom: 24, textAlign: 'center' }}>
                {t['Forgot:AlreadyHaveAccount'].get()}
                <br></br>
                <a className="text-sailorblue-main"
                    onClick={() => {
                        setLocation("login")
                    }}
                >{t['Forgot:SignIn'].get()}</a>
            </div>

        </>
    )
}

const ShowSendConfirmation = () => {
    const t = useT()
    return <>
        <div className='text-justify end' style={{ marginBottom: 24, textAlign: 'center' }}>
            {t['Forgot:CheckEmail'].get()}
            <br></br>
            {t['Forgot:ClosePage'].get()}
        </div>
    </>
}