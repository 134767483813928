
const OrganizationsPageContentI18n = {
    'UserOrganizations:Organizations': {
        'de': 'Organisationen',
        'en': 'Organizations',
    },
    'UserOrganizations:Organization': { //_one does not work
        'de': 'Organisation',
        'en': 'Organization',
    },
    'UserOrganizations:Path': {
        'de': 'Pfad',
        'en': 'Path',
    },

    'UserOrganizations:CreateOrganization': {
        'de': 'Organisation Erstellen',
        'en': 'Create Organization',
    },
    'UserOrganizations:AddedOrganization': {
        'de': 'Organisation hinzugefügt',
        'en': 'Added organization',
    },

    'UserOrganizations:FailedToCreateOrganization': {
        'de': 'Organisation konnte nicht erstellt werden',
        'en': 'Failed to create organization',
    },

    'UserOrganizations:Cancel': {
        'de': 'Abbrechen',
        'en': 'Cancel',
    },
    'UserOrganizations:Close': {
        'de': 'Schliessen',
        'en': 'Close',
    },
    'UserOrganizations:Save': {
        'de': 'Speichern',
        'en': 'Save?',
    },
    'UserOrganizations:Delete': {
        'de': 'Löschen',
        'en': 'Delete',
    },
    'UserOrganizations:View': {
        'de': 'Ansehen',
        'en': 'View',
    },
    'UserOrganizations:Edit': {
        'de': 'Bearbeiten',
        'en': 'Edit',
    },
    "UserOrganizations:DeleteOrganization" : {
        en: "Delete Organization",
        de: "Organisation löschen",
    },
    'UserOrganizations:DeletedOrganization': {
        'de': 'Organisation gelöscht',
        'en': 'Organization deleted',
    },
    'UserOrganizations:DeletedOrganizationText': {
        'de': 'Die Organisation wurde erfolgreich gelöscht.',
        'en': 'The organization was deleted successfully.',
    },
    "UserOrganizations:DeleteOrganizationConfirmation" : {
        en: "Are you sure you want to delete the organization: ",
        de: "Sind Sie sicher, dass Sie die Organisation löschen möchten: ",
    },
    'UserOrganizations:UpdatedOrganization': {
        'de': 'Organisation aktualisiert',
        'en': 'Organization updated',
    },
    'UserOrganizations:UpdatedOrganizationText': {
        'de': 'Die Organisation wurde erfolgreich aktualisiert.',
        'en': 'The organization was updated successfully.',
    },
    'UserOrganizations:FailedToUpdateOrganization': {
        'de': 'Organisation konnte nicht aktualisiert werden',
        'en': 'Failed to update organization',
    },

    'UserOrganizations:Loading': {
        'de': 'Laden',
        'en': 'Loading',
    },
    'UserOrganizations:Name': {
        'de': 'Name',
        'en': 'Name',
    },

    'UserOrganizations:CreatedSuccessfully': {
        'de': 'erfolgreich erstellt',
        'en': 'created successfully',
    },

    'UserOrganizations:EditingOrgUrl': {
        'en': 'Editing the organization name will change the URL',
        'de': 'Das Ändern des Organisationsnamens wird die URL ändern',
    },
    'UserOrganizations:EditOrganization': {
        'de': 'Organisation bearbeiten',
        'en': 'Edit Organization',
    
    },
    'UserOrganizations:EditRetypePls': {
        'en': 'Please Retype Previous Organization:',
        'de': 'Bitte geben Sie die vorherige Organisation erneut ein:',
    },
    'UserOrganizations:EditRetype': {
        'en': 'Retype Organization:',
        'de': 'Organisation erneut eingeben:',
    },

}


export default OrganizationsPageContentI18n
export type OrganizationsPageContentI18nType = typeof OrganizationsPageContentI18n
