import { FC } from 'react'
import { OptionMenu } from '@shared/components/core/EditEntityMenu'
import { api } from '@shared/index'
import {
  ContextItem,
  EditIcon,
  Toast,
  Text,
  ToggleIcon,
  useDialog,
  useToast,
} from '@colombalink/basedui'
import { useLocation } from 'wouter'
import { useT } from '@app/i18n'
import { EditMonitorRegularUserDialog } from '@app/pages/Orgs/Thing/Fragments/Monitors/Dialogs/AddDialogRegular'

type RowMenuProps = {
  monitor: ReturnType<
    typeof api.monitor.getAllAlarmsFromThing.useLiveData
  >['data'][0]
}

export const RowMenu = (props: RowMenuProps) => {
  const CategoryMenu: FC<{}> = ({ }) => {
    const t = useT()
    const { open } = useDialog()
    const [, setLocation] = useLocation()
    const toast = useToast()

    const deactivateMonitor = api.monitor.deactivateAlarm.useMutation()
    const activateMonitor = api.monitor.activate.useMutation()
    return (
      <>
        <ContextItem
          onClick={() => {
            open(<EditMonitorRegularUserDialog monitorId={props.monitor['id']} />)
          }}
          icon={EditIcon}
        >
          {t['ThingMonitors:Edit'].get()}
        </ContextItem>
        <ContextItem

          onClick={async () => {
            if (props?.monitor?.activeState === 'active') {
              await deactivateMonitor.mutateAsync({ monitorId: props.monitor.id })
              toast.add(
                <Toast
                  label={t['ThingMonitors:Deactivate'].get()}
                  type="success"
                  description={
                    <Text>
                      {t['ThingMonitors:DeactivatedSuccessfully'].get()}
                    </Text>
                  }
                />
              )
            } else {
              await activateMonitor.mutateAsync({ monitorId: props.monitor.id })
              toast.add(
                <Toast
                  label={t['ThingMonitors:Activate'].get()}
                  type="success"
                  description={
                    <Text>
                      {t['ThingMonitors:ActivedSuccsessfully'].get()}
                    </Text>
                  }
                />
              )
            }
          }
          }
          icon={ToggleIcon}
        >
          {props?.monitor?.activeState === 'active' ? t['ThingMonitors:Deactivate'].get() : t['ThingMonitors:Activate'].get()}
        </ContextItem>
      </>
    )
  }

  return <OptionMenu menu={CategoryMenu} style={{ width: '150px' }} />
}
