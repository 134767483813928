import { Dialog, Input, MultiSelect, Select, Spacer, Text, Toast, useToast } from '@colombalink/basedui'
import { ZodInputValidator } from '@shared/zod/InputValidator'
import { api } from '@shared/index'
import { createNotificationTemplateParams } from '@server/app'
import { useState } from 'react'
import { WithI18n } from '@shared/i18n/withI18n'
import i18n from '../../i18n'
import { useT } from '@app/i18n'


const AddNotificationDialog = () => {
  const t = useT()
  const createTemplate = api.organization.monitor.notification.template.create.useMutation()
  const toast = useToast()

  const [createTemplateArgs, setCreateTemplateArgs] = useState(
    {
      name: '', title: '', message: '',
    }
  )

  return (
    <Dialog label={t["MonitorTemplate:CreateTemplate"].get()}>
      <div>
        <Text >
         {t["MonitorTemplate:CreateNotificationTemplate"].get()} <br />
        </Text>
        <Spacer />
        <Input
          description={t["MonitorTemplate:Name"].get()}
          value={createTemplateArgs.name}
          pattern="true"
          error={(value) => ZodInputValidator(createNotificationTemplateParams, createTemplateArgs, "name")}
          onChange={(name: string) => {
            setCreateTemplateArgs({ ...createTemplateArgs, name })
          }
          }
        />
        <Spacer />
        <Input
          description={t["MonitorTemplate:MessageTitle"].get()}
          value={createTemplateArgs.title}
          pattern="true"
          error={(value) => ZodInputValidator(createNotificationTemplateParams, createTemplateArgs, "title")}
          onChange={(title: string) => {
            setCreateTemplateArgs({ ...createTemplateArgs, title })
          }
          }
        />
        <Spacer />
        <Input
          description={t["MonitorTemplate:MessageBody"].get()}
          value={createTemplateArgs.message}
          pattern="true"
          error={(value) => ZodInputValidator(createNotificationTemplateParams, createTemplateArgs, "message")}
          onChange={(message: string) => {
            setCreateTemplateArgs({ ...createTemplateArgs, message })
          }
          }
        />
        <Dialog.Buttons>
          <Dialog.Cancel displayShortcut={false}>{t["MonitorTemplate:Close"].get()} </Dialog.Cancel>
          <Dialog.Confirm displayShortcut={false} onConfirm={async () => {

            try {
              await createTemplate.mutateAsync(createTemplateArgs)
              toast.add(
                <Toast
                  label={t["MonitorTemplate:AddedTemplate"].get()}
                  type="success"
                  description={
                    <Text>
                      {t["MonitorTemplate:Template"].get()} <b>{createTemplateArgs.name}</b> {t["MonitorTemplate:CreatedSuccessfully"].get()}
                    </Text>
                  }
                />
              )
            } catch (e) {
              toast.add(
                <Toast
                  label={t["MonitorTemplate:FailedToCreateTemplate"].get()}
                  type="error"
                  description={
                    <Text style={{ whiteSpace: 'normal' }}>
                      {e.message}
                    </Text>
                  }
                />
              )
              throw e
            }
          }}>{t["MonitorTemplate:Save"].get()}</Dialog.Confirm>
        </Dialog.Buttons>
      </div>
    </Dialog>
  )
}




export default ()=> (
  <WithI18n i18n={i18n}>
    <AddNotificationDialog />
  </WithI18n>
)

