import React, { useEffect } from 'react'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'
import { RowMenu } from './RowMenu'
import { api } from '@shared/index'
import { useRouteParams } from '@shared/router'
import { useTable } from '@shared/components/core/table/Table'
import { useT } from '@app/i18n'

type PageProps = {
  params: { orgAliasId: string; thingAliasId: string; projectAliasId: string }
}
export type PageParams = PageProps['params']

export const UnitsFragment = () => {
  const { thingAliasId, orgAliasId, projectAliasId } = useRouteParams<PageParams>()

  return (
    <div className="m-4">
      <div className="flex flex-col">
        <UnitsTable thingAliasId={thingAliasId} />
      </div>
    </div>
  )
}

const UnitsTable = ({ thingAliasId }) => {
  const t = useT()
  const propertiesWithUnits =
    api.thing.getPayloadProperties.useLiveData({
      thingAliasId,
    })
  const [tableData, setTableData] = React.useState([])

  const [table, TableComponent] = useTable({
    header: [
      { label: t["Thing:Label"].get(), key: 'label.en' },
      { label: t["Thing:Symbol"].get(), key: 'symbol' },
      { label: t["Thing:PrimitiveDataType"].get(), key: 'primitiveDataType'},
      { label: t["Thing:Actions"].get(), key: 'action' },
    ],
    data: tableData,
  })

  useEffect(() => {
    if (propertiesWithUnits.data) {
      setTableData(
        Object.values(propertiesWithUnits.data.properties)
          .filter((property, index, self) => {
            // Use indexOf to check for the first occurrence of the label in the array
            return index === self.findIndex((p) => p.unit.id === property.unit.id);
          })
          .sort((a, b) => a.label.en.localeCompare(b.label.en)) //filtering it by english need toggle for languages
          .map((property) => {
            return {
              ...property.unit,
              action: <RowMenu unit={property.unit} />,
              customAttribute: {},
            }
          })
      )
    }
  }, [propertiesWithUnits.data])

  if (propertiesWithUnits.loading) {
    return (
      <>
        {t["Thing:Loading"].get()} <LoadingIcon />
      </>
    )
  }
  return <>{TableComponent}</>
}
