import { api } from "@shared/index";
import { Children, useEffect, useState } from "react";
import { LoadingPage } from "./LoadingPage";
import { useRouteParams } from "@shared/router";
import { log } from "@shared/logger";
import { WithI18n } from "@shared/i18n/withI18n";
import { useT } from "@app/i18n";
import i18n from "./i18n";

const _PageRoleSwitcher = ({ children }) => {
  const t = useT()
  let AdminPage = null;
  let OwnerPage = null;
  let SupervisorPage = null;
  let OperatorPage = null;
  let DefaultPage = null;

  const roles = api.user.getRoles.useLiveData()
  Children.forEach(children, (child) => {
    if (child?.type?.displayName.endsWith('AdminPage')) {
      AdminPage = child
    } else if (child?.type?.displayName.endsWith('OwnerPage')) {
      OwnerPage = child
    } else if (child?.type?.displayName.endsWith('SupervisorPage')) {
      SupervisorPage = child
    } else if (child?.type?.displayName.endsWith('OperatorPage')) {
      OperatorPage = child
    } else if (child.type?.displayName.endsWith('DefaultPage')) {
      DefaultPage = child
    }
  })

  const [page, setPage] = useState(LoadingPage)

  const { userAliasId, orgAliasId, projectAliasId } = useRouteParams<{ userAliasId, orgAliasId, projectAliasId }>()
  
  useEffect(() => {
    let page = null

    if (roles.loading) {
      page = LoadingPage
      setPage(page)
      return
    }

    if (!userAliasId && !orgAliasId && !projectAliasId) {
      log.warn(t['SharedPage:NoAlias'].get())
      page = DefaultPage
      setPage(page)
    }

    if (roles.data) {
      const alias = projectAliasId || orgAliasId || userAliasId
      const role = roles.data[alias]
      if (!role) {
        log.warn(t['SharedPage:NoRoleFoundForAlias'].get() + alias)
        page = DefaultPage
        setPage(page)
      }

      if (role === 'ADMIN') {
        page = AdminPage || DefaultPage
      } else if (role === 'OWNER') {
        page = OwnerPage || DefaultPage
      } else if (role === 'SUPERVISOR') {
        page = SupervisorPage || DefaultPage
      } else if (role === 'OPERATOR') {
        page = OperatorPage || DefaultPage
      } else {
        log.debug(t['SharedPage:Default'].get())
        page = DefaultPage
      }
      if (!page && DefaultPage) {
        page = DefaultPage
      }
    
      setPage(page)
    }
  }, [roles.data])

  return page
}

export const PageRoleSwitcher = (props) => (
  <WithI18n i18n={i18n}>
    <_PageRoleSwitcher {...props} />
  </WithI18n>
)
