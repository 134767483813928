
const ForgotPageContentI18n = {
    'Forgot:ResetPassword': {
        en: 'Reset password',
        de: 'Passwort zurücksetzen',
    },
    'Forgot:EmailPlaceholder': {
        en: 'Enter your email address',
        de: 'Geben Sie Ihre Email-Adresse ein',
    },
    'Forgot:FailedToResetPassword' : {
        en: 'Reset password failed',
        de: 'Passwort zurücksetzen fehlgeschlagen '
    },
    'Forgot:FailedToResetPasswordInvalidEmail' : {
        en: 'Sorry, we could not find an account with this e-mail address. Please check that you have entered the correct e-mail address.',
        de: 'Entschuldigung, wir konnten kein Konto mit dieser E-Mail-Adresse finden. Bitte überprüfen Sie, ob Sie die richtige E-Mail-Adresse eingegeben haben.'
    },
    'Forgot:SignIn': {
        en: 'Sign in',
        de: 'Anmelden'
    },
    'Forgot:CheckEmail': {
        en: 'Check your Email!',
        de: 'Prüfen Sie Ihre E-Mail!'
    },
    'Forgot:ClosePage': {
        en: 'You can close this page.',
        de: 'Sie können diese Seite schließen.'
    },
    'Forgot:AlreadyHaveAccount': {
        en: 'Already have an account?',
        de: 'Sie haben bereits ein Konto?'
    },
}


export default ForgotPageContentI18n
export type ForgotContentI18nType = typeof ForgotPageContentI18n