import ErrorBoundary from '../errors/ErrorBoundary'
import React, { ReactElement, createContext, useContext, useEffect, useMemo, useState } from 'react'
import { Error } from '@shared/components/errors/Error'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'
import { useConnected } from '@shared/client/useConnected'

const ServiceUnavailableErrorPage = () => {
  return (
    <div className="flex flex-col items-center mt-4">
      <Error
        title="Service Unavailable"
        code="503"
        message={
          'Please verify your internet connection. If the issue persists, our server may be temporarily unavailable. Kindly retry after some time. Thank you for your patience.'
        }
      />
    </div>
  )
}


function useProvideContext(components: ReactElement[]) {
  const [main, setMain] = useState(<LoadingIcon></LoadingIcon>)
  const [navigationOverlay, setNavigationOverlay] = useState<ReactElement>(() => <></>)
  const [isNavigationOverlayOpen, setIsNavigationOverlayOpen] = useState(false)
  const [isUnloaded, setIsUnloaded] = useState(false);

  const childMap = useMemo(() => {
    const childrenMap = {
      navigation: null,
      sidePane: null,
      main: null,
    }
    React.Children.forEach(components, (child) => {
      if (child?.type?.displayName?.endsWith('Navigation')) {
        childrenMap.navigation = child
      } else if (child?.type?.displayName?.endsWith('SidePane')) {
        childrenMap.sidePane = child
      } else if (child?.type?.displayName?.endsWith('Context')) {
        childrenMap.main = child
      } else if (child?.type?.displayName?.endsWith('Main')) {
        childrenMap.main = <ErrorBoundary>{child}</ErrorBoundary>
      }
    })
    return childrenMap
  }, [components])

  const { connected } = useConnected()

  const [firstRender, setFirstRender] = useState(true)

  useEffect(() => {
    const handleBeforeUnload = () => {
      // Set the unloaded state when the page is about to unload
      setIsUnloaded(true);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      // Cleanup: Remove the event listener when the component is unmounted
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (connected && childMap?.main) {
      setMain(childMap.main)
      setFirstRender(false)
    } else if (document.visibilityState === 'visible' && !connected && !firstRender && !isUnloaded) {
      setMain(<ServiceUnavailableErrorPage />)
    } else {
      setMain(<LoadingIcon></LoadingIcon>)
    }
  }, [connected])

  // main switcher
  useEffect(() => {
    if (isNavigationOverlayOpen) {
      setMain(navigationOverlay)
      return
    }
    if (childMap?.main) {
      setMain(childMap.main)
      return
    }
  }, [childMap?.main, isNavigationOverlayOpen, navigationOverlay])


  return {
    main,
    sidePane: childMap.sidePane,
    navigation: {
      component: childMap.navigation ,
      overlay: {
        isOpen: isNavigationOverlayOpen,
        setIsOpen: setIsNavigationOverlayOpen,
        setNavigationOverlay,
      },
    },
  }
}

type ContextType = ReturnType<typeof useProvideContext>

const Context = createContext<ContextType | undefined>(undefined)

const PageContext = ({ children, components }: { children, components: ReactElement[] }) => {
  const value = useProvideContext(components)
  return <Context.Provider value={value}>{children}</Context.Provider>
}

const usePageContext = (): ContextType => {
  const context = useContext(Context)
  if (!context) {
    throw new Error('usePageContext must be used within a PageContextProvider')
  }
  return context
}

export { usePageContext, PageContext }
