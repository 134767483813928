import { useEffect, useState } from 'react'
import { Menu, MenuData } from '@colombalink/basedui'
import { useChartContext } from './context'
import { selectChild } from '@shared/utils/reactChildSelect'
import { useViewThingContext } from '@app/pages/Orgs/Project/ExplorerFragment/MultiThingContex'
import {useProjectExplorerContext} from "@app/pages/Orgs/Project/ExplorerFragment/ExplorerContext";

export const ThingsLegend = ({
  children,
}: {
  children
}) => {
  const chartContext = useChartContext()
  const [properties, setProperties] = chartContext.useProperties // [properties, BoundFunctionObject]<
  const { things, view } = useViewThingContext()
  const [menuOptions, setMenuOptions] = useState<MenuData>([])
  const [visibleFields,] = chartContext.useVisibleFields

  useEffect(() => {
    if (!properties || !things) return
    const newMenuOptions: MenuData = []
    for (let thingId of Object.keys(things)) {
      const thing = things[thingId]
      if (!thing) continue

      const nextItems: MenuData = {
        label: thing.label,
        value: thingId,
        items: [],
      }
      newMenuOptions.push(nextItems)

      for (let thingIdAndPropertyKey of Object.keys(thing.properties)) {

        const property = thing.properties[thingIdAndPropertyKey]
        let label = <div>{property.label.en}</div>

        const color = view.selections && view.selections[0] && view.selections[0].thingProperties
            ? view.selections[0].thingProperties.find(prop => prop.key === property.name)?.color || 'none'
            : 'none';

        if (visibleFields.get(thingIdAndPropertyKey)) {
          label = (
            <b
              style={{ display: 'inline-block', position: 'relative' }}
              onMouseEnter={() => {
                chartContext.useHighlightedField[1](
                  `${thingIdAndPropertyKey}`
                )
              }}
              onMouseLeave={() => {
                chartContext.useHighlightedField[1](null)
              }}
            >
              <div style={{display: 'inline-block', position: 'relative', paddingLeft: '5px'}}>
                <span style={{
                  borderLeft: `3px solid ${color}`,
                  height: '100%',
                  position: 'absolute',
                  left: '-5px'
                }}></span>
                {property.label.en}
              </div>
            </b>
          )
        }
        const subItem = {
          label,
          value: thingIdAndPropertyKey,
        }
        nextItems.items.push(subItem)
      }
      setMenuOptions(newMenuOptions)

    }
  }, [things, visibleFields])

  return (
    <Menu
      style={{
        height: '100%',
        width: '300px',
        padding: '10px 14px 0px 0px',
        border: 'none',
      }}
      header={selectChild(children).endsWith('Selector')}
      collapse
      onChange={(propIdentifier) => {  // thingID or thingId_propertyKey

        if (!propIdentifier.includes('_')) {
          return
        }

        const updatedValue = properties.values[propIdentifier] 
        updatedValue.visible = !properties.values[propIdentifier].visible


        chartContext.useVisibleFields[1](prevState => new Map(prevState).set(propIdentifier, !prevState.get(propIdentifier)))
        const visibleProperties = Object.values(properties.values).filter(option => option.visible);
        if (visibleProperties.length === 0) {
          return;
        }
        console.log("Setting properties", properties) 
        // setProperties({
        //   ...properties,
        //   values: {
        //     ...properties.values,
        //     [propIdentifier]: updatedValue,
        //   },
        // })
      }}
      data={menuOptions}
    />
  )
}
