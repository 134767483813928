import FadeInBox from "../../shared/FadeInBox"
import MainContainer from "../../shared/MainContainer"
import { useAuthState } from '@shared/client/useAuthState';

import { Button, Input, LockIcon, Spacer, Text, Toast, useToast } from '@colombalink/basedui';
import { email as isEmail } from '@saulx/validators'
import { useLocation } from 'wouter';
import { useState, useRef } from 'react';
import { WithI18n } from "@shared/i18n/withI18n";
import i18n from "../../i18n";
import { useT } from "@app/i18n";
import { useIdentityClient } from "@shared/client/context";

export default ({ params }: { params: { token } }) => (
    <WithI18n i18n={i18n}>
        <Loginpage params={params} />
    </WithI18n>
)

const Loginpage = ({ params }: { params: { token } }) => {
    const t = useT()
    const client = useIdentityClient()
    const authState = useAuthState()
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const passwordRef = useRef<HTMLInputElement>(null);
    const [, setLocation] = useLocation()

    const toast = useToast()

    if (authState.userId) {
        setLocation('/invitation/join')
    }

    return (
        <MainContainer>
            <FadeInBox>
                <Input
                    large
                    label= {t['Login:Email'].get()}
                value={email}
                    type="email"
                    placeholder={t['Login:EmailPlaceholder'].get()}
                    onChange={(str) => setEmail(str)}
                />
                <Spacer />
                <Input
                    label={t['Login:Password'].get()}
                    large
                    inputRef={passwordRef}
                    icon={LockIcon}
                    type="password"
                    placeholder={t['Login:PasswordPlaceholder'].get()}
                    onChange={(password) => {
                        setPassword(password)
                    }}
                    style={{ marginBottom: 12 }}
                />
                <div className='text-justify end' style={{ marginBottom: 24 }}>
                    <a className="text-sailorblue-main"
                        onClick={() => {
                            setLocation("login/forgot")
                        }}
                    >{t['Login:ForgotPassword'].get()}</a>
                </div>

                <Button
                    large
                    fill
                    style={{
                        marginBottom: 24,
                        height: 48,
                    }}
                    textAlign="center"
                    keyboardShortcut="Enter"
                    disabled={!isEmail(email) || password.length < 3}
                    onClick={async () => {
                        try {
                            await client.call("login", { password, email });
                            setLocation(`/invitation/join`)
                        } catch (e) {
                            toast.add(
                                <Toast
                                    label={t['Login:LoginFailed'].get()}
                                    type="error"
                                    description={
                                        <Text style={{ whiteSpace: 'normal' }}>
                                            {t['Login:LoginFailedInvalidCredentials'].get()}                                            
                                        </Text>
                                    }
                                />)
                            throw e
                        }
                    }}
                >
                    {t['Login:Signin'].get()}
                </Button>
            </FadeInBox>
        </MainContainer>
    );

}







