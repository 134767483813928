import { useRouteParams } from '@shared/router'
import { useLink } from '@app/routes'
import { Menu } from '@colombalink/basedui'
import { useLocation } from 'wouter'
import {
  FunctionSquareIcon,
  HandMetalIcon,
  LayoutTemplateIcon,
  UngroupIcon,
} from 'lucide-react'
import { usePageRole } from '@shared/components/page/usePageRole'
import { WithI18n } from '@shared/i18n/withI18n'
import i18n from '../i18n'
import { useT } from '@app/i18n'

export const MonitorMenu= ({page})=> (
  <WithI18n i18n={i18n}>
    <MonitorMenuMain page={page}/>
  </WithI18n>
)


const MonitorMenuMain = ({ page }) => {
  const t = useT()
  const [location, setLocation] = useLocation()
  const { orgAliasId } = useRouteParams<{ orgAliasId: string }>()
  const role = usePageRole()
  const owner = {
        Options: [
          {
            icon: <HandMetalIcon className="h-4 w-4" />,
            value: 'actions',
            label: t['Monitor:Actions'].get(),
            onClick: () =>
              setLocation(
                useLink('/org/:orgAliasId/monitors/actions', { orgAliasId })
              ),
          },
          {
            icon: <FunctionSquareIcon className="h-4 w-4" />,
            value: 'functions',
            label: t['Monitor:Functions'].get(),
            onClick: () =>
              setLocation(
                useLink('/org/:orgAliasId/monitors/functions', { orgAliasId })
              ),
          },
        ],
        Notification: [
          {
            icon: <LayoutTemplateIcon className="h-4 w-4" />,
            value: 'notificationTemplates',
            label: t['Monitor:Templates'].get(),
            onClick: () =>
              setLocation(
                useLink('/org/:orgAliasId/monitors/notifications/templates', {
                  orgAliasId,
                })
              ),
          },
          {
            icon: <UngroupIcon className="h-4 w-4" />,
            value: 'notificationGroups',
            label: t['Monitor:Groups'].get(),
            onClick: () =>
              setLocation(
                useLink('/org/:orgAliasId/monitors/notifications/groups', {
                  orgAliasId,
                })
              ),
          },
        ],
      }

  const others = {
        Notification: [
          {
            icon: <UngroupIcon className="h-4 w-4" />,
            value: 'notificationGroups',
            label: 'Groups',
            onClick: () =>
              setLocation(
                useLink('/org/:orgAliasId/monitors/notifications/groups', {
                  orgAliasId,
                })
              ),
          },
        ],
      }
  return (
    <Menu
      style={{
        padding: '10px 14px 0px 0px',
        width: '140px',
      }}
      active={page}
      data={
        role.isOwner ? owner : others
      }
    />
  )
}
