// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        debug: import.meta.env.VITE_LOG_LEVEL === 'debug',
        resources: {
            en: {
                translation: {
                    "login.email": "E-Mail",
                    "login.password": "Password",
                    "login.emailPlaceholder": "Enter your email address",
                    "intlDateTime": "{{val, datetime}}",
                }
            },
            ["de-CH"]: {
                translation: {
                    "login.email": "E-Mail",
                    "login.password": "Passwort",
                    "login.emailPlaceholder": "Geben Sie Ihre E-Mail-Adresse ein",
                    "intlDateTime": "{{val, datetime}}",
                } 
            },
            // add other languages here
        },
        lng: "de-CH", // language to use, more info: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        fallbackLng: {
            "en-US": ["en"],
            "de-CH": ["de"],
            "de-DE": ["de"],
            "default": ["en"]
        }, // use 'en' if detected language is not available
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;