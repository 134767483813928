import {
  Code,
  Dialog,
  Input,
  Select,
  Spacer,
  Text,
  Toast,
  useToast,
} from '@colombalink/basedui'
import React, { useState } from 'react'
import { z } from 'zod'
import { createFunctionParams } from '@server/app'
import { api } from '@shared/index'
import { ZodInputValidator } from '@shared/zod/InputValidator'
import { WithI18n } from '@shared/i18n/withI18n'
import i18n from '../../i18n'
import { useT } from '@app/i18n'

type createFunctionArgs = z.infer<typeof createFunctionParams>

const AddFunctionDialog = () => {
  const t = useT()
  const createFunction = api.function.createFunction.useMutation()
  const toast = useToast()

  const [functionArgs, setFunctionArgs] = useState<createFunctionArgs>({
    name: '',
    code: `console.log('Hello world')`,
    configSchema: `{
    "$schema": "http://json-schema.org/draft/pingu/schema#",
    "$id": "http://example.com/example.json",
    "title": "Temperature threshold checker",
    "description": "Temperature threshold checker",
    "type": "object",
    "properties": {
        "measurement": {
            "description": "The measurement being described eg temperature",
            "type": "string"
        },
        "threshold": {
            "description": "Name of the product",
            "type": "number"
        }
    }
}`,
    language: 'javascript',
  })

  return (
    <Dialog label={t["MonitorFunction:CreateFunction"].get()}>
      <div>
        <Spacer />
        <Select
          value={functionArgs.language}
          onChange={(language: string | undefined) => {
            setFunctionArgs({ ...functionArgs, language })
          }}
          label= {t["MonitorFunction:Language"].get()}
          options={[{ label: 'javascript', value: 'javascript' }]}
        />
        <Spacer />
        <Input
          description={t["MonitorFunction:EnterNameHere"].get()}
          value={functionArgs.name}
          pattern="true"
          error={(value) =>
            ZodInputValidator(createFunctionParams, functionArgs, 'name')
          }
          onChange={(name: string) => {
            setFunctionArgs({ ...functionArgs, name })
          }}
          type={'text'}
        />

        <Text
          style={{
            paddingLeft: '5px',
            paddingBottom: '15px',
            paddingTop: '10px',
            color: 'gray',
          }}
        >
          {t["MonitorFunction:CreateFunction"].get()}
        </Text>

        <Code
          value={functionArgs.code}
          onChange={(code: string) => {
            setFunctionArgs({ ...functionArgs, code })
          }}
        />
        <Text
          style={{
            paddingLeft: '5px',
            paddingBottom: '15px',
            paddingTop: '10px',
            color: 'gray',
          }}
        >
          {t["MonitorFunction:AddConfigurationSchemaHere"].get()}
        </Text>
        <Code
          value={functionArgs.configSchema}
          onChange={(configSchema: string) => {
            setFunctionArgs({ ...functionArgs, configSchema })
          }}
        />

        <Dialog.Buttons>
          <Dialog.Cancel displayShortcut={false}>{t["MonitorFunction:Close"].get()} </Dialog.Cancel>
          <Dialog.Confirm
            keyboardShortcut="nooo"
            displayShortcut={false}
            onConfirm={async () => {
              try {
                await createFunction.mutateAsync(functionArgs)
                toast.add(
                  <Toast
                    label={t["MonitorFunction:AddedFunction"].get()}
                    type="success"
                    description={
                      <Text>
                        {t["MonitorFunction:Function"].get()} <b>{functionArgs.name}</b> {t["MonitorFunction:CreatedSuccessfully"].get()}
                      </Text>
                    }
                  />
                )
              } catch (e) {
                toast.add(
                  <Toast
                    label={t["MonitorFunction:FailedToCreateFunction"].get()}
                    type="error"
                    description={
                      <Text style={{ whiteSpace: 'normal' }}>{e.message}</Text>
                    }
                  />
                )
                throw e
              }
            }}
          >
            {t["MonitorFunction:Save"].get()}
          </Dialog.Confirm>
        </Dialog.Buttons>
      </div>
    </Dialog>
  )
}

export default ()=> (
  <WithI18n i18n={i18n}>
    <AddFunctionDialog />
  </WithI18n>
)