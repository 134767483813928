import { Dialog, Input, Select, Spacer, Text, Toast, useToast } from '@colombalink/basedui'
import { EmailSelection } from '../../../../../app/pages/Orgs/Monitor/MonitorNotificationGroup/shared/EmailSelection'
import { ZodInputValidator } from '@shared/zod/InputValidator'
import { api } from '@shared/index'
import { createNotificationGroupParams } from '@server/app'
import { useState } from 'react'
import { z } from 'zod'
import { WithI18n } from '@shared/i18n/withI18n'
import i18n from '../../i18n'
import { useT } from '@app/i18n'

type createNotificationGroupArgs = z.infer<typeof createNotificationGroupParams>

const AddNotificationGroupDialog = () => {
  const t = useT()
  const createGroup = api.organization.monitor.notification.group.create.useMutation()
  const toast = useToast()

  const [createGroupArgs, setCreateGroupArgs] = useState<createNotificationGroupArgs>(
    {
      name: '',
      description: '',
      notificationGroupType: 'email',
      values: [],
    }
  )

  return (
    <Dialog label={t["MonitorGroup:CreateGroup"].get()}>
      <div>
        <Spacer />
        <Input
          description={t["MonitorGroup:Name"].get()}
          value={createGroupArgs.name}
          pattern="true"
          error={(value) => ZodInputValidator(createNotificationGroupParams, createGroupArgs, "name")}
          onChange={(name: string) => {
            setCreateGroupArgs({ ...createGroupArgs, name })
          }
          }
        />
        <Spacer />
        <Input
          description= {t["MonitorGroup:Description"].get()}
          value={createGroupArgs.description}
          pattern="true"
          error={(value) => ZodInputValidator(createNotificationGroupParams, createGroupArgs, "title")}
          onChange={(description: string) => {
            setCreateGroupArgs({ ...createGroupArgs, description })
          }
          }
        />
        <Spacer />

        <Select
          value={createGroupArgs.notificationGroupType}
          onChange={(notificationGroupType: string | undefined) => {
            setCreateGroupArgs({ ...createGroupArgs, notificationGroupType })
          }}
          label={t["MonitorGroup:NotificationType"].get()}
          options={[
            { label: 'E-Mail', value: 'email' },
            { label: 'Monidas', value: 'monidas' },
          ]}
        />

        {
          createGroupArgs.notificationGroupType === 'email' &&
          <>
            <Spacer />
            <EmailSelection
              values={createGroupArgs.values}
              onChange={(values: string[]) => {
                setCreateGroupArgs({ ...createGroupArgs, values })
              }}
            />
          </>
        }

        <Dialog.Buttons>
          <Dialog.Cancel displayShortcut={false}>{t["MonitorGroup:Close"].get()}</Dialog.Cancel>
          <Dialog.Confirm keyboardShortcut='nooo' displayShortcut={false} onConfirm={async () => {

            try {
              await createGroup.mutateAsync(createGroupArgs)
              toast.add(
                <Toast
                  label={t["MonitorGroup:AddedGroup"].get()}
                  type="success"
                  description={
                    <Text>
                      {t["MonitorGroup:Group"].get()} <b>{createGroupArgs.name}</b> {t["MonitorGroup:CreatedSuccessfully"].get()}
                    </Text>
                  }
                />
              )
            } catch (e) {
              toast.add(
                <Toast
                  label={t["MonitorGroup:FailedToCreateGroup"].get()}
                  type="error"
                  description={
                    <Text style={{ whiteSpace: 'normal' }}>
                      {e.message}
                    </Text>
                  }
                />
              )
              throw e
            }
          }}>{t["MonitorGroup:Save"].get()}</Dialog.Confirm>
        </Dialog.Buttons>
      </div>
    </Dialog>
  )
}

const MonidasSelect = ({ value, onChange, orgAliasId }: { orgAliasId: string, value: string[], onChange: (value: string[]) => void }) => {
  const org = api.organization.getOrganizationById.withMembers.useLiveData({
    orgAliasId: orgAliasId,
  })
}


export default ()=> (
  <WithI18n i18n={i18n}>
    <AddNotificationGroupDialog />
  </WithI18n>
)
