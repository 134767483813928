import { RouteParamsProvider, useRouteParams } from '../../../router'
import { Button, Style, useDialog } from '@colombalink/basedui'
import { ButtonRowContainer } from './ButtonRowContainer'

export const DynamicButtonRow = ({
  buttons,
}: {
  buttons: Array<{
    icon: JSX.Element
    dialogComponent: JSX.Element
    label: JSX.Element | string
    style: Style
  }>
}) => {
  const { open } = useDialog()
  const params = useRouteParams()
  return (
    <ButtonRowContainer>
      {buttons.map(({ icon, dialogComponent, label, style }, index) => {
        return (
          <Button
            key={index}
            className="w-full md:w-auto"
            style={{
              ...style,
            }}
            icon={icon}
            onClick={async () => {
              await open(
                <RouteParamsProvider params={params}>
                  {dialogComponent}
                </RouteParamsProvider>
              )
            }}
          >
            {label}
          </Button>
        )
      })}
    </ButtonRowContainer>
  )
}
