import { useRouteParams } from '../../../../shared/router'
import { AddIcon } from '@colombalink/basedui'
import { DynamicButtonRow } from '@shared/components/core/DynamicButtonRow'
import { MainToolBar } from '@shared/components/core/MainToolbar'
import { Page } from '@shared/components/page/Page'
import DefaultNavigation from '@shared/components/page/navigation/DefaultNavigation'
import { AddDeviceDialog } from '@shared/components/thing/dialogs/AddDialog'
import { DevicesTable } from './DevicesTable'
import { AddGenericThingDialog } from '@shared/components/thing/dialogs/AddThingDialog'
import { ThingLandingFragment } from '@shared/components/thing/LandingFragment'
import { WithI18n } from '@shared/i18n/withI18n'
import i18n from './i18n'
import { useT } from '@app/i18n'

type PageProps = { params: { orgAliasId: string; projectAliasId: string } }
export type PageParams = PageProps['params']

export default (props: PageProps) => {
  return (
    <Page>
      <DefaultNavigation />
      <Main />
    </Page>
  )
}

const Main = () => {
  return (
    <WithI18n i18n={i18n}>
      <ManageProjectDevicesMain />
    </WithI18n>
  )
}
Main.displayName = 'Main'

const ManageProjectDevicesMain = () => {
  const t = useT()
  const { orgAliasId, projectAliasId } = useRouteParams<PageParams>()
  return (
    <ThingLandingFragment>
      <div className="flex flex-col">
          <>
            <MainToolBar>
              <DynamicButtonRow
                buttons={[
                  {
                    icon: <AddIcon />,
                    dialogComponent: <AddGenericThingDialog />,
                    label: t['Devices:AddGeneric'].get(),
                    style: { cursor: 'pointer' },
                  },
                  {
                    icon: <AddIcon />,
                    dialogComponent: <AddDeviceDialog />,
                    label: t['Devices:Add'].get(),
                    style: { cursor: 'pointer' },
                  },
                ]}
              />
            </MainToolBar>
            <DevicesTable></DevicesTable>
          </>
      </div>
    </ThingLandingFragment>
  )
}

ManageProjectDevicesMain.displayName = 'ManageProjectDevicesMain'
