import { Dialog } from '@colombalink/basedui'
import { HistogramView } from '@shared/components/core/dashboard/HistogramView'
import { WithI18n } from '@shared/i18n/withI18n'
import i18n from '../i18n'
import { useT } from '@app/i18n'

type Props = {
  thingId: string
  influxField: string
  thingName: string
}

const HistogramViewDialog = ({
  thingId,
  influxField,
  thingName,
}: Props) => {
  const t = useT()
  return (
    <Dialog style={{ width: '80%' }} label={thingName}>
      <HistogramView thingId={thingId} influxField={influxField} />
      <Dialog.Buttons border>
        <Dialog.Cancel>{t["CoreDashboard:Close"].get()} </Dialog.Cancel>
      </Dialog.Buttons>
    </Dialog>
  )
}

export default (props: Props) => (
  <WithI18n i18n={i18n}>
    <HistogramViewDialog {...props} />
  </WithI18n>
)  