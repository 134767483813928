
const ProfileChangePasswordPageContentI18n = {
    'Profil:AccountDetails': {
        en: 'Account Details',
        de: 'Konto Informationen',
    },
    'Profil:Name': {
        en: 'Name',
        de: 'Name',
    },
    'Profil:Email': {
        en: 'Email',
        de: 'E-mail',
    },
    'Profil:EmailNotChange': {
        en: 'Email can not be changed',
        de: 'E-mail kann nicht geändert werden',
    },
    'Profil:Password': {
        en: 'Password',
        de: 'Passwort',
    },  
    'Profil:CurrentPassword': {
        en: 'Current Password',
        de: 'Aktuelles Passwort',
    },
    'Profil:NewPassword': {
        en: 'New Password',
        de: 'Neues Passwort',
    },
    'Profil:NewPasswordConfirm': {
        en: 'Confirm New Password',
        de: 'Neues Passwort bestätigen',
    },
    'Profil:PasswordNotMatch': {
        en: 'Passwords do not match',
        de: 'Passwörter stimmen nicht überein',
    },
    'Profil:ResetPassword': {
        en: 'Reset Password',
        de: 'Passwort zurücksetzen',
    },
    'Profil:ConfirmPassword': {
        en: 'Confirm new Password',
        de: 'Neues Passwort bestätigen',
    },
    'Profil:Save': {
        en: 'Save',
        de: 'Speichern',
    },
    'Profil:PasswordChangedSuccessfully': {
        en: 'Password changed successfully',
        de: 'Passwort erfolgreich geändert',
    },
    'Profil:PasswordChanged': {
        en: 'Password changed',
        de: 'Passwort geändert',
    },



}


export default ProfileChangePasswordPageContentI18n
export type ProfileChangePasswordPageContentI18nType = typeof ProfileChangePasswordPageContentI18n
