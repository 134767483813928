import React, { createContext, useContext, useEffect, useState } from 'react'

function useProvideContext() {
  const useStep = useState(1)
  const useValidJSON = useState(false)
  const useSampleEvent = useState("{}")
  const useSelectedPaths = useState<any>([])
  const useExistingPropertyPaths = useState<any>([])
  const useFlatPaths = useState<any>([])
  const useIdentifiers = useState({})
  const useThingType = useState<'chirp' | 'generic' | 'unknown'>('unknown')
  const useTags = useState<string[]>([])
  const useValues = useState<string[]>([])

  const sampleEvent = useSampleEvent[0]
  const setFlatPaths = useFlatPaths[1]
  const setValidJSON = useValidJSON[1]

  
  useEffect(() => {
    try {
      if (sampleEvent) {
        const flattenObject = (obj: any, path: string = ''): string[] => {
          if (!(obj instanceof Object)) return [path]

          return Object.keys(obj)
            .map((key) => flattenObject(obj[key], path ? `${path}.${key}` : key))
            .flat()
        }
        const res = JSON.parse(sampleEvent)
        if(typeof res === "string" || !res){
          throw new Error("this is a string")
        }
        setFlatPaths(flattenObject(res))
        setValidJSON(true)
      }
    } catch (e) {
      setValidJSON(false)
    }
  }, [sampleEvent])

  return {
    useStep,
    useValidJSON,
    useSampleEvent,
    useSelectedPaths,
    useFlatPaths,
    useIdentifiers,
    useThingType,
    useTags,
    useValues,
    useExistingPopertyPaths: useExistingPropertyPaths,
  }
}

type ContextType = ReturnType<typeof useProvideContext>

const Context = createContext<ContextType | undefined>(undefined)

const PageContext: React.FC = ({ children }) => {
  const value = useProvideContext()
  return <Context.Provider value={value}>{children}</Context.Provider>
}

const usePageContext = (): ContextType => {
  const context = useContext(Context)
  if (!context) {
    throw new Error('usePageContext must be used within a PageContextProvider')
  }
  return context
}

export { usePageContext, PageContext }
