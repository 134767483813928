import { Dialog, Input, Text, Toast, useToast, Select, Label, Spacer } from '@colombalink/basedui'
import React from 'react'
import { api } from '@shared/index'
import { ZodInputValidator } from '@shared/zod/InputValidator'
import { useT } from '@app/i18n'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'
import { updateIntegrationParams } from '@server/app'

const EditIntegration = ({ integration }: { integration: { id: string, name: string, url: string, token: string, type: string } }) => {
  const t = useT()
  const updateIntegration = api.integrations.update.useMutation()
  const [updateIntegrationArgs, setUpdateIntegrationArgs] = React.useState({
    integrationId: integration.id,
    name: integration.name,
    url: integration.url,
    token: integration.token,
  })

  const toast = useToast()

  if (!updateIntegrationArgs) {
    return <LoadingIcon />
  }

  return (
    <Dialog label={t['OrgIntegration:EditIntegration'].get()}>
      <div>
        <Select options={[{ label: 'Chirpstack', value: 'integrationChirpstack' }]}
          value={integration.type}
          disabled
        ></Select>
        {
          integration.type === 'integrationChirpstack' && (
            <>
              <Spacer />
              <Input
                type='text'
                label={t['OrgIntegration:Name'].get()}
                value={updateIntegrationArgs.name}
                onChange={(name: string) => {
                  setUpdateIntegrationArgs({ ...updateIntegrationArgs, name })
                }}

                pattern="true"
                error={(value) => ZodInputValidator(updateIntegrationParams, updateIntegrationArgs, "name")}
              />
              <Input
                type='text'
                label="Url"
                value={updateIntegrationArgs.url}
                onChange={(url: string) => {
                  setUpdateIntegrationArgs({ ...updateIntegrationArgs, url })
                }}

                pattern="true"
                error={(value) => ZodInputValidator(updateIntegrationParams, updateIntegrationArgs, "url")}
              />

              <Input
                type='text'
                label="Token"
                value={updateIntegrationArgs.token}
                onChange={(token: string) => {
                  setUpdateIntegrationArgs({ ...updateIntegrationArgs, token })
                }}

                pattern="true"
                error={(value) => ZodInputValidator(updateIntegrationParams, updateIntegrationArgs, "token")}
              />
            </>
          )
        }

        <Dialog.Buttons>
          <Dialog.Cancel displayShortcut={false}>{t['OrgIntegration:Close'].get()}</Dialog.Cancel>
          <Dialog.Confirm
            disabled={!!ZodInputValidator(updateIntegrationParams, updateIntegrationArgs)}
            displayShortcut={false} onConfirm={async () => {

              try {
                await updateIntegration.mutateAsync(updateIntegrationArgs)
                toast.add(
                  <Toast
                    label={t['OrgIntegration:UpdatedIntegration'].get()}
                    type="success"
                    description={
                      <Text>
                        {t['OrgIntegration:UpdatedIntegrationText'].get()}
                      </Text>
                    }
                  />
                )
              } catch (e) {
                toast.add(
                  <Toast
                    label= {t['OrgIntegration:FailedToUpdateIntegration'].get()}
                    type="error"
                    description={
                      <Text style={{ whiteSpace: 'normal' }}>
                        {e.message}
                      </Text>
                    }
                  />
                )
                throw e
              }
            }}>{t['OrgIntegration:Save'].get()}</Dialog.Confirm>
        </Dialog.Buttons>
      </div>
    </Dialog>
  )
}



export default EditIntegration;