import { usePageContext } from "../context"
import { Checkbox} from "@colombalink/basedui"
import { useEffect, useState } from "react"
import { useTable } from "@shared/components/core/table/Table"
import { Title } from "@shared/components/core/Title"
import { useT } from "@app/i18n"

export const ValueSelectionFragment = () => {
  const t = useT()
  const {
    useStep: [, setStep],
    useFlatPaths: [flatPath],
    useTags: [tags],
    useValues: [values, setValues],
  } = usePageContext()

  const [tableData, setTableData] = useState([])

  const [table, TableComponent] = useTable({
    header: [
      { label: t["ThingConfigure:Selected"].get(), key: 'selected' },
      { label: t["ThingConfigure:PropertyPath"].get(), key: 'path' },
    ],
    data: tableData,
  })
  useEffect(() => {
    const tableData = flatPath
      .filter((path) => !tags.includes(path))
      .sort()
      .map((path) => {
        return {
          path,
          isSelectedDefault: values.includes(path),
          selected: ({ row }: { row: Row<any> }) => {
            return (
              <Checkbox
                value={row.getIsSelected() || values.includes(path)}
                onChange={() => {
                  row.toggleSelected()
                }}
              />
            )
          },
          customAttribute: {
            row: {
              onClick: ({ row }: { row: Row<any> }) => row.toggleSelected(),
              className: 'cursor-pointer',
            },
          },
        }
      })
    setTableData(tableData)
  }, [])


  const selected = table.getSelectedRowModel()
  useEffect(() => {
    if (!selected?.rows) {
      return
    }
    const selectedValues = selected?.rows.map((row) => row?.original?.path)
    setValues(selectedValues)
    //console.log("values:", values)
  }, [selected])

  return (
    <div className="flex flex-col">
      <Title>4) {t["ThingConfigure:ValuesSelection"].get()} </Title>
      <p>
        {t["ThingConfigure:SelectValues"].get()}  
      </p>
      <p>
        {t["ThingConfigure:MeasurementsAre"].get()}
      </p>

      <div className="align-middle p-2 m-2">{TableComponent}</div>
    </div>
  )
}