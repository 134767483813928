import EditAction from '@shared/components/monitor/action/dialogs/EditDialog'
import { FC, useState } from 'react'
import { OptionMenu } from '@shared/components/core/EditEntityMenu'
import { api } from '@shared/index'
import {
  ContextItem,
  DeleteIcon,
  EditIcon,
  Text,
  Toast,
  useDialog,
  useToast,
} from '@colombalink/basedui'
import { useLocation } from 'wouter'
import { useT } from '@app/i18n'

type RowMenuProps = {
  action: ReturnType<
    typeof api.organization.monitor.action.getAll.useLiveData
  >['data'][0]
}

export const RowMenu = (props: RowMenuProps) => {

  const CategoryMenu: FC<{}> = ({ }) => {
    const t = useT()
    const { open } = useDialog()
    const toast = useToast()

    const removeAction = api.organization.monitor.action.delete.useMutation()

    return (
      <>
        <ContextItem
          onClick={() => {
            open(
              <EditAction
                action={props.action}
              />
            )
          }}
          icon={EditIcon}
        >
          {t['Monitor:Edit'].get()}
        </ContextItem>
        <ContextItem
          onClick={async () => {
            await removeAction.mutateAsync({ id: props.action.id })

            toast.add(
              <Toast
                label={t['Monitor:ActionRemoved'].get()}
                type="success"
                description={<Text>{t['Monitor:RemovedSuccess'].get()}</Text>}
              />
            )
          }}
          icon={DeleteIcon}
        >
          {t['Monitor:Remove'].get()}
        </ContextItem>
      </>
    )
  }

  return <OptionMenu menu={CategoryMenu} style={{ width: '150px' }} />
}
