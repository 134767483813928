import {
  Checkbox,
  ColorPicker,
  Dialog,
  Text,
  useDialog,
} from '@colombalink/basedui'
import { Title } from '@shared/components/core/Title'
import React from 'react'
import { useT } from '@app/i18n'

const PropertySelection = ({
  thingId,
  thingName,
  influxFields,
  selectedProperties,
  onPropertySelectionChange,
}) => {
  const t = useT()
  const dialog = useDialog()
  // Open color picker dialog
  const openColorPicker = (propertyName, currentColor) => {
    dialog.open(
      <Dialog label={t['Project:SelectColor'].get()}>
        <ColorPicker
          value={currentColor}
          onChange={(color) =>
            onPropertySelectionChange(thingId, propertyName, true, color)
          }
        />
        <Dialog.Buttons border>
          <Dialog.Cancel>{t['ProjectClose'].get()}</Dialog.Cancel>
          <Dialog.Confirm>{t['Project:Confirm'].get()}</Dialog.Confirm>
        </Dialog.Buttons>
      </Dialog>
    )
  }

  return (
    <div className="mt-4">
      <Title>{thingName}</Title>
      <div className="ml-4">
        {influxFields.map((field) => {
          const prop = influxFields.find((prop) => prop.name === field.name)
          const selectedProp = selectedProperties.find((prop) => prop.key === field.name) 
          return(
            <div key={field?.name} className="flex items-center">
            <Checkbox
              value={selectedProp}
              onChange={(isSelected) => {
                onPropertySelectionChange(
                  thingId,
                  field.name,
                  isSelected,
                  selectedProp?.color || 'rgba(1,1,1,1)'
                )
              }}
            />

            <div
              className="m-2 w-6 h-6 rounded-full border border-gray-300"
              style={{
                backgroundColor: selectedProp?.color || 'rgba(1,1,1,1)'
              }}
              onClick={() =>
                openColorPicker(
                  field.name, 
                  selectedProp?.color || 'rgba(1,1,1,1)'
                )
              }
            />
            <Text>{prop?.label?.en}</Text>
          </div>
          )
        })}
      </div>
    </div>
  )
}

export default PropertySelection
