import { usePageContext } from "../context"
import { Select, Spacer } from "@colombalink/basedui"
import { useEffect, useState } from "react"
import { Title } from "@shared/components/core/Title"
import { useT } from "@app/i18n"

export const IdentifiersSelectionFragment = () => {
  const t= useT()
    const {
      useStep: [step, setStep],
      useFlatPaths: [flatPaths],
      useIdentifiers: [identifiers, setIdentifiers],
      useTags: [, setTags],
      useThingType: [thingType],
    } = usePageContext()
    const [isChirp, setIsChirp] = useState(false)
    useEffect(() => {
      if (
        flatPaths.find((path) => path === 'deviceInfo.tenantId') &&
        flatPaths.find((path) => path === 'deviceInfo.applicationId') &&
        flatPaths.find((path) => path === 'deviceInfo.devEui')
      ) {
        setIdentifiers({
          tenant: 'deviceInfo.tenantId',
          application: 'deviceInfo.applicationId',
          device: 'deviceInfo.devEui',
        })
        setTags([
          'deviceInfo.devEui',
          'deviceInfo.tenantId',
          'deviceInfo.applicationId',
        ])
        setIsChirp(true)
      }
    }, [flatPaths])
  
  
    if (thingType === 'generic') {
      // We can skip this step
      return (
        <div>
          <Title>2) {t["ThingConfigure:DeviceIdentifier"].get()} </Title>
          <div className="flex flex-col max-w-xl">
            <p>
              {t["ThingConfigure:SelectIdentifier"].get()}
            </p>
            <p>
              {t["ThingConfigure:SkipStep"].get()}
            </p>
            <Spacer />
          </div>
        </div>
      )
    }
  
  
    return (
      <div className="mb-4">
        <Title>2) {t["ThingConfigure:DeviceIdentifier"].get()} </Title>
        <div className="flex flex-col max-w-xl">
          <p>
          {t["ThingConfigure:SelectIdentifier"].get()}
          </p>
          {isChirp && (
            <p>
              {t["ThingConfigure:PayloadIsChirpstack"].get()}
            </p>
          )}
          <Spacer />
          <Select
            label= {t["ThingConfigure:TenantIdentifier"].get()}
            filterable
            disabled={isChirp}
            value={identifiers.tenant}
            options={flatPaths}
            onChange={(tenant: string) =>
              setIdentifiers({ ...identifiers, tenant })
            }
          />
          <Spacer />
          <Select
            label= {t["ThingConfigure:ApplicationIdentifier"].get()}
            filterable
            disabled={isChirp}
            value={identifiers.application}
            options={flatPaths}
            onChange={(application: string) =>
              setIdentifiers({ ...identifiers, application })
            }
          />
          <Spacer />
          <Select
            label= {t["ThingConfigure:DeviceIdentifier"].get()}
            filterable
            disabled={isChirp}
            value={identifiers.device}
            options={flatPaths}
            onChange={(device: string) =>
              setIdentifiers({ ...identifiers, device })
            }
          />
          <Spacer />
  
        </div>
      </div>
    )
  }

