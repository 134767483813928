import { AccordionItem } from '@app/pages/User/Home/AccordeonItem'
import { useRouteParams } from '@shared/router'
import { LoadingIcon } from '@shared/components/core/LoadingIcon';
import { Accordion } from '@shared/components/core/shadcn-ui/Accordion'
import { Page } from '@shared/components/page/Page'
import DefaultNavigation from '@shared/components/page/navigation/DefaultNavigation'
import { api, createTrpcBasedClient } from '@shared/index'
import { ProjectLandingFragment } from '@shared/components/project/LandingFragment'
import { WithI18n } from '@shared/i18n/withI18n'
import i18n from './i18n'
import { useT } from '@app/i18n'
import React, { useEffect } from 'react';
import { useTable } from '@shared/components/core/table/Table';
import { Button } from '@colombalink/basedui';
import { RowMenu } from "@app/pages/Orgs/Home/RowMenu";
import { useClientsContext } from '@shared/client/context';

type PageProps = { params: { orgAliasId: string } }
export type PageParams = PageProps['params']

const Home = () => {
  return (
    <Page>
      <DefaultNavigation />
      <Main />
    </Page>
  )

}

export default Home

const Main = () => {
  return (
    <WithI18n i18n={i18n}>
      <ProjectLandingFragment>
        <ProjectStatsList />
      </ProjectLandingFragment>
    </WithI18n>
  )
}

const ProjectStatsList = () => {
  const t = useT()
  const { orgAliasId } = useRouteParams<PageParams>()
  const stats = api.user.getStats.useLiveData({
    filters: {
      orgAliasId
    }
  })
  const roles = api.user.getRoles.useLiveData()

  if (stats.error || roles.error) {
    throw stats.error
  }

  if (stats.loading || roles.loading) {
    return <LoadingIcon />
  }

  return (
    <>
      <h3 className="m-4 text-txt scroll-m-20 text-2xl font-semibold tracking-tight text-left flex-grow">
        {t['Home:Projects'].get()}
      </h3>
      <Accordion
        type="multiple"
      >
        {stats.data.organizations?.map((org, index) => {
          return org.projects.map((project) => {
            return <ProjectAccordionItem key={project.alias} project={project} orgAliasId={orgAliasId}></ProjectAccordionItem>
          })
        })}
      </Accordion>
    </>
  )
}

Main.displayName = 'Main'

const ProjectAccordionItem = (props: { project, orgAliasId }) => {
  const t = useT()
  return (
    <AccordionItem
      key={props.project.id}
      id={props.project.id} title={props.project.name} statusStats={props.project.status} alias={props.project.alias}  >

      <h3 className="mb-4 ml-4 text-txt scroll-m-20 text-2xl font-semibold tracking-tight flex-grow">
        {
          props.project.things?.length > 0 ? t['Home:Devices'].get() : t['Home:NoDevices'].get()
        }
      </h3>

      <div className='ml-8'>
        {props.project.things?.map(
          (thing, index) => {
            return <ThingAccordionItem key={thing.alias} thing={thing} orgAliasId={props.orgAliasId} projectAliasId={props.project.alias}></ThingAccordionItem>
          }
        )}
      </div>
    </AccordionItem>
  )
}

const ThingAccordionItem = (props: { thing, orgAliasId, projectAliasId }) => {
  return (
    <AccordionItem
      key={props.thing.id}
      id={props.thing.id} title={props.thing.name} statusStats={props.thing.status} alias={props.thing.alias}  >
      {/*<AlarmTable thingId={props.thing.id} orgAliasId={props.orgAliasId} projectAliasId={props.projectAliasId}/>*/}
      <ListView thingId={props.thing.id} orgAliasId={props.orgAliasId} projectAliasId={props.projectAliasId} />
    </AccordionItem>
  )
}

const ListView = (props: { thingId: string, orgAliasId: string, projectAliasId: string }) => {
  const t = useT()
  const monitors = api.monitor.getAllMonitorsInAlarmStateByThing.useLiveData({
    thingAliasId: props.thingId,
    orgAliasId: props.orgAliasId,
    projectAliasId: props.projectAliasId
  })

  if (monitors.loading) {
    return (
      <>
        {t["Home:Loading"].get()} <LoadingIcon />
      </>
    )
  }

  if (monitors.data.length === 0) {
    return (
      <>
        {t["Home:NoAlarms"].get()}
      </>
    )
  }

  return (
    <ul role="list" className="divide-y divide-gray-200 mb-0 mr-5">
      {monitors.data.map((monitor) => (
        <li key={monitor.id} className="flex items-center justify-between gap-x-6 hover:bg-gray-100 p-2 mb-4">
          <div className="min-w-[150px]">
            <div>
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-red-100 text-red-800">
                {t["Home:Alarm"].get()}
              </span>
            </div>
            <div className="flex items-start gap-x-1 text-md font-semibold leading-6 text-gray-900">
              {monitor.name}
            </div>
            <div className="mt-1 flex items-center gap-x-1 text-sm leading-5 text-gray-500">
              <p className="whitespace-nowrap">
                {monitor.statusMessage.title} : {monitor.statusMessage.message}
              </p>
            </div>
          </div>
          <div className="flex flex-none items-center gap-x-4">
            <div>
              <RowMenu monitor={monitor} orgAliasId={props.orgAliasId} projectAliasId={props.projectAliasId} thingId={props.thingId} />
            </div>
          </div>
        </li>
      ))}
    </ul>
  )
}



// later
// <HomeContent
//   leftPanel={<RecentViews />}
//   midPanel={midPanel()}
//   rightPanel={rightPanel()}
//   mobPanel={<RecentViews />}
// />