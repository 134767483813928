import { Input, Select, MultiSelect} from '@colombalink/basedui';
import { useMonitorsBaseContext } from './BaseContext';
import { useT } from '@app/i18n';
import { t } from 'i18next';
import { LoadingIcon } from '@shared/components/core/LoadingIcon';

export const LoadingHandler = ({children}) => {
  const { loading } = useMonitorsBaseContext();
    
    if (loading) {
        return <LoadingIcon />;
    }
    
    return children;
}

// NameInput Component
export const NameInput = () => {
  const t = useT();
  const { formData: {
    useName: [name, setName]
  } } = useMonitorsBaseContext();
  return (
    <Input
      value={name}
      label= {t["ThingMonitors:Name"].get()}
      type="text"
      placeholder= {t["ThingMonitors:EnterNameHere"].get()}
      onChange={setName}
    />
  );
};

// StatusSelect Component
export const StatusSelect = () => {
  const t = useT();
  const { formData: {
    useStatus: [status, setStatus]
  } } = useMonitorsBaseContext();

  return (
    <Select
      value={status}
      onChange={(status: string) => setStatus(status)}
      label= {t["ThingMonitors:Status"].get()}
      options={['alert', 'idle']}
    />
  );
};

// ActiveStateSelect Component
export const ActiveStateSelect = () => {
  const t = useT();
  const { formData: {
    useActiveState: [activeState, setActiveState]
  } } = useMonitorsBaseContext();

  return (
    <Select
      value={activeState}
      onChange={setActiveState}
      label= {t["ThingMonitors:ActiveState"].get()}
      options={['active', 'inactive']}
    />
  );
};

// RuleFunctionSelect Component
export const RuleFunctionSelect = () => {
  const t = useT();
  const { 
    formData: {
      useRuleFunction: [ruleFunction, setRuleFunction]
    },
    formOptions: { ruleFunctions }
  } = useMonitorsBaseContext();

  return (
    <Select
      value={ruleFunction}
      options={ruleFunctions?.data?.map((item) => ({
        label: item.name,
        value: item.id,
      }))}
      onChange={(ruleFunction: string) => {
        setRuleFunction(ruleFunction)
      }}
      label= {t["ThingMonitors:RuleFunction"].get()}
    />
  );
};

// RuleConfigInput Component
export const RuleConfigInput = () => {
  const t = useT();
  const {
    formData: {
      useRuleConfig: [ruleConfig, setRuleConfig],
    },
  } = useMonitorsBaseContext();
  
  return (
    <Input
      value={ruleConfig}
      label={t["ThingMonitors:RuleConfig"].get()}
      type="json"
      onChange={(ruleConfig) => setRuleConfig(ruleConfig)}
    />
  );
};

// ActionsMultiSelect Component
export const ActionsMultiSelect = () => {
  const t = useT();
  const {
    formData: {
      useActions: [actions, setActions], 
    },
    formOptions: { actions: actionsOptions }
  } = useMonitorsBaseContext();
  return (
    <MultiSelect
      values={actions}
      label= {t["ThingMonitors:Actions"].get()}
      options={actionsOptions?.data?.map((item) => ({
        label: item.name,
        value: item.id,
      }))}
      onChange={(actions: string[]) => setActions(actions)}
    />
  );
};

// StatusMessageTemplateSelect Component
export const StatusMessageTemplateSelect = () => {
  const t = useT();
  const {
    formData: {
      useStatusMessageTemplate: [statusMessageTemplate, setStatusMessageTemplate], 
    },
    formOptions: { notificationTemplates: notificationTemplatesOptions }
  } = useMonitorsBaseContext();
  return (
    <Select
      value={statusMessageTemplate}
      label= {t["ThingMonitors:StatusMessageTemplate"].get()}
      options={notificationTemplatesOptions.data?.map((item) => ({
        label: item.name,
        value: item.id,
      }))}
      onChange={(template: string) => setStatusMessageTemplate(template)}
    />
  );
};


// DataInput Component
export const DataInput = () => {
  const t = useT();
  const {
    formData: {
      useData: [data, setData],
    },
  } = useMonitorsBaseContext();

  return (
    <Input
      value={data}
      label= {t["ThingMonitors:Data"].get()}
      type="json"
      onChange={(data) => setData(data)}
    />
  );
};