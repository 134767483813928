import { z } from 'zod'

export const apiTokenSchema = z.object({
    id: z.string(),
    type: z.string(),
    version: z.string(),
    organization: z.object({
      alias: z.string(),
    }),
    permissions: z.record(z.boolean()), 
});