import { DefaultProcedure } from '@server/app/trpc/router'
import { z } from 'zod'

export const createIntegrationParams = z.object({
        orgAliasId: z.string().optional(),
        name: z.string().refine((name) => name.length > 0, {message: 'Name must be at least 1 character long'}),
        type: z.literal('integrationChirpstack'),
        url: z.string().url({message: 'Please enter a valid URL'}),
        token: z.string()
})

export const createIntegrationProcedure = (
  procedure: DefaultProcedure
) =>
  procedure
    .input(createIntegrationParams)
    .mutation(async ({ ctx, input }) => {
      ctx.logger.debug('procedure::createIntegration ', input)
      const { name, token, type, url } = input;
      if(!ctx.org.id){
        throw new Error(ctx.i18n.t['AppIntegration:NoOrgId'].get())
      }
      console.log(await ctx.based.encode(token))
      console.log(await ctx.based.decode(await ctx.based.encode(token)))
      const integration = await ctx.based.db.default.set({
        type,
        name,
        url,
        token: await ctx.based.encode(token),
        parents: [ctx.org.id]
      })

      try {
        await ctx.based.db.default.set({
          $id: ctx.org.id,
          integrations: [integration.id]
        })
      }catch(e) {
        ctx.logger.error('procedure::createIntegration', e)
        await ctx.based.db.default.delete({$id: integration.id})
        ctx.logger.debug('procedure::createIntegration::rollback deleted: ' + integration.id)
        throw new Error(ctx.i18n.t["AppIntegration:CouldNotAddIntegrationToOrg"].get())
      }
    })
