const SharedMonitorPageContentI18n = {

    "Monitor:NoActionsFound": {
        "en": "No actions found",
        "de": "Keine Aktionen gefunden",
    },
    "Monitor:CreateAnAction": {
        "en": "Create an action",
        "de": "Aktion erstellen",
    },
    "Monitor:ContactTheOwner": {
        "en": "Please contact the organization owner to create actions",
        "de": "Bitte kontaktieren Sie den Organisationsinhaber, um Aktionen zu erstellen",
    },
    "MonitorAction:AddAction": {
        "en": "Add action",
        "de": "Aktion hinzufügen",
    },
    "MonitorAction:Name": {
        "en": "Name",
        "de": "Name",
    },
    "MonitorAction:EnterNameHere": {
        "en": "Enter name here",
        "de": "Name hier eingeben",
    },
    "MonitorAction:SelectTrigger": {
        "en": "Select trigger",
        "de": "Auslöser auswählen",
    },
    "MonitorAction:SelectTriggerType": {
        "en": "Select trigger type",
        "de": "Auslösertyp auswählen",
    },
    "MonitorAction:Idle": {
        "en": "Idle",
        "de": "Ruhezustand",
    },
    "MonitorAction:Alert": {
        "en": "Alert",
        "de": "Warnung",
    },
    "MonitorAction:Alarm": {
        "en": "Alarm",
        "de": "Alarm",
    },
    "MonitorAction:Once": {
        "en": "Once",
        "de": "Einmal",
    },
    "MonitorAction:Always": {
        "en": "Always",
        "de": "Immer",
    },
    "MonitorAction:SelectGroups": {
        "en": "Select groups",
        "de": "Gruppeen auswählen",
    },
    "MonitorAction:SelectTemplate": {
        "en": "Select template",
        "de": "Vorlage auswählen",
    },
    "MonitorAction:Close": {
        "en": "Close",
        "de": "Schliessen",
    },
    "MonitorAction:Save": {
        "en": "Save",
        "de": "Speichern",
    },
    "MonitorAction:AddedAction": {
        "en": "Added action",
        "de": "Aktion hinzugefügt",
    },
    "MonitorAction:Action": {
        "en": "Action",
        "de": "Aktion",
    },
    "MonitorAction:CreatedSuccessfully": {
        "en": "created successfully",
        "de": "erfolgreich erstellt",
    },
    "MonitorAction:FailedToCreateAction": {
        "en": "failed to create action",
        "de": "Aktion konnte nicht erstellt werden",
    },
    "MonitorAction:EditAction": {
        "en": "Edit action",
        "de": "Aktion bearbeiten",
    },
    "MonitorAction:ActionEditedSuccessfully": {
        "en": "Action edited successfully",
        "de": "Aktion erfolgreich bearbeitet",
    },
    "MonitorAction:FailedToEditAction": {
        "en": "Failed to edit action",
        "de": "Aktion konnte nicht bearbeitet werden",
    },
    "MonitorAction:UpdatedAction": {
        "en": "Updated action",
        "de": "Aktion aktualisiert",
    },
    "MonitorFunction:NoFunctionsFound": {
        "en": "No functions found",
        "de": "Keine Funktionen gefunden",
    },
    "MonitorFunction:PleaseContactTheOwner": {
        "en": "Please contact the organization owner to create functions",
        "de": "Bitte kontaktieren Sie den Organisationsinhaber, um Funktionen zu erstellen",
    },
    "MonitorFunction:CreateFunction": {
        "en": "Create function",
        "de": "Funktion erstellen",
    },
    "MonitorFunction:Name": {
        "en": "Name",
        "de": "Name",
    },
    "MonitorFunction:EnterNameHere": {
        "en": "Enter name here",
        "de": "Name hier eingeben",
    },
    "MonitorFunction:Language": {
        "en": "Language",
        "de": "Sprache",
    },
    "MonitorFunction:AddConfigurationSchemaHere": {
        "en": "Add configuration schema here",
        "de": "Konfigurationsschema hier hinzufügen",
    },
    "MonitorFunction:Close": {
        "en": "Close",
        "de": "Schliessen",
    },
    "MonitorFunction:Save": {
        "en": "Save",
        "de": "Speichern",
    },
    "MonitorFunction:AddedFunction": {
        "en": "Added function",
        "de": "Funktion hinzugefügt",
    },
    "MonitorFunction:Function": {
        "en": "Function",
        "de": "Funktion",
    },
    "MonitorFunction:CreatedSuccessfully": {
        "en": "created successfully",
        "de": "erfolgreich erstellt",
    },
    "MonitorFunction:FailedToCreateFunction": {
        "en": "Failed to create function",
        "de": "Funktion konnte nicht erstellt werden",
    },
    "MonitorFunction:AddEvents": {
        "en": "Add events",
        "de": "Ereignisse hinzufügen",
    },
    "MonitorFunction:AddedEvent" : {
        "en": "Added event",
        "de": "Ereignis hinzugefügt",
    },
    "MonitorFunction:FailedToAddEvent": {
        "en": "Failed to add event",
        "de": "Ereignis konnte nicht hinzugefügt werden",
    },
    "MonitorFunction:EventCreatedSuccessfully": {
        "en": "Event created successfully",
        "de": "Ereignis erfolgreich erstellt",
    },
    "MonitorFunction:AddEventHere": {
        "en": "Add event here",
        "de": "Ereignis hier hinzufügen",
    },
    "MonitorGroup:NoGroupsFound": {
        "en": "No groups found",
        "de": "Keine Gruppen gefunden",
    },
    "MonitorGroup:CreateGroup": {
        "en": "Create group",
        "de": "Gruppe erstellen",
    },
    "MonitorGroup:Name": {
        "en": "Name",
        "de": "Name",
    },
    "MonitorGroup:EnterNameHere": {
        "en": "Enter name here",
        "de": "Name hier eingeben",
    },
    "MonitorGroup:ContactTheOwner": {
        "en": "Please contact the organization owner to create groups",
        "de": "Bitte kontaktieren Sie den Organisationsinhaber, um Gruppen zu erstellen",
    },
    "MonitorGroup:Close": {
        "en": "Close",
        "de": "Schliessen",
    },
    "MonitorGroup:Save": {
        "en": "Save",
        "de": "Speichern",
    },
    "MonitorGroup:AddedGroup": {
        "en": "Added group",
        "de": "Gruppe hinzugefügt",
    },
    "MonitorGroup:Group": {
        "en": "Group",
        "de": "Gruppe",
    },
    "MonitorGroup:CreatedSuccessfully": {
        "en": "created successfully",
        "de": "erfolgreich erstellt",
    },
    "MonitorGroup:FailedToCreateGroup": {
        "en": "failed to create group",
        "de": "Gruppe konnte nicht erstellt werden",
    },
    "MonitorGroup:EditGroup": {
        "en": "Edit group",
        "de": "Gruppe bearbeiten",
    },
    "MonitorGroup:GroupEditedSuccessfully": {
        "en": "Group edited successfully",
        "de": "Gruppe erfolgreich bearbeitet",
    },
    "MonitorGroup:FailedToEditGroup": {
        "en": "Failed to edit group",
        "de": "Gruppe konnte nicht bearbeitet werden",
    },
    "MonitorGroup:UpdatedGroup": {
        "en": "Updated group",
        "de": "Gruppe aktualisiert",
    },
    "MonitorGroup:Description": {
        "en": "Description",
        "de": "Beschreibung",
    },
    "MonitorGroup:NotificationType": {
        "en": "Notification type",
        "de": "Benachrichtigungstyp",
    },
    "MonitorGroup:CreateNotificationGroup": {
        "en": "Create a notification group to automate the messages.",
        "de": "Erstellen Sie eine Benachrichtigungsgruppe, um die Nachrichten zu automatisieren.",
    },
    "MonitorGroup:VariablesAre": {
        "en": "Variables are defined as {{variable.name}}.",
        "de": "Variablen sind definiert als {{variablen.name}}.",
    },
    "MonitorTemplate:NoTemplatesFound": {
        "en": "No templates found",
        "de": "Keine Vorlagen gefunden",
    },
    "MonitorTemplate:CreateTemplate": {
        "en": "Create template",
        "de": "Vorlage erstellen",
    },
    "MonitorTemplate:PleaseContactTheOwner": {
        "en": "Please contact the organization owner to create templates",
        "de": "Bitte kontaktieren Sie den Organisationsinhaber, um Vorlagen zu erstellen",
    },
    "MonitorTemplate:Name": {
        "en": "Name",
        "de": "Name",
    },
    "MonitorTemplate:CreateNotificationTemplate": {
        "en": "Create a notification template to automate the messages.",
        "de": "Erstellen Sie eine Benachrichtigungsvorlage, um die Nachrichten zu automatisieren.",
    },
    "MonitorTemplate:VariablesAre": {
        "en": "Variables are defined as ",
        "de": "Variablen sind definiert als ",
    },
    "MonitorTemplate:MessageTitle": {
        "en": "Message title",
        "de": "Nachrichtentitel",
    },
    "MonitorTemplate:MessageBody": {
        "en": "Message body",
        "de": "Nachrichtentext",
    },
    "MonitorTemplate:Close": {
        "en": "Close",
        "de": "Schliessen",
    },
    "MonitorTemplate:Save": {
        "en": "Save",
        "de": "Speichern",
    },
    "MonitorTemplate:AddedTemplate": {
        "en": "Added template",
        "de": "Vorlage hinzugefügt",
    },
    "MonitorTemplate:Template": {
        "en": "Template",
        "de": "Vorlage",
    },
    "MonitorTemplate:CreatedSuccessfully": {
        "en": "created successfully",
        "de": "erfolgreich erstellt",
    },
    "MonitorTemplate:FailedToCreateTemplate": {
        "en": "failed to create template",
        "de": "Vorlage konnte nicht erstellt werden",
    },
    "MonitorTemplate:EditTemplate": {
        "en": "Edit template",
        "de": "Vorlage bearbeiten",
    },
    "MonitorTemplate:TemplateEditedSuccessfully": {
        "en": "Template edited successfully",
        "de": "Vorlage erfolgreich bearbeitet",
    },
    "MonitorTemplate:FailedToEditTemplate": {
        "en": "Failed to edit template",
        "de": "Vorlage konnte nicht bearbeitet werden",
    },
    "MonitorTemplate:UpdatedTemplate": {
        "en": "Updated template",
        "de": "Vorlage aktualisiert",
    },

    










}

export default SharedMonitorPageContentI18n
export type SharedMonitorPageContentI18nType = typeof SharedMonitorPageContentI18n
