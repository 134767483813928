import { routesWithDoc } from "@app/routes"
import { NavigationItem } from "@shared/components/page/navigation/context"
import {
  BookOpen
} from 'lucide-react'



export const setDocsNavigation = (navigation: NavigationItem, t) => {

  const doc: NavigationItem = {
    name: t['Navigation:Documentation'].get(),
    //link: '/docs',
    subItems: {
      "introduction": {
        name: t["Navigation:Introduction"].get(),
        link: "/docs/introduction",
        subItems: {}
      },
      "user": {
        name: "User",
        //link: "/docs/user",
        subItems: {
          "profile": {
            name: t["Navigation:Profile"].get(),
            link: "/docs/user/profile",
            subItems: {}
          },
          "account": {
            name: t["Navigation:Account"].get(),
            //link: "/docs/user/account",
            subItems: {
              "invitation": {
                name: t["Navigation:Invitation"].get(),
                link: "/docs/user/account/invitation",
                subItems: {}
              },
              "registration": {
                name: t["Navigation:Registration"].get(),
                link: "/docs/user/account/registration",
                subItems: {}
              }
            }
          }
        }
      },
      "org": {
        name: t["Navigation:Organization"].get(),
        //link: "/docs/org",
        subItems: {
          "introduction": {
            name: t["Navigation:Introduction"].get(),
            link: "/docs/org/introduction",
            subItems: {}
          },
          "auditlog": {
            name: t["Navigation:AuditLog"].get(),
            link: "/docs/org/auditlog",
            subItems: {}
          },
          "members": {
            name: t["Navigation:Members"].get(),
            link: "/docs/org/members",
            subItems: {}
          },
          "settings": {
            name: t["Navigation:Settings"].get(),
            //link: "/docs/org/settings",
            subItems: {
              "quota": {
                name: t["Navigation:Quota"].get(),
                link: "/docs/org/settings/quota",
                subItems: {}
              }
            }
          },
          "projects": {
            name: t["Navigation:Project"].get(),
            //link: "/docs/org/projects",
            subItems: {
              "auditlog": {
                name: t["Navigation:AuditLog"].get(),
                link: "/docs/org/projects/auditlog",
                subItems: {}
              },
              "members": {
                name: t["Navigation:Members"].get(),
                link: "/docs/org/projects/members",
                subItems: {}
              },
              "editor": {
                name: t["Navigation:ProjectOverview"].get(),
                link: "/docs/org/projects/editor",
                subItems: {}
              },
              "account": {
                name: t["Navigation:Account"].get(),
                //link: "/docs/org/projects/account",
                subItems: {
                  "quota": {
                    name: t["Navigation:Quota"].get(),
                    link: "/docs/org/projects/account/quota",
                    subItems: {}
                  }
                }
              },
              "thing": {
                name: t["Navigation:Devices"].get(),
                link: "/docs/org/projects/thing/thing",
                subItems: {}
              }
            }
          },
          "monitors": {
            name: t["Navigation:Monitor"].get(),
            //link: "/docs/org/monitors",
            subItems: {
              "notification": {
                name: t["Navigation:Notification"].get(),
                link: "/docs/org/monitors/notification",
                subItems: {}
              }
            }
          }
        }
      }
    },
    icon: <BookOpen />,
  }

  navigation.subItems.doc = doc
}

