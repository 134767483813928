import FadeInBox from "../../shared/FadeInBox"
import MainContainer from "../../shared/MainContainer"
import { Button, Input, LockIcon, Spacer, Text, Toast, useToast } from '@colombalink/basedui';
import { email as isEmail } from '@saulx/validators'
import { useLocalStorageContext } from "@shared/utils/react/LocalStorage/context";
import { useLocation } from "wouter";
import { useRef, useState } from 'react';
import { WithI18n } from "@shared/i18n/withI18n";
import i18n from "../../i18n";
import { useT } from "@app/i18n";
import { useIdentityClient } from "@shared/client/context";


export default () => (
    <WithI18n i18n={i18n}>
        <RegistrationPage />
    </WithI18n>
)

const RegistrationPage = () => {
    const t = useT()
    const client = useIdentityClient()
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [showCode, setShowCode] = useState<boolean>(false);
    const [code, setCode] = useState<string>();

    const passwordRef = useRef<HTMLInputElement>(null);
    const [, setLocation] = useLocation()
    const toast = useToast()

    const {
        value: globalInvitation,
    } = useLocalStorageContext('invitation')

    if (showCode) {
        return (
            <MainContainer>
                <FadeInBox>
                    <p className='w-full text-center font-bold text-4xl'>Welcome {name} to Monidas!</p>
                    <p className='w-full text-center'>We have sent you an e-mail to the address you provided {email}. In this e-mail you will find a 5-digit confirmation code.</p>
                    <Spacer />
                    <p className="w-full text-center  font-bold">Please enter the code below to activate your account</p>

                    <div className="flex justify-center space-x-2">
                        <Input
                            large
                            value={code}
                            type="text"
                            placeholder="Enter your code"
                            onChange={(code) => setCode(code)} />

                        <Spacer />

                    </div>

                    <Spacer />

                    <Button
                        large
                        fill
                        style={{
                            marginBottom: 24,
                            height: 48,
                        }}
                        textAlign="center"
                        keyboardShortcut="Enter"

                        onClick={async () => {
                            try {

                                const isCodeValid = await client.call("login:registration:verifyCode", { token: globalInvitation, code });
                            
                                if (isCodeValid) {
                                  //  console.log("Account wurde erstellt")
                                    await client.call("login:registration:new", { token: globalInvitation, user: { password, email, name, firstName, lastName } });
                                    setLocation(`/invitation/login`)
                                }

                            } catch (e) {
                                toast.add(
                                    <Toast
                                        label="Failed to resgister."
                                        type="error"
                                        description={
                                            <Text style={{ whiteSpace: 'normal' }}>
                                                {e.message}
                                            </Text>
                                        }
                                    />)
                                throw e
                            }
                        }
                        }
                    >
                        Activate Account
                    </Button>

                </FadeInBox>
            </MainContainer>
        )
    }

    return (
        <MainContainer>
            <FadeInBox>
                <Input
                    large
                    label={t['Login:Username'].get()}
                    value={name}
                    type="text"
                    placeholder={t['Login:UsernamePlaceholder'].get()}
                    onChange={(str) => setName(str)}
                />
                <Spacer />

                <Input
                    large
                    label={t['Login:FirstName'].get()}
                    value={firstName}
                    type="text"
                    placeholder="Enter your first name"
                    onChange={(str) => setFirstName(str)} />

                <Spacer />
                <Input
                    large
                    label={t['Login:LastName'].get()}
                    value={lastName}
                    type="text"
                    placeholder={t['Login:LastNamePlaceholder'].get()}
                    onChange={(str) => setLastName(str)} />

                <Spacer />
                <Input
                    large
                    label={t['Login:Email'].get()}
                    value={email}
                    type="email"
                    placeholder={t['Login:EmailPlaceholder'].get()}
                    onChange={(str) => setEmail(str)}
                />
                <Spacer />
                <Input
                    label={t['Login:Password'].get()}
                    large
                    inputRef={passwordRef}
                    icon={LockIcon}
                    type="password"
                    placeholder={t['Login:PasswordPlaceholder'].get()}
                    onChange={(password) => {
                        setPassword(password)
                    }}
                    style={{ marginBottom: 12 }}
                />

                <Button
                    large
                    fill
                    style={{
                        marginBottom: 24,
                        height: 48,
                    }}
                    textAlign="center"
                    keyboardShortcut="Enter"
                    disabled={!isEmail(email) || password.length < 3}
                    onClick={async () => {
                        try {

                            await client.call('login:registration:validateUserInfo', { token: globalInvitation, user: { password, email, name, firstName, lastName } });
                            console.log("User info valid")
                            await client.call("login:registration:sendCode", { token: globalInvitation, firstName, lastName, email });
                            setShowCode(true);
                        } catch (e) {
                            toast.add(
                                <Toast
                                    label={t['Login:RegistrationFailed'].get()}
                                    type="error"
                                    description={
                                        <Text style={{ whiteSpace: 'normal' }}>
                                            {e.message}
                                        </Text>
                                    }
                                />)
                            throw e
                        }
                    }
                    }
                >
                    {t['Login:Register'].get()}
                </Button>
            </FadeInBox>
        </MainContainer>
    );
}

