import { useDialog } from "@shared/components/core/useDialog"
import CreateNewProjectDialog from "./dialogs/AddDialog"
import { Role } from "@server/shared"
import { api } from "@shared/index"
import { useRouteParams } from "@shared/router"
import { NoItemGuidance } from "@shared/components/page/generic/NoItemGuidance"
import { useEffect, useState } from "react"
import { LoadingIcon } from '@shared/components/core/LoadingIcon'
import { CustomizableButton } from "@shared/components/core/CustomizableButton"
import { WithI18n } from "@shared/i18n/withI18n"
import i18n from "./i18n"
import { useT } from "@app/i18n"

const _ProjectLandingFragment = ({ children }) => {
    const t = useT()
    const createProjectDialog = useDialog(<CreateNewProjectDialog />)
    const { orgAliasId } = useRouteParams<{ orgAliasId: string }>()
    const roles = api.user.getRoles.useLiveData()
    const [hasProjects, setHasProjects] = useState<boolean>(null)
    useEffect(() => {
        if (!roles.data?.asArray) {
            return
        }
        const hasProjects = roles.data.asArray.some((role) => {
            if (Array.isArray(role)
                && role[0].startsWith(orgAliasId)
                && role[0].split("/").length > 3
            ) {
                return true
            }

        })

        setHasProjects(hasProjects)

    }, [roles.data?.asArray, orgAliasId])



    if (roles.error) {
        throw roles.error
    }


    if (roles.loading || hasProjects === null) {
        return <LoadingIcon />
    }


    if (hasProjects) {
        return children
    }

    const orgRole = roles.data[orgAliasId] as Role
    if (orgRole === "OWNER") {
        return (
            <NoItemGuidance>

                {
                    t["SProject:NoProjectsFound"].get()
                }
                {
                    t["SProject:CreateYourFirstProject"].get()
                }
                <CustomizableButton
                    onClick={async () => {
                        await createProjectDialog.open()
                    }}
                >
                    {
                        t["SProject:CreateProject"].get()
                    }
                </CustomizableButton>
            </NoItemGuidance>
        )
    }

    // should at least have an orgRole otherwise they should not even see that page
    // or it does not exist for the user
    if(orgRole === undefined){
        throw new Error("The organization you are looking for does not exist or you do not have access to it.")
    }

    return (
        <NoItemGuidance
        >
            {
                t["SProject:NoProjectsFound"].get()
             
            }
            {
                t["SProject:PleaseContact"].get()
            }
        </NoItemGuidance>
    )

}

export const ProjectLandingFragment = (props) => (
    <WithI18n i18n={i18n}>
        <_ProjectLandingFragment {...props} />
    </WithI18n>
)