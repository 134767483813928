import EditProject from "../../../../../shared/components/project/dialogs/EditDialog"
import { FC, useState } from "react"
import { OptionMenu } from "@shared/components/core/EditEntityMenu"
import { api } from "@shared/index"
import { useDialog, useToast, ContextItem, Toast, Text, EditIcon, DeleteIcon } from "@colombalink/basedui"
import { useLocation } from "wouter"
import ConfirmationDialog from "@shared/components/core/ConfirmationDialog"
import { useT } from "@app/i18n"

export const RowMenu = ({ projectAliasId, name }) => {

  const CategoryMenu: FC<{}> = ({ }) => {
    const t = useT()
    const { open } = useDialog()
    const [, setLocation] = useLocation()
    const toast = useToast()
    const [hidden, setHidden] = useState('hidden')

    const deleteProject = api.project.deleteById.useMutation()

    return (
      <>
        <ContextItem
          onClick={() => {
            open(<EditProject projectAliasId={projectAliasId} />)
          }}
          icon={EditIcon}
        >
          {t["ProjectsEditor:Edit"].get()}
        </ContextItem>
        <ContextItem
          onClick={async () => {

            open(<ConfirmationDialog
              label={t["ProjectsEditor:DeleteProject"].get()}
              message={t["ProjectsEditor:DeleteProjectConfirmation"].get() + name + "?"}
              confirm={t["ProjectsEditor:Delete"].get()}
              onConfirm={async () => {
                await deleteProject.mutateAsync({ projectAliasId })
                toast.add(
                  <Toast
                    label={t["ProjectsEditor:ProjectDeleted"].get()}
                    type="success"
                    description={
                      <Text>
                        {t["ProjectsEditor:DeleteProjectSuccess"].get()}
                      </Text>
                    }
                  />
                )

              }}
            ></ConfirmationDialog>)
          }}
          icon={DeleteIcon}
        >
          {t["ProjectsEditor:Delete"].get()}
        </ContextItem>
      </>
    )
  }

  return <OptionMenu menu={CategoryMenu} style={{ width: '150px' }} />
}