
const HomePageContentI18n = {
    "UserHome:Organizations": {
        "de": "Organisationen:",
        "en": "Organizations:",
    },
    "UserHome:ProjectTitle": {
        "de": "Projekt:",
        "en": "Project:",
    },
    "UserHome:ProjectTitle_zero": {
        "de": "Es wurden keine Projekte gefunden.",
        "en": "No projects found.",
    },
    "UserHome:ProjectTitle_other": {
        "de": "Projekte:",
        "en": "Projects:",
    },
    "UserHome:Alarm": {
        "en": "Alarm",
        "de": "Alarm",
    },
    "UserHome:ThingTitle": {
        "de": "Geräte:",
        "en": "Devices:",
    },
    "UserHome:ThingTitle_zero": {
        "de": "Es wurden keine Geräte gefunden.",
        "en": "No devices found.",
    },
    "UserHome:ThingTitle_other": {
        "de": "Geräte:",
        "en": "Devices:",
    },
    "UserHome:NoAlarms": {
        "en": "No alarms found",
        "de": "Keine Alarme gefunden",
    },
    "UserHome:Loading": {
        "en": "Loading...",
        "de": "Laden...",
    },
    "UserHome:CancelAlarm": {
        "en": "Cancel",
        "de": "Quittieren",
    },
    "UserHome:DeactivateAlarm": {
        "en": "Deactivate",
        "de": "Deaktivieren",
    },
    "UserHome:MonitorName": {
        "en": "Monitor name",
        "de": "Monitorname",
    },
    "UserHome:MonitorStatus": {
        "en": "Status",
        "de": "Status",
    },
    "UserHome:MonitorStatusMessage": {
        "en": "Status message",
        "de": "Statusmeldung",
    },
    "UserHome:CanceledAlarm": {
        "en": "Canceled alarm",
        "de": "Alarm quittiert",
    },
    "UserHome:CancelAlarmSuccessfully": {
        "en": "Canceled successfully",
        "de": "Erfolgreich quittiert",
    },
    "UserHome:DeactivatedAlarm": {
        "en": "Deactivated alarm",
        "de": "Deaktivierter Alarm",
    },
    "UserHome:DeactivateAlarmSuccessfully": {
        "en": "Deactivated successfully",
        "de": "Erfolgreich deaktiviert",
    },
}


export default HomePageContentI18n
export type HomePageContentI18nType = typeof HomePageContentI18n
