import { api } from '@shared/index'
import routes, { useLink } from '../routes'
import {
  NavigationItem,
} from '@shared/components/page/navigation/context'
import {
  Building2Icon,
  SettingsIcon,
  StickyNoteIcon,
  User2Icon,
  UserCircleIcon,
} from 'lucide-react'
import { Role } from '@server/shared'

const notifications = ({ userAliasId }, t) => ({
  notifications: {
    name: t['Navigation:Notifications'].get(),
    link: useLink('/user/:userAliasId/notifications', { userAliasId }),
    subItems: {},
    icon: <StickyNoteIcon />,
  }
})

const profile = ({ userAliasId }, t) => ({
  profile: {
    name: t['Navigation:Profile'].get(),
    link: useLink('/user/:userAliasId/profile', { userAliasId }),
    subItems: {},
    icon: <UserCircleIcon />,
  }
})

const settings = ({ userAliasId }, t) => ({
  settings: {
    name: t['Navigation:Settings'].get(),
    link: useLink('/user/:userAliasId/settings', { userAliasId }),
    subItems: {},
    icon: <SettingsIcon />,
  }
});

const organizations = ({ userAliasId }, t) => ({
  org: {
    name: t['Navigation:Organizations'].get(),
    link: useLink('/user/:userAliasId/organizations', { userAliasId }),
    subItems: {},
    icon: <Building2Icon />,
  }
});

export function setUserNavigationSubItems(
  navigation: NavigationItem,
  user: ReturnType<typeof api.user.getUserNav.useLiveData>['data'],
  t
) {
  const userAliasId = user.alias
  //  console.log('setUserNavigationSubItems', userAliasId)
  navigation.subItems.user = {
    name: user.name,
    link: routes['/'].path,
    parent: navigation,
    active: false,
    icon: <User2Icon />,
    subItems: {
      ...organizations({ userAliasId }, t),
      // ...notifications({ userAliasId }),
      ...profile({ userAliasId }, t),
      ...settings({ userAliasId }, t),
    },
  }
}