import { useTranslation } from 'react-i18next';

type UseTranslationOptions<KPrefix, Args extends Record<string, any> | undefined = Record<string, any> | undefined> = {
  count?: number;
  defaultValue?: string;
  replace?: Record<string, string>;
  lng?: string;
  ns?: string;
} & Args;


type WithoutArgs = Record<string, any>
type WithArgs = Record<string, { args?: Record<string, any> }>

export const useTypedTranslation = <Keys extends WithoutArgs | WithArgs >() => {
  const { t } = useTranslation();

  return new Proxy({}, {
    get: (_, key: keyof Keys) => {
      return {
        get: (options?: UseTranslationOptions<typeof key, Keys[typeof key]['args']>) => t(key as string, {...options})
      };
    }
  }) as ({ [K in keyof Keys]: { get: (options?: UseTranslationOptions<K, Keys[K]['args']>) => string } });
}