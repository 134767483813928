import { useRouteParams } from '../../../../shared/router'
import { Menu } from '@colombalink/basedui'
import { Page } from '@shared/components/page/Page'
import DefaultNavigation from '@shared/components/page/navigation/DefaultNavigation'
import { useLocation } from 'wouter'
import { Fragment, useEffect, useState } from 'react'
import { TrendsFragment } from './InfoFragments/TrendsFragment'
import {
  ProjectDashboardContext,
  useProjectDashboardContext,
} from './DashboardContext'
import { LatestFragment } from './InfoFragments/LatestFragment'
import { StatusFragment } from './StatusFragment'
import { ExplorerFragment } from './ExplorerFragment'
import {
  ProjectExplorerContext,
  useProjectExplorerContext,
} from './ExplorerFragment/ExplorerContext'
import { ThingLandingFragment } from '@shared/components/thing/LandingFragment'
import i18n from './i18n'
import { WithI18n } from '@shared/i18n/withI18n'
import { useT } from '@app/i18n'

type PageProps = { params: { orgAliasId: string; projectAliasId: string } }
export type PageParams = PageProps['params']

const ProjectPage = (props: PageProps) => {
  return (
    <ProjectDashboardContext projectAliasId={props.params.projectAliasId}>
      <ProjectExplorerContext projectAliasId={props.params.projectAliasId}>
        <Page className="pl-4">
          <DefaultNavigation />
          <Main {...props} />
        </Page>
      </ProjectExplorerContext>
    </ProjectDashboardContext>
  )
}

const Main = (props: PageProps) => {
  return (
    <WithI18n i18n={i18n}>
      <ManageOrganizationProjectsMain />
    </WithI18n>
  )
}
Main.displayName = 'Main'

export default ProjectPage

const ManageOrganizationProjectsMain = () => {
  const { orgAliasId, projectAliasId } = useRouteParams<{
    projectAliasId: string
    orgAliasId: string
  }>()
  const { things } = useProjectDashboardContext()
  const explorerContext = useProjectExplorerContext()

  const [location, setLocation] = useLocation()
  const [active, setActive] = useState('status')
  const t = useT()

  useEffect(() => {    
    if (window.location.hash.length < 1) {
      setLocation(`${location}#status`)
      return
    }
    setActive(window.location.hash.replace('#', ''))
  }, [location])

  useEffect(() => {
    // update filter
    if(['explorer-daily', 'explorer-monthly', 'explorer-yearly', 'explorer-live'].includes(active)) {
      explorerContext.filters.timeFrame.set(active.split('-')[1] as any)
    }
  }, [active])

  if (things.error) {
    throw things.error
  }

  if (things.loading) {
    return <div>Loading...</div>
  }

  if (!things.array) {
    return <div>Device not found</div>
  }

  const switchFragment = (newFragment) => {
    setLocation(`${location}#${newFragment}`)
    setActive(newFragment);
  };

  return (
    <ThingLandingFragment>
      <div className="h-full flex">
        <div>
          <Menu
            style={{
              padding: '10px 14px 0px 0px',
              width: '140px',
            }}
            collapse
            active={active}
            onChange={(page) => {
              if(["Dashboard", "Explorer"].includes(page)) {
                return 
              }
              const newLocation = location.split('#')[0] + `#${page}`
              setActive(page)
              setLocation(newLocation)
            }}
            data={{
              Dashboard: {
                status: t['Project:Status'].get(),
                trends: t['Project:Trends'].get(),
                latest: t['Project:Latest'].get(),
              },
              ...(things.array.length > 0 && {
                Explorer: {
                  "explorer-live": t['Project:Live'].get(),
                  "explorer-daily": t['Project:Daily'].get(),
                  "explorer-monthly": t['Project:Monthly'].get(),
                  "explorer-yearly": t['Project:Yearly'].get(),
                },
              }),
            }}
          />
        </div>

        <div className="w-full ml-4 h-full overflow-auto">
          <FragmentSwitch active={mapActiveToFragmetKey(active)}>
            <StatusFragment key={'status'} switchFragment={switchFragment}/>
            <TrendsFragment key={'trends'} />
            <LatestFragment key={'latest'} />
            <ExplorerFragment key={'explorer'} />
          </FragmentSwitch>
        </div>
      </div>
    </ThingLandingFragment>
  )
}


function mapActiveToFragmetKey(active){
    if(['explorer-daily', 'explorer-monthly', 'explorer-yearly', 'explorer-live'].includes(active)) {
      return 'explorer' 
    }
    return active
}


const FragmentSwitch = ({ active, children }: { active: string; children }) => {
  let ActiveFragment = null
  if (children instanceof Array === false) {
    children = [children]
  }
  for (let child of children) {
    if (child.key === active) {
      ActiveFragment = child
      break
    }
  }
  if (!ActiveFragment) {
    ActiveFragment = <>Sorry. We did not find this page.</>
  }
  return <Fragment>{ActiveFragment}</Fragment>
}
