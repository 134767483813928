const SharedUserPageContentI18n = {
    'SUser:CreateOrganization': {
        'de': 'Organisation Erstellen',
        'en': 'Create Organization',
    },
    'SUser:AddedOrganization': {
        'de': 'Organisation hinzugefügt',
        'en': 'Added organization',
    },
    "SUser:FailedToCreateOrganization": {
        "en": `Failed to create organization`,
        "de": `Organisation konnte nicht erstellt werden`
    },
    "SUser:Name": { 
        "en": `Name`,
        "de": `Name`
    },
    "SUser:Organization": {
        "en": `Organization`,
        "de": `Organisation`
    },
    "SUser:Path": {
        "en": `Path`,
        "de": `Pfad`
    },
    "SUser:Close": {
        "en": `Close`,
        "de": `Schliessen`
    },
    "SUser:Save": {
        "en": `Save`,
        "de": `Speichern`
    },
    "SUser:CreatedSuccessfully": {
        "en": `created successfully`,
        "de": `erfolgreich erstellt`
    },
    "SUser:NotPart": {
        "en":  `You are not part of any organization yet.
        Create your first organization.`,
        "de": `Du bist noch keiner Organisation beigetreten.
        Erstelle deine erste Organisation.`
    },
    "SUser:Create": {
        "en": `Create`,
        "de": `Erstellen`
    },
    "SUser:NoProjects": {
        "en": `No projects found.
        Please contact the project owner to create and invite you to a project.`,
        "de": `Keine Projekte gefunden.
        Bitte kontaktiere den Projektinhaber um ein Projekt zu erstellen und dich einzuladen.`
    },






}

export default SharedUserPageContentI18n
export type SharedUserPageContentI18nType = typeof SharedUserPageContentI18n
