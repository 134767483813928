import { useProjectDashboardContext } from "@app/pages/Orgs/Project/DashboardContext"
import { useThingContext } from "@app/pages/Orgs/Thing/Fragments/Latest/context"
import { LoadingIcon } from '@shared/components/core/LoadingIcon'
import { getValueOfGenericProperty } from "@server/app"
import { Gauge } from "@shared/components/core/Gauge"
import { WithI18n } from "@shared/i18n/withI18n"
import i18n from "../i18n"
import { useT } from "@app/i18n"
import { log } from '@shared/logger'

const useLatestContainerContext = ({thingId}: {thingId?: string}) => {
    try {
        const ctx = useProjectDashboardContext()
        return {
            loading: ctx.things.loading,
            useThingProperty: (property: string) => ctx.useThingProperty(thingId, property), 
            properties:  {
                keys: ctx.useThingToUnitsKey[0][thingId] || [],
            },
            lastEvent: ctx.things.withLastEvent.find(t => t.id === thingId)?.lastEvent
        }
    } catch (error) {}
   
    try {
        const ctx = useThingContext()
        return {
            loading: ctx.loading,
            useThingProperty: (property: string) => ctx.useThingProperty(property), 
            properties:  {
                keys: Object.keys(ctx.properties) 
            },
            lastEvent: ctx.lastEvent 
        }
    } catch(e) {
        //console.log('error', e)
        log.error(e)

    }

    throw new Error('useLatestContainerContext: context is undefined')
}


const _LatestThingContainer = ({ thingId }: { thingId?: string }) => {
    const t = useT()
    const { properties, lastEvent, loading} = useLatestContainerContext({thingId})
    // console.log('thingsToFields', thingsToFields[thingId])

    if(loading) {
        return <div> {t["SThing:Loading"].get()}  <LoadingIcon/> </div>
    }

    if (properties.keys.length === 0) {
        return <div> {t["SThing:NoPropertiesFoundInThisDevice"].get()} </div>
    }
    if (!lastEvent) {
        return <div> {t["SThing:NoDataFoundForThisDevice"].get()} </div>
    }
    return (
        <ul role="list" className='w-full grid grid-cols-[180px_180px] md:grid-cols-[180px_180px_180px_180px] lg:grid-cols-[180px_180px_180px_180px_180px] gap-3 '
        >
            {properties.keys.map((field, index) => (
                <li
                    key={index}
                    className="bg-white rounded-lg shadow-lg border border-gray-300"
                >
                    {<StatsCard property={field} thingId={thingId}></StatsCard>}
                </li>
            ))}
        </ul>
    )
}

const StatsCard = ({ property, thingId }) => {

    const {
        lastEvent,
        useThingProperty
    } = useLatestContainerContext({thingId}) 


    const value = getValueOfGenericProperty(lastEvent.value, property)
    const statusBasedOnIdealRange = useThingProperty(property).getStatus()

    const propertyObject = useThingProperty(property).getProperty()
   // console.log('units', propertyObject)


    return (
        <div className="relative flex justify-center items-center" style={{ minHeight: '180px' }}>
            <div className="absolute z-10 w-full px-4">
                <div className="flex flex-col items-center">
                    <h3 className="w-[152px] text-lg font-medium text-gray-900 overflow-hidden whitespace-nowrap text-ellipsis">
                        {propertyObject.label.en}
                    </h3>
                    <br />
                    <div className="text-lg text-gray-500 h-[110px] w-[140px] mt-4">

                        {value === undefined && (<>NaN</>)}

                        {
                            value && (
                                propertyObject.primitiveDataType === 'int' ||
                                propertyObject.primitiveDataType === 'float')
                                ? (
                                    <Gauge
                                        units={propertyObject.unit.symbol || ''}
                                        value={
                                            truncateFloat(
                                                getValueOfGenericProperty(
                                                    lastEvent.value,
                                                    property
                                                ),
                                                propertyObject.primitiveDataType,
                                                2
                                            )
                                        }
                                        color={statusBasedOnIdealRange}
                                        min={propertyObject.idealRange?.min || 0}
                                        max={propertyObject.idealRange?.max || 100}
                                    />
                                ) : (
                                    <div className="flex flex-col h-[130px] justify-start items-center">
                                        <div className="block items-center w-[130px] h-[110px] overflow-auto">
                                            {getValueOfGenericProperty(lastEvent.value, property)}
                                        </div>
                                    </div>
                                )}
                    </div>
                </div>
            </div>
        </div>

    )
}


function truncateFloat(value: number, type: string, decimals: number) {
    if (type !== 'float' || Number.isInteger(value)) {
        return value
    }

    return value.toFixed(decimals) as unknown as number
}

export const LatestThingContainer = (props) => (
    <WithI18n i18n={i18n}>
        <_LatestThingContainer {...props} />
    </WithI18n>
  )