import React, { PropsWithChildren, createContext, useContext, useState } from 'react'

function useProvideContext() {
  const useEditMode = useState(false)
  return {
    useEditMode
  }
}

type ContextType = ReturnType<typeof useProvideContext>
const Context = createContext<ContextType | undefined>(undefined)


type Props = {
} & PropsWithChildren

const FragmentContext: React.FC<Props> = ({ children }) => {
  const value = useProvideContext()


  return <Context.Provider value={value}>{children}</Context.Provider>
}

const useFragmentContext = (): ContextType => {
  const context = useContext(Context)
  if (!context) {
    //TODO: need to have a multilingual error
    throw new Error('useFragmentContext must be used within a FragmentContextProvider')
  }
  return context
}

export { useFragmentContext, FragmentContext }
