import React, { createContext, useEffect, useReducer, useState } from 'react'
import { hashQueryKey } from '@shared/trpc/shared/utils'
import { TRPCUntypedClient, createTRPCUntypedClient } from '@trpc/client'
import { resultReducer } from './reducer'
import { OperationScope } from '@shared/basedLink'

type TRPCProviderProps = { 
    children, 
    scope: OperationScope, 
    client: TRPCUntypedClient<any>, 
    setClient: (c: TRPCUntypedClient<any>) => void 
  }

/**
 * @internal
 */
export function createRootHooks() {
  const Context = createContext(null as any)
  const createClient = (opts) => {
    return createTRPCUntypedClient(opts) 
  }

  const TRPCProvider = ({ client, setClient, children, scope }: TRPCProviderProps) => {
    // console.log("TRPCProvider", props)
    const useScope = useState(scope || {})
    
    return (
      <Context.Provider
        value={{
          client,
          setClient,
          scope: {
            set: (newScope) => {
              useScope[1](newScope)
            },
            get: () => useScope[0],
          }
        }}
      >
        {children}
      </Context.Provider>
    )
  }

  function useContext() {
    // console.log("useContext", Context)
    return React.useContext(Context)
  }

  function useLiveData(
    pathAndInput: [
      // FIXME: tuple me in next major
      path: string,
      ...args: unknown[]
    ]
  ) {
    const [result, dispatch] = useReducer(resultReducer, {
      loading: true,
      data: null,
      checksum: 0,
    })
    const queryKey = hashQueryKey(pathAndInput)
    const { client } = useContext()
    const scope  = useContext().scope.get()

    useEffect(() => {
      const [path, input] = pathAndInput
      //  console.log("useLiveData", path, input)
      const subscription = client.subscription(
        path,
        (input ?? undefined) as any,

        {
          context: { scope },
          onStarted: () => {
            dispatch({ error: null, loading: true, data: null })
          },
          onData: (data) => {
            dispatch({ error: null, loading: false, data })
          },
          onError: (error) => {
            dispatch({ error, loading: false })
          },
        }
      )


      return () => {
        subscription.unsubscribe()
      }
    }, [queryKey, client])

    return result
  }

  function useData(pathAndInput: [path: string, ...args: unknown[]]) {
    const { client }: { client } = useContext()
    const scope  = useContext().scope.get()

    const [result, dispatch] = useReducer(resultReducer, {
      loading: true,
      data: null,
      checksum: 0,
    })
    const queryKey = hashQueryKey(pathAndInput)

    useEffect(() => {
      const [path, input] = pathAndInput
      //  console.log("useLiveData", path, input)

      dispatch({ error: null, loading: true, data: null })
      client
        .query(path, (input ?? undefined) as any, {context: { scope }})
        .then((data) => {
          dispatch({ error: null, loading: false, data })
        })
        .catch((error) => {
          dispatch({ error, loading: false })
        })

      return () => { }
    }, [queryKey, client])

    return result
  }

  function useMutation(pathAndInput: [path: string, ...args: unknown[]]) {
    const { client }: { client } = useContext()
    const scope  = useContext().scope.get()

    const [path] = pathAndInput
    return {
      mutateAsync: (input) => {
        return client.mutation(path, (input ?? undefined) as any, { context: { scope }})
      },
    }
  }

  return {
    Provider: TRPCProvider,
    createClient,
    useContext,
    useLiveData,
    useData,
    useMutation,
  }
}
