import React, { useState } from 'react'
import { Dialog, Input, Select, Spacer, Text, Toast, useToast } from '@colombalink/basedui'
import { ZodInputValidator } from '@shared/zod/InputValidator'
import { api } from '@shared/index'
import { useRouteParams } from '../../../../shared/router'
import { useT } from '@app/i18n'
import { createIntegrationParams } from '@server/app'

type PageProps = { params: { orgAliasId: string } }
export type  PageParams = PageProps['params']


const AddNewIntegrationToOrgDialog = () => {
  const t = useT()
  const { orgAliasId } = useRouteParams<PageParams>()
  const createIntegration = api.integrations.create.useMutation()
  const [createIntegrationArgs, setInviteMemberArgs] = React.useState({ type: 'integrationChirpstack', name: '', url: '', token: '', orgAliasId, })
  const toast = useToast()
  const [integrationType, setIntegrationType] = useState('integrationChirpstack')
  
  return (
    <Dialog label= {t['OrgIntegration:AddIntegration'].get()}>
      <div>
        <Select options={[{ label: 'Chirpstack', value: 'integrationChirpstack' }]}
          value={integrationType}
          onChange={(type: string) => {
            setInviteMemberArgs({ ...createIntegrationArgs, type })
            setIntegrationType(type)
          }}
        ></Select>

        {
          integrationType === 'integrationChirpstack' && (
            <>
              <Spacer />
              <Input
                type='text'
                label={t['OrgIntegration:Name'].get()}
                value={createIntegrationArgs.name}
                onChange={(name: string) => {
                  setInviteMemberArgs({ ...createIntegrationArgs, name })
                }}

                pattern="true"
                error={(value) => ZodInputValidator(createIntegrationParams, createIntegrationArgs, "name")}
              />
              <Input
                type='text'
                label="Url"
                value={createIntegrationArgs.url}
                onChange={(url: string) => {
                  setInviteMemberArgs({ ...createIntegrationArgs, url })
                }}

                pattern="true"
                error={(value) => ZodInputValidator(createIntegrationParams, createIntegrationArgs, "url")}
              />

              <Input
                type='text'
                label="Token"
                value={createIntegrationArgs.token}
                onChange={(token: string) => {
                  setInviteMemberArgs({ ...createIntegrationArgs, token })
                }}

                pattern="true"
                error={(value) => ZodInputValidator(createIntegrationParams, createIntegrationArgs, "token")}
              />
            </>
          )
        }

        <Dialog.Buttons>
          <Dialog.Cancel displayShortcut={false}>{t['OrgIntegration:Cancel'].get()}</Dialog.Cancel>
          <Dialog.Confirm

            disabled={!!ZodInputValidator(createIntegrationParams, createIntegrationArgs)}
            displayShortcut={false} onConfirm={async () => {

              try {
                await createIntegration.mutateAsync(createIntegrationArgs)
                toast.add(
                  <Toast
                    label= {t['OrgIntegration:AddedIntegration'].get()}
                    type="success"
                    description={
                      <Text>
                        {t['OrgIntegration:Integration'].get()} <b>{createIntegrationArgs.name}</b> {t['OrgIntegration:CreatedSuccessfully'].get()}
                      </Text>
                    }
                  />
                )
              } catch (e) {
                toast.add(
                  <Toast
                    label= {t['OrgIntegration:FailedToAddIntegration'].get()}
                    type="error"
                    description={
                      <Text style={{ whiteSpace: 'normal' }}>
                        {e.message}
                      </Text>
                    }
                  />
                )
                throw e
              }
            }}>{t['OrgIntegration:Save'].get()}</Dialog.Confirm>
        </Dialog.Buttons>
      </div>
    </Dialog >
  )
}

export default AddNewIntegrationToOrgDialog

