import { ReactNode } from "react"

export const NoItemGuidance = ({ children }: { children: ReactNode }) => {

    if (!children) {
        throw new Error('No children provided')
    }
    if (!Array.isArray(children)) {

        return (
            <div className="flex flex-col h-full items-center justify-center">
                <div className="flex flex-col items-center text-center font-bold py-4 max-w-2xl">
                    {children}
                </div>
            </div>
        )
    }

    return (
        <div className="flex flex-col h-full items-center justify-center">
            {children.map((child, index) => {
                if (child?.type?.displayName === 'CustomizableButton') {
                    const Button = child.type(child.props)
                    return (
                        <div key={index} className="flex flex-col items-center text-center font-bold py-2 w-full max-w-2xl ">
                            {
                                <Button className="w-full md:w-auto" />
                            }
                        </div>
                    )
                }

                if(typeof child === 'string') {
                    const children = child.split('\n')
                    return children.map((child, index) => {
                        return (
                            <div key={index} className="flex flex-col items-center text-center font-bold py-2 w-full max-w-2xl ">
                                {child}
                            </div>
                        )
                    })
                }

                return (
                    <div key={index} className="flex flex-col items-center text-center font-bold py-2 w-full max-w-2xl ">
                        {child}
                    </div>
                )
            })
            }
        </div>
    )
}