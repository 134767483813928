import {
  Checkbox,
  Dialog,
  Input,
  Text,
  Toast,
  useToast,
} from '@colombalink/basedui'
import React, { useEffect, useState } from 'react'
import { api } from '@shared/index'
import '../toolTip.css'
import { useT } from '@app/i18n'

type Props = {
  monitorId: string
}

export const EditMonitorRegularUserDialog = ({ monitorId }: Props) => {
  const t = useT()
  const updateMonitor = api.monitor.updateMonitorConfig.useMutation()
  const toast = useToast()
  const [jsonSchema, setJsonSchema] = useState<any>()
  const [formData, setFormData] = useState(null)

  return (
    <Dialog label={jsonSchema?.title}>
      <ShowErrorIfJsonSchemaIsInvalid
        monitorId={monitorId}
        setJsonSchema={setJsonSchema}
        jsonSchema={jsonSchema}
        setFormData={setFormData}
        formData={formData}
      >
        <JsonSchemaForm
          jsonSchema={jsonSchema}
          formData={formData}
          setFormData={setFormData}
        ></JsonSchemaForm>
      </ShowErrorIfJsonSchemaIsInvalid>
      <Dialog.Buttons border>
        <Dialog.Cancel>Close</Dialog.Cancel>
        <Dialog.Confirm
          onConfirm={async () => {
            await updateMonitor.mutateAsync({
              id: monitorId,
              config: formData,
            })
            toast.add(
              <Toast
                label={t['ThingsMonitors:UpdateMonitor'].get()}
                type="success"
                description={
                  <Text> {t['ThingMonitors:UpdatedSuccessfully'].get()} </Text>
                }
              />
            )
          }}
        >
          {t['ThingMonitors:Confirm'].get()}{' '}
        </Dialog.Confirm>
      </Dialog.Buttons>
    </Dialog>
  )
}

const ShowErrorIfJsonSchemaIsInvalid = ({
  monitorId,
  children,
  setJsonSchema,
  jsonSchema,
  setFormData,
  formData,
}) => {
  const t = useT()
  const monitor = api.monitor.getAlarmById.useLiveData({ id: monitorId })
  const [parseError, setParseError] = useState(false)
  useEffect(() => {
    try {
      if (!monitor.data) {
        return
      }
      setJsonSchema(JSON.parse(monitor.data.ruleFunction.userConfigSchema))
    } catch (e) {
      setParseError(true)
    }
  }, [monitor?.data?.ruleFunction?.userConfigSchema])

  useEffect(() => {
    if (!jsonSchema) {
      return
    }

    setFormData(getInitialFormData(jsonSchema, monitor.data?.ruleConfig))
  }, [jsonSchema])

  useEffect(() => {
    if (!jsonSchema) {
      return
    }

    setFormData(getInitialFormData(jsonSchema, monitor.data?.ruleConfig))
  }, [monitor.data?.ruleConfig])

  if (!jsonSchema || !formData) {
    return <div>{t['ThingMonitors:Loading'].get()} </div>
  }

  if (monitor.loading || !jsonSchema) {
    return <div>{t['ThingMonitors:Loading'].get()}</div>
  }
  if (monitor.error) {
    return <div> {t['ThingMonitors:SomethingWentWrong'].get()} </div>
  }

  if (parseError) {
    return <div>{t['ThingMonitors:UserConfig'].get()} </div>
  }

  return children
}

const JsonSchemaForm = ({ jsonSchema, setFormData, formData }) => {
  return (
    <GenericInput
      name="root"
      schema={jsonSchema}
      formData={formData}
      setFormData={(v) => setFormData(v)}
    />
  )
}

const getInitialFormData = (schema, existingRuleConfigValue) => {
  //console.log('getInitialFormData', schema, existingRuleConfigValue)
  if (schema.type === 'object') {
    return Object.keys(schema.properties).reduce((acc, key) => {
      acc[key] = getInitialFormData(
        schema.properties[key],
        existingRuleConfigValue?.[key] || {}
      )
      return acc
    }, {})
  }

  if (schema.type === 'boolean') {
    return existingRuleConfigValue || false
  }

  if (schema.type === 'number' || schema.type === 'integer') {
    return existingRuleConfigValue || 0
  }

  if (schema.type === 'string') {
    return existingRuleConfigValue || ''
  }

  return undefined
}

const GenericInput = ({ name, schema, formData, setFormData }) => {
  if (schema.type === 'object') {
    return (
      <div>
        {Object.keys(schema.properties).map((key) => {
          return (
            <GenericInput
              key={key}
              name={key}
              schema={schema.properties[key]}
              formData={formData[key]}
              setFormData={(value) =>
                setFormData({ ...formData, [key]: value })
              }
            />
          )
        })}
      </div>
    )
  }

  if (schema.type === 'boolean') {
    return (
      <Checkbox
        style={{ marginBottom: '4px' }}
        label={schema.title || name}
        description={schema?.description}
        checked={formData as boolean}
        onChange={(v) => setFormData(v)}
      ></Checkbox>
    )
  }

  const [showDescription, setShowDescription] = useState(false)

  return (
    <Input
      style={{ marginBottom: '4px' }}
      label={
        <div
          style={{ cursor: 'help' }}
          onMouseEnter={() => setShowDescription(true)}
          onMouseLeave={() => setShowDescription(false)}
        >
          {schema.title || name}
          {showDescription && schema?.description && (
            <Text wrap typography="body500" color={'text2'}>
              {schema?.description}
            </Text>
          )}
        </div>
      }
      key={name}
      name={name}
      // description={}
      type={
        schema.type === 'integer' || schema.type === 'number'
          ? 'number'
          : 'text'
      }
      value={
        formData && Object.keys(formData).length > 0
          ? formData
          : schema.type === 'integer' || schema.type === 'number'
          ? formData || 0
          : ''
      }
      onChange={(v) => setFormData(v)}
    />
  )
}
