import { useDialog } from "@shared/components/core/useDialog"
import { AddDeviceDialog } from "./dialogs/AddDialog"
import { AddGenericThingDialog } from "./dialogs/AddThingDialog"
import { Role } from "@server/shared"
import { api } from "@shared/index"
import { useRouteParams } from "@shared/router"
import { NoItemGuidance } from "@shared/components/page/generic/NoItemGuidance"
import { useEffect, useState } from "react"
import { AddIcon} from "@colombalink/basedui"
import { CustomizableButton } from "@shared/components/core/CustomizableButton"
import { WithI18n } from "@shared/i18n/withI18n"
import i18n from "./i18n"
import { useT } from "@app/i18n"
import { LoadingIcon } from '@shared/components/core/LoadingIcon'

const _ThingLandingFragment = ({ children }) => {
    const t = useT()
    const createDeviceDialog = useDialog(<AddDeviceDialog />)
    const createGenericDeviceDialog = useDialog(<AddGenericThingDialog />)

    const { projectAliasId } = useRouteParams<{ projectAliasId: string }>()
    const roles = api.user.getRoles.useLiveData()
    const devices = api.thing.getAll.useLiveData()
    const [hasThings, setHasThings] = useState<boolean>(null)



    useEffect(() => {
        if (!roles.data?.asArray) {
            return
        }
        if (!devices.data) {
            return
        }
        const hasThings = roles.data.asArray.some((role) => {
            if (Array.isArray(role) && role[0].startsWith(projectAliasId) &&
                Array.isArray(devices.data) && devices.data.length > 0
            ){
                return true
            }
        })

        setHasThings(hasThings)

    }, [roles.data?.asArray, devices.data, projectAliasId])



    if (roles.error) {
        throw roles.error
    }


    if (roles.loading || hasThings === null) {
        return <LoadingIcon />
    }

    //console.log("hasThings:", hasThings)
    if (hasThings) {
        return children
    }

    const projectRole = roles.data[projectAliasId] as Role
    if (projectRole === "OWNER") {
        return (
            <NoItemGuidance>
                {
                    t["SThing:NoDevicesFound"].get()
                }

                {
                    t["SThing:AddFirst"].get()
                    
                }
                <CustomizableButton
                    icon={<AddIcon />}
                    onClick={async () => {
                        await createDeviceDialog.open()
                    }}
                >
                    {t["SThing:CreateDevice"].get()}
                </CustomizableButton>
                <CustomizableButton
                    icon={<AddIcon />}
                    onClick={async () => {
                        await createGenericDeviceDialog.open()
                    }}
                >
                    {t["SThing:CreateGenericThing"].get()}
                </CustomizableButton>
            </NoItemGuidance>
        )
    }

    return (
        <NoItemGuidance
        >
            {
                t["SThing:NoPlease"].get()
            }
        </NoItemGuidance>
    )

}

export const ThingLandingFragment = (props) => (
    <WithI18n i18n={i18n}>
        <_ThingLandingFragment {...props} />
    </WithI18n>
)