
const OrgHomePageContentI18n = {

    "Home:Devices": {
        "en": "Devices:",
        "de": "Geräte:",
    },
    "Home:NoDevices": {
        "en": "No devices found",
        "de": "Keine Geräte gefunden",
    },

    "Home:Projects": {
        "en": "Projects:",
        "de": "Projekte:",
    },
    "Home:NoProjects": {
        "en": "No projects found",
        "de": "Keine Projekte gefunden",
    },
    "Home:NoAlarms": {
        "en": "No alarms found",
        "de": "Keine Alarme gefunden",
    },
    "Home:Alarm": {
        "en": "Alarm",
        "de": "Alarm",
    },
    "Home:Loading": {
        "en": "Loading...",
        "de": "Laden...",
    },
    "Home:CancelAlarm": {
        "en": "Cancel",
        "de": "Quittieren",
    },
    "Home:CanceledAlarm": {
        "en": "Canceled alarm",
        "de": "Alarm quittiert",
    },
    "Home:CancelAlarmSuccessfully": {
        "en": "Canceled successfully",
        "de": "Erfolgreich quittiert",
    },
    "Home:DeactivateAlarm": {
        "en": "Deactivate",
        "de": "Deaktivieren",
    },
    "Home:DeactivatedAlarm": {
        "en": "Deactivated alarm",
        "de": "Deaktivierter Alarm",
    },
    "Home:DeactivateAlarmSuccessfully": {
        "en": "Deactivated successfully",
        "de": "Erfolgreich deaktiviert",
    },
    "Home:MonitorName": {
        "en": "Monitor name",
        "de": "Monitorname",
    },
    "Home:MonitorStatus": {
        "en": "Status",
        "de": "Status",
    },
    "Home:MonitorStatusMessage": {
        "en": "Status message",
        "de": "Statusmeldung",
    },

}


export default OrgHomePageContentI18n
export type OrgHomePageContentI18nType = typeof OrgHomePageContentI18n
