import { useEffect, useState } from 'react'
import DefaultNavigation from '@shared/components/page/navigation/DefaultNavigation'
import { Error } from '@shared/components/errors/Error'
import { Page } from '@shared/components/page/Page'
import { api } from '@shared/index'
import { useRouteParams } from '../../../../shared/router'
import { useTable } from '@shared/components/core/table/Table'
import { DatePicker } from '@shared/components/core/shadcn-ui/DatePicker'
import i18n from './i18n'
import { WithI18n } from '@shared/i18n/withI18n'
import { useT } from '@app/i18n'
import { log } from '@shared/logger'

type PageProps = { params: { orgAliasId: string, projectAliasId: string } }
export type PageParams = PageProps['params']
const OrgAuditLogPage = (params: PageProps) => {
  return (
    <Page>
      <DefaultNavigation />
      <Main {...params} />
    </Page>
  )
}

export default OrgAuditLogPage

const Main = (props: PageProps) => {
  return (
    <WithI18n i18n={i18n}>
      <AuditLogMain />
    </WithI18n>
  )
}
Main.displayName = 'Main'

function AuditLogMain() {
  const t = useT()
  const [selectedDate, setSelectedDate] = useState(new Date())

  const { orgAliasId, projectAliasId } = useRouteParams<PageParams>()
  const logList = api.auditLog.getProjectLog.useData({
    orgAliasId,
    projectAliasId,
    date: new Date(selectedDate).toISOString(),
  })
  const [tableData, setTableData] = useState([])

  const [table, TableComponent] = useTable({
    className: 'h-[95%] overflow-y-scroll mb-4 min-h-[35px]',
    header: [
      { label: t['ProjectAuditLog:Time'].get(), key: 'time' },
      { label: t['ProjectAuditLog:LogMessage'].get(), key: 'logMessage' },
      { label: t['ProjectAuditLog:ActorName'].get(), key: 'author' },
      { label: t['ProjectAuditLog:ActorEmail'].get(), key: 'authorEmail' },
      //{ label: 'Data', key: 'data' },
    ],
    data: tableData,
  })

  useEffect(() => {
    if (logList.data) {
      setTableData(
        logList.data.map((logList) => {
          try {
            const data = JSON.parse(logList.data)
            const authorInfo = JSON.parse(logList.author)

            return {
              time: getFormattedTime(logList.time), // Directly copy the 'time' property
              author: authorInfo.name, // Extract the 'name' from the parsed 'author' JSON
              authorEmail: authorInfo.email, // Extract the 'email' from the parsed 'author' JSON
              logMessage: data.message, // Extract 'message' from the parsed 'data' JSON
              data: data.value, // Directly copy the 'data' property
            }
          } catch (error) {
            if (error instanceof SyntaxError) {
              //TODO: multilingual support
              log.error(t['ProjectAuditLog:ErrorParsingJson'].get())
            } else {
              //TODO: multilingual support
              log.error( error , t['ProjectAuditLog:UnexpectedError'].get())
            }
          }
        })
      )
    }
  }, [logList])


  return (
    <>
      {!logList.loading && (
        <>
          <PageSelector
            selectedDate={selectedDate}
            setDate={date => setSelectedDate(date)}
          />

          {logList.data.length > 0 ? (
            TableComponent
          ) : (
            <div className="flex flex-col items-center mt-4">
              <Error
                title={t['ProjectAuditLog:NoRecordsFound'].get()}
                code=""
                message={<>
                  {t['ProjectAuditLog:DefaultRetentionPeriod'].get()}
                  <br />
                  {t['ProjectAuditLog:ContactSupport'].get()}
                </>}
              />
            </div>
          )}
        </>
      )}
    </>
  )
}

const PageSelector = ({ setDate, selectedDate }) => {
  return (
    <div className="pb-2 flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:space-x-2 sm:justify-end">
      <DatePicker date={selectedDate} setDate={setDate} ></DatePicker>
    </div>
  )
}

const getFormattedTime = (time: string) => {
  try {
    const date = new Date(time)
    return date.toLocaleString('de')
  } catch (e) {
    return ''
  }
}
