import { createContext, useContext, useEffect, useState } from 'react'

function useProvideContext<T>(key: string, defaultValue: T, overwrite?: boolean = false) {
    const [value, setValue] = useState<T>(defaultValue)
    useEffect(() => {
        if (overwrite) {
            localStorage.setItem(key, JSON.stringify(value))
        } else {
            const storedValue = localStorage.getItem(key)
            try {
                setValue(JSON.parse(storedValue))
            }catch(e) {
                setValue(undefined)
            }
        }
    }, [])
    useEffect(() => {
        if(!value) {
            return
        }
        localStorage.setItem(key, JSON.stringify(value))
    }, [value])

  return {
    value,
    setValue,  
}
}

type ContextType = ReturnType<typeof useProvideContext>

const Context = createContext<ContextType | undefined>(undefined)

const LocalStorageContext = ({ children }) => {
  const value = useProvideContext()
  return <Context.Provider value={value}>{children}</Context.Provider>
}

function useLocalStorageContext<T>( key: string, defaultValue?: T, overwrite?: boolean): ContextType {
  const context = useContext(Context)


  if (!context) {
    throw new Error('useLocalStorageContext must be used within a LocalStorageContextProvider')
  }
  return context
}

export { useLocalStorageContext, LocalStorageContext }
