import React, { useEffect, useState } from 'react'
import { AddIcon, Button, Dialog, Input, SearchIcon, Spacer, Text, Toast, useCopyToClipboard, useDialog, useToast } from '@colombalink/basedui'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'
import { ZodInputValidator } from '@shared/zod/InputValidator'
import { api } from '@shared/index'
import { OldCompatTable } from '@shared/components/core/OldCompatTable'
import { Role, getRoleLabel } from '@server/shared'
import { useRouteParams } from '@shared/router'
import { PageParams } from './Page'
import { useT } from '@app/i18n'
import { createApiTokenParams } from '@server/app'


const ShowNewToken = ({ token, onCancel }: { token: string, onCancel: () => void }) => {
  const t = useT()
  const [copied, setCopied] = React.useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(token).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1000); // Change the duration as per your requirement
    });
  };

  return (
    <Dialog label={t["SettingsApiTokens:NewToken"].get()}>
      <Input type={"text"} value={token}></Input>
      <Dialog.Buttons>
        <Dialog.Cancel displayShortcut={false} onCancel={onCancel}>
          {t["SettingsApiTokens:Cancel"].get()}
        </Dialog.Cancel>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Dialog.Confirm
            displayShortcut={false}
            keyboardShortcut='Enter'
            onConfirm={copyToClipboard}
          >
            {t["SettingsApiTokens:Copy"].get()}
          </Dialog.Confirm>
          {copied && <div style={{ marginLeft: '0.5rem' }}>{t["SettingsApiTokens:TokenCopied"].get()}</div>}
        </div>
      </Dialog.Buttons>
    </Dialog>
  );
};


const AddApiTokenDialog = () => {
  const t = useT()
  const { orgAliasId } = useRouteParams<PageParams>()
  const createApiToken = api.organization.apiTokens.create.useMutation()
  const [createTokenArgs, setCreateTokenArgs] = React.useState({ orgAliasId, name: '', functions: ['datahub:events:push'] })
  const toast = useToast()
  const open = useDialog()

  const handleCloseDialog = () => {
    open.close()
  };

  return (
    <Dialog label= {t["SettingsApiTokens:CreateApiToken"].get()}>
      <div>

        <Spacer />
        <Input
            type={"text"}
          description={t["SettingsApiTokens:TokenName"].get()}
          value={createTokenArgs.name}
          pattern="true"
          error={(value) => ZodInputValidator(createApiTokenParams, createTokenArgs, "name")}
          onChange={(name: string) => {
            setCreateTokenArgs({ ...createTokenArgs, name })
          }
          }
        />

        <Dialog.Buttons>
          <Dialog.Cancel displayShortcut={false}>{t["SettingsApiTokens:Cancel"].get()}</Dialog.Cancel>
          <Dialog.Confirm displayShortcut={false} onConfirm={async () => {

            try {
              const token = await createApiToken.mutateAsync(createTokenArgs)
              //console.log('token', token)
              await open(<ShowNewToken token={token} onCancel={handleCloseDialog} />)
              toast.add(
                <Toast
                  label={t["SettingsApiTokens:CreatedApiToken"].get()}
                  type="success"
                  description={
                    <Text>
                      Token <b>{createTokenArgs.name}</b>  {t["SettingsApiTokens:CreatedSuccessfully"].get()}
                    </Text>
                  }
                />
              )
            } catch (e) {
              toast.add(
                <Toast
                  label={t["SettingsApiTokens:FailedToCreateApiToken"].get()}
                  type="error"
                  description={
                    <Text style={{whiteSpace: 'normal'}}>
                      {e.message}
                    </Text>
                  }
                />
              )
              throw e
            }
          }}>{t["SettingsApiTokens:Save"].get()}</Dialog.Confirm>
        </Dialog.Buttons>
      </div>
    </Dialog>
  )
}




export default AddApiTokenDialog




function MemberSelectorTable() {
  // todo: add paginated search (it is ok for now)
  const org = api.organization.getOrganizationById.withMembers.useLiveData({
    orgId,
  })
  const project = api.project.getById.withMembers.useLiveData({ orgId, projectId });


  const addMemberToProject =
    api.project.addMemberToProject.useMutation()
  const [search, setSearch] = React.useState('')
  const toast = useToast()
  const [tableData, setTableData] = React.useState([])
  const tableHeader = [
    { label: 'Name', key: 'user.name' },
    { label: 'Role', key: 'roleLabel' },
    { label: 'Action', key: 'action' },
  ]

  useEffect(() => {
    if (!org.data?.members || !project.data?.members) {
      return
    }

    const tableRows = org.data.members
      .filter(
        (orgMember) => orgMember.role !== 'OWNER'
          && !project.data.members.find((projectMember) => projectMember.user.id === orgMember.user.id)
      )
      .map((member) => {
        return {
          ...member,
          roleLabel: getRoleLabel(member.role as Role, 'en'),
          action: (
            <Button
              onClick={async () => {
                try {
                  await addMemberToProject.mutateAsync({
                    projectId,
                    memberToRoleId: member.id,
                  })
                  toast.add(
                    <Toast
                      label="Added member"
                      type="success"
                      description={
                        <Text>
                          Member <b>{member?.user?.name}</b> added to project
                        </Text>
                      }
                    />
                  )
                } catch (e) {
                  toast.add(
                    <Toast
                      label="Error"
                      type="error"
                      description="Sorry, something went wrong."
                    />
                  )
                }
              }}
            >
              <AddIcon />
            </Button>
          ),
          customAttribute: {
            action: {},
          },
        }
      })

    if (search === '') {
      setTableData(tableRows.slice(0, 10))
    } else {
      setTableData(
        tableRows.filter((member) =>
          member.user.name.toLowerCase().startsWith(search.toLowerCase())
        )
      )
    }
  }, [org.data?.members, project.data?.members, search])

  if (org.loading) {
    return (
      <div>
        <LoadingIcon /> Loading users...
      </div>
    )
  }

  return (
    <div>
      <Input
        icon={SearchIcon}
        placeholder="Type member name here"
        onChange={setSearch}
      />
      {tableData.length > 0 && (
        <div>
          <Spacer space="15px" />
          <OldCompatTable
            data={tableData}
            loading={org.loading}
            header={tableHeader}
          />
        </div>
      )}
    </div>
  )
}



