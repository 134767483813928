import { WithI18n } from "@shared/i18n/withI18n"
import { FC, ReactNode, useEffect, useState } from "react"
import { useLocation } from "wouter"
import i18n from "../i18n"
import { useT } from "@app/i18n"
import { useClientsContext } from "@shared/client/context"
import { useAuthState } from "@shared/client/useAuthState"

type AuthProps = {
  children?: ReactNode
}

export const _Authorizer: FC<AuthProps> = ({ children }) => {
  const t = useT()
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [location, setLocation] = useLocation()
  const [redirectLocation, setRedirectLocation] = useState<string>('/')
  const authState = useAuthState()

  useEffect(() => {
    if(location.startsWith('/login')) {
      setRedirectLocation('/')
    }else {
      setRedirectLocation(location)
    }
  },[])


  useEffect(() => {
    setIsLoggedIn(!!authState?.userId)
  }, [
    authState,
    authState?.userId,
  ])


  if (isLoggedIn) {
    if(location.startsWith('/login')) {
      setLocation(redirectLocation)
    }

    if (children) {
      return <>{children}</>
    } else {
      return <div>{t["CoreAuthorize:LoggedIn"].get()}</div>
    }
  }
  else if (location.startsWith('/login') || location.startsWith('/invitation')) {
    return <>{children}</>
  }
  else {
    setLocation('/login')
  }
}

export const Authorizer = (props) => (
  <WithI18n i18n={i18n}>
    <_Authorizer {...props} />
  </WithI18n>
)

