import Logo from '@shared/assets/monidas-logo.svg'
export default ({ children }) => {
    return <div className="w-screen h-screen absolute top-0 left-0"
        style={{
            minHeight: '600px',
        }}
    >
        <div className='flex justify-center items-center'>
                <Logo style={{
                    width: '220px',
                    height: '50px',
                }} />
        </div>

        <div className="flex justify-center items-center h-full">
            {children}
        </div>
    </div>
}