/**
 * Converts HSV (Hue, Saturation, Value) color values to RGB (Red, Green, Blue) format.
 * @param h - The hue value in degrees (0 to 360).
 * @param s - The saturation value in percentage (0 to 100).
 * @param v - The value/brightness value in percentage (0 to 100).
 * @returns An array containing the corresponding RGB color values.
 */
export function hsvToRgb(h, s, v) {
  // Normalize hue value to the range [0, 360]
  h = ((h % 360) + 360) % 360

  // Normalize saturation and value to the range [0, 1]
  s = Math.max(0, Math.min(1, s / 100))
  v = Math.max(0, Math.min(1, v / 100))

  // Convert HSV to RGB
  const c = v * s
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1))
  const m = v - c
  let r, g, b

  if (h >= 0 && h < 60) {
    ;[r, g, b] = [c, x, 0]
  } else if (h >= 60 && h < 120) {
    ;[r, g, b] = [x, c, 0]
  } else if (h >= 120 && h < 180) {
    ;[r, g, b] = [0, c, x]
  } else if (h >= 180 && h < 240) {
    ;[r, g, b] = [0, x, c]
  } else if (h >= 240 && h < 300) {
    ;[r, g, b] = [x, 0, c]
  } else {
    ;[r, g, b] = [c, 0, x]
  }

  // Scale RGB values to the range [0, 255] and round them
  r = Math.round((r + m) * 255)
  g = Math.round((g + m) * 255)
  b = Math.round((b + m) * 255)

  return [r, g, b]
}
