import { FC } from 'react'
import { OptionMenu } from '@shared/components/core/EditEntityMenu'
import { api } from '@shared/index'
import {
  ArchiveIcon,
  CloseCircleIcon,
  ContextItem,
  Text,
  Toast,
  useToast,
} from '@colombalink/basedui'
import { useT } from '@app/i18n'

type RowMenuProps = {
  monitor: ReturnType<
    typeof api.monitor.getAllAlarmsFromThing.useLiveData
  >['data'][0]
  orgAliasId: string
  projectAliasId: string
  thingId: string
}

export const RowMenu = (props: RowMenuProps) => {
  const CategoryMenu: FC<{}> = ({}) => {
    const t = useT()
    const toast = useToast()

    const clearAlarm = api.monitor.clearAlarm.useMutation()
    const deactivateAlarm = api.monitor.deactivateAlarm.useMutation()
    return (
      <>
        <ContextItem
          onClick={async () => {
            await clearAlarm.mutateAsync({
              monitorId: props.monitor.id,
              orgAliasId: props.orgAliasId,
              projectAliasId: props.projectAliasId,
              thingAliasId: props.thingId,
            })

            toast.add(
              <Toast
                label={t['UserHome:CanceledAlarm'].get()}
                type="success"
                description={
                  <Text> {t['UserHome:CancelAlarmSuccessfully'].get()} </Text>
                }
              />
            )
          }}
        >
          {t['UserHome:CancelAlarm'].get()}
        </ContextItem>

        {props.monitor.activeState === 'active' && (
          <ContextItem
            onClick={async () => {
              await deactivateAlarm.mutateAsync({
                monitorId: props.monitor.id,
                orgAliasId: props.orgAliasId,
                projectAliasId: props.projectAliasId,
                thingAliasId: props.thingId,
              })

              toast.add(
                <Toast
                  label={t['UserHome:DeactivatedAlarm'].get()}
                  type="success"
                  description={
                    <Text> {t['UserHome:DeactivateAlarmSuccessfully'].get()} </Text>
                  }
                />
              )
            }}
          >
            {t['UserHome:DeactivateAlarm'].get()}
          </ContextItem>
        )}
      </>
    )
  }

  return <OptionMenu menu={CategoryMenu} style={{ width: '150px' }} />
}
