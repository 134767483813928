import { useEffect, useState } from "react";

export const LastSeenLabel = ({ updatedAt }: { updatedAt: string }) => {
    const [lastSeen, setLastSeen] = useState('');

    useEffect(() => {

        const updateDisplayTime = () => {
            setLastSeen(getLastSeen(updatedAt));
        };
        
        updateDisplayTime();
        const intervalId = setInterval(updateDisplayTime, 1000);

        return () => clearInterval(intervalId);
    }, [updatedAt]);

    return <>{lastSeen}</>;
}


function getLastSeen(updatedAt) {
    if(!updatedAt) {
        return "Never"
    }
    
    const lastTime = new Date(updatedAt).getTime();
    const now = new Date().getTime();
    const diffInSeconds = Math.floor((now - lastTime) / 1000);

    if (diffInSeconds < 1) {
        return `Just now`;
    }
    else if (diffInSeconds < 60) {
        return `${diffInSeconds} seconds ago`;
    }
    else if (diffInSeconds < 3600) {
        return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    } else if (diffInSeconds < 86400) {
        return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    } else {
        return `${Math.floor(diffInSeconds / 86400)} days ago`;
    }
}