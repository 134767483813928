import { Dialog, Input, Text, Toast, useToast, Spacer } from '@colombalink/basedui'
import React from 'react'
import { api } from '@shared/index'
import { ZodInputValidator } from '@shared/zod/InputValidator'
import { useT } from '@app/i18n'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'
import { updateDeviceParams } from '@server/app'

type EditProps = {
  thing: ReturnType<typeof api.thing.getAll.useLiveData>['data'][0]
}

const EditNotificaitonTemplate = (props: EditProps) => {
  const t = useT()
  const updateTemplate = api.thing.update.useMutation()
  const [updateDeviceArgs, setUpdateDeviceArgs] = React.useState({
    id: props.thing.id,
    name: props.thing.name,
  })

  const toast = useToast()

  if (!updateDeviceArgs) {
    return <LoadingIcon />
  }

  return (
    <Dialog label={t['Devices:EditDevice'].get()}>
      <div>
        <Spacer />
        <Input
          description= {t['Devices:Name'].get()}
          value={updateDeviceArgs.name}
          pattern="true"
          error={(value) => ZodInputValidator(updateDeviceParams, updateDeviceArgs, "name")}
          onChange={(name: string) => {
            setUpdateDeviceArgs({ ...updateDeviceArgs, name })
          }
          }
        />
        <Dialog.Buttons>
          <Dialog.Cancel displayShortcut={false}>{t['Devices:Cancel'].get()}</Dialog.Cancel>
          <Dialog.Confirm displayShortcut={false}

            onConfirm={async () => {

              try {
                await updateTemplate.mutateAsync(updateDeviceArgs)
                toast.add(
                  <Toast
                    label={t['Devices:CreatedThing'].get()}
                    type="success"
                    description={
                      <Text>
                        {t["Devices:Device"].get()} <b>{updateDeviceArgs.name}</b> {t["Devices:CreatedSuccessfully"].get()}
                      </Text>
                    }
                  />
                )
              } catch (e) {
                toast.add(
                  <Toast
                    label= {t['Devices:FailedToCreate'].get()}
                    type="error"
                    description={
                      <Text style={{ whiteSpace: 'normal' }}>
                        {e.message}
                      </Text>
                    }
                  />
                )
                throw e
              }
            }}>{t['Devices:Save'].get()}</Dialog.Confirm>
        </Dialog.Buttons>
      </div>
    </Dialog>
  )
}



export default EditNotificaitonTemplate;