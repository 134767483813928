import { LoadingIcon } from '@shared/components/core/LoadingIcon'
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomResource, updateI18nResource } from './updateI18nResource';


export const WithI18n = ({ children, i18n }: { i18n: CustomResource, children }) => {
  const { i18n: i } = useTranslation();
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    updateI18nResource(i, i18n)
    setLoading(false)
  }, [])

  if (loading) {
    return <LoadingIcon />
  }

  return children
}

