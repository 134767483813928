import { api } from '@shared/index'
import { ContextBuilder } from '@shared/utils/react/ContextBuilder';
import { createContextUtility } from '@shared/utils/react/createContextUtility';
import { useEffect, useState } from 'react';


function useProvideContext() {
  return new ContextBuilder({})
    .with(formOptions)
    .with(formData)
    .with((c) => { // loading
      const loading = c.formOptions.actions.loading
        || c.formOptions.ruleFunctions.loading
        || c.formOptions.notificationTemplates.loading;
      return {
        ...c,
        loading,
      };
    })
    .build();
}

function formOptions<C>(context: C) {
  const actions = api.organization.monitor.action.getAll.useLiveData();
  const ruleFunctions = api.function.getFunctionsForOrg.useLiveData();
  const notificationTemplates = api.organization.monitor.notification.template.getAll.useLiveData();
  return {
    ...context,
    formOptions: {
      actions: actions,
      ruleFunctions: ruleFunctions,
      notificationTemplates: notificationTemplates,
    },
  };
}


function formData<C>(context: C) {
  const useActions = useState<string[]>([]);
  const useActiveState = useState<string>('');
  const useData = useState('{}');
  const useName = useState<string>('');
  const useStatusMessageTemplate = useState<string>('');
  const useRuleConfig = useState('{}');
  const useRuleFunction = useState('');
  const useStatus = useState<string>('');

  return {
    ...context,
    formData: {
      useActions,
      useActiveState,
      useData,
      useName,
      useStatusMessageTemplate,
      useRuleConfig,
      useRuleFunction,
      useStatus,
    },
  };
}

export const {
  Provider: MonitorsBaseContext,
  useCustomContext: useMonitorsBaseContext
} = createContextUtility(
  useProvideContext,
  () => { throw new Error('BaseContext must be used within a Provider') }
);
