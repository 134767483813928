import { useEffect, useRef } from 'react'
import BaseError from './ErrorTemplate.svg'

export const Error = ({ title, code, message}) => {
   const ref = useRef(null)

   useEffect(() => {
      if (ref.current) {
           ref.current.getElementById("errorCode").innerHTML = code || ''
      }
   }, [])

   return (
      <div className="flex flex-col items-center min-w-[150px] max-w-[300px] sm:min-w-[400px] sm:max-w-[800px]">
          <h1 className="font-semibold text-center text-xl mb-4">{title}</h1>
          <BaseError ref={ref}></BaseError>
            <p className="text-center text-txt mb-4">{message}</p>
      </div>
  )
  

}