import { PropsWithChildren, createContext, useContext, useState } from 'react'
import { MonidasClient, MonidasHubClient, Options } from '.'

function useProvideContext(client: MonidasClient | undefined, options: Options) {
    const [clients,] = useState(client || new MonidasHubClient(options))
    return {
        clients 
    }
}

type ContextType = ReturnType<typeof useProvideContext>

const Context = createContext<ContextType | undefined>(undefined)

type Props = PropsWithChildren<{ options?: Options, client?: MonidasClient }>

const ClientsContext: React.FC<Props> = ({ children, options, client}) => {
    if(!client && !options) {
        throw new Error("No client or options provided to the ClientsContext. At least one must be defined.")
    }
    const value = useProvideContext(client, options);
    
    return <Context.Provider value={value}>{children}</Context.Provider>;
  };

function useClientsContext<Services>(): ContextType {
    const context = useContext(Context)

    if (!context) {
        throw new Error('useClientsContext must be used within a ClientContext')
    }
    return context
}

const useIdentityClient = () => {
    const { clients } = useClientsContext()
    return clients.getClient('identity')
}

const useAppClient = () => {
    const { clients } = useClientsContext()
    return clients.getClient('app')
}

export { useClientsContext, ClientsContext, useIdentityClient, useAppClient}
