import { useRouteParams } from '../../../../shared/router'
import { Button } from '@colombalink/basedui'
import { MainToolBar } from '@shared/components/core/MainToolbar'
import { Page } from '@shared/components/page/Page'
import DefaultNavigation from '@shared/components/page/navigation/DefaultNavigation'
import { EditIcon } from 'lucide-react'
import { CardView } from './shared/CardView'
import { useLocation } from 'wouter'
import { useLink } from '../../../routes'
import { usePageRole } from '@shared/components/page/usePageRole'
import { ProjectLandingFragment } from '@shared/components/project/LandingFragment'
import { WithI18n } from '@shared/i18n/withI18n'
import i18n from './i18n'

type PageProps = { params: { orgAliasId: string } }
export type PageParams = PageProps['params']

export default (props: PageProps) => {
  return (
    <Page>
      <DefaultNavigation />
      <Main />
    </Page>
  )
}
const Main = () => {
  return (
    <WithI18n i18n={i18n}>
      <ManageOrganizationProjectsMain />
    </WithI18n>
  )
}

Main.displayName = 'Main'

const ManageOrganizationProjectsMain = () => {
  const [, setLocation] = useLocation()
  const { orgAliasId } = useRouteParams<PageParams>()
  const role = usePageRole()
  return (
    <ProjectLandingFragment>
      <div className="flex flex-col p-4">
        {role.isOwner && (
          <MainToolBar>
            <Button
              icon={<EditIcon style={{ height: '16px', width: '16px' }} />}
              onClick={() =>
                setLocation(
                  useLink('/org/:orgAliasId/projects/editor', { orgAliasId })
                )
              }
            />
          </MainToolBar>
        )}
        <CardView></CardView>
      </div>
    </ProjectLandingFragment>
  )
}

ManageOrganizationProjectsMain.displayName = 'ManageOrganizationProjectsMain'
