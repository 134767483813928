const UserSettingsPageContentI18n = {
  'UserSettings:Label': {
    en: 'Settings',
    de: 'Einstellungen',
  },
  'UserSettings:Preferences': {
    en: 'Language Preference:',
    de: 'Sprachpräferenz:',
  },
  'UserSettings:Language': {
    en: 'Language',
    de: 'Sprache',
  },
  'UserSettings:LanguageOptionEnglish': {
    en: 'English',
    de: 'English',
  },
  'UserSettings:LanguageOptionGerman': {
    en: 'Deutsch',
    de: 'Deutsch',
  },
  'UserSetting:SuccessfullyChangedOrganization': {
    en: 'You have successfully changed the language',
    de: 'Die Sprache wurde erfolgreich geändert.',
  },
}

export default UserSettingsPageContentI18n
export type UserSettingsPageContentI18nType = typeof UserSettingsPageContentI18n
