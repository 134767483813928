
const OrgMembersPageContentI18n = {

    'Member:AllMembers': {
        en: 'All Members',
        de: 'Alle Mitglieder'
    },
    'Member:Invite': {
        en: 'Invite',
        de: 'Einladen'
    },
    'Member:Name': {
        en: 'Name',
        de: 'Name'
    },
    'Member:Role': {
        en: 'Role',
        de: 'Rolle'
    },
    'Member:Loading': {
        en: 'Loading',
        de: 'Laden'
    },
    'Member:Edit': {
        en: 'Edit',
        de: 'Bearbeiten'
    },
    'Member:Removed': {
        en: 'Member Removed',
        de: 'Mitglied entfernt'
    },
    'Member:InviteMember': {
        en: 'Invite Member',
        de: 'Mitglied Einladen'
    },
    'Member:RemovedText': {
        en: 'Member has been removed successfully',
        de: 'Mitglied wurde erfolgreich entfernt'
    },
    'Member:RemoveFailed': {
        en: 'Failed to remove member',
        de: 'Mitglied konnte nicht entfernt werden'
    },
    'Member:Remove': {
        en: 'Remove',
        de: 'Entfernen'
    },
    'Member:InviteText': {
        en: 'Invite a new member to your organization by entering their email address below.',
        de: 'Laden Sie ein neues Mitglied in Ihre Organisation ein, indem Sie unten seine E-Mail-Adresse eingeben.'
    },
    'Member:InviteFailed': {
        en: 'Failed to send Email invitation to member',
        de: 'Mitglied konnte nicht eingeladen werden'
    },
    'Member:EmailSent': {
        en: 'Invitation Email sent',
        de: 'Einladungs E-Mail gesendet'
    },
    'Member:EmailSentSuccessfully': {
        en: 'sent successfully',
        de: 'erfolgreich gesendet'
    },
      
    'Member:Save': {
        en: 'Save',
        de: 'Speichern'
    },
    'Member:Close': {
        en: 'Close',
        de: 'Schliessen'
    },
    'Member:UpdateRole': {
        en: 'Update Member Role',
        de: 'Mitgliedsrolle aktualisieren'
    },
    'Member:User': {
        en: 'User',
        de: 'Benutzer'
    },
    'Member:RoleUpdated': {
        en: 'Member Role Updated',
        de: 'Mitgliedsrolle aktualisieren'
    },
    'Member:SetRoleTo': {
        en: 'Set role to',
        de: 'Rolle setzen auf'
    },
    'Member:UpdateFailed': {
        en: 'Failed to update member role',
        de: 'Mitgliedsrolle konnte nicht aktualisiert werden'
    },
    'Member:Successfully': {
        en: 'successfully',
        de: 'erfolgreich'
    },

    'Member:RevokeInvitation': {
        en: 'Revoke',
        de: 'Zurückziehen'
    },

    'Member:RevokedInvitation': {
        en: 'Invitation revoked',
        de: 'Einladung zurückgezogen'
    },

    'Member:RevokeInvitationFailed': {
        en: 'Failed to revoke invitation',
        de: 'Einladung konnte nicht zurückgezogen werden.'
    },

    'Member:InvitationExpirationDate': {
        en: 'Expiration date',
        de: 'Ablaufdatum'
    },

    'Member:InvitationEmail': {
        en: 'Email',
        de: 'E-mail'
    },
}


export default OrgMembersPageContentI18n
export type OrgMembersPageContentI18nType = typeof OrgMembersPageContentI18n
