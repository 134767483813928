import React, { useEffect } from 'react'
import { Dialog, Input, Text, Toast, useToast } from '@colombalink/basedui'
import { ZodInputValidator } from '@shared/zod/InputValidator'
import { api } from '@shared/index'
import { createOrgParams } from '@server/app'
import processString from '@app/pages/tools/convertName'
import { useT } from '@app/i18n'
import { WithI18n } from '@shared/i18n/withI18n'
import i18n from './i18n'
import { log } from '@shared/logger'

const CreateOrganizationDialog = () => {
  const t = useT()
  const createOrganization = api.organization.createOrganization.useMutation()
  const [createOrgArgs, setCreateOrgParams] = React.useState({ name: '', alias: '' })
  const toast = useToast()

  useEffect(() => {

    log.debug(createOrgArgs, "createOrgArgs")
    //console.log("createOrgArgs", ZodInputValidator(createOrgParams, createOrgArgs, "name"))
  }, [createOrgArgs.name])

  return (
    <Dialog label= {t['SUser:CreateOrganization'].get()}>
      <div>
        <Input
          type="text"
          description={t['SUser:Name'].get()}
          value={createOrgArgs.name}
          pattern="true"
          error={(value) => ZodInputValidator(createOrgParams, createOrgArgs, "name")}
          onChange={(name: string) => {
            setCreateOrgParams({ ...createOrgArgs, name, alias: processString(name) })
          }}
        />
      </div>
      <div>
        <Text style={{ marginTop: 24 }}>{t['SUser:Organization'].get()} URL {t['SUser:Path'].get()}: {createOrgArgs.alias}</Text> 
      </div>
      <div>
        <Dialog.Buttons>
          <Dialog.Cancel displayShortcut={false}>{t['SUser:Close'].get()}</Dialog.Cancel>
          <Dialog.Confirm displayShortcut={false} onConfirm={async () => {

            try {
              await createOrganization.mutateAsync(createOrgArgs)

              toast.add(
                <Toast
                  label={t['SUser:AddedOrganization'].get()}
                  type="success"
                  description={
                    <Text>
                      {t['SUser:Organization'].get()} <b>{createOrgArgs.name}</b> {t['SUser:CreatedSuccessfully'].get()}
                    </Text>
                  }
                />
              )
            } catch (e) {
              toast.add(
                <Toast
                  label={t['SUser:FailedToCreateOrganization'].get()}
                  type="error"
                  description={
                    <Text style={{whiteSpace: 'normal'}}>
                      {e.message}
                    </Text>
                  }
                />
              )
              throw e
            }
          }}>{t['SUser:Save'].get()}</Dialog.Confirm>
        </Dialog.Buttons>
      </div>
    </Dialog>
  )
}


export default ()=> (
  <WithI18n i18n={i18n}>
    <CreateOrganizationDialog />
  </WithI18n>
)
