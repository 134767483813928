import { Spacer } from "@colombalink/basedui"
import { api } from "@shared/index"
import { StatsCard } from "@shared/components/core/dashboard/StatsCard"
import { useProjectDashboardContext } from "../DashboardContext"
import { AccordionItem } from "./shared/AccordeonItem"
import { Accordion } from "@radix-ui/react-accordion"
import { Filters } from "./shared/Filters"
import { NoThingFoundWithFilter } from "./shared/NoThingFoundWithFilter"
import { FragmentContainer } from "./shared/FragmentContainer"
import { MainContainer } from "./shared/MainContainer"
import { AsyncStateHandler } from "./shared/AsyncStateHandler"
import { useEffect, useState } from "react"
import { useT } from '@app/i18n'

type Project = ReturnType<typeof api.project.getById.withThingsAndAlarms.useLiveData>['data']

export const TrendsFragment = () => {
    const {
        things,
        useSelectedThingsIds: [selectedThingsIds],
        useOpenAccordionItems: [openAccordionItems, setOpenAccordionItems],
    } = useProjectDashboardContext()

    return (
        <AsyncStateHandler>
            <FragmentContainer>
                <Filters />
                <MainContainer>
                    <NoThingFoundWithFilter />
                    {
                        selectedThingsIds?.length > 0 && (
                            <Accordion
                                type="multiple"
                                value={openAccordionItems}
                                onValueChange={setOpenAccordionItems}
                            >
                                {selectedThingsIds.map((thingId, index) => (
                                    <AccordionItem
                                        alias={things.map[thingId]?.alias}
                                        key={thingId}
                                        id={thingId} title={things.map[thingId]?.name} status={things.map[thingId].status} updatedAt={things.map[thingId].lastEvent?.updatedAt}  >
                                        <SingleThingTrendsContainer key={thingId} thingId={thingId} />
                                    </AccordionItem>
                                ))}
                            </Accordion>)
                    }
                </MainContainer>
            </FragmentContainer>
        </AsyncStateHandler>
    )
}


const SingleThingTrendsContainer = ({ thingId }: { thingId: string }) => {
    const t = useT()
    const { useThingToUnitsKey: [thingsToFields], things } = useProjectDashboardContext()

    // we keep track of the last event id to know when to refresh the data
    const [cachedEventId, setCachedEvent] = useState<any>(null)
    // we then use the thingId as a trigger to refresh the data in the chart
    const [thingIdTrigger, setThingIdTrigger] = useState<string>(thingId)
    // console.log('thingsToFields', thingsToFields[thingId])
    if (!thingsToFields[thingId] || thingsToFields[thingId].length === 0) {
        return <div> {t['Project:NoPropertiesFound'].get()} </div>
    }

    useEffect(() => {
        if(!things.map[thingId]?.lastEvent?.id) return
        if (!cachedEventId) {
            setCachedEvent(things.map[thingId]?.lastEvent.id)
            return
        }
        if (cachedEventId === things.map[thingId]?.lastEvent?.id) return
        setCachedEvent(things.map[thingId]?.lastEvent.id)
        setThingIdTrigger(thingId)

    }, [things.map[thingId]?.lastEvent?.id])

    if(!things.map[thingId]?.lastEvent?.id) return <div> {t['Project:NoDataFound'].get()} </div>
    
    return (
        <ul role="list" className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3'>
            {thingsToFields[thingId].map((fields, index) => (
                <li
                    key={index}
                    className="bg-white rounded-lg shadow-lg border border-gray-300"
                >
                    <StatsCard
                        thingId={thingId}
                        property={fields}
                        thingName={thingId}
                        thingAlias={things.map[thingId]?.alias}
                    />
                    <Spacer />
                </li>
            ))}
        </ul>
    )
}