import { DefaultProcedure } from '@server/app/trpc/router'
import { z } from 'zod'
import { createMonidasId } from '@server/app/trpc/monidas/utils/uuidv5/uuidv5'
import { apiTokenSchema } from '@server/app/trpc/monidas/routers/organization/apiTokens/shared'
import { verifyApiTokenProcedure } from './verifyToken'

export const createApiTokenParams = z.object({
        name: z.string(),
        functions: z.array(z.string()),
      })

export const createApiTokenProcedure = (procedure: DefaultProcedure) =>
  procedure
    .input(createApiTokenParams)
    .mutation(async ({ ctx, input }) => {

      const tokenType = 'api'
      const { name, functions } = input
      const { based } = ctx
      const { id: tokenId } = await based.db.default.set({
        name,
        type: 'token', 
      })
  
      const uuid = createMonidasId(name)
      const alias = `api/token/${uuid}` 
      await based.db.default.set({
        $id: tokenId,
        aliases: [alias],
        parents: [ctx.org.id] 
      })

      await based.db.default.set({
        $id: ctx.org.id,
        apiTokens: {
          $add: [tokenId]
        } 
      })

      const permissionPayload = [
        "/datahub/events/push"
      ].reduce((obj, func) => ({ ...obj, [func]: true }), {});

      // create payload
      const claims = apiTokenSchema.parse({
        id: alias,
        type: tokenType,
        version: "1.0",
        organization: {
          alias: ctx.org.alias,
        },
        permissions: permissionPayload,
      });

      return based.createToken(
        {
          ...claims,
        },
        { 
          expiresIn: '1y',
          issuer: 'https://monidas.com',
        }
      )

    })
