import {
  AddIcon, GridIcon, Tab, Tabs, Button, EditIcon, useTooltip
} from '@colombalink/basedui'
import DefaultNavigation from '@shared/components/page/navigation/DefaultNavigation'
import CreateNewProjectDialog from '../../../../shared/components/project/dialogs/AddDialog'
import { ProjectsTable } from './shared/ProjectsTable'
import { DynamicButtonRow } from '@shared/components/core/DynamicButtonRow'
import { Page } from '@shared/components/page/Page'
import { useRouteParams } from '../../../../shared/router'
import { PageParams } from './Page'
import { MainToolBar } from '@shared/components/core/MainToolbar'
import { useLink } from '../../../routes'
import { useLocation } from 'wouter'
import { LayoutDashboard } from 'lucide-react'
import { ProjectLandingFragment } from '@shared/components/project/LandingFragment'
import { WithI18n } from '@shared/i18n/withI18n'
import i18n from './i18n'
import { useT } from '@app/i18n'

export const OwnerPage = () => {
  return (
    <Page>
      <DefaultNavigation />
      <Main />
    </Page>
  )
}
OwnerPage.displayName = 'OwnerPage'

const Main = () => {
  return (
    <WithI18n i18n={i18n}>
      <ManageOrganizationProjectsMain  />
    </WithI18n>
  )
}

Main.displayName = 'Main'

const ManageOrganizationProjectsMain = () => {
  const t = useT()
  const { orgAliasId } = useRouteParams<PageParams>()
  const [, setLocation] = useLocation()
  return (
    <ProjectLandingFragment>
      <div className="flex flex-col">
        <MainToolBar>
            <DynamicButtonRow buttons={[
              {
                icon: <AddIcon />,
                dialogComponent: <CreateNewProjectDialog />,
                label: t["ProjectsEditor:Create"].get(),
                style: { cursor: 'pointer' }
              }
            ]
            } />
            <div className="my-4 pr-4">
              <Button
                icon={<LayoutDashboard style={{width: '16px', height: '16px'}}/>}
                onClick={() => setLocation(useLink('/org/:orgAliasId/projects', { orgAliasId }))}
              />
            </div>
        </MainToolBar> 
            <ProjectsTable />
      </div>
    </ProjectLandingFragment>
  )
}

ManageOrganizationProjectsMain.displayName = 'ManageOrganizationProjectsMain'