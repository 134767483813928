import React, { useEffect, useState } from 'react'
import { api } from '@shared/index'
import {
  Accordion,
  AccordionItem,
  AddIcon,
  Button,
  Card,
  CliIcon,
  Code,
  Container,
  EditIcon,
  ErrorIcon,
  Page as TabContent,
  Tab,
  Tabs,
  Text,
  Toast,
  UploadIcon,
  useDialog,
  useToast,
  Callout,
} from '@colombalink/basedui'
import trpcErrorHandler from '@shared/utils/trpcErrorHandler'
import AddEventDialog from '@shared/components/monitor/function/dialogs/EventDialog'
import { useT } from '@app/i18n'
import { InfoIcon } from 'lucide-react'

const FunctionEditor = ({
  functionId,
  name,
  functionCode,
  functionUserConfigSchema,
  functionGeneralConfigSchema,
  functionRuleConfig,
  playground,
}) => {
  const t = useT()
  const [code, setCode] = useState(functionCode)
  const [userConfigSchema, setUserConfigSchema] = useState(
    functionUserConfigSchema
  )
  const [generalConfigSchema, setGeneralConfigSchema] = useState(
    functionGeneralConfigSchema
  )
  const [eventList, setEventList] = useState(
    playground?.events.map(JSON.stringify)
  )
  useEffect(() => {
    setEventList(playground?.events)
  }, [playground, playground?.events])
  const [playGroundCode, setPlayGroundCode] = useState(functionCode)

  const [playGroundRuleConfig, setPlayGroundRuleConfig] =
    useState(functionRuleConfig)
  const [stateList, setStateList] = useState([])

  const updateFunction = api.function.updateFunction.useMutation()
  const updateEventSubscription =
    api.function.updatePlayGroundEvent.useMutation()
  const executeFunctionSubscription = api.function.executeFunction.useMutation()

  const toast = useToast()
  const { open } = useDialog()

  const changeEventValue = (value, index) => {
    const newList = [...eventList]
    newList[index] = value
    setEventList(newList)
  }

  const isJsonString = (str) => {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }

  const updateEvent = async (eventId, playgroundId, index) => {
    if (!isJsonString(eventList[index])) {
      return toast.add(<Toast label="Enter valid Json event" type="error" />)
    }

    playground.events[index] = eventList[index]
    try {
      await updateEventSubscription.mutateAsync(playground)
      toast.add(
        <Toast
          label={t['Monitor:UpdatedPlayground'].get()}
          type="success"
          description={
            <Text>
              {t['Monitor:Playground'].get()} <b>{playground.name}</b> {t['Monitor:UpdatedSuccess'].get()}
            </Text>
          }
        />
      )
    } catch (e) {
      const trpcErrorObject = trpcErrorHandler(e.message)
      trpcErrorObject.map(
        (e: {
          code: string
          message: string
          path: string[]
          validation: string
        }) => {
          toast.add(
            <Toast label="Error" type="error" description={e.message} />
          )
        }
      )
      throw e
    }
  }
  // Todo: if the config schema is changed, to not parsable Json, it will throw an error and does not update the function

  const handleSubmit = async () => {
    const ruleFunction = {
      id: functionId,
      function: code,
      configSchema: generalConfigSchema,
      generalConfigSchema,
      userConfigSchema,
    }
    try {
      await updateFunction.mutateAsync(ruleFunction)
      setPlayGroundRuleConfig(
        generateExampleFromSchema(ruleFunction.configSchema)
      )
      toast.add(
        <Toast
          label={t['Monitor:UpdatedFunction'].get()}
          type="success"
          description={
            <Text>
             {t['Monitor:Function'].get()} <b>{name}</b> {t['Monitor:UpdatedSuccess'].get()}
            </Text>
          }
        />
      )
    } catch (e) {
      const trpcErrorObject = trpcErrorHandler(e.message)
      trpcErrorObject.map(
        (e: {
          code: string
          configSchema: string
          message: string
          path: string[]
          validation: string
        }) => {
          toast.add(
            <Toast label="Error" type="error" description={e.message} />
          )
        }
      )
      throw e
    }
  }

  const executeFunction = async () => {
    if (eventList.length === 0) {
      return toast.add(
        <Toast label={t['Monitor:PleaseAddEventBeforeProceeding'].get()} type="error" />
      )
    }
    const newStateList = []
    let state = {}
    for (let i = 0; i < eventList.length; i++) {
      const payload = {
        function: playGroundCode,
        ruleConfig: JSON.parse(playGroundRuleConfig),
        event: JSON.parse(eventList[i]),
        state,
      }
      //console.log('ruleConfig:  ', payload.ruleConfig)
      state = await executeFunctionSubscription.mutateAsync(payload)
      newStateList.push(state)
      setStateList([...newStateList])
    }
  }
  return (
    <div className="flex flex-col">
      <h1 className="text-txt font-semibold m-4" style={{ marginBottom: '16px' }}>
        {name}
      </h1>
      <div className="min-w-full align-middle m-2">
        <Tabs activeTab={0}>
          <Tab label={t['Monitor:Editor'].get()} icon={EditIcon}>
            <Button
              onClick={() => {
                handleSubmit()
                setPlayGroundCode(code)
              }}
              disabled={
                !isJsonString(generalConfigSchema) ||
                !isJsonString(userConfigSchema)
              }
              style={{ marginTop: '10px', marginRight: '30px', float: 'right' }}
            >
              {t['Monitor:Save'].get()}
            </Button>
            <TabContent>
              <Code onChange={(value) => setCode(value)} value={code} />
            </TabContent>
          </Tab>
          <Tab label={t['Monitor:Playground'].get()} icon={CliIcon}>
            <Button
              style={{ marginTop: '10px', marginRight: '30px', float: 'right' }}
              icon={<AddIcon />}
              onClick={async () => {
                await open(
                  <AddEventDialog
                    functionId={functionId}
                    playground={playground}
                  />
                )
              }}
            >
              {t['Monitor:AddEvents'].get()}
            </Button>
            <TabContent>
              <Code
                onChange={(value) => setPlayGroundCode(value)}
                value={playGroundCode}
              />
              <div
                style={{ marginTop: '10px', marginBottom: '10px' }}
              >
                <h1 className="font-semibold">
                  {t['Monitor:RuleConfigValues'].get()}
                </h1>
                <Callout
                    color="babyblue" // normal blue does not work
                    outline
                    label={t['Monitor:RuleConfigValuesInfoTitle'].get()}
                    icon={<InfoIcon />}
                  >
                  <Text>{t["Monitor:RuleConfigValuesInfoText"].get()}</Text>
                </Callout>
              </div>
              <Code
                onChange={(value) => setPlayGroundRuleConfig(value)}
                value={playGroundRuleConfig}
              />
              <h1
                className="font-semibold"
                style={{ marginTop: '10px', marginBottom: '10px' }}
              >
                {t['Monitor:InitialValues'].get()}
              </h1>

              <Accordion>
                {playground?.events ? (
                  <>
                    {eventList?.map((event, index) => (
                      <AccordionItem key={index} label={t['Monitor:Event'].get() + `${index + 1}`}>
                        <Code
                          key={event.id}
                          onChange={(value) => changeEventValue(value, index)}
                          topRight={
                            <UploadIcon
                              className="cursor-pointer"
                              onClick={() =>
                                updateEvent(event.id, playground.id, index)
                              }
                            />
                          }
                          value={event}
                        />
                      </AccordionItem>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </Accordion>
              <Container
                topLeft={<Text weight={700}>{t['Monitor:TestOutput'].get()}</Text>}
                topRight={<Button onClick={executeFunction}>{t['Monitor:Run'].get()}</Button>}
              >
                {stateList?.map((value, index) => (
                  <Card key={index} style={{ margin: '10px' }}>
                    { t['Monitor:Result'].get()+ `:${index + 1}: `}
                    {JSON.stringify(value)}
                  </Card>
                ))}
              </Container>
            </TabContent>
          </Tab>
        </Tabs>

        <Tabs activeTab={0}>
          <Tab label={t['Monitor:General'].get()}>
            <TabContent>
              <Code
                onChange={(value) => setGeneralConfigSchema(value)}
                value={generalConfigSchema}
              />
              {!isJsonString(generalConfigSchema) && (
                <div className="pr-2 pt-2 text-red-600 font-bold red flex flex-row items-center">
                  <ErrorIcon
                    style={{
                      height: '17px',
                      width: '17x',
                    }}
                  />
                  {t['Monitor:InvalidJson'].get()}
                </div>
              )}
            </TabContent>
          </Tab>
          <Tab label={t['Monitor:User'].get()}>
            <TabContent>
              <Code
                onChange={(value) => setUserConfigSchema(value)}
                value={userConfigSchema}
              />
              {!isJsonString(userConfigSchema) && (
                <div className="pt-2 text-red-600 font-bold red flex flex-row items-center">
                  <ErrorIcon
                    style={{
                      height: '17px',
                      width: '17x',
                    }}
                  />
                  {t['Monitor:InvalidJson'].get()}
                </div>
              )}
            </TabContent>
          </Tab>
        </Tabs>
      </div>
    </div>
  )
}

function generateExampleFromSchema(schema: object): string {
  function generatePropertyExample(propertySchema: object): any {
    if (propertySchema.hasOwnProperty('type')) {
      switch (propertySchema['type']) {
        case 'string':
          return 'ExampleString'
        case 'number':
          return 42
        case 'integer':
          return 42
        case 'boolean':
          return true
        case 'object':
          if (propertySchema.hasOwnProperty('properties')) {
            const objExample: { [key: string]: any } = {}
            for (const key in propertySchema['properties']) {
              if (propertySchema['properties'].hasOwnProperty(key)) {
                objExample[key] = generatePropertyExample(
                  propertySchema['properties'][key]
                )
              }
            }
            return objExample
          } else {
            return {}
          }
        case 'array':
          if (propertySchema.hasOwnProperty('items')) {
            const arrayExample: any[] = []
            for (let i = 0; i < 2; i++) {
              arrayExample.push(
                generatePropertyExample(propertySchema['items'])
              )
            }
            return arrayExample
          } else {
            return []
          }
        default:
          return null
      }
    } else {
      // If 'type' is not specified, return an empty object.
      return {}
    }
  }

  return JSON.stringify(generatePropertyExample(schema))
}

export default FunctionEditor
