import React, { useEffect } from 'react'
import { Button } from '@colombalink/basedui'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'
import { RowMenu } from './RowMenu'
import { api } from '@shared/index'
import { useRouteParams } from '@shared/router'
import { useTable } from '@shared/components/core/table/Table'
import { useT } from '@app/i18n'

type PageProps = {
  params: {thingAliasId:string} //{ orgId: string; thingId: string; projectId: string }
}
export type PageParams = PageProps['params']

export const AlarmsFragment = () => {
  //const { thingId, orgId, projectId } = useRouteParams<PageParams>()
  return (
    <div className="m-4">
      <div className="flex flex-col">
        <AlarmTable />
      </div>
    </div>
  )
}


function AlarmTable() {
  const t = useT()

  const { thingAliasId } = useRouteParams<PageParams>()

  const monitors = api.monitor.getAllMonitorsInAlarmStateByThing.useLiveData({thingAliasId})
  const [tableData, setTableData] = React.useState([])

  const clearAlarm = api.monitor.clearAlarm.useMutation()
  const deactivateAlarm = api.monitor.deactivateAlarm.useMutation()

  const [table, TableComponent] = useTable({
    header: [
      { label: t["Thing:MonitorName"].get(), key: 'name' },
      { label: t["Thing:StatusMessage"].get(), key: 'statusMessage' },
      { label: t["Thing:Status"].get(), key: 'status' },
      { label: '', key: 'actions' },
    ],
    data: tableData,
  })


  useEffect(() => {
    if (monitors.data) {
      setTableData(
        monitors.data
          .filter((monitor) => monitor.status === 'alarm')
          .map((monitor) => {
            return {
              ...monitor,
              statusMessage: (<div className='flex flex-col'>
                <b>{monitor?.statusMessage?.title}</b>
                <span>{monitor?.statusMessage?.message}</span>
              </div>),
              actions: (<div className='flex space-x-2 justify-end
            '>
                <Button
                  onClick={async () => {
                    await clearAlarm.mutateAsync({ monitorId: monitor.id })
                  }}
                >
                  {t["Thing:Cancel"].get()}
                </Button>

                {
                  monitor.activeState === 'active' && (
                    <Button

                      onClick={async () => {
                        await deactivateAlarm.mutateAsync({ monitorId: monitor.id })
                      }}
                    >
                      {t["Thing:Deactivate"].get()}
                    </Button>
                  )
                }
              </div>),
              customAttribute: {},
            }
          })
      )
    }
  }, [monitors.data])

  if (monitors.loading) {
    return (
      <>
        {t["Thing:Loading"].get()} <LoadingIcon />
      </>
    )
  }

  if(tableData.length === 0) {
    return (
      <>
        {t["Thing:NoAlarms"].get()}
      </>
    )
  }

  return <>{TableComponent}</>
}
