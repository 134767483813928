import { Dialog, Input, Text, Toast, useToast, Select, Label, Spacer } from '@colombalink/basedui'
import React from 'react'
import { api } from '@shared/index'
import { ZodInputValidator } from '@shared/zod/InputValidator'
import { EmailSelection } from '../../../../../app/pages/Orgs/Monitor/MonitorNotificationGroup/shared/EmailSelection'
import { updateNotificationGroupParams } from '@server/app'
import { useT } from '@app/i18n'
import i18n from '../../i18n'
import { WithI18n } from '@shared/i18n/withI18n'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'

type EditProps = {
  group: ReturnType<typeof api.organization.monitor.notification.group.getAll.useLiveData>['data'][0]
}

const EditNotificaitonGroup = (props: EditProps) => {
  const t = useT()
  const updateGroup = api.organization.monitor.notification.group.update.useMutation()
  const [updateGroupArgs, setCreateGroupArgs] = React.useState({
    ...props.group 
  })

  const toast = useToast()

  if (!updateGroupArgs) {
    return <LoadingIcon />
  }

  return (
    <Dialog label={t["MonitorGroup:EditGroup"].get()}>
      <div>
        <Text>
          {t["MonitorGroup:CreateNotificationGroup"].get()} <br />
          {t["MonitorGroup:VariablesAre"].get()}
        </Text>
        <Spacer />
        <Input
          description= {t["MonitorGroup:Name"].get()}
          value={updateGroupArgs.name}
          pattern="true"
          error={(value) => ZodInputValidator(updateNotificationGroupParams, updateGroupArgs, "name")}
          onChange={(name: string) => {
            setCreateGroupArgs({ ...updateGroupArgs, name })
          }
          }
        />       
        <Spacer />
        <Input
          description= {t["MonitorGroup:Description"].get()}
          value={updateGroupArgs.description}
          pattern="true"
          error={(value) => ZodInputValidator(updateNotificationGroupParams, updateGroupArgs, "title")}
          onChange={(title: string) => {
            setCreateGroupArgs({ ...updateGroupArgs, title })
          }
          }
        />
        <Spacer />

        <Select
          value={updateGroupArgs.notificationGroupType}
          onChange={(notificationGroupType: string | undefined) => {
            setCreateGroupArgs({ ...updateGroupArgs, notificationGroupType })
          }}
          label={t["MonitorGroup:NotificationType"].get()}
          options={[
            { label: 'E-Mail', value: 'email' },
            { label: 'Monidas', value: 'monidas' },
          ]}
        />

        {
          updateGroupArgs.notificationGroupType === 'email' &&
          <>
            <Spacer />
            <EmailSelection
              values={updateGroupArgs.values}
              onChange={(values: string[]) => {
                setCreateGroupArgs({ ...updateGroupArgs, values })
              }}
            />
          </>
        }

        <Dialog.Buttons>
          <Dialog.Cancel displayShortcut={false}>{t["MonitorGroup:Close"].get()} </Dialog.Cancel>
          <Dialog.Confirm displayShortcut={false} onConfirm={async () => {
            try {
              await updateGroup.mutateAsync(updateGroupArgs)
              toast.add(
                <Toast
                  label={t["MonitorGroup:UpdatedGroup"].get()}
                  type="success"
                  description={
                    <Text>
                      {t["MonitorGroup:Group"].get()} <b>{updateGroupArgs.name}</b> {t["MonitorGroup:GroupEditedSuccessfully"].get()}
                    </Text>
                  }
                />
              )
            } catch (e) {
              toast.add(
                <Toast
                  label={t["MonitorGroup:FailedToEditGroup"].get()}
                  type="error"
                  description={
                    <Text style={{ whiteSpace: 'normal' }}>
                      {e.message}
                    </Text>
                  }
                />
              )
              throw e
            }
          }}>{t["MonitorGroup:Save"].get()} </Dialog.Confirm>
        </Dialog.Buttons>
      </div>
    </Dialog>
  )
}



export default (props)=> (
  <WithI18n i18n={i18n}>
    <EditNotificaitonGroup {...props}  />
  </WithI18n>
)