import '@shared/polyfills/default.js'
import { createRoot } from 'react-dom/client'
import { Provider as BasedProvider } from '@colombalink/basedui'

import '@shared/logger'
import '@shared/styles/tailwind/index.css'
import { Router } from 'wouter'
import { CustomSwitch } from '../shared/router'
import defaultRoutes from './routes'
import { NavigationContextProvider, NavigationItem, navigation } from '@shared/components/page/navigation/context'
import { Authorizer } from '@shared/components/core/Authorize'
import { SetTrcpClientOptions, WithTrpcProvider } from '@shared/trpc/Provider'

import { LocalStorageContext } from '@shared/utils/react/LocalStorage/context'
import { I18nextProvider } from 'react-i18next'
import i18n from '@shared/i18n'
import i18nGlobal from './nav/i18n'
import { WithI18LanguageSwitcher } from '@shared/i18n/with18nLanguageSwitcher'
import { WithI18n } from '@shared/i18n/withI18n';
import { ClientsContext, useIdentityClient } from '@shared/client/context'
import { config } from './config'
import { log } from '@shared/logger'
import { UserNavigationContentLoader } from './nav/useNavigation'

let rootEl = document.getElementById('root')
if (!rootEl) {
  rootEl = document.createElement('div')
  rootEl.id = 'root'
  document.body.appendChild(rootEl)
}

log.info(config, 'Starting app with config')

const Root = () => {
  return (
    <ClientsContext options={config} >
      <Authorizer>
        <I18nextProvider i18n={i18n}>
          <WithI18n i18n={i18nGlobal}>
            <LocalStorageContext>
              <WithTrpcProvider >
                <BasedWrapper>
                  <WithI18LanguageSwitcher />
                  <Router base="">
                    <SetTrcpClientOptions>
                        <NavigationContextProvider routes={defaultRoutes}>
                          <UserNavigationContentLoader/>
                          <CustomSwitch defaultRoutes={defaultRoutes} />
                        </NavigationContextProvider>
                    </SetTrcpClientOptions>
                  </Router>
                </BasedWrapper>
              </WithTrpcProvider >
            </LocalStorageContext>
          </WithI18n>
        </I18nextProvider>
      </Authorizer>
    </ClientsContext>
  )
}

const BasedWrapper = ({ children }) => {
  const appClient = useIdentityClient()

  return (
    <BasedProvider client={appClient} theme='light'>
      {children}
    </BasedProvider>
  )
}

const root = createRoot(rootEl)
root.render(<Root />)
