import { DefaultProcedure } from '@server/app/trpc/router'
import { z } from 'zod'

export const createFunctionParams = z.object({
  name: z.string({
    required_error: 'Name is required',
  }),
  code: z.string({
    required_error: 'Function is required',
  }),
  configSchema: z.any(),
  language: z.string(),
})
export const createFunctionProcedure = (procedure: DefaultProcedure) =>
  procedure.input(createFunctionParams).mutation(async ({ ctx, input }) => {
    const res = await ctx.based.db.default.set({
      name: input.name,
      language: input.language,
      function: input.code,
      configSchema: input.configSchema,
      userConfigSchema: input.configSchema,
      type: 'ruleFunction',
      parents: [ctx.org.id],
    })
    ctx.logger.debug('function::createFunctionProcedure ', res)
  })
