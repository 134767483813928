import { useRouteParams } from "@shared/router"
import { api } from "@shared/index"
import { usePageContext } from "../context"
import { Input, Spacer } from "@colombalink/basedui"
import { PageParams } from "../Page"
import { useEffect, useState } from "react"
import { Title } from "@shared/components/core/Title"
import { useT } from "@app/i18n"
import { LoadingIcon } from '@shared/components/core/LoadingIcon'

export const PayloadSelectionFragment = () => {
    const t = useT()
    const { thingAliasId } = useRouteParams<PageParams>()
    const latestEvent = api.event.getLatestEventById.useLiveData({
        thingAliasId,
    })

    //console.log('latestEvent', latestEvent)
    const {
        useStep: [step, setStep],
        useSampleEvent: [sampleEvent, setSampleEvent],
        useFlatPaths: [flatPaths, setFlatPaths],
        useValidJSON: [validJson]
    } = usePageContext()

    const [setOnce, setSetOnce] = useState(false)
    useEffect(() => {
        //console.log("sampleEvent:", sampleEvent)

        if (setOnce || !latestEvent.data?.value) {
            return
        }

        //console.log('latestEvent.data?.value', latestEvent.data?.value)
        try {
            setSetOnce(true)
            setSampleEvent(JSON.stringify(latestEvent.data?.value || '{}'))
        } catch (e) {
            setSampleEvent("{}")
        }
    }, [latestEvent.data, sampleEvent])

    if (latestEvent.loading) {
        return <LoadingIcon></LoadingIcon>
    }


    const sampleJsonString: string =
        `{
        "temp": 27,
        ...
  }`

    return (
        <div>
            <Title>1) {t["ThingConfigure:PayloadDefinition"].get()} </Title>
            {
                !latestEvent?.data?.value && (
                    <>
                        <p>{t["ThingConfigure:WeDidNotReceive"].get()} </p>
                        <p>{t["ThingConfigure:PleaseUse"].get()} </p>
                        <p>{t["ThingConfigure:OtherwiseYouCan"].get()} </p>
                        <p>{t["ThingConfigure:ForGenericThing"].get()} </p>
                    </>
                )
            }

            <Spacer />
            <Input
                type="json"
                description={t["ThingConfigure:PayloadSample"].get()}
                placeholder={sampleJsonString}
                value={sampleEvent}
                onChange={setSampleEvent}
            />

            {!validJson && (t["ThingConfigure:NotValidJSON"].get())}
        </div>
    )
}

