const NavigationContentI18n = {
    'Navigation:Notifications': {
        en: 'Notifications',
        de: 'Benachrichtigungen'
    },
    'Navigation:Notification': {
        en: 'Notification',
        de: 'Benachrichtigung'
    },
    'Navigation:Profile': {
        en: 'Profile',
        de: 'Profil'
    },
    'Navigation:Settings': {
        en: 'Settings',
        de: 'Einstellungen'
    },
    'Navigation:Organizations': {
        en: 'Organizations',
        de: 'Organisationen'
    },
    'Navigation:Organization': {
        en: 'Organization',
        de: 'Organisation'
    },
    'Navigation:Members': {
        en: 'Members',
        de: 'Mitglieder'
    },
    'Navigation:AuditLog': {
        en: 'Audit Log',
        de: 'Audit-Log'
    },
    'Navigation:Integrations': {
        en: 'Integrations',
        de: 'Integrationen'
    },
    'Navigation:Quota': {
        en: 'Quota',
        de: 'Quote'
    },
    'Navigation:ApiTokens': {
        en: 'Api Tokens',
        de: 'Api-Tokens'
    },
    'Navigation:Functions': {
        en: 'Functions',
        de: 'Funktionen'
    },
    'Navigation:Actions': {
        en: 'Actions',
        de: 'Aktionen'
    },
    'Navigation:Templates': {
        en: 'Templates',
        de: 'Vorlagen'
    },
    'Navigation:Groups': {
        en: 'Groups',
        de: 'Gruppen'
    },
    'Navigation:Monitors': {
        en: 'Monitors',
        de: 'Überwachungen'
    },
    'Navigation:Monitor': {
        en: 'Monitor',
        de: 'Überwachung'
    },
    'Navigation:Documentation': {
        en: 'Documentation',
        de: 'Dokumentation'
    },
    'Navigation:Projects': {
        en: 'Projects',
        de: 'Projekte'
    },
    'Navigation:Project': {
        en: 'Project',
        de: 'Projekt'
    },
    'Navigation:ProjectOverview': {
        en: 'Project Overview',
        de: 'Projektübersicht'
    },
    'Navigation:Editor': {
        en: 'Editor',
        de: 'Editor'
    },
    'Navigation:Configure': {
        en: 'Configure',
        de: 'Konfigurieren'
    },
    'Navigation:Devices': {
        en: 'Devices',
        de: 'Geräte'
    },
    'Navigation:Introduction': {
        en: 'Introduction',
        de: 'Einführung'
    },
    'Navigation:Account': {
        en: 'Account',
        de: 'Konto'
    },
    'Navigation:Invitation': {
        en: 'Invitation',
        de: 'Einladung'
    },
    'Navigation:Registration': {
        en: 'Registration',
        de: 'Anmeldung'
    },
}

export default NavigationContentI18n
export type NavigationContentI18nType = typeof NavigationContentI18n
