import Logo from '@shared/assets/monidas-icon-logo.svg'
import './index.css'
import { useEffect, useState } from 'react'

export const LoadingIcon = () => {
  const [opacity, setOpacity] = useState('opacity-0')
  useEffect(() => {
    const t =  setTimeout(() => {
      setOpacity('opacity-100')
    }, 300)

    return () => {
      clearTimeout(t)
    }
  }, [])

  return (
    <div>
      <Logo
        style={{ width: '25px', height: '25px' }}
        className={`transition-opacity duration-1000 loading-icon ` + opacity}
      />
    </div>
  )
}
