import EditNotificaitonTemplate from "./EditDialog"
import { FC, useState } from "react"
import { OptionMenu } from "@shared/components/core/EditEntityMenu"
import { api } from "@shared/index"
import { useDialog, useToast, ContextItem, Toast, Text, EyeIcon, EditIcon, DeleteIcon } from "@colombalink/basedui"
import { useLocation } from "wouter"
import { useLink } from "../../../routes"
import { useRouteParams } from "../../../../shared/router"
import { PageParams } from "./Page"
import { useT } from "@app/i18n"

type RowMenuProps = {
  thing: ReturnType<typeof api.thing.getAll.useLiveData>['data'][0]
}



export const RowMenu = (props: RowMenuProps) => {
  const t = useT()
  const { orgAliasId, projectAliasId } = useRouteParams<PageParams>()
  const CategoryMenu: FC<{}> = ({ }) => {
    const { open } = useDialog()
    const [, setLocation] = useLocation()
    const toast = useToast()
    const [hidden, setHidden] = useState('hidden')
    const deleteThing = api.thing.delete.useMutation()
    const getChirpstackDeviceUrl = api.integrations.chirpstack.getDeviceUrl.useData({ thingAliasId: props.thing.alias })

    return (
      <>
        <ContextItem
          onClick={() => {
            open(<EditNotificaitonTemplate thing={props.thing} />)
          }}
          icon={EditIcon}
        >
          {t['Devices:Edit'].get()}
        </ContextItem>
        <ContextItem
          onClick={() => {
            setLocation(useLink('/org/:orgAliasId/project/:projectAliasId/thing/:thingAliasId', { orgAliasId, projectAliasId, thingAliasId: props.thing.alias }))
          }}
          icon={EditIcon}
        >
          {t['Devices:Configure'].get()}
        </ContextItem>
        <ContextItem
          onClick={() => {
            window.open(getChirpstackDeviceUrl.data, '_blank')
          }}
          icon={EyeIcon}
        >
          {t['Devices:OpenInChirpstack'].get()}
        </ContextItem>
        <ContextItem
          onClick={async () => {

            await deleteThing.mutateAsync({ thingAliasId: props.thing.alias, projectAliasId: projectAliasId })

            toast.add(
              <Toast
                label={t['Devices:DeletedDevice'].get()}
                type="success"
                description={
                  <Text>
                    {t['Devices:DeletedSuccessfully'].get()}
                  </Text>
                }
              />
            )
          }}
          icon={DeleteIcon}
        >
          {t['Devices:Delete'].get()}
        </ContextItem>
      </>
    )
  }
  return <OptionMenu menu={CategoryMenu} style={{ width: '200px' }} />
}
