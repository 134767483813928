import { PageRoleSwitcher } from "@shared/components/page/PageRoleSwitcher"
import { OwnerPage } from "./Page.owner"
import { SupervisorPage } from "./Page.supervisor"

type PageProps = { params: { orgAliasId: string } }
export type  PageParams = PageProps['params']

const DefaultPage = () => (<SupervisorPage/>) 
DefaultPage.displayName = 'DefaultPage'

export default (props: PageProps) => {
  return (
    <PageRoleSwitcher>
      <OwnerPage />
      <DefaultPage />
    </PageRoleSwitcher>
  )
} 