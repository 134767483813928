import { DefaultProcedure } from '@server/app/trpc/router'
import { z } from 'zod'
import { createOrgAuditLogger } from '../audit-log/shared/writeLog'
import { TRPCError } from '@trpc/server'
import { createDefaultOrganizaionQuota } from './quotas/createDefault'

export const createOrgParams = z.object({
  // name is the display name  
  name: z.string()
    .refine(value => value.length <= 100, 'Organization name should not exceed 100 characters.')
    .refine(value => /^[a-zA-Z0-9 \-]+$/.test(value), 'Name can only contain alphabets, numbers, dashes, and spaces.')
  ,
  // alias is the name transformed to lowercase and spaces as dashes
  alias: z.string({
    required_error: 'Please provide an organization name.'
  })
    .refine(value => value.length <= 100, 'Organization name should not exceed 100 characters.')
    .refine(value => /^[a-zA-Z0-9\-]+$/.test(value), 'Name can only contain alphabets, numbers, dashes, and spaces.')
})

export const createOrganizationProcedure = (procedure: DefaultProcedure) =>
  procedure
    .input(createOrgParams)
    .mutation(async ({ ctx, input }) => {
      ctx.logger.debug('procedure::createOrganization inputAlias:', input?.alias)

      let org: null | {id: string} = null
      try {
        const alias = `org/${input.alias}`
        // todo: check that name and alias are conforming with the rules

        // todo: check that org does not exist yet
        const res = await ctx.based.db.default.get({
          $alias: alias,
          id: true
        })

        if(res.id){
          ctx.logger.error('procedure::createOrganization The org already exists')
          throw new TRPCError( {message: ctx.i18n.t["AppOrg:OrgAlreadyExists"].get(), code: "CONFLICT"})
        }

        // todo: if something fails here we need to rollback
        const memberToRole = await ctx.based.db.default.set({
          type: 'memberToRole',
          user: ctx.user.id,
          role: "OWNER",
        })

        
        org = await ctx.based.db.default.set({
          name: input.name,
          aliases: [alias],
          members: {
            $add: memberToRole.id,
          },
          type: 'organization',
        })

        await createDefaultOrganizaionQuota(ctx.based, org.id)

        await ctx.based.db.default.set({
          $id: ctx.user.id,
          organizations: {
            $add: [org.id],
          }
        })

        // init auditlog for org
        await ctx.based.call('auditLog:append', { userId: ctx.user.id, proOrOrgId: org.id, message: `Organization ${input.alias} created`, data: {}, influx: ctx.influxDb })

        // we can not use the project audit log from the context as it is not ready yet
        const appendToOrgAuditLog = (await createOrgAuditLogger(
          {
            based: ctx.based,
            influx: ctx.influxDb,
            userId: ctx.user.id,
            orgId: org.id,
          }
        ))

        await appendToOrgAuditLog({ message: `Created organization ${input.alias}`, data: {} })

        ctx.logger.debug('procedure::createOrganization orgId:', org.id)

        return { id: org.id, alias: alias };

      } catch (err) {
        ctx.logger.error('procedure::createOrganization err:', err) 
        if(org) {
          // await deleteObjectByPolicy(org.id, ctx.based)
          await ctx.based.db.default.delete({ $id: org.id })
        }
        throw ctx.i18n.t["AppOrg:FailedToCreateOrg"].get()
      }
    })
