
const ProfilePageContentI18n = {
    'Profile:AccountDetails': {
        en: 'Account Details',
        de: 'Konto Informationen',
    },
    'Profile:Name': {
        en: 'Username',
        de: 'Benutzername',
    },
    'Profile:FirstName': {
        en: 'First Name',
        de: 'Vorname',
    },
    'Profile:LastName': {
        en: 'Last Name',
        de: 'Nachname',
    },
    'Profile:Email': {
        en: 'Email',
        de: 'E-mail',
    },
    'Profile:NameNotChange': {
        en: 'Username can not be changed',
        de: 'Benutzername kann nicht geändert werden',
    },
    'Profile:EmailNotChange': {
        en: 'Email can not be changed',
        de: 'E-mail kann nicht geändert werden',
    },
    'Profile:Password': {
        en: 'Password',
        de: 'Passwort',
    },  
    'Profile:ResetPassword': {
        en: 'Reset Password',
        de: 'Passwort zurücksetzen',
    },
    'Profile:ConfirmPassword': {
        en: 'Confirm Password',
        de: 'Passwort bestätigen',
    },
    'Profile:Save': {
        en: 'Save',
        de: 'Speichern',
    },
    'Profile:Cancel': {
        en: 'Cancel',
        de: 'Abbrechen',
    },
    'Profile:Close': {
        en: 'Close',
        de: 'Schliessen',
    },
    'Profile:Delete': {
        en: 'Delete',
        de: 'Löschen',
    },
    'Profile:View': {
        en: 'View',
        de: 'Ansehen',
    },



}


export default ProfilePageContentI18n
export type ProfilePageContentI18nType = typeof ProfilePageContentI18n
