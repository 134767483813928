import Home from './pages/User/Home'
import OrganizationProjectsEditPage from './pages/Orgs/ProjectsEditor'
import OrganizationProjectsPage from './pages/Orgs/Projects'

import OrganizationsHomePage from './pages/Orgs/Home'
import OrganizationsMembersPage from './pages/Orgs/Members'
import OrganizationSettingsQuotas from './pages/Orgs/SettingsQuota'
import OrganizationSettingsApiTokens from './pages/Orgs/SettingsApiTokens'
import UserOrganizationsPage from './pages/User/Organizations'
import OrganizationIntegrations from './pages/Orgs/Integrations'
import OrganizationAuditLog from './pages/Orgs/AuditLog'

import MonitorFunctionsPage from './pages/Orgs/Monitor/MonitorFunctions'
import MonitorFunctionEditDialog from '../shared/components/monitor/function/dialogs/EditDialog'
import MonitorActionsPage from './pages/Orgs/Monitor/MonitorActions'
import MonitorNotificationGroupPage from './pages/Orgs/Monitor/MonitorNotificationGroup'
import MonitorNotificationTemplatePage from './pages/Orgs/Monitor/MonitorNotificationTemplate'

import UserProfilePage from './pages/User/Profile'
import UserNotificationsPage from './pages/User/Notifications'
import UserProfileChangePasswordPage from './pages/User/ProfileChangePassword'
import UserSettingsPage from './pages/User/Settings'

import ProjectPage from './pages/Orgs/Project'
import ProjectAuditLogPage from './pages/Orgs/ProjectAuditLog'
import ProjectQuotaPage from './pages/Orgs/ProjectQuota'
import ProjectDevices from './pages/Orgs/Things'
import ProjectMembersPage from './pages/Orgs/ProjectsMembers'
import Thing from './pages/Orgs/Thing'
import ThingConfigure from './pages/Orgs/ThingConfigure'

import Invitation from './pages/Login/Invitation'
import Forgot from './pages/Login/Forgot'
import Login from './pages/Login'
import GlobalSettings from './pages/settings'
import { setAliasesId } from '../shared/router'
import Registration from './pages/Login/Invitation/Registration'
import InvitationLogin from './pages/Login/Invitation/Login'
import InvitationJoin from './pages/Login/Invitation/Join'
import Reset from './pages/Login/Reset'

import DefaultPage from './pages/Default'

import Doc from './pages/Doc'


export const  routesWithDoc = {
  ...Registration,
  ...Invitation,
  ...InvitationLogin,
  ...InvitationJoin,
  ...Login,
  ...Forgot,
  ...Reset,

  ...Home,
  ...UserOrganizationsPage,
  ...UserProfilePage,
  ...UserProfileChangePasswordPage,
//   ...UserNotificationsPage,
  ...UserSettingsPage,
  ...OrganizationsHomePage,

  ...OrganizationProjectsPage,
  ...OrganizationProjectsEditPage,
  ...OrganizationsMembersPage,
  ...OrganizationIntegrations,
  ...OrganizationSettingsQuotas,
  ...OrganizationSettingsApiTokens,
  ...OrganizationAuditLog,

  ...MonitorActionsPage,
  ...MonitorFunctionsPage,
  ...MonitorFunctionEditDialog,
  ...MonitorNotificationGroupPage,
  ...MonitorNotificationTemplatePage,

  ...ProjectPage,
  ...ProjectAuditLogPage,
  ...ProjectQuotaPage,
  ...ProjectDevices,
  ...ProjectMembersPage,
  ...Thing,
  ...ThingConfigure,
  ...Doc,

}


let defaultRoutes = {
  ...routesWithDoc,
  ...Doc,
  



  '/settings/global': {
    params: false,
    path: '/settings/global',
    page: GlobalSettings,
  },

  ...DefaultPage
}

/// ////////////////////////////////////////////////////
// # Routes TYPE
/// ///////////////////////////////////////////////////
type ComponentWithParams<P extends object> = (params: P) => JSX.Element

type RouteDefinition = {
  page: ComponentWithParams<any>
  path?: string
}

export type Routes = Record<string, RouteDefinition>

type RouteParams<T> = T extends { params: infer P } ? P : never

function createUseLink<T extends Routes>(routes: T) {
  return function useLink<Path extends keyof T>(
    path: Path,
    params: RouteParams<Parameters<T[Path]['page']>[0]>
  ) {
    if (!routes[path]) return
    const component = routes[path].page
    let link = path as string
    params = setAliasesId(params)
    for (const key of Object.keys(params)) {
        link = link.replace(`:${key}`, params[key])
    }
    return link
  }
}

const withPath = (routes: Routes) => {
  for (const path of Object.keys(routes)) {
    routes[path].path = path
  }
  return routes
}

export const useLink = createUseLink(defaultRoutes)

export default withPath(defaultRoutes)

