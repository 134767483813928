export function getValueOfGenericProperty(instance, property: string) {
  try {
    if (!instance && property === '') return undefined
    const path = property.split('.')
    let result = instance
    for (const segment of path) {
      result = result[segment]
    }
    return result
  } catch (e) {
    /* WHY?
       We need to catch this error because it happens that some properties are not defined in the payload.

       For us this first happened when adding and removing multiple gateways.
        - rx.0.gateway.name
        - rx.1.gateway.name

      The array positions are hardcoded in the property path, so if not present we will run into an error.
     */
    return undefined
  }
}
