import { ZodObject } from "zod";

/**
 * Validates input values against a provided Zod schema and generates error messages for a specific input field.
 *
 * @param {ZodObject<any>} schema - The Zod schema to validate input values against.
 * @param {any} values - The input values to be validated against the schema.
 * @param {string} path - The path to the input field that you want to validate. i.e "name" or "address.street"
 *
 * @returns {JSX.Element | null} A JSX element containing error messages for the specified input field, or null if no errors are found.
 */
export const ZodInputValidator = (schema: ZodObject<any>, values, path?: string ) => {
    const message = []
    try {
        const validatedName = schema.parse(values);
    } catch (e) {
        if(!path) {
            return e.errors.map((error) => <p>{error.message}</p>)
        }
        for (const error of e.errors) {
            if (error.path.join(".") === path) {
                message.push(<p>{error.message}</p>)
            }
        }
       
        if(message.length === 0) return null
        // todo: fix this in @colombalink/basedui but for now we just return a string
        return <>{message}</> as unknown as string
    }
}