import { useRouteParams } from '@shared/router'
import React, { useEffect } from 'react'
import { useT } from '@app/i18n'
import { api } from '@shared/index'
import { useTable } from '@shared/components/core/table/Table'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'
import { RowMenu } from '@app/pages/Orgs/Thing/Fragments/Monitors/RowMenu.supervisor'
import { ToggleGroup, ToggleIcon } from '@colombalink/basedui'
import { WithI18n } from '@shared/i18n/withI18n';
import i18n from './i18n';

type PageProps = {
  params: { thingAliasId: string }
}

export type PageParams = PageProps['params']

export const MonitorsFragment = () => {
  return (
    <WithI18n i18n={i18n}>
      <div className="">
        <div className="flex flex-col">
          <MonitorsTable />
        </div>
      </div>
    </WithI18n>
  )
}

const MonitorsTable = () => {
  
  const t = useT()
  const { thingAliasId } = useRouteParams<PageParams>()

  const thing = api.thing.get.useLiveData({ thingAliasId })

  if (thing.loading) {
    return <div>{t['ThingMonitors:Loading'].get()}</div>
  }

  if (!thing.data) {
    return <div>{t['ThingMonitors:DeviceNotFound'].get()} </div>
  }

  return (
    <div className="flex flex-col">
      <AlarmsTable />
    </div>
  )
}

function AlarmsTable() {
  const t = useT()

  const { thingAliasId } = useRouteParams<PageParams>()
  const monitors = api.monitor.getAllAlarmsFromThing.useLiveData({
    thingAliasId,
  })

  const [tableData, setTableData] = React.useState([])

  const [table, TableComponent] = useTable({
    header: [
      { label: t['ThingMonitors:Name'].get(), key: 'name' },
      { label: t['ThingMonitors:Status'].get(), key: 'status' },
      {
        label: t['ThingMonitors:RuleFunction'].get(),
        key: 'ruleFunction.name',
      },
      { label: t['ThingMonitors:OperationalStatus'].get(), key: 'activeState' },
      { label: '', key: 'action' },
    ],
    data: tableData,
  })

  useEffect(() => {
    if (monitors.data) {
      console.log('monitors.data', monitors.data)
      setTableData(
        monitors.data.map((monitor) => {
          return {
            ...monitor,
            activeState: monitor.activeState === 'active'
              ? t['ThingMonitors:Active'].get()
              : t['ThingMonitors:Inactive'].get(),
            action: <RowMenu monitor={monitor} />,
            customAttribute: {},
          }
        })
      )
    }
  }, [monitors.data])

  if (monitors.loading) {
    return (
      <>
        {t['ThingMonitors:Loading'].get()} <LoadingIcon />
      </>
    )
  }
  return <>{TableComponent}</>
}
