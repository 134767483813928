export const statusColors = {
    default: {
        hsv: [142, 83, 76],
        border: "borde-gray-400",
        bg: 'bg-gray-400'
    },
    ['no-monitors']: {
        hsv: [142, 83, 76],
        border: "border-gray-400",
        bg: 'bg-gray-400'
    },
    idle:  {
        hsv: [142, 83, 76],
        border: "border-green-400", 
        bg: 'bg-green-400'
    },
    alert: {
        hsv: [24, 92, 98],
        // rgb(249, 115, 22)
        border: "border-orange-400",
        bg: 'bg-orange-400'
    },
    alarm: {
        // rgb(239, 68, 68)
        hsv: [0, 92, 98],
        border: "border-red-400",
        bg: 'bg-red-400'
    }
}