import based, { AuthState, BasedClient } from "@based/client";
import { log } from "@shared/logger";
import { useState } from "react";

type BasedOptions = ConstructorParameters<typeof BasedClient>[0];

export type Options = {
    deployment: 'blue' | 'green' | 'local' | 'e2e-test'
    services: {
        [key: string]: { // app, idenity, hub
            name?: string
            url: {
                ws: string
                http: string
            }
        }
    },
}

type Settings = ConstructorParameters<typeof BasedClient>[1];

// backend, todo export from hub function
type Service = {
    name?: string
    url: {
        ws: string
        http: string
    }
}

type DiscoveryResult = {
    services: Record<string, Service>
}

export interface MonidasClient {
    isReady: boolean
    getClient(serviceId): BasedClient
}


export class MonidasHubClient<ServiceIds> implements MonidasClient {
    private hubClient: BasedClient | null
    private serviceIds: ServiceIds[]
    private services: Map<ServiceIds, BasedClient> = new Map<ServiceIds, BasedClient>()
    private discoveryResult: Promise<DiscoveryResult>
    public isReady = false

    constructor(options: Options) {

        this.initHub(options)
        this.serviceIds = Object.keys(options.services).filter(s => s !== 'hub') as ServiceIds[]

        console.log('hub client', options.deployment)
        this.initServices();

    }

    private initHub(options: Options) {
        if (options?.services?.hub?.url?.ws) {
            this.hubClient = based({ url: options.services.hub.url.ws, env: 'app' })
            this.discoveryResult = this.hubClient.call('hub:service:discovery', { deployment: options.deployment })

        } else {
            this.hubClient = null
            this.discoveryResult = Promise.resolve({ services: options.services })
        }

    }

    private async initServices() {
        for (const serviceId of this.serviceIds) {
            this.services.set(serviceId, based({ url: () => this.loadUrl(serviceId) }, { persistentStorage: '/app/' }));
        }

        this.initAuthStateListener();
        this.initAuthToken();
        this.isReady = true
    }


    initAuthStateListener() {
        const client = this.getClient("identity")
        client.on('authstate-change', (state) => {
            this.saveAuthToken(client.authState);
            for (let serviceId of this.serviceIds as ServiceIds[]) {
                if (serviceId === 'identity') continue

                const otherClient = this.services.get(serviceId)


                if (client.authState.error) {
                    log.debug(`Identity client is in error state.`)
                    if (!this.hasEmptyAuthState(otherClient)) {
                        otherClient.setAuthState({})
                    }
                    continue
                }

                if (otherClient.authState?.token !== client.authState?.token) {
                    otherClient.setAuthState(client.authState);
                }
            }
        })
    }

    hasEmptyAuthState(client: BasedClient) {
        return Object.keys(client.authState).length === 0
    }

    private async loadUrl(serviceId: ServiceIds): Promise<string> {
        try {
            console.log('load url', serviceId)
            console.log('discoveryResult', await this.discoveryResult)
            const discoveryResult = await this.discoveryResult;
            const url = discoveryResult.services[serviceId as string].url.ws;
            if (!url) {
                throw new Error('Service url is empty.')
            }
            return url

        } catch (e) {
            console.error('Failed to load service url from discovery result. For serviceId:', serviceId);
            console.error(e)
        }
    }


    public getClient(serviceId: ServiceIds): BasedClient {
        return this.services.get(serviceId);
    }

    private initAuthToken(): void {
        const encodedToken = localStorage.getItem('@monias-authState');
        if (encodedToken) {
            const token = atob(encodedToken); // Decode the Base64-encoded token
            const authState = JSON.parse(token);
            for (const client of this.services.values()) {
                if (client.authState?.token === authState.token) {
                    continue;
                }
                client.setAuthState(authState);
            }
        }
    }

    private saveAuthToken(authState: AuthState): void {
        if (Object.keys(authState).length === 0 || authState.error) {
            console.log('Auth state is an empty object');
            localStorage.removeItem('@monias-authState');
            return;
        }
        const encodedAuthState = btoa(JSON.stringify(authState)); // Encode the token as Base64
        localStorage.setItem('@monias-authState', encodedAuthState);
    }



}




type AdminServiceIds = 'admin' | 'identity'

export class MonidasAdminClient<ServiceIds = AdminServiceIds> implements MonidasClient {
    private hubClient: BasedClient | null
    private serviceIds: ServiceIds[]
    private services: Map<ServiceIds, BasedClient> = new Map<ServiceIds, BasedClient>()
    private discoveryResult: Promise<DiscoveryResult>
    public isReady = false

    constructor(private options: Options) {
        console.log('hub client', options)
        this.initHub(options)
        this.serviceIds = Object.keys(options.services).filter(s => s !== 'hub') as ServiceIds[]

        console.log('hub client', options.deployment)
        this.initServices();
    }

    private initHub(options: Options) {
        if (options?.services?.hub?.url?.ws) {
            this.hubClient = based({ url: options.services.hub.url.ws, env: 'app' })
            this.discoveryResult = this.hubClient.call('hub:service:discovery', { deployment: options.deployment })

        } else {
            this.hubClient = null
            this.discoveryResult = Promise.resolve({ services: options.services })
        }
    }

    private async initServices() {
        for (const serviceId of this.serviceIds) {
            this.services.set(serviceId, based({ url: () => this.loadUrl(serviceId) }, { persistentStorage: '/app/' }));
        }

        this.initAuthStateListener();
        this.initAuthToken();
        this.isReady = true
    }


    initAuthStateListener() {
        const client = this.getClient("identity")
        client.on('authstate-change', (state) => {
            this.saveAuthToken(client.authState);
            for (let serviceId of this.serviceIds as ServiceIds[]) {
                if (serviceId === 'identity') continue

                const otherClient = this.services.get(serviceId)


                if (client.authState.error) {
                    log.debug(`Identity client is in error state.`)
                    if (!this.hasEmptyAuthState(otherClient)) {
                        otherClient.setAuthState({})
                    }
                    continue
                }

                if (otherClient.authState?.token !== client.authState?.token) {
                    otherClient.setAuthState(client.authState);
                }
            }
        })
    }

    hasEmptyAuthState(client: BasedClient) {
        return Object.keys(client.authState).length === 0
    }

    public getClient(serviceId: AdminServiceIds): BasedClient {
        return this.services.get(serviceId);
    }

    private async loadUrl(serviceId: ServiceIds): Promise<string> {
        try {
            console.log('load url', serviceId)
            console.log('discoveryResult', await this.discoveryResult)
            const discoveryResult = await this.discoveryResult;
            const url = discoveryResult.services[serviceId as string].url.ws;
            if (!url) {
                throw new Error('Service url is empty.')
            }
            return url

        } catch (e) {
            console.error('Failed to load service url from discovery result. For serviceId:', serviceId);
            console.error(e)
        }
    }


    private initAuthToken(): void {
        const encodedToken = localStorage.getItem('@monias-authState');
        if (encodedToken) {
            nv: 'app'
            const token = atob(encodedToken); // Decode the Base64-encoded token
            const authState = JSON.parse(token);
            for (const client of this.services.values()) {
                if (client.authState?.token === authState.token) {
                    continue;
                }
                client.setAuthState(authState);
            }
        }
    }

    private saveAuthToken(authState: AuthState): void {
        if (Object.keys(authState).length === 0 || authState.error) {
            console.log('Auth state is an empty object');
            localStorage.removeItem('@monias-admin-authState');
            return;
        }
        const encodedAuthState = btoa(JSON.stringify(authState)); // Encode the token as Base64
        localStorage.setItem('@monias-admin-authState', encodedAuthState);
    }

}