const ThingMonitorsPageContentI18n = {
    'ThingMonitors:Loading': {
        'en': 'Loading...',
        'de': 'Laden...',
    },
    'ThingMonitors:DeviceNotFound': {
        'en': 'Device not found.',
        'de': 'Gerät nicht gefunden.',
    },
    'ThingMonitors:TheThing': {
        'en': 'The Thing ',
        'de': 'Thing ',
    },
    'ThingMonitors:HasNotBeenConfigured': {
        'en': ' has not been configured yet.',
        'de': ' wurde noch nicht konfiguriert.',
    },
    'ThingMonitors:Configure': {
        'en': 'Configure',
        'de': 'Konfigurieren',
    },
    'ThingMonitors:EditPro': {
        'en': 'Edit Project Owner',
        'de': 'Bearbeiten des Projektinhabers',
    },
    'ThingMonitors:Edit': {
        'en': 'Edit',
        'de': 'Bearbeiten',
    },
    'ThingMonitors:RemovedMonitor': {
        'en': 'Removed monitor',
        'de': 'Überwachung entfernt',
    },
    'ThingMonitors:RemovedSuccessfully': {
        'en': 'Removed successfully',
        'de': 'Erfolgreich entfernt',
    },
    'ThingMonitors:Remove': {
        'en': 'Remove',
        'de': 'entfernen',
    },
    'ThingMonitors:Add': {
        'en': 'Add',
        'de': 'Hinzufügen',
    },
    'ThingMonitors:Name': {
        'en': 'Name',
        'de': 'Name',
    },
    'ThingMonitors:Status': {
        'en': 'Status',
        'de': 'Status',
    },
    'ThingMonitors:RuleFunction': {
        'en': 'Rule Function',
        'de': 'Regelfunktion',
    },
    'ThingsMonitors:UpdateMonitor': {
        'en': 'Updated monitor',
        'de': 'Überwachung aktualisiert',
    },
    'ThingMonitors:UpdatedSuccessfully': {
        'en': 'Updated successfully',
        'de': 'Erfolgreich aktualisiert',
    },
    'ThingMonitors:Confirm': {
        'en': 'Confirm',
        'de': 'Bestätigen',
    },
    'ThingMonitors:SomethingWentWrong': {
        'en': 'Something went wrong. We could not load the user config json schema. Please contact the project owner.',
        'de': 'Etwas ist schief gelaufen. Wir konnten das Benutzerkonfigurations-JSON-Schema nicht laden. Bitte kontaktieren Sie den Projektinhaber.'
    },
    'ThingMonitors:UserConfig': {
        'en': 'The user config json schema contains errors. Please contact the project owner.',
        'de': 'Das Benutzerkonfigurations-JSON-Schema enthält Fehler. Bitte kontaktieren Sie den Projektinhaber.'
    },
    'ThingMonitors:CreatedMonitor': {
        'en': 'Created monitor',
        'de': 'Überwachung erstellt',
    },
    'ThingMonitors:CreatedSuccessfully': {
        'en': 'Created successfully',
        'de': 'Erfolgreich erstellt',
    },
    'ThingMonitors:Monitor': {
        'en': 'Monitor',
        'de': 'Überwachung',
    },
    'ThingMonitors:FailedToCreateMonitor': {
        'en': 'Failed to create monitor',
        'de': 'Fehler beim Erstellen des Monitors',
    },
    'ThingMonitors:Close': {
        'en': 'Close',
        'de': 'Schliessen',
    },
    'ThingMonitors:EditedMonitor': {
        'en': 'Edited monitor',
        'de': 'Überwachung bearbeitet',
    },
    'ThingMonitors:FailedToUpdatedMonitor': {
        'en': 'Failed to update monitor',
        'de': 'Fehler beim Aktualisieren der Überwachung',
    },
    'ThingMonitors:EnterNameHere': {
        'en': 'enter name here',
        'de': 'Name hier eingeben',
    },
    'ThingMonitors:ActiveState': {
        'en': 'Active state',
        'de': 'Aktiver Zustand',
    },
    'ThingMonitors:RuleConfig': {
        'en': 'Rule config',
        'de': 'Regelkonfiguration',
    },
    'ThingMonitors:Actions': {
        'en': 'Actions',
        'de': 'Aktionen',
    },
    'ThingMonitors:StatusMessageTemplate': {
        'en': 'Status message template',
        'de': 'Statusnachricht Vorlage',
    },
    'ThingMonitors:Data': {
        'en': 'Data',
        'de': 'Daten',
    },
    'ThingMonitors:CreateNewMonitor': {
        'en': 'Create new monitor',
        'de': 'Neue Überwachung erstellen',
    },

    "ThingMonitors:OperationalStatus": {
        "en": "Operational Status",
        "de": "Betriebsstatus"
    },

}

export default ThingMonitorsPageContentI18n
export type ThingMonitorsPageContentI18nType = typeof ThingMonitorsPageContentI18n
