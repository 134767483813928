import EditNotificaitonGroup from "@shared/components/monitor/group/dialogs/EditDialog"
import { FC, useState } from "react"
import { OptionMenu } from "@shared/components/core/EditEntityMenu"
import { api } from "@shared/index"
import { useDialog, useToast, ContextItem, Toast, Text, EyeIcon, EditIcon, DeleteIcon } from "@colombalink/basedui"
import { useT } from "@app/i18n"

type RowMenuProps = {
  group: ReturnType<typeof api.organization.monitor.notification.group.getAll.useLiveData>['data'][0]
  isOwner: boolean
} 

export const RowMenu = (props: RowMenuProps) => {

  const CategoryMenu: FC<{}> = ({ }) => {
    const t = useT()
    const { open } = useDialog()
    const toast = useToast()

    const deleteGroup = api.organization.monitor.notification.group.delete.useMutation()

    return (
      <>
        <ContextItem
          onClick={() => {
            open(<EditNotificaitonGroup group={props.group} />)
          }}
          icon={EditIcon}
        >
          {t['Monitor:Edit'].get()}
        </ContextItem>
        {props.isOwner && (
          <ContextItem
            onClick={async () => {
              
              await deleteGroup.mutateAsync({ id: props.group.id })

              toast.add(
                <Toast
                  label={t['Monitor:RemovedGroup'].get()}
                  type="success"
                  description={
                    <Text>
                      {t['Monitor:RemovedSuccess'].get()}
                    </Text>
                  }
                />
              )
            }}
            icon={DeleteIcon}
          >
            {t['Monitor:Remove'].get()}
          </ContextItem>
        )}
      </>
    )
  }

  return <OptionMenu menu={CategoryMenu} style={{ width: '150px' }} />
}