import DefaultNavigation from '@shared/components/page/navigation/DefaultNavigation'
import { Error } from '@shared/components/errors/Error'
import { Page } from '@shared/components/page/Page'
import { api } from '@shared/index'
import { useEffect, useState } from 'react'
import { useRouteParams } from '../../../../shared/router'
import { useTable } from '@shared/components/core/table/Table'
import { DateWidget } from '@colombalink/basedui'
import { DatePicker } from '@shared/components/core/shadcn-ui/DatePicker'
import i18n from './i18n'
import { WithI18n } from '@shared/i18n/withI18n'
import { useT } from '@app/i18n'
import { log } from '@shared/logger'

type PageProps = { params: { orgAliasId: string } }
export type PageParams = PageProps['params']

const OrgAuditLogPage = (params: PageProps) => {
  return (
    <Page>
      <DefaultNavigation />
      <Main />
    </Page>
  )
}

const Main = () => (
  <WithI18n i18n={i18n}>
    <AuditLogMain />
  </WithI18n>
)
Main.displayName = 'Main'

export default OrgAuditLogPage

function AuditLogMain() {
  const t = useT()
  const [selectedDate, setSelectedDate] = useState(new Date())

  const { orgAliasId } = useRouteParams<PageParams>()
  const logList = api.auditLog.getOrganizationLog.useData({
    orgAliasId,
    date: new Date(selectedDate).toISOString(),
  })
  const [tableData, setTableData] = useState([])

  const [, TableComponent] = useTable({
    className: 'h-[95%] overflow-y-scroll mb-4 min-h-[35px]',
    header: [
      { label: t['AuditLog:Time'].get(), key: 'time' },
      { label: t['AuditLog:LogMessage'].get(), key: 'logMessage' },
      { label: t['AuditLog:UserName'].get(), key: 'author' },
      { label: t['AuditLog:UserEmail'].get(), key: 'authorEmail' },
      //{ label: 'Data', key: 'data' },
    ],
    data: tableData,
  })

  useEffect(() => {
    if (logList.data) {
      setTableData(
        logList.data.map((logList) => {
          try {
            const data = JSON.parse(logList.data)
            const authorInfo = JSON.parse(logList.author)

            return {
              time: t['intlDateTime'].get({
                val: new Date(new Date(logList.time).getTime()),
                formatParams: {
                  val: { dateStyle: 'short', timeStyle: 'short' },
                },
              }), // Directly copy the 'time' property
              author: authorInfo.name, // Extract the 'name' from the parsed 'author' JSON
              authorEmail: authorInfo.email, // Extract the 'email' from the parsed 'author' JSON
              logMessage: data.message, // Extract 'message' from the parsed 'data' JSON
              data: data.value, // Directly copy the 'data' property
            }
          } catch (error) {
            if (error instanceof SyntaxError) {
              log.error(t['AuditLog:ErrorJson'].get())
            } else {
              log.error(error ,t['AuditLog:ErrorUnexpected'].get())
            }
          }
        })
      )
    }
  }, [logList])

  if(logList.error){
    throw logList.error
  }

  return (
    <>
      {!logList.loading && (
        <>
          <PageSelector
            selectedDate={selectedDate}
            setDate={date => setSelectedDate(date)}
          />

          {logList.data.length > 0 ? (
            TableComponent
          ) : (
            <div className="flex flex-col items-center mt-4">
              <Error
                title={t['AuditLog:NoEntries'].get()}
                code=""
                message={<>
                 {t['AuditLog:DefaultTimePeriod'].get()}
                  <br />
                  {t['AuditLog:OlderThan'].get()}
                </>}
              />
            </div>
          )}
        </>
      )}
    </>
  )
}

const PageSelector = ({ setDate, selectedDate }) => {
  return (
  
    <div className="pb-2 flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:space-x-2 sm:justify-end">
      <DatePicker date={selectedDate} setDate={setDate} ></DatePicker>
    </div>
  )
}

AuditLogMain.displayName = 'AuditLogMain'

const getFormattedTime = (time: string) => {
  try {
    const date = new Date(time)
    return date.toLocaleString('de')
  } catch (e) {
    return ''
  }
}
