import { i18n } from "i18next";

export type CustomResource = {
    [key: string]: { // key
        // todo:  handle args type
        [key: string]: any // language key + value 
    }
}
export function updateI18nResource(i18n: i18n, i18nUpdate: CustomResource) {
    const resources = {}

    for (const key in i18nUpdate) {
        const [namespace, keyName] = key.split(':')
        for(const lang in i18nUpdate[key]) {
            if(!resources[namespace]) {
                resources[namespace] = {}
            }
            if(!resources[namespace][lang]) {
                resources[namespace][lang] = {}
            }
            resources[namespace][lang][keyName] = i18nUpdate[key][lang]
        }
    }

    for (const ns in resources) {
        for (const lang in resources[ns]) {
            i18n.addResourceBundle(lang, ns, resources[ns][lang], true, true)
        }
    }
}
