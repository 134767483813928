import { api } from "@shared/index"
import { useProjectDashboardContext } from "../DashboardContext"
import { AccordionItem } from "./shared/AccordeonItem"
import { Accordion } from "@radix-ui/react-accordion"
import { Filters } from "./shared/Filters"
import { NoThingFoundWithFilter } from "./shared/NoThingFoundWithFilter"
import { FragmentContainer } from "./shared/FragmentContainer"
import { MainContainer } from "./shared/MainContainer"
import { AsyncStateHandler } from "./shared/AsyncStateHandler"
import { LatestThingContainer } from "@shared/components/thing/LatestThingValuesContainer"

type Project = ReturnType<typeof api.project.getById.withThingsAndAlarms.useLiveData>['data']

export const LatestFragment = () => {
    const {
        things,
        useSelectedThingsIds: [selectedThingsIds],
        useOpenAccordionItems: [openAccordionItems, setOpenAccordionItems],
    } = useProjectDashboardContext()

    return (
        <AsyncStateHandler>
            <FragmentContainer>
                <Filters />
                <MainContainer>
                    <NoThingFoundWithFilter />
                    {
                        selectedThingsIds?.length > 0 && (
                            <Accordion
                                type="multiple"
                                value={openAccordionItems}
                                onValueChange={setOpenAccordionItems}
                            >
                                {selectedThingsIds.map((thingId, index) => (
                                    <AccordionItem
                                        alias={things.map[thingId]?.alias}
                                        key={thingId} id={thingId} title={things.map[thingId]?.name} status={things.map[thingId].status} updatedAt={things.map[thingId].lastEvent?.updatedAt}
                                    >
                                        <LatestThingContainer key={thingId} thingId={thingId} />
                                    </AccordionItem>
                                ))}
                            </Accordion>)
                    }
                </MainContainer>
            </FragmentContainer>
        </AsyncStateHandler>
    )
}

