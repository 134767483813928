import DefaultNavigation from '@shared/components/page/navigation/DefaultNavigation'
import { Page } from '@shared/components/page/Page'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'

export const LoadingPage = () => {
  return (
    <Page>
      <DefaultNavigation />
      <LoadingMain />
    </Page>
  )
}

const LoadingMain = () => (<LoadingIcon></LoadingIcon>)

LoadingMain.displayName = 'LoadingMain'