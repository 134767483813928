 import { useRouteParams } from "@shared/router"
 import { Role } from "@server/shared"
 import { api } from "@shared/index"
 import { useEffect, useState } from "react"

 export const usePageRole = (orgOrProjectAlias?: string) => {
     const { orgAliasId, projectAliasId } = useRouteParams<{ orgAliasId, projectAliasId }>()
     const roles = api.user.getRoles.useLiveData()
     const pageRole = useState({
         loading: true,
         isOwner: false,
         isSupervisor: false,
     })
     useEffect(() => {
         if (!roles.data) return

         const alias = orgOrProjectAlias || projectAliasId || orgAliasId
         const role: Role = roles.data[alias]

         pageRole[1]({
             loading: false,
             isOwner: role === 'OWNER',
             isSupervisor: role === 'SUPERVISOR',
         })

     }, [roles.data, orgAliasId, projectAliasId])
     return pageRole[0]
 }