import { DefaultProcedure } from "@server/app/trpc/router"
import { TRPCError } from "@trpc/server"
import { z } from "zod"

export const updateDeviceParams =  z.object({
                id: z.string(),
                name: z.string(),
            })
export const updateDeviceProcedure = (procedure: DefaultProcedure) =>
    procedure
        .input(updateDeviceParams)
        .mutation(async ({ ctx, input }) => {
            // check if thing is in this project
            const project = await ctx.based.db.default.get({
                $id: input.id,
                id: true,
                $find: {
                    $traverse: 'parents',
                    $filter: {
                        $field: 'type',
                        $operator: '=',
                        $value: 'project',
                    }
                }
            })

            if(!project || !project.id || project.id !== ctx.project.id) {
                throw new TRPCError({ code: 'NOT_FOUND', message: ctx.i18n.t['AppThing:ThingNotFoundInProject'].get() })
            }

            return ctx.based.db.default.set({
                $id: input.id,
                name: input.name,
                type: 'thing',
            })
        })