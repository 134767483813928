import { z } from 'zod'
import { DefaultProcedure } from '@server/app/trpc/router'
import exp from 'constants'

export const changePasswordParams = z.object({
  current: z.string()
    .refine(value => value.trim().length > 0, {
      message: 'Please enter your current password',
    }),
  newPassword: z.string()
    .min(5, 'Password must be at least 5 characters long')
    .max(20, 'Password must be at most 20 characters long')
    .refine(value => value.trim().length > 0, {
      message: 'Please enter your new password',
    }),
  newConfirmed: z.string({
  })  
})

export const changePasswordProcedure = (procedure: DefaultProcedure) =>
  procedure
    .input(changePasswordParams)
    .mutation(async ({ ctx, input }) => {
      const { password: oldPasswordDigest } = await ctx.based.db.default.get({
        $id: ctx.based.ctx.session?.authState?.userId,
        password: true,
      })
      
      const currentPasswordDigest = await ctx.based.db.default.digest(
        input.current
      )
      const newPasswordDigest = await ctx.based.db.default.digest(
        input.newPassword
      )
      const newPasswordConfirmDigest = await ctx.based.db.default.digest(
        input.newConfirmed
      )

      if (oldPasswordDigest !== currentPasswordDigest) {
        throw new Error(ctx.i18n.t['AppUser:OldPasswordIsNotCorrect'].get())
      }

      if (newPasswordDigest !== newPasswordConfirmDigest) {
        throw new Error(ctx.i18n.t['AppUser:NewPasswordDoesNotMatch'].get())
      }

      if (newPasswordDigest === oldPasswordDigest) {
        throw new Error(ctx.i18n.t['AppUser:NewPasswordCannotBeTheSameAsTheOldOne'].get())
      }
      await ctx.based.db.default.set({
        $id: ctx.based.ctx.session?.authState?.userId,
        password: input.newPassword,
      });
    })
