import { AddIcon } from '@colombalink/basedui'
import DefaultNavigation from '@shared/components/page/navigation/DefaultNavigation'
import React, { useEffect } from 'react'
import { DynamicButtonRow } from '@shared/components/core/DynamicButtonRow'
import { RowMenu } from './RowMenu'
import { Page } from '@shared/components/page/Page'
import { Title } from '@shared/components/core/Title'
import { api } from '@shared/index'
import { useTable } from '@shared/components/core/table/Table'
import AddActionDialog from '@shared/components/monitor/action/dialogs/AddDialog'
import { MonitorMenu } from '../shared/Menu'
import { usePageRole } from '@shared/components/page/usePageRole'
import AddNotificationTemplateDialog from '@app/pages/Orgs/Monitor/MonitorNotificationTemplate/AddDialog'
import { CenteredContainerOld } from '@shared/components/core/CenteredContainerOld'
import { ActionLandingFragment } from '@shared/components/monitor/action/LandingFragment'
import i18n from '../i18n'
import { WithI18n } from '@shared/i18n/withI18n'
import { useT } from '@app/i18n'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'

type PageProps = { params: { orgAliasId: string } }
export type PageParams = PageProps['params']

const MonitorActionsPage = (props: PageProps) => {
  return (
    <Page className="pl-4">
      <DefaultNavigation />
      <Main {...props} />
    </Page>
  )
}

const Main = (props: PageProps) => {
  return (
    <WithI18n i18n={i18n}>
      <ManageActions orgAliasId={props.params.orgAliasId} />
    </WithI18n>
  )
}
Main.displayName = 'Main'


export default MonitorActionsPage

const ManageActions = ({ orgAliasId }) => {
  const t = useT()
  const role = usePageRole()
  const actions = api.organization.monitor.action.getAll.useLiveData()
  return (
    <div className="flex h-full">
      <MonitorMenu page={t['Monitor:Actions'].get()} />

      <div className="flex flex-col overflow-auto w-full mx-4 mt-4 ">
        <ActionLandingFragment>
          <>
            {role.isOwner && (
              <DynamicButtonRow
                buttons={[
                  {
                    icon: <AddIcon />,
                    dialogComponent: <AddActionDialog />,
                    label: t['Monitor:Add'].get(),
                    style: { cursor: 'pointer' },
                  },
                ]}
              />
            )}
            <ActionsTable actions={actions} />
          </>
          </ActionLandingFragment>
      </div>
    </div>
  )
}

function ActionsTable({ actions }) {

  const t = useT()
  const [tableData, setTableData] = React.useState([])

  const [table, TableComponent] = useTable({
    header: [
      { label: t['Monitor:Name'].get(), key: 'name' },
      { label: t['Monitor:Trigger'].get(), key: 'trigger' },
      { label: t['Monitor:TriggerTyoe'].get(), key: 'triggerType' },
      { label: t['Monitor:NotificationTemplate'].get(), key: 'notificationTemplate.name' },
      { label: t['Monitor:Actions'].get(), key: 'action' },
    ],
    data: tableData,
  })

  useEffect(() => {
    if (actions.data) {
      setTableData(
        actions.data.map((action) => {
          return {
            ...action,
            action: <RowMenu action={action} />,
            customAttribute: {},
          }
        })
      )
    }
  }, [actions.data])

  if (actions.error) {
    throw actions.error
  }

  if (actions.loading) {
    return (
      <>
        {t['Monitor:Loading'].get()} <LoadingIcon />
      </>
    )
  }
  return <>{TableComponent}</>
}
