import { Button as BaseButton } from '@colombalink/basedui'
import React from 'react';
const _CustomizableButton = (props) => {
    // This component now returns a React component directly
    const Button = (props2) => <BaseButton {...props} {...props2}/> ;

    // Adding displayName for debugging purposes
    Button.type = { displayName: 'Button'}

    return Button;
};

_CustomizableButton.displayName = 'CustomizableButton';

export const CustomizableButton = _CustomizableButton as any as typeof BaseButton ;