import { Dialog, Input, Text, Toast, useToast, Select, Label, Callout, ErrorIcon, Spacer } from '@colombalink/basedui'
import React, { useEffect } from 'react'
import { api } from '@shared/index'
import { ZodInputValidator } from '@shared/zod/InputValidator'
import { createOrgParams } from '@server/app'
import { WithI18n } from '@shared/i18n/withI18n'
import i18n from '../i18n'
import { useT } from '@app/i18n'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'

const EditProject = ({ projectAliasId }) => {
  const t = useT()
  const project = api.project.getById.withNames.useLiveData({ projectAliasId })
  const setProjectName = api.project.setName.useMutation()
  
  const [retypeProjectAlias, setRetypeProjectAlias] = React.useState({name: ''})
  const [isMatch, setIsMatch] = React.useState(false);
  const [hide, setHide] = React.useState(false);
  
  const [updateProjectArgs, setUpdateOrgParams] = React.useState({
    projectAliasId,
    name: ''
  })  
  const toast = useToast()

  useEffect(() => {
    if (project.data) {
      setUpdateOrgParams({
        ...updateProjectArgs,
        name: project.data.name
      })
    }
  }, [project.data])

  if (!updateProjectArgs && project.loading) {
    return <LoadingIcon />
  }

  return (
    <Dialog label={t["SProject:EditProject"].get()}>
      <div>
        <Input
          description={t["SProject:Name"].get()}
          value={updateProjectArgs.name}
          pattern="true"
          error={(value) => ZodInputValidator(createOrgParams, updateProjectArgs, "name")}
          onChange={(name: string) => {
            setUpdateOrgParams({ ...updateProjectArgs, name })
            setHide(name !== project.data.name)
          }}
        />
        <Spacer/>
        {hide && (
          <Callout
            color={isMatch ? "green" : "red"} // Set color based on the match
            outline
            label={t["SProject:EditingTheOrg"].get()}
            icon={<ErrorIcon />}
          >
            <Spacer />
            <Text>{t["SProject:PleaseRetypePreviousProjectName"].get()} {project.data.name}</Text>
            <Spacer />
            <Input
              type="text"
              placeholder={t["SProject:RetypeProjectName"].get()}
              value={retypeProjectAlias.name}
              onChange={(name: string) => {
                setRetypeProjectAlias({ ...retypeProjectAlias, name });
                setIsMatch(name === project.data.name);
              }}
            />
          </Callout>
        )}
        <Dialog.Buttons>
          <Dialog.Cancel displayShortcut={false}>{t["SProject:Close"].get()}</Dialog.Cancel>
          <Dialog.Confirm 
            displayShortcut={false} 
            disabled={!isMatch} // Disable the button when there is no match
            onConfirm={async () => {
            try {
              await setProjectName.mutateAsync(updateProjectArgs)
              toast.add(
                <Toast
                  label={t["SProject:UpdatedProject"].get()}
                  type="success"
                  description={
                    <Text>
                      {t["SProject:UpdatedProject"].get()} <b>{updateProjectArgs.name}</b> {t["SProject:Successfully"].get()}
                    </Text>
                  }
                />
              )
            } catch (e) {
              toast.add(
                <Toast
                  label= {t["SProject:FailedToUpdateProject"].get()}
                  type="error"
                  description={
                    <Text style={{ whiteSpace: 'normal' }}>
                      {e.message}
                    </Text>
                  }
                />
              )
              throw e
            }
          }}>{t["SProject:Save"].get()}</Dialog.Confirm>
        </Dialog.Buttons>
      </div>
    </Dialog>
  )
}



export default (props)=> (
  <WithI18n i18n={i18n}>
    <EditProject {...props}  />
  </WithI18n>
)