import { Button, Spacer } from "@colombalink/basedui"
import { useLocation } from "wouter"
import { PageParams } from "../../Page"
import { useT } from '@app/i18n'
import { useRouteParams } from "@shared/router"
import { useLink } from "@app/routes"

export const NoThingFoundRedirect = () => {
    const t = useT()
    const { orgAliasId, projectAliasId } = useRouteParams<PageParams>()
    const [, setLocation] = useLocation()
    return (
        <div className="p-4">
            {t['Project:NoDevicesFound'].get()}
            <Spacer />
            <Button
                onClick={() => {
                    setLocation(useLink('/org/:orgAliasId/project/:projectAliasId/things', { orgAliasId, projectAliasId }))
                }}
            > {t['Project:AddADevice'].get()} </Button>
        </div>

    )
}