import { DefaultProcedure } from '@server/app/trpc/router'
import { z } from 'zod'

export const createNotificationTemplateParams = z.object({
        name: z.string(),
        title: z.string({
          required_error: 'Title is required',
        }),
        message: z.string(),
      })

export const createProcedure = (
  procedure: DefaultProcedure
) =>
  procedure
    .input(createNotificationTemplateParams)
    .mutation(async ({ ctx, input }) => {
      const res = await ctx.based.db.default.set({
        name: input.name,
        title: input.title,
        message: input.message,
        type: 'notificationTemplate',
        parents: [ctx.org.id],
      })
      ctx.logger.debug('procedure::createNotificationTemplate result:', res)
      ctx.org.auditLog.append({message: `Notification template ${input.name} created`, data: {}})
    })
