import { Select } from "@colombalink/basedui"
import { api } from "@shared/index"
import { useProjectDashboardContext } from "../../DashboardContext"
import { useT } from '@app/i18n'

type Project = ReturnType<typeof api.project.getById.withThingsAndAlarms.useLiveData>['data']

export const Filters = () => {
    const t = useT()
    const {
        filters
    } = useProjectDashboardContext()
    return (
        <div className='w-full flex flex-row space-x-4 pt-4 pb-4 sticky top-0 bg-white'
            style={{ zIndex: 20 }}
        >
            <Select
                style={{ marginBottom: '8px', width: '200px', minHeight: '40px' }}
                value={filters.things.selectedOption}
                options={filters.things.options}
                label={t["Project:SelectDevice"].get()}
                onChange={(id: string) => {
                    if (!id) {
                        return
                    }
                    if (id === 'all') {
                        filters.things.setSelectedOption('all')
                        return
                    }
                    filters.things.setSelectedOption(id)
                }}
            />
            <Select
                style={{ marginBottom: '8px', width: '200px', minHeight: '40px' }}
                value={filters.status.selected}
                options={filters.status.options}
                label={t['Project:Status'].get()}
                onChange={(nextStatus: string) => {
                    if (!nextStatus) {
                        filters.status.setSelected('idle')
                        return
                    }
                    filters.status.setSelected(nextStatus)
                }}
            />
        </div>
    )
}