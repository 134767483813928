const SharedPagePageContentI18n = {

    "SharedPage:NoAlias": {
        en: 'No alias specified, using default page',
        de: 'Kein Alias angegeben, Standardseite wird verwendet',
    },
    "SharedPage:NoRoleFoundForAlias": {
        en: 'No role found for alias: ',
        de: 'Keine Rolle für dieses Alias gefunden: ',
    },
    "SharedPage:Default": {
        en: '"WE ARE GOING TO DEFAULT"',
        de: '"WIR GEHEN ZUM STANDARD"',
    }

}

export default SharedPagePageContentI18n
export type SharedPagePageContentI18nType = typeof SharedPagePageContentI18n
