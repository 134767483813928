import DefaultNavigation from '@shared/components/page/navigation/DefaultNavigation'
import {
  Button,
  Input,
  Select,
  Spacer,
  Text,
  Toast,
  useToast,
} from '@colombalink/basedui'
import { Page } from '@shared/components/page/Page'
import { api } from '@shared/index'
import { useLink } from '../../../routes'
import { useLocation } from 'wouter'
import { useState } from 'react'
import { Title } from '@shared/components/core/Title'
import i18n from './i18n'
import { WithI18n } from '@shared/i18n/withI18n'
import { useT } from '@app/i18n'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'

export default function SettingsPage() {
  return (
    <Page>
      <DefaultNavigation />
      <Main />
    </Page>
  )
}

const Main = () => (
  <WithI18n i18n={i18n}>
    <SettingsMain />
  </WithI18n>
)
Main.displayName = 'Main'

const SettingsMain = () => {
  const t = useT()
  const settings = api.user.getSettings.useLiveData()

  if (settings.loading) return <LoadingIcon />

  if (settings.error) {
    throw settings.error
  }

  return (
    <>
      <h1 className="m-4"> {t['UserSettings:Label'].get()} </h1>
      <h2 className="m-4"> {t['UserSettings:Preferences'].get()}</h2>
      <LanguageSelector setting={settings.data} />
    </>
  )
}

type Setting = ReturnType<typeof api.user.getSettings.useLiveData>['data']

const LanguageSelector = ({ setting }: { setting: Setting }) => {
  const t = useT()
  const setSettings = api.user.setSetting.useMutation()
  const toast = useToast()

  const [options] = useState([
    { label: t['UserSettings:LanguageOptionEnglish'].get(), value: 'en' },
    { label: t['UserSettings:LanguageOptionGerman'].get(), value: 'de' },
  ])

  return (
    <div className="max-w-[200px] m-4">
      <Select
        label="Language"
        options={options}
        value={setting.preference.language}
        onChange={async (language: 'en' | 'de') => {
          if (!language) return

          await setSettings.mutateAsync({
            setting: { preference: { language } },
          })
          toast.add(
            <Toast
              label="Language Changed"
              type="success"
              description={
                <Text>
                  {t['UserSetting:SuccessfullyChangedOrganization'].get()}{' '}
                </Text>
              }
            />
          )
        }}
      />
    </div>
  )
}
