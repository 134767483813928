import { LoadingIcon } from '@shared/components/core/LoadingIcon'
import { Accordion } from '@shared/components/core/shadcn-ui/Accordion'
import { Page } from '@shared/components/page/Page'
import DefaultNavigation from '@shared/components/page/navigation/DefaultNavigation'
import { api } from '@shared/index'
import { AccordionItem } from './AccordeonItem'
import i18n from './i18n'
import { WithI18n } from '@shared/i18n/withI18n'
import { UserLandingFragment } from '@shared/components/user/LandingFragment'
import { useT } from '@app/i18n'
import React, { useEffect } from 'react'
import { useTable } from '@shared/components/core/table/Table'
import { Button } from '@colombalink/basedui'
import { RowMenu } from './RowMenu'



export default () => {
  return (
    <Page>
      <DefaultNavigation />
      <Main />
    </Page>
  )
}


const Main = () => (
  <WithI18n i18n={i18n}>
    <UserLandingFragment>
      <HomeContent />
    </UserLandingFragment>
  </WithI18n>
)
Main.displayName = 'Main'



const HomeContent = () => {
  const t = useT()
  const stats = api.user.getStats.useLiveData()

  if (stats.error) {
    throw stats.error
  }

  if (stats.loading) {
    return <LoadingIcon />
  }

  return (
    <>
      <h3 className="m-4 text-txt scroll-m-20 text-2xl font-semibold tracking-tight text-left flex-grow">
        {t['UserHome:Organizations'].get()}
      </h3>
      <Accordion
        type="multiple"
      >
        {stats.data.organizations?.map((org, index) => (
          <AccordionItem
            key={org.alias}
            id={org.id} title={org.name} statusStats={org.status} alias={org.alias} >

            <h3 className="mb-4 ml-4 text-txt scroll-m-20 text-2xl font-semibold tracking-tight text-left flex-grow">
              {
                t['UserHome:ProjectTitle'].get({ count: org.projects?.length || 0 })
              }
            </h3>
            <div className='ml-8'>
              {org.projects?.map(
                (project, index) => {
                  return <ProjectAccordionItem orgAliasId={org.alias} key={project.alias} project={project}></ProjectAccordionItem>
                }
              )}
            </div>
          </AccordionItem>
        ))}
      </Accordion>
    </>
  )
}

const ProjectAccordionItem = (props:{ project, orgAliasId }) => {
  const t = useT()
  return (
    <AccordionItem
      key={props.project.id}
      id={props.project.id} title={props.project.name} statusStats={props.project.status} alias={props.project.alias}  >

      <h3 className="mb-4 ml-4 text-txt scroll-m-20 text-2xl font-semibold tracking-tight text-left flex-grow">
        {
          t['UserHome:ThingTitle'].get({ count: props.project.things?.length || 0 })
        }
      </h3>

      <div className='ml-8'>
        {props.project.things?.map(
          (thing, index) => {
            return <ThingAccordionItem key={thing.alias} thing={thing} orgAliasId={props.orgAliasId} projectAliasId={props.project.alias} ></ThingAccordionItem>
          }
        )}
      </div>
    </AccordionItem>
  )
}

const ThingAccordionItem = (props: { thing, orgAliasId, projectAliasId }) => {
  return (
    <AccordionItem
      key={props.thing.id} id={props.thing.id} title={props.thing.name} statusStats={props.thing.status} alias={props.thing.alias}  >
      <ListView thingId={props.thing.id} orgAliasId={props.orgAliasId} projectAliasId={props.projectAliasId} />
    </AccordionItem>
  )
}

const ListView = (props: { thingId: string, orgAliasId: string, projectAliasId: string }) => {
  const t = useT()
  const monitors = api.monitor.getAllMonitorsInAlarmStateByThing.useLiveData({
    thingAliasId: props.thingId,
    orgAliasId: props.orgAliasId,
    projectAliasId: props.projectAliasId
  })

  if (monitors.loading) {
    return (
      <>
        {t["UserHome:Loading"].get()} <LoadingIcon />
      </>
    )
  }

  if (monitors.data.length === 0) {
    return (
      <>
        {t["UserHome:NoAlarms"].get()}
      </>
    )
  }

  return (
    <ul role="list" className="divide-y divide-gray-200 mb-0 mr-5">
      {monitors.data.map((monitor) => (
        <li key={monitor.id} className="flex items-center justify-between gap-x-6 hover:bg-gray-100 p-2 mb-4">
          <div className="min-w-[150px]">
            <div>
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-red-100 text-red-800">
                 {t["UserHome:Alarm"].get()} 
              </span>
            </div>
            <div className="flex items-start gap-x-1 text-md font-semibold leading-6 text-gray-900">
              {monitor.name}
            </div>
            <div className="mt-1 flex items-center gap-x-1 text-sm leading-5 text-gray-500">
              <p className="whitespace-nowrap">
                {monitor.statusMessage.title} : {monitor.statusMessage.message}
              </p>
            </div>
          </div>
          <div className="flex flex-none items-center gap-x-4">
            <div>
              <RowMenu monitor={monitor} orgAliasId={props.orgAliasId} projectAliasId={props.projectAliasId} thingId={props.thingId} />
            </div>
          </div>
        </li>
      ))}
    </ul>
  )
}
