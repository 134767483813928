import EditMemberOfOrganization from "./EditDialog"
import { FC, useState } from "react"
import { OptionMenu } from "@shared/components/core/EditEntityMenu"
import { api } from "@shared/index"
import { useDialog, useToast, ContextItem, Toast, Text, EyeIcon, EditIcon, DeleteIcon } from "@colombalink/basedui"
import { useLocation } from "wouter"
import { Role } from "@server/shared"
import { useT } from "@app/i18n"

export const RowMenu = ({ member }: { member: { id: string, role: Role, user: { name: string, id: string } } }) => {

  const CategoryMenu: FC<{}> = ({ }) => {
    const t = useT()
    const { open } = useDialog()
    const [, setLocation] = useLocation()
    const toast = useToast()
    const [hidden, setHidden] = useState('hidden')

    const removeMemberFromOrganization = api.organization.removeMemberFromOrganization.useMutation()

    return (
      <>
        <ContextItem
          onClick={() => {
            open(<EditMemberOfOrganization member={member} />)
          }}
          icon={EditIcon}
        >
          {t['Member:Edit'].get()}
        </ContextItem>
        <ContextItem
          onClick={async () => {
            try{
              await removeMemberFromOrganization.mutateAsync({ memberId: member.id })

              toast.add(
                <Toast
                  label={t['Member:Removed'].get()}
                  type="success"
                  description={
                    <Text>
                      {t['Member:RemovedText'].get()}
                    </Text>
                  }
                />
              )
            } catch (e) {
              toast.add(
                <Toast
                  label={t['Member:RemoveFailed'].get()}
                  type="error"
                  description={
                    <Text style={{ whiteSpace: 'normal' }}>
                      {e.message}
                    </Text>
                  }
                />
              )
              //throw e
            }
          }}
          icon={DeleteIcon}
        >
          {t['Member:Remove'].get()}
        </ContextItem>
      </>
    )
  }

  return <OptionMenu menu={CategoryMenu} style={{ width: '150px' }} />
}