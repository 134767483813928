import { DefaultProcedure } from '@server/app/trpc/router'
import { z } from 'zod'
import { TRPCError } from '@trpc/server'
import { createMonidasThingUUID } from '../../utils/uuidv5/uuidv5'
import { incrementProjectQuota } from './quotas/increment'

export const addThingsParams = z.object({

  // name is the display name  
  name: z.string()
    .refine(value => value.length <= 100, 'Thing name should not exceed 100 characters.')
    .refine(value => /^[a-zA-Z0-9 \-]+$/.test(value), 'Name can only contain alphabets, numbers, dashes, and spaces.')
  ,
  projectAliasId: z.string().optional(),
})

export const addThingToProjectProcedure = (procedure: DefaultProcedure) =>
  procedure
    .input(addThingsParams)
    .mutation(async ({ ctx, input }) => {
      ctx.logger.debug('procedure::addThingToProject input:', input)
      if(!ctx?.project?.id || !ctx.org?.id ) {
        throw new TRPCError({ code: 'INTERNAL_SERVER_ERROR', message: ctx.i18n.t['AppProject:ContextIsMissingRequiredFields'].get() })
      }
      const aliasId = input.name.toLocaleLowerCase().replace(/ /g, '-')
      const thingAlias =  `${ctx.project.alias}/thing/${aliasId}`
      
      const orgs = await ctx.based.db.default.get({
        $alias: thingAlias,
        id: true,
        $list: {
          $find: {
            $traverse: 'parents',
            $filter: {
              $field: 'type',
              $operator: '=',
              $value: "organization",
            },
          }
        }
      })

      if(orgs && orgs instanceof Array && orgs.map(org => org.id).includes(ctx.org.id)) {
        throw new TRPCError({ code: 'CONFLICT', message: ctx.i18n.t['AppProject:ThingAlreadyExistsInProject'].get() })
      }

      // try to update quota to increment the count when adding a device to project 
      await incrementProjectQuota(ctx.based, ctx.project.id)

      const thingDetail = await ctx.based.db.default.set({
        type: 'genericThingDetail',
        description: 'Generic thing',
        parents: [],
      })

      const thing = await ctx.based.db.default.set({
        type: 'thing',
        name: input.name,
        isConfigured: false,
        detail: thingDetail.id,
        parents: [ctx.org.id, ctx.project.id],
      })

      const thingUUIDAlias = createMonidasThingUUID(ctx.org.id, ctx.project.id, thing.id) 

      await ctx.based.db.default.set({
        $id: thing.id, 
        aliases: [thingAlias, thingUUIDAlias],
      })

      await ctx.based.db.default
        .set({
          $id: ctx.project.id,
          things: {
            $add: thing.id,
          },
          type: 'project',
        })

      // add to audit log
      ctx.project.auditLog.append({
        message: `Added Generic Thing ${input.name}`,
        data: {
          ...input
        }
      })

      return { thingId: thing.id, alias: thingAlias, UUID: thingUUIDAlias}; 
    })
