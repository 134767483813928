import {
  Dialog,
  Spacer,
  Toast,
  useToast,
  Text,
} from '@colombalink/basedui'
import { EditMonitorsContext } from './EditContext'
import { ActionsMultiSelect, ActiveStateSelect, DataInput, LoadingHandler, NameInput, RuleConfigInput, RuleFunctionSelect, StatusMessageTemplateSelect, StatusSelect } from './FormElements'
import { MonitorsBaseContext, useMonitorsBaseContext } from './BaseContext'
import { api } from '@shared/index'
import { useT } from '@app/i18n'

type EditProps = {
  monitorId: string
}

const EditMonitor = ({ monitorId }: EditProps) => {
  return (
    <MonitorsBaseContext>
      <EditMonitorsContext monitorId={monitorId}>
        <Dialog label="Edit Monitor">
          <div className="flex flex-col">
            <LoadingHandler>
              <NameInput />
              <Spacer />
              <StatusSelect />
              <Spacer />
              <ActiveStateSelect />
              <Spacer />
              <RuleFunctionSelect />
              <Spacer />
              <RuleConfigInput />
              <Spacer />
              <ActionsMultiSelect />
              <Spacer />
              <StatusMessageTemplateSelect />
              <Spacer />
              <DataInput />
              <Spacer />
            </LoadingHandler>
          </div>
          <Dialog.Buttons border>
            <Dialog.Cancel displayShortcut={false}>Close</Dialog.Cancel>
            <ConfirmEditButton monitorId={monitorId} />
          </Dialog.Buttons>
        </Dialog>
      </EditMonitorsContext>
    </MonitorsBaseContext>
  );
}

export default EditMonitor



export const ConfirmEditButton = ({ monitorId }) => {
  const t = useT();
  const { formData: {
    useName: [name],
    useStatus: [status],
    useStatusMessageTemplate: [statusMessageTemplate],
    useActiveState: [activeState],
    useRuleFunction: [ruleFunction],
    useRuleConfig: [ruleConfig],
    useActions: [actions],
    useData: [data],
  } } = useMonitorsBaseContext();

  const createMonitor = api.monitor.update.useMutation();
  const toast = useToast();

  const handleConfirm = async () => {
    try {
      await createMonitor.mutateAsync({
        monitorId,
        name,
        status,
        statusMessageTemplate,
        activeState,
        ruleFunction,
        ruleConfig: JSON.parse(ruleConfig),
        actions,
        data: JSON.parse(data),
      });
      toast.add(<Toast label={t["ThingMonitors:EditedMonitor"].get()} type="success" description={<Text> {t["ThingMonitors:Monitor"].get()} <b>{name}</b> {t["ThingMonitors:UpdatedSuccessfully"].get()}</Text>} />);
    } catch (e) {
      toast.add(<Toast label={t["ThingMonitors:FailedToUpdatedMonitor"].get()} type="error" description={<Text style={{ whiteSpace: 'normal' }}>{e.message}</Text>} />);
      throw e;
    }
  };

  return (
    <Dialog.Confirm displayShortcut={false} onConfirm={handleConfirm}>
      {t["ThingMonitors:Confirm"].get()}
    </Dialog.Confirm>
  );
};