import {
  useToast,
} from '@colombalink/basedui'
import { useEffect, useState } from 'react'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'
import { api } from '@shared/index'
import { useLocation } from 'wouter'
import { useTable } from '@shared/components/core/table/Table'
import { useRouteParams } from '@shared/router'
import { useT } from '@app/i18n'
import { RowMenu } from './RowMenu'

type PageProps = { params: { orgAliasId: string, thingAliasId: string; projectAliasId: string } }
export type PageParams = PageProps['params']

const PropertiesFragment = () => {
  return (
    <div className="m-4">
      <div className="flex flex-col">
        <ManageQuotas />
      </div>
    </div>
  )
}

export default PropertiesFragment

const ManageQuotas = () => {
  return (
    <div className="flex flex-col">
      <QuotaTable />
    </div>
  )
}

function isNullOrUndefined(value) {
  return value === undefined || value === null;
}

ManageQuotas.displayName = 'Main'

function QuotaTable() {
  const { thingAliasId } = useRouteParams<PageParams>()
  const t = useT()

  const props = api.thing.getPayloadProperties.useLiveData({
    thingAliasId,
  });

  const [tableData, setTableData] = useState([]);
  const [propIds, setPropIds] = useState(null);

  const mutateProperty = api.thing.updatePayloadPropertiesWithProperties.useMutation();

  // TODO: make this a editable table 
  const [table, TableComponent] = useTable({
    header: [
      { label: t["Thing:Label"].get(), key: 'label.en', isEditable: false },
      { label: t["Thing:PropertyPath"].get(), key: 'name' },
      { label: t["Thing:PrimitiveDataType"].get(), key: 'primitiveDataType' },
      { label: 'Min', key: 'min', isEditable: false },
      { label: 'Max', key: 'max', isEditable: false },
      { label: t["Thing:Tolerance"].get(), key: 'tolerance', isEditable: false },
      { label: t["Thing:Actions"].get(), key: 'action' },
    ],
    data: tableData,
    // onUpdatedCell(rowIndex, columnId, value) {
    //   console.log('onUpdatedCell', rowIndex, columnId, value, propIds[rowIndex].id)
    //   if (columnId === 'label.en') {
    //     const args = { propertyId: propIds[rowIndex].id, label: { en: value } }
    //     mutateProperty.mutateAsync(args)
    //   } else { // min, max, tolerance
    //     const args = { propertyId: propIds[rowIndex].id, [columnId]: parseInt(value) }
    //     console.log('args', args)
    //     mutateProperty.mutateAsync(args)
    //   }
    // }
  })



  useEffect(() => {
    // console.log('updateTable', props.data)
    if (!props.data) return

    const propsWithIds = []
    const newTableData = []
    const sortedProps = props.data.properties.sort((a, b) => {
      const comparison = a?.label?.en?.localeCompare(b?.label?.en);

      // If labels are equal, sort by id
      if (comparison === 0) {
        return a.id.localeCompare(b.id);
      }

      return comparison;
    })



    for (let i = 0; i < props.data.properties.length; i++) {
      const prop = sortedProps[i]
      const propWithId = {
        ...prop,
        id: prop.id,
      }
      propsWithIds.push(propWithId)

      let min = undefined
      let max = undefined
      let tolerance = undefined
      if (!isNullOrUndefined(prop.idealRange?.min)) {
        min = prop.idealRange.min.toString()
      }
      if (!isNullOrUndefined(prop.idealRange?.max)) {
        max = prop.idealRange.max.toString()
      }
      if (!isNullOrUndefined(prop.idealRange?.tolerance)) {
        tolerance = prop.idealRange.tolerance.toString()
      }

      const row = {
        ...prop,
        min: min,
        max: max,
        tolerance: tolerance,
        action: <RowMenu property={prop} />,
        customAttribute: {
          name: {
            style: {
              userSelect: 'text',
            }
          }
        },
      }

      newTableData.push(row)

    } [props.data]
    setPropIds(propsWithIds)
    setTableData(newTableData)

    // not working
    // setSorting({
    //   id: 'min',
    //   desc: false,
    // })

  }, [props.data])

  if (props.error) {
    throw props.error
  }

  if (props.loading) {
    return <>{t["Thing:Loading"].get()} <LoadingIcon /></>
  }
  return (
    <>
      {TableComponent}
    </>
  )
}