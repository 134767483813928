import { RouteParamsProvider, useRouteParams } from "@shared/router"
import { useDialog as useDialogBase } from "@colombalink/basedui"

export const useDialog =  (dialog) => {
    const { open } = useDialogBase()
    const params = useRouteParams()

    return {
        open: async () => open(<RouteParamsProvider params={params}>{dialog}</RouteParamsProvider>)
    }
}