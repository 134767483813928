import DefaultNavigation from '@shared/components/page/navigation/DefaultNavigation'
import { Button, Input, Text, Toast, useToast } from '@colombalink/basedui'
import { Page } from '@shared/components/page/Page'
import { api } from '@shared/index'
import { useLink } from '../../../routes'
import { useLocation } from 'wouter'
import { useEffect, useState } from 'react'
import { ZodInputValidator } from '@shared/zod/InputValidator'
import { changePasswordParams } from '@server/app'
import { useRouteParams } from '../../../../shared/router'
import i18n from './i18n'
import { WithI18n } from '@shared/i18n/withI18n'
import { useT } from '@app/i18n'

type PageProps = { params: { userAliasId: string } }
export type PageParams = PageProps['params']

export default function ProfilePage(props: PageProps) {
  return (
    <Page>
      <DefaultNavigation />
      <Main />
    </Page>
  )
}

const Main = () => (
  <WithI18n i18n={i18n}>
    <ChangePasswordMain />
  </WithI18n>
)
Main.displayName = 'Main'

function ChangePasswordMain() {
  const t = useT()
  const { userAliasId } = useRouteParams<PageParams>()
  const [changePasswordArgs, setChangePasswordArgs] = useState({
    current: '',
    newPassword: '',
    newConfirmed: '',
  })

  const [passwordMatch, setPasswordMatch] = useState(false);
  const [, setLocation] = useLocation()
  const toast = useToast()

  useEffect(() => {
    const doPasswordsMatch = changePasswordArgs.newPassword === changePasswordArgs.newConfirmed;
    setPasswordMatch(doPasswordsMatch);
  }, [changePasswordArgs.newPassword, changePasswordArgs.newConfirmed]);

  const changePassword = api.user.changePassword.useMutation()

  return (
    <div>
      <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <Input
            label= {t['Profil:CurrentPassword'].get()}
            type='password'
            pattern="true"
            value={changePasswordArgs.current}
            error={(value) => ZodInputValidator(changePasswordParams, changePasswordArgs, 'current')}
            onChange={(current) => setChangePasswordArgs({ ...changePasswordArgs, current })}
          />
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <Input
            label={t['Profil:NewPassword'].get()}
            type='password'
            pattern="true"
            value={changePasswordArgs.newPassword}
            error={(value) => ZodInputValidator(changePasswordParams, changePasswordArgs, 'newPassword')}
            onChange={(newPassword) => {
              setChangePasswordArgs({ ...changePasswordArgs, newPassword })
            }}
          />
        </div>
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <Input
            label={t['Profil:ConfirmPassword'].get()}
            type='password'
            value={changePasswordArgs.newConfirmed}
            onChange={(newConfirmed) => setChangePasswordArgs({ ...changePasswordArgs, newConfirmed })}
          />
          <div>
            {!passwordMatch && (
              <p className="text-red-500">{t['Profil:PasswordNotMatch'].get()}</p> // TODO: equal stylings like the other errors
            )}
          </div>
        </div>

        <div className="flex w-full justify-center mt-10">
          <Button
            light
            onClick={async () => {
              if (passwordMatch) {
                await changePassword.mutateAsync({
                  current: changePasswordArgs.current,
                  newPassword: changePasswordArgs.newPassword,
                  newConfirmed: changePasswordArgs.newConfirmed,
                });
                toast.add(
                  <Toast
                    label={t['Profil:PasswordChanged'].get()}
                    type="success"
                    description={
                      <Text>
                        {t['Profil:PasswordChangedSuccessfully'].get()}
                      </Text>
                    }
                  />
                )
                setLocation(useLink('/user/:userAliasId/profile', { userAliasId }));
              }
            }}
          >
            {t['Profil:Save'].get()}
          </Button>
        </div>
      </div>
    </div>
  )
}

ChangePasswordMain.displayName = 'ChangePasswordMain'