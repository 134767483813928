import { useEffect } from 'react'
import {
  Select,
  Style,
} from '@colombalink/basedui'
import { useChartContext } from './context'
import { DatePicker } from '../shadcn-ui/DatePicker'
import { WithI18n } from '@shared/i18n/withI18n'
import i18n from '../i18n'
import { useT } from '@app/i18n'
import {useViewThingContext} from "@app/pages/Orgs/Project/ExplorerFragment/MultiThingContex";

const currentYear = new Date().getFullYear()

export const _FixedRangeSelector = ({
  customKey,
  style,
}: {
  customKey: string
  style?: Style
}) => {
  const {
    useFromDate: [, setFromDate],
    useToDate: [, setToDate],
  } = useChartContext()
  const t = useT()
  const chartContext = useChartContext()
  const useTimeFilters = useViewThingContext().useTimeFilters


  const monthNames = [
    t['CoreCharts:January'].get(),
    t['CoreCharts:February'].get(),
    t['CoreCharts:March'].get(),
    t['CoreCharts:April'].get(),
    t['CoreCharts:May'].get(),
    t['CoreCharts:June'].get(),
    t['CoreCharts:July'].get(),
    t['CoreCharts:August'].get(),
    t['CoreCharts:September'].get(),
    t['CoreCharts:October'].get(),
    t['CoreCharts:November'].get(),
    t['CoreCharts:December'].get(),
  ]

  useEffect(() => {setDateRange()}, [customKey])

  useEffect(() => {
   setDateRange()
  }, [useTimeFilters[0]])

  function setDateRange() {
    console.log(customKey)
    if(customKey === 'live') return 

    if (customKey === 'yearly') {
      setFromDate(new Date(useTimeFilters[0].yearly, 0, 1).getTime())
      setToDate(new Date(useTimeFilters[0].yearly + 1, 0, 1).getTime() - 1)
    } else if (customKey === 'monthly') {
      setFromDate(new Date(useTimeFilters[0].yearly, useTimeFilters[0].monthly, 1).getTime())
      setToDate(new Date(useTimeFilters[0].yearly, useTimeFilters[0].monthly + 1, 1).getTime() - 1)
    } else {
      setFromDate(new Date(new Date(useTimeFilters[0].daily).setHours(0, 0, 0, 0)).getTime())
      setToDate(new Date(new Date(useTimeFilters[0].daily).setHours(23, 59, 59, 999)).getTime())
    }
  }

  return (
    <>
      {customKey === 'daily' && (
        //<DateWidget value={Date.now()} onChange={(value) => setDate(value)} />
        <PageSelector
          selectedDate={useTimeFilters[0].daily}
          setDate={(date) => {
            const dt = new Date(date)
            useTimeFilters[1]({
              daily: dt,
              monthly: dt.getMonth(),
              yearly: dt.getFullYear()
            })
          }}
        />
      )}
      {customKey === 'monthly' && (
        <div className="flex flex-row justify-between">
          <Select
            style={{ width: '140px', marginBottom: '8px', ...style }}
            value={monthNames[useTimeFilters[0].monthly]}
            options={monthNames}
            onChange={(option: string) => {
              if (!option) {
                option = monthNames[useTimeFilters[0].monthly]
              }
              useTimeFilters[1]((prevState) => ({
                ...prevState,
                daily: new Date(prevState.yearly, monthNames.indexOf(option), 1),
                monthly: monthNames.indexOf(option)
              }))
            }}
          />
          <Select
            style={{ width: '140px', marginBottom: '8px', ...style }}
            value={useTimeFilters[0].yearly}
            options={[currentYear, currentYear - 1, currentYear - 2]}
            onChange={(option: number) => {
              if (!option) {
                option = useTimeFilters[0].yearly
              }
              useTimeFilters[1]((prevState) => ({
                ...prevState,
                daily: new Date(option, prevState.monthly, prevState.daily.getDate()),
                yearly: option
              }))
            }}
          />
        </div>
      )}
      {customKey === 'yearly' && (
        <Select
          style={{ width: '140px', marginBottom: '8px', ...style }}
          value={useTimeFilters[0].yearly}
          options={[currentYear, currentYear - 1, currentYear - 2]}
          onChange={(option: number) => {
            if (!option) {
              option = useTimeFilters[0].yearly
            }
            useTimeFilters[1]((prevState) => ({
              ...prevState,
              daily: new Date(option, prevState.monthly, prevState.daily.getDate()),
              yearly: option
            }))
          }}
        />
      )}
    </>
  )
}

const PageSelector = ({ setDate, selectedDate }) => {
  return (
    <div className="pb-2 flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:space-x-2 sm:justify-end">
      <DatePicker date={selectedDate} setDate={setDate}></DatePicker>
    </div>
  )
}

export const FixedRangeSelector = (props) => (
  <WithI18n i18n={i18n}>
    < _FixedRangeSelector {...props}/>
  </WithI18n>
)

