import { Component, useEffect, useRef } from 'react'
import BaseError from './ErrorTemplate.svg'
import { TRPCError } from '@trpc/server'
import { WithI18n } from '@shared/i18n/withI18n'
import i18n from '../core/i18n'
import { log } from '@shared/logger'
class ErrorBoundary extends Component {
  state = { hasError: false, errorMessage: '', code: '' }

  static getDerivedStateFromError(error) {
    return { hasError: true, errorMessage: error.toString() }
  }

  componentDidCatch(error, errorInfo) {

    if (error?.message === 'Page not found') {
      // todo maybe we should not use the error message to determine the error type....
      this.setState({ hasError: true, errorMessage: 'Page not found.', code: '404' })
      return
    }
    // You can also log the error to an error reporting service here
    const maybeTrcpError = error?.data as TRPCError
    if(maybeTrcpError?.code === 'UNAUTHORIZED' || maybeTrcpError?.code === 'NOT_FOUND') {
      this.setState({ hasError: true, errorMessage: error.message, code: maybeTrcpError?.httpStatus || '' })
    } else {
      const maybeErrorMessage = error?.message
      const errorMessage = maybeErrorMessage ? maybeErrorMessage : 'Something went wrong.'
      this.setState({ hasError: true, errorMessage , code: '500' })
    }
    log.error(error, 'Error')
  }

  render() {
    if (this.state.hasError) {
      // Render fallback UI (you can customize this part)
      return <ErrorPage code={this.state.code} message={this.state.errorMessage} />
    }
    return this.props.children
  }
}

//export default ErrorBoundary


export default (props) => (
  <WithI18n i18n={i18n}>
    <ErrorBoundary {...props} />
  </WithI18n>
)

function ErrorPage({ message, code }) {

  const ref = useRef(null)

  useEffect(() => {
    if (ref.current) {
      ref.current.getElementById("errorCode").innerHTML = code || ''
    }
  }, [code, message])
  return (
    <div className="min-h-100 flex items-center justify-center">
      <div className="bg-white w-full">
        <h2 className="text-xl font-bold text-center mb-4">

          Oops!
          {
            code === '404' ? ' Page not found' : ' Sorry, something went wrong.'
          }

        </h2>
        <BaseError ref={ref} className="w-full"></BaseError>
        {
          code === '404' ?
            <p className="text-center text-txt mb-4">The page you are looking for does not exist.</p>
            : <p className="text-center text-txt mb-4">{message}</p>
        }

      </div>
    </div>
  )
}
