import React, { useMemo, useState } from 'react'
import { api } from '@shared/index'
import { Button} from '@colombalink/basedui'
import ReactECharts, { EChartsOption } from 'echarts-for-react'
import { useT } from '@app/i18n'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'

const intervals = [
  { label: 'Last 24h', value: '-24h' },
  { label: 'Last 7d', value: '-7d' },
  { label: 'Last 30d', value: '-30d' },
]
export const HistogramView = ({
  thingId,
  influxField,
}: {
  thingId: string
  influxField: string
}) => {
  const t = useT()
  const [interval, setInterval] = useState(intervals[0])

  const { data: rawData } = api.event.getStringEventStatsById.useLiveData({
    thingId: thingId,
    interval: interval.value as any,
    influxField,
  })

  const data = useMemo(() => {
    if (!rawData) return null
    return Object.keys(rawData).map((key) => ({
      value: rawData[key],
      label: key,
    }))
  }, [rawData])
  if (!data) return <LoadingIcon />

  const option: EChartsOption = {
    grid: {
      top: 0,
      bottom: 0,
    },
    xAxis: {
      type: 'value',
      position: 'top',
      splitLine: {
        lineStyle: {
          type: 'dashed',
        },
      },
    },
    yAxis: {
      type: 'category',
      axisLine: { show: false },
      axisLabel: { show: false },
      axisTick: { show: false },
      splitLine: { show: false },
    },
    series: [
      {
        type: 'bar',
        itemStyle: {
          color: '#e2e1f5',
        },
        label: {
          show: true,
          formatter: function ({ data }) {
            return `${data.label}: ${data.value} `
          },
        },
        data,
      },
    ],
  }

  return (
    <div className="relative w-full">
      <div className="w-full mb-4">
        <h1 className="text-2xl font-bold">{interval.label}</h1>
      </div>
      <div style={{ minHeight: '300px', width: '100%' }}>
        <ReactECharts
          option={option}
          style={{ height: '100%', minHeight: '400px' }}
        />
      </div>

      <div className="flex p-1 justify-end">
        <Button style={{ margin: '2px' }} color="grey" onClick={() => {}}>
          {t["CoreDashboard:Last30D"].get()}
        </Button>
        <Button
          style={{ margin: '2px' }}
          color="grey"
          onClick={() => {
            setInterval(intervals[2])
          }}
        >
          {t["CoreDashboard:Last14D"].get()}
        </Button>
        <Button
          style={{ margin: '2px' }}
          color="grey"
          onClick={() => {
            setInterval(intervals[1])
          }}
        >
          {t["CoreDashboard:Last7D"].get()}
        </Button>
        <Button
          style={{ margin: '2px' }}
          color="grey"
          onClick={() => {
            setInterval(intervals[0])
          }}
        >
          {t["CoreDashboard:Last24H"].get()}
        </Button>
      </div>
    </div>
  )
}
