import { FC, useState } from "react"
import { OptionMenu } from "@shared/components/core/EditEntityMenu"
import { api } from "@shared/index"
import { useDialog, useToast, ContextItem, Toast, Text, EyeIcon, EditIcon, DeleteIcon } from "@colombalink/basedui"
import { useLink } from '../../../routes'
import { useLocation } from "wouter"
import { useT } from "@app/i18n"
import ConfirmationDialog from "@shared/components/core/ConfirmationDialog"


export const RowMenu = ({ orgAliasId, isAmin, name}) => {
  const t = useT()

  const longestItem = Math.min(
    t['UserOrganizations:View'].get().length,
    t['UserOrganizations:Edit'].get().length,
    t['UserOrganizations:Delete'].get().length
  );
  const width = 100 + longestItem * 5;

  const CategoryMenu: FC<{}> = ({ }) => {
    const { open } = useDialog()
    const [, setLocation] = useLocation()
    const toast = useToast()
    const [hidden, setHidden] = useState('hidden')

    const deleteOrganization = api.organization.deleteOrganization.useMutation()

    
    return (
      <>
        <ContextItem
          style={{ width: '100%', maxWidth: '100%' }}
          onClick={() => {
            setLocation(useLink('/org/:orgAliasId', { orgAliasId }))
          }}
          icon={EyeIcon}
        >
          {t['UserOrganizations:View'].get()}
        </ContextItem>
        {/* TODO: uncomment once the renaming of org is fixed */}
        {/* {isAmin && (
          <ContextItem
            onClick={() => {
              open(<EditOrganizationDialog orgAliasId={orgAliasId}/>)
            }}
            icon={EditIcon}
          >
            {t['UserOrganizations:Edit'].get()}
          </ContextItem>
        )} */}
        {isAmin && (
          <ContextItem
            onClick={async () => {
              open(<ConfirmationDialog
                label={t["UserOrganizations:DeleteOrganization"].get()}
                message={t["UserOrganizations:DeleteOrganizationConfirmation"].get() + name + "?"}
                confirm={t["UserOrganizations:Delete"].get()}
                onConfirm={async () => {
                  await deleteOrganization.mutateAsync({ orgAliasId })
                  toast.add(
                    <Toast
                      label={t['UserOrganizations:DeletedOrganization'].get()}
                      type="success"
                      description={
                        <Text>
                          {t['UserOrganizations:DeletedOrganizationText'].get()}
                        </Text>
                      }
                    />
                  )
                }}
              ></ConfirmationDialog>)
            }}
            icon={DeleteIcon}
          >
            {t['UserOrganizations:Delete'].get()}
          </ContextItem>
        )}
      </>
    )
  }

  return <OptionMenu menu={CategoryMenu} style={{width: `${width}px`}}/>
}