import { Routes } from '@app/routes'
import { HomeIcon } from 'lucide-react'
import React, {
  createContext,
  Dispatch,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { useLocation, useRouter } from 'wouter'

/// ////////////////////////////////////////////////////
// # Navigation TYPE
/// ///////////////////////////////////////////////////
export type NavigationItem = {
  name: string
  link?: string
  subItems: { [key: string]: NavigationItem }
  parent?: NavigationItem
  active?: boolean
  hidden?: boolean // if true, the item will not be shown in the navigation // todo: come up with a better name and implementation
  icon?: React.ReactNode
}

export type Path = NavigationItem[]

export type NavigationState = {
  activeDrawerPath: Path
  activeNavPath: Path
  currentDrawerItem: NavigationItem
  navigation: NavigationItem
  isVisible: boolean
}

export type NavigationContext = {
  state: NavigationState
  setPath: (path: Path) => void
  setNavigationVisibility: (isVisible: boolean) => void
  setNavigation: (navigation: NavigationItem) => void
  setCurrentDrawerItem: (item: NavigationItem) => void
  getNavItemByPath: (path: string) => NavigationItem
}

function mapToNavigationWithParents(
  navigationItem: NavigationItem,
  parent = null
): NavigationItem & { parent: NavigationItem | null } {
  const item = { ...navigationItem, parent }
  for (const key in navigationItem.subItems) {
    const child = navigationItem.subItems[key]
    item.subItems[key] = mapToNavigationWithParents(child, item)
  }
  return item
}

/**
 * 
 * Returns the longest matching navigation item for a given path
 * 
 * **/
export function getNavLocationItem(
  navigationItem: NavigationItem,
  path: string,
): () => NavigationItem {
  if (!navigationItem) {
    return null
  }
  navigationItem = mapToNavigationWithParents(navigationItem)
  return () => {
    const map = new Map<string, NavigationItem>()
    const flatItems = flattenNavigation(navigationItem).filter((item) => item.link)
    for (const item of flatItems) {
      map.set(item.link, item)
    }

    const fragments = path.split('/')
    for (let i = fragments.length; i > 0; i--) {
      const path = `${fragments.slice(0, i).join('/')}`
      const item = map.get(path)
      if (item) {
        return item
      }
    }
  }
}

function flattenNavigation(
  navigationItem: NavigationItem,
  flattened = []
): NavigationItem[] {
  flattened.push(navigationItem)
  for (const keys in navigationItem.subItems) {
    const item = navigationItem.subItems[keys]
    flattenNavigation(item, flattened)
  }
  return flattened
}

/// ////////////////////////////////////////////////////
// # IMPLEMENTATION
/// ///////////////////////////////////////////////////

const defaultState: NavigationState = {
  activeDrawerPath: null,
  activeNavPath: null,
  currentDrawerItem: null,
  navigation: null,
  isVisible: null,
}

const setPathReducer = (state: NavigationState, path: Path) => {
  // console.log('SET_PATH', path)
  return {
    ...state,
    activeDrawerPath: path,
  }
}

const setNavigationVisibilityReducer = (
  state: NavigationState,
  isVisible: boolean
) => {
  return {
    ...state,
    isVisible: isVisible,
  }
}

const setActivePathReducer = (state: NavigationState, path: Path) => {
  return {
    ...state,
    activeNavPath: path,
  }
}

const setNavigationReducer = (state: NavigationState, item: NavigationItem) => {
  return {
    ...state,
    navigation: item,
  }
}

const setCurrentDrawerItemReducer = (
  state: NavigationState,
  item: NavigationItem
) => {
  return {
    ...state,
    currentDrawerItem: item,
  }
}

const NavigationContext = createContext<NavigationContext>({
  state: defaultState,
  setPath: () => { },
  setNavigationVisibility: () => { },
  setNavigation: () => { },
  setCurrentDrawerItem: () => { },
  getNavItemByPath: () => null,
})


export const navigation: NavigationItem = {
  subItems: {},
  name: 'Monidas',
}

navigation.subItems.user = {
  name: 'Loading....',
  link: "/",
  subItems: {},
  parent: navigation,
}


function NavigationContextProvider<T extends Routes>({
  children,
  routes: defaultRoutes,
}: {
  children: JSX.Element | JSX.Element[]
  routes: T
}) {
  const [state, dispatch]: [NavigationState, Dispatch<any>] = useReducer(
    (prevState: NavigationState, action: any) => action(prevState),
    {
      navigation,
      isVisible: false,
      activeDrawerPath: [navigation],
      activeNavPath: [navigation],
    } || defaultState
  )

  const [path] = useLocation()

  useEffect(() => {
    function invalidatePreviousActivePath(path: NavigationItem) {
      if (!path) return
      path.active = false
      if (path.subItems) {
        for (const subItem of Object.values(path.subItems)) {
          invalidatePreviousActivePath(subItem)
        }
      }
    }
    function getRoot(path: NavigationItem): NavigationItem {
      if (path?.parent) {
        return getRoot(path.parent)
      }
      return path
    }
    function setActivePath(path: NavigationItem) {
      if (!path) return
      path.active = true
      if (path.parent) {
        setActivePath(path.parent)
      }
    }
    let location = getNavLocationItem(navigation, path)()
    let root = getRoot(location)
    // important for invalid orgs 
    if(root === undefined){
      location = getNavLocationItem(navigation, "/")()
      root = getRoot(location)
    }

    invalidatePreviousActivePath(root)
    setActivePath(location)

    const pathAsList = []
    let current = location
    while (current?.parent) {
      pathAsList.push(current)
      current = current.parent
    }
    pathAsList.push(current) // push root that has no parent
    pathAsList.reverse()

    dispatch((prevState: NavigationState) =>
      setActivePathReducer(prevState, pathAsList)
    )
  }, [path, state.navigation])

  const setPath = useCallback(
    (path: Path) => {
      dispatch((prevState: NavigationState) => setPathReducer(prevState, path))
    },
    [dispatch]
  )

  const setActiveDrawerPath = useCallback(
    (activeDrawerPath: Path) => {
      dispatch((prevState: NavigationState) => setPathReducer(prevState, activeDrawerPath))
    },
    [dispatch]
  )

  const setNavigationVisibility = useCallback(
    (isVisible: boolean) => {
      dispatch((prevState: NavigationState) =>
        setNavigationVisibilityReducer(prevState, isVisible)
      )
    },
    [dispatch]
  )

  const setNavigation = useCallback(
    (navigation: NavigationItem) => {
      dispatch((prevState: NavigationState) =>
        setNavigationReducer(prevState, navigation)
      )
    },
    [dispatch]
  )

  const setCurrentDrawerItem = useCallback(
    (item: NavigationItem) => {
      dispatch((prevState: NavigationState) =>
        setCurrentDrawerItemReducer(prevState, item)
      )
    },
    [dispatch]
  )

  useEffect(() => {
    setCurrentDrawerItem(state.activeNavPath[state.activeNavPath.length - 1])
  }, [state.activeNavPath])


  const getNavItemByPath = useCallback((path: string) => {
    return getNavLocationItem(navigation, path)()
  }, [])

  useEffect(() => {
    let nextItem = state?.currentDrawerItem
    if (!nextItem) {
      return
    }
    const activeDrawerPath = []
    while(nextItem.parent) {
      activeDrawerPath.push(nextItem)
      nextItem = nextItem.parent
    }
    activeDrawerPath.push(nextItem)
    activeDrawerPath.reverse()
    setActiveDrawerPath(activeDrawerPath)
  }, [state.currentDrawerItem])

  return (
    <NavigationContext.Provider
      value={{
        state,
        setPath,
        setNavigationVisibility,
        setNavigation,
        setCurrentDrawerItem,
        getNavItemByPath,
      }}
    >
      {children}
    </NavigationContext.Provider>
  )
}
const useNavigator = () => useContext(NavigationContext)



export { useNavigator, NavigationContextProvider }
