import { DefaultProcedure } from '@server/app/trpc/router'
import { z } from 'zod'
import { createProjectAuditLogger } from '../audit-log/shared/writeLog'
import { TRPCError } from '@trpc/server'
import { createDefaultProjectQuota } from './quotas/createDefault'
import { incrementOrganizationQuota } from './quotas/increment'

export const createProjectParams = z.object({
  name: z.string()
    .refine(value => value.length <= 100, 'Project name should not exceed 100 characters.')
    .refine(value => /^[a-zA-Z0-9 \-]+$/.test(value), 'Name can only contain alphabets, numbers, dashes, and spaces.')
  ,
  // alias is the name transformed to lowercase and spaces as dashes
  alias: z.string({
    required_error: 'Please provide an project name.'
  })
    .refine(value => value.length <= 100, 'Project name should not exceed 100 characters.')
    .refine(value => /^[a-zA-Z0-9\-]+$/.test(value), 'Name can only contain alphabets, numbers, dashes, and spaces.'),
  orgAliasId: z.string().optional(),
})


export const createProjectProcedure = (procedure: DefaultProcedure) =>
  procedure
    .input(createProjectParams)
    .mutation(async ({ ctx, input }) => {
      ctx.logger.debug('procedure::createProject input:', input)

      if(input.name.toLocaleLowerCase().replace(/ /g, '-') !== input.alias){
        throw new TRPCError( {message: ctx.i18n.t["AppProject:AliasDoesNotMatchTheProjectName"].get(), code: "BAD_REQUEST"})
      }

      const alias = `${ctx.org.alias}/project/${input.alias}`

      // check if project already exists in organization
      const res = await ctx.based.db.default.get({
        $alias: alias,
        id: true
      })
     
      if(res.id){
        ctx.logger.error('procedure::createProject project already exists:')
        throw new TRPCError( {message: ctx.i18n.t["AppProject:ProjectAlreadyExists"].get(), code: "CONFLICT"})
      }

      const project = await ctx.based.db.default.set({
        name: input.name,
        aliases: [alias],
        parents: {
          $add: ctx.org.id,
        },
        type: 'project',
      })

      // we add the quotas
      await createDefaultProjectQuota(ctx.based, project.id)
      // increment project counter in org quota
      await incrementOrganizationQuota(ctx.based, ctx.org.id!)

      ctx.logger.debug('procedure::createProject project:', project)
      await ctx.based.db.default
        .set({
          $id: ctx.org.id,
          projects: {
            $add: project.id,
          },
          type: 'organization',
        })

      // we can not use the project audit log from the context as it is not ready yet
      const appendToProjectAuditLog = (await createProjectAuditLogger(
        {
          based: ctx.based,
          influx: ctx.influxDb,
          userId: ctx.user.id,
          projectId: project.id,
        }
      ))
      await appendToProjectAuditLog({ message: `Created project ${input.name}`, data: {} })

      // make all existing owners of the org also owners of this project
      const org = await ctx.based.db.default.get({
        $id: ctx.org.id,
        members:{
          $list: {},
          id: true,
          role: true,
          user: {
            id: true
          },
        }
      })

      for(const member of org.members) {

        if(member.role === "OWNER"){ 

          const memberToRole = await ctx.based.db.default.set({
            type: 'memberToRole',
            user: member.user.id,
            role: "OWNER",
            parents: {
              $add: project.id,
            },
          })
    
          await ctx.based.db.default.set({
            $id: project.id,
            members: {
              $add: memberToRole.id,
            },
          })
        }
      }

      return { id: project.id, alias: alias };
    })
