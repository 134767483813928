import { FC } from "react"
import { OptionMenu } from "@shared/components/core/EditEntityMenu"
import { api } from "@shared/index"
import { useDialog, useToast, ContextItem, Toast, Text, DeleteIcon } from "@colombalink/basedui"
import { useT } from "@app/i18n"

export const InvitationRowMenu = ({ invitationId }: { invitationId: string }) => {

  const CategoryMenu: FC<{}> = ({ }) => {
    const t = useT()
    const { open } = useDialog()
    const toast = useToast()

    const removeMemberFromOrganization = api.organization.revokeMemberInvitation.useMutation()

    return (
      <>
        <ContextItem
          onClick={async () => {
            try{
              await removeMemberFromOrganization.mutateAsync({ invitationId })

              toast.add(
                <Toast
                  label={t['Member:RevokedInvitation'].get()}
                  type="success"
                />
              )
            } catch (e) {
              toast.add(
                <Toast
                  label={t['Member:RevokeInvitation'].get()}
                  type="error"
                  description={
                    <Text style={{ whiteSpace: 'normal' }}>
                      {e.message}
                    </Text>
                  }
                />
              )
              //throw e
            }
          }}
          icon={DeleteIcon}
        >
          {t['Member:RevokeInvitation'].get()}
        </ContextItem>
      </>
    )
  }

  return <OptionMenu menu={CategoryMenu} style={{ width: '150px' }} />
}