import {
  AccordionContent,
  AccordionItem as BaseAccordionItem,
  AccordionTrigger,
} from '@shared/components/core/shadcn-ui/Accordion'
import { statusColors } from '@styles/colors'
import { cn } from '@shared/utils/tw'
import { useProjectDashboardContext } from '../../DashboardContext'
import { useLocation } from 'wouter'
import { LastSeenLabel } from './LastSeenLabel'

export const AccordionItem = ({ title, children, status, id, alias, updatedAt }: { id, title, children?: React.ReactNode, status?: string, alias?: string, updatedAt?: string }) => {
  const {
    useOpenAccordionItems: [openAccordionItems, setOpenAccordionItems],
  } = useProjectDashboardContext()
  const [, setLocation] = useLocation()

  return (
    <BaseAccordionItem value={id}>
      <AccordionTrigger
        onClick={() => {
          if (openAccordionItems.includes(id)) {
            setOpenAccordionItems(openAccordionItems.filter(item => item !== id))
          } else {
            setOpenAccordionItems([...openAccordionItems, id])
          }
        }
        }
        headerClassName={cn("border-l-8", statusColors[status]?.border)}>
        <div className="flex flex-row flex-wrap items-center w-full">
          <h3 className="ml-4 text-txt text-2xl font-semibold tracking-tight text-start flex-grow">

            {alias
              ?
              <a className="hover:underline" onClick={(e) => {
                setLocation(alias)
                e.preventDefault()
              }}>
                {title}
              </a>
              : title
            }

            {' '}

          </h3>

          <span className="mr-2 text-txt-2 text-sm font-normal tracking-tight h-full">
            <LastSeenLabel updatedAt={updatedAt} />
          </span>
        </div>
      </AccordionTrigger>
      <AccordionContent>
        {children}
      </AccordionContent>
    </BaseAccordionItem>
  )
}