const trpcErrorHandler = (message) => {
  let errorMessage = message.substring(
    message.indexOf('[') - 1,
    message.lastIndexOf(']') + 1
  )
  const errorObject: [
    { code: string; message: string; path: string[]; validation: string }
  ] = JSON.parse(errorMessage)
  return errorObject
}

export default trpcErrorHandler
