
import { LoadingIcon } from '@shared/components/core/LoadingIcon'
import { useProjectDashboardContext } from "../DashboardContext"
import { useT } from '@app/i18n'

export const StatusFragment = ( {switchFragment}) => {
  const t = useT()
  const { things, filters} = useProjectDashboardContext()

  if (things.loading) {
    return <LoadingIcon />
  }


  const statusCount = {
    idle: 0,
    alert: 0,
    alarm: 0,
    'no-monitors': 0,
  }

  for (const thing of things.array) {

    if (!thing.monitors || thing.monitors.length === 0) {
      statusCount['no-monitors']++
      continue
    }

    const hasActiveMonitor = thing.monitors.some((alarm) => alarm.activeState === 'active')
    if (!hasActiveMonitor) {
      statusCount["no-monitors"]++
      continue
    }

    let hasAlarm = false
    let hasAlert = false
    let hasIdle = false

    for (const monitors of thing.monitors) {
      if (monitors.status === 'alarm') {
        hasAlarm = true
      } 
      else if (monitors.status === 'alert') {
        hasAlert = true
      }
      else if (monitors.status === 'idle') {
        hasIdle = true
      }
    }

    if (hasAlarm) {
      statusCount.alarm++
    } else if (hasAlert) {
      statusCount.alert++
    } else if (hasIdle) {
      statusCount.idle++
    }
  }

  const statusList = [
    {
      count: statusCount.idle,
      statusType: "idle",
      bgColor: 'bg-green-500',
      label: t["Project:Idle"].get(),
    },
    {
      count: statusCount.alert,
      statusType: "alert",
      bgColor: 'bg-orange-500',
      label: t["Project:Alert"].get(),
    },
    {
      count: statusCount.alarm,
      statusType: "alarm",
      bgColor: 'bg-red-500',
      label: t["Project:Alarm"].get(),
    }
  ]



  const noMonitorCount = statusCount['no-monitors']

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <>
      <div className="pt-5 pb-5 pl-20 pr-20">
        <ul
          role="list"
          className="p-0 mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4"
        >
          {statusList.map((status, index) => (
            <li
            onClick={() => {
              switchFragment('latest')
              filters.status.setSelected(status.statusType)
            }}
            
            key={index} className="col-span-1 flex rounded-xl shadow-sm min-w-[150px] cursor-pointer">
              <div
                className={classNames(
                  status.bgColor,
                  'flex w-3 flex-shrink-0 items-center justify-center rounded-l-xl text-sm font-medium text-white'
                )}
              ></div>
              <div className="flex flex-1 items-center justify-between truncate rounded-r-xl border-b border-r border-t border-gray-200 bg-white">
                <div className="px-4 py-2 text-sm">
                  <div className="font-medium text-2xl text-gray-900 hover:text-gray-600">
                    {status.count}{" "}{t['Project:Device'].get({ count: status.count})}
                  </div>
                  <p className="text-gray-500">{status.label}</p>
                </div>
              </div>
            </li>
          ))}
          {(
            <li 
            onClick={() => {
              switchFragment('latest')
              filters.status.setSelected('no-monitors')
            }}
            className="col-span-1 min-w-[150px] flex rounded-xl shadow-sm">
              <div
                className={classNames(
                  'bg-gray-400',
                  'flex w-3 flex-shrink-0 items-center justify-center rounded-l-xl text-sm font-medium text-white'
                )}
              ></div>
              <div className="flex flex-1 items-center justify-between truncate rounded-r-xl border-b border-r border-t border-gray-200 bg-white cursor-pointer">
                <div className="px-4 py-2 text-sm">
                  <div className="font-medium text-2xl text-gray-900 hover:text-gray-600">
                    {noMonitorCount}{" "}{t['Project:Device'].get({ count: noMonitorCount})}
                  </div>
                  <p className="text-gray-500">{t["Project:NoMonitor"].get()}</p>
                </div>
              </div>
            </li>
          )}
        </ul>
      </div>
    </>
  )
}
