import MainContainer from '@app/pages/Login/shared/MainContainer';
import FadeInBox from '@app/pages/Login/shared/FadeInBox';
import { Button, Toast, useToast, Text } from '@colombalink/basedui';
import { useEffect, useState } from 'react';

import { api } from '@shared/index'
import { useLocalStorageContext } from '@shared/utils/react/LocalStorage/context';
import { useLocation } from 'wouter';
import { WithI18n } from '@shared/i18n/withI18n';
import i18n from '../../i18n';
import { useT } from '@app/i18n';
import { useClientsContext, useIdentityClient } from '@shared/client/context';
import { useAuthState } from '@shared/client/useAuthState';

type Status = 'validating' | 'valid' | 'invalid'

export default () => (
    <WithI18n i18n={i18n}>
        <JoinPage />
    </WithI18n>
)

const JoinPage = () => {
    const t=useT()
    const client = useIdentityClient()
    const [status, setStatus] = useState<Status>('validating')
    const authState = useAuthState()
    const joinOrg = api.organization.joinOrganizationWithToken.useMutation()
    const user = api.user.getUserById.useLiveData()
    const [, setLocation] = useLocation()
    const [invitation, setInvitation] = useState<any>(null)
    // console.log('invitation', invitation)

    const toast = useToast()

    const {
        value: globalInvitation,
        setValue: setGlobalInvitation,
    } = useLocalStorageContext<string>('invitation')

    useEffect(() => {
        if (!globalInvitation) {
            return
        }
        // console.log('validating invitation')
        client.call('login/validate/invitation', { token: globalInvitation })
            .then((invitation) => {
                setInvitation(invitation)
                setStatus('valid')

            })
            .catch((e) => {
                console.log(e)
                setStatus('invalid')
            })
    }, [globalInvitation])

    if (!authState.userId) {
        return (
            <MainContainer>
                <FadeInBox>
                    <p className='mb-4'>{t['Login:LoginBeforeJoining'].get()}</p>
                    <Button
                        onClick={
                            () => {
                                setLocation('/invitation/login')
                            }
                        }
                    >{t['Login:Login'].get()}</Button>
                </FadeInBox>
            </MainContainer>
        )
    }

    return (
        <MainContainer>
            <FadeInBox>

                <p >{`Hi, ${user.data?.name}.`}</p>
                <p className="mb-4">
                    {t['Login:InvitedToOrganization'].get()} {' '}
                    {invitation?.organization?.name}
                </p>

                {status === 'valid' && (user?.data?.email === invitation?.email) && (
                    <>
                        <Button
                            onClick={async () => {
                                try {

                                    const org = await joinOrg.mutateAsync({
                                        token: globalInvitation,
                                        userId: authState.userId
                                    })

                                    toast.add(
                                        <Toast
                                            label="Joined organisation"
                                            type="success"
                                            description={
                                                <Text>
                                                    {t['Login:SuccessfullyJoinedOrganization'].get()} <b>{invitation?.organization?.name}</b>
                                                </Text>
                                            }
                                        />
                                    )

                                    setLocation(org.alias)
                                } catch (e) {
                                    toast.add(<Toast label={t['Login:FailedToJoinOrganization'].get()} type="error" description={e.message} />)
                                }
                            }
                            }
                        >{t['Login:Join'].get()}</Button>
                    </>
                )
                }
            </FadeInBox>
        </MainContainer>
    )
}

