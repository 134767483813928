import { usePageContext } from "../context"
import { Title } from "@shared/components/core/Title"
import { useEffect, useState } from "react"
import { useTable } from "@shared/components/core/table/Table"
import { Checkbox } from "@colombalink/basedui"
import { useT } from "@app/i18n"

export const TagsSelectionFragment = () => {
  const t = useT()
    const {
      useStep: [, setStep],
      useFlatPaths: [flatPaths],
      useTags: [tags, setTags],
      useValues: [values],
    } = usePageContext()
  
    const [tableData, setTableData] = useState([])
    const [table, TableComponent] = useTable({
      header: [
        { label: t["ThingConfigure:Selected"].get(), key: 'selected' },
        { label: t["ThingConfigure:PropertyPath"].get(), key: 'path' },
      ],
      data: tableData,
    })
    useEffect(() => {
      const tableData = flatPaths
        .filter((path) => !values.includes(path))
        .sort()
        .map((path) => {
          return {
            path,
            isSelectedDefault: tags.includes(path),
            selected: ({ row }: { row: Row<any> }) => {
              return (
                <Checkbox
                  value={row.getIsSelected()}
                  onChange={() => {
                    row.toggleSelected()
                  }}
                />
              )
            },
            customAttribute: {
              row: {
                onClick: ({ row }: { row: Row<any> }) => row.toggleSelected(),
                className: 'cursor-pointer',
              },
            },
          }
        })
  
  
      setTableData(tableData)
  
    }, [])
  
    const selected = table.getSelectedRowModel()
    useEffect(() => {
      if (!selected?.rows) {
        return
      }
      const selectedTags = selected?.rows.map((row) => row?.original?.path)
      setTags(selectedTags)
      //console.log("tags:", tags)
    }, [selected])
  
    return (
      <div className="flex flex-col">
        <Title>3) {t["ThingConfigure:TagsSelection"].get()} </Title>
        <p>
          {t["ThingConfigure:SelectProps"].get()}
        </p>
        <p>
          {t["ThingConfigure:TagsAre"].get()}
        </p>
  
        <div className="align-middle p-2 m-2">{TableComponent}</div>
      </div>
    )
  }