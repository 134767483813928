import { Link } from 'wouter'
import { ChevronRightIcon } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { NavigationItem, Path, useNavigator } from './context'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function hasNextColumn(item: NavigationItem) {
  try {
    if (!item?.subItems || Object.keys(item.subItems).length === 0) {
      return false
    }
    const visibleSubItems = Object.values(item.subItems).filter((subItem) => !subItem?.hidden)

    return visibleSubItems.length > 0

  } catch (e) {
    return false
  }
}

export const ColumnViewer = ({ index }: { index: number }) => {

  const { state } = useNavigator()
  const [breadCrumbAction, setBreadCrumbAction] = useState(index !== undefined)

  let activeDrawerPath =
    index && breadCrumbAction
      ? state.activeDrawerPath.slice(0, index)
      : state.activeDrawerPath

  if (index === 1 && !breadCrumbAction) {
    activeDrawerPath = state.activeDrawerPath
  }

  //console.log("ACTIVE DRAWER PATH", activeDrawerPath)

  // Create a reference to the last column.
  const lastColumnRef = useRef(null)

  useEffect(() => {
    if (lastColumnRef.current) {
      // This will ensure the last column is visible within its container.
      lastColumnRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [activeDrawerPath.length]) // This effect will run every time a new column is added.

  return (
    <div className="h-full flex flex-col ml-4 mr-4">
      <div className="h-full flex flex-row mt-4">
        {activeDrawerPath.map((item, i) => {
          if (breadCrumbAction && index + 1 === i + 1) {
            return <div key={i} />
          }
          const path = activeDrawerPath.slice(0, i + 1)

          if (!hasNextColumn(item)) {
            return null
          }
          return (
            // Add the reference to the last column.
            <div
              key={i}
              className="flex flex-col mr-4 w-48"
              ref={i === activeDrawerPath.length - 1 ? lastColumnRef : null}
            >
              <div className="p-1 m-1 text-gray-400 font-semibold flex flex-col w-44 h-14 overflow-hidden text-ellipsis">
                {i === 0 ? 'User / Organizations' : item.name || ''}
              </div>
              <div className="h-full p-4 border-r border-gray-300">
                <div className="flex-row">
                  <Column
                    isLastColumn={activeDrawerPath.length - 1 === i}
                    item={item}
                    path={path}
                    resetAction={setBreadCrumbAction}
                    setShowPath
                  />
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const SubItem = ({
  item,
  onLinkClick,
  onSubItemClick,
  isLastColumn,
}) => {
  let className =
    'mx-0.5 p-3 text-base w-40 overflow-hidden whitespace-nowrap text-ellipsis'
  className += item.link ? ' cursor-pointer underline' : ''
  const { state } = useNavigator()

  /*
  const isInActivePath = (next) => {
    if(next === item) {
      return true
    }
    if(next.parent){
      return isInActivePath(next.parent)
    }else{
      return false
    }
  }
  className += isInActivePath(state.currentDrawerItem) ? ' text-red-600 ' : '';
 */
  className += state.activeDrawerPath.some((i) => i === item)
    ? ' font-bold  text-gray-900 '
    : ' '

  if (isLastColumn) {
    className += ' text-gray-900 '
  } else {
    className += ' text-gray-500 '
  }
  const iconColor = state.activeDrawerPath.some(x => x.name === item.name) ? 'black': 'rgba(0, 0, 0, 0.5)';

//  console.log('SubItem', item, state.activeDrawerPath)
  return (
    <div className="flex flex-row relative">
      <div className="flex flex-row items-center">{item.icon}</div>
      <div className={className}>
        {item.link ? (
          <Link
            className='className="overflow-hidden whitespace-nowrap text-ellipsis mr-1'
            onClick={onLinkClick}
            href={item.link}
          >
            {item.name}
          </Link>
        ) : (
          <div className="overflow-hidden whitespace-nowrap text-ellipsis mr-1">
            {item.name}
          </div>
        )}
      </div>
      {Object.keys(item.subItems || {}).length > 0 &&
        hasNextColumn(item) && (
          <div
            onClick={onSubItemClick}
            className="cursor-context-menu w-6 h-6 absolute top-1/2 right-0 transform -translate-y-1/2"
          >
            <ChevronRightIcon style={{ color: iconColor }} />
          </div>
        )}
    </div>
  )
}

const Column = ({
  item,
  path,
  resetAction,
  isLastColumn,
}: {
  item: NavigationItem
  setShowPath
  path: Path
  resetAction: (isReset: boolean) => void
  isLastColumn: boolean
}) => {
  const { setNavigationVisibility, setPath, setCurrentDrawerItem } =
    useNavigator()
  const items: NavigationItem[] = Object.values(item.subItems)
  return (
    <div className="w-[150px]">
      <div className="-mx-2 space-y-1">
        {items
          .filter((i) => !i.hidden)
          .map((subItem, i) => (
            <SubItem
              isLastColumn={isLastColumn}
              key={subItem.name}
              item={subItem}
              onLinkClick={() => {
                if (subItem.link) {
                  setNavigationVisibility(false)
                  setPath([...path, subItem])
                }
              }}
              onSubItemClick={() => {
                resetAction(false)
                setPath([...path, subItem])
                setCurrentDrawerItem(subItem)
              }}
            />
          ))}
      </div>
    </div>
  )
}
