import { DefaultProcedure } from '@server/app/trpc/router'
import { z } from 'zod'
import { v4 as uuidv4 } from 'uuid'
import { TRPCError } from '@trpc/server'

export const inviteMemberParams = z.object({
    orgAliasId: z.string().optional(),
    email: z.string().email("Please enter a valid email address"),
})

let index = 0

export const inviteMemberToOrganizationProcedure = (procedure: DefaultProcedure) =>
    procedure
        .input(inviteMemberParams)
        .mutation(async ({ ctx, input }) => {
            ctx.logger.debug('procedure::inviteMemberToOrganization input:', input)
            const { email } = input;
            const correlationId = uuidv4()

            if (!ctx.org.id) {
                throw new TRPCError({
                    code: 'BAD_REQUEST',
                    message: ctx.i18n.t["Error:OrganizationIdentifierNotSet"].get(),
                })
            }
            const isMember = await isUserMemberOfOrganization(ctx, email)

            if (isMember) {
                throw new Error(ctx.i18n.t['AppOrg:UserAlreadyInOrg'].get())
            }

            const { id: invitationId } = await ctx.based.db.default.set({
                type: 'invitation',
                organization: ctx.org.id,
                email,
            });

            await ctx.based.db.default.set({
                $id: invitationId,
                aliases: [correlationId]
            })

            const invitationToken = await ctx.based.createToken({
                type: 'inviteMemberToOrganization',
                correlationId
            }, {
                expiresIn: '24h',
                issuer: 'app.monidas.com',
                audience: 'app.monidas.com',
                subject: 'inviteMemberToOrganization',
            })

            // encode as base64
            // need to change it to correct baseurl in env file in prod
            // Set a default value for the environment variable or use the provided one
            const invitationLinkBase = process.env.INVITATION_LINK || "https://app.monidas.com/login/invitation";
            const invitationLink = `${invitationLinkBase}/${Buffer.from(invitationToken).toString('base64')}`
            ctx.logger.debug('procedure::inviteMemberToOrganization invitationLink:', invitationLink)
            const orgName = input.orgAliasId?.split("/")[1]
            const title = `Monidas: ` + ctx.i18n.t["AppOrg:InvitationToJoin"].get() + `${orgName}`
            const invitationEmail = (organization, invitationLink) =>
                ctx.i18n.t["AppEmail:Join"].get() + `${organization} ` + ctx.i18n.t["AppEmail:On"].get() + ` MONIDAS

            `+ ctx.i18n.t["AppEmail:Hello"].get() + `,
            
            `+ ctx.i18n.t["AppEmail:YouHaveBeenInvited"].get() + `${organization} ` + ctx.i18n.t["AppEmail:On"].get() + ` Monidas.
            
            
            
            `+ ctx.i18n.t["AppEmail:JoinTheOrg"].get() + ` [${invitationLink}]` + ctx.i18n.t["AppEmail:AndVisitUs"].get() + `
            https://monidas.com/ [https://monidas.com/]
            
            --------------------------------------------------------------------------------
            `
                +
                ctx.i18n.t["AppEmail:IfNotYou"].get()



            const message = invitationEmail(orgName, invitationLink)
            // console.log("------------------msg:  " + message)
            const username = input.email.split("@")[0]

            const content = await ctx.based.call('email:render:template:orgInvitation', {
                username: username,
                orgName: orgName!,
                inviteLink: invitationLink,
            })


            // send the invitation link to the email
            await ctx.based.call('app:emailer:send', {
                to: email,
                subject: title,
                ...content
            })
                .catch((e) => {
                    ctx.logger.debug('procedure::inviteMemberToOrganization error:', e.message)

                    throw new TRPCError({
                        code: 'INTERNAL_SERVER_ERROR',
                        message: ctx.i18n.t['AppEmail:FailedToSendEmail'].get(),
                    })
                })

           await ctx.based.db.default.set({
                $id: ctx.org.id,
                memberInvitations: {
                    $add: [invitationId]
                },
            })

            ctx.org.auditLog.append({
                message: `Invited ${email} to the organization.`,
                data: {
                    email,
                    orgAlias: ctx.org.alias
                }
            })
        })

async function isUserMemberOfOrganization(ctx, email: string) {
    const orgId = ctx.org.id
    const org = await ctx.based.db.default.get({
        $id: orgId,
        id: true,
        name: true,
        members: {
            id: true,
            role: true,
            $list: {},
            user: {
                id: true,
                email: true,
            },
        },
    })

    const isMember = org.members.some((entry) => entry.user.email === email)

    return isMember
}




