import { Menu } from '@colombalink/basedui'
import { useChartContext } from './context'
import { selectChild } from '@shared/utils/reactChildSelect'
import {useThingContext} from "@app/pages/Orgs/Thing/Fragments/Latest/context";

interface LegendProps {
    children?: React.ReactNode | React.ReactNode[];
}

export const Legend: React.FC<LegendProps> = ({ children }) => {
  const chartContext = useChartContext()
    const thingCtx = useThingContext();
  const [properties,] = chartContext.useProperties // [properties, BoundFunctionObject]
  const [visibleFields,] = chartContext.useVisibleFields

  if (!visibleFields) {
      return <div>Something went wrong!!</div>
  }
  return (
    <Menu
      style={{
        height: '100%',
        width: '150px',
        padding: '10px 14px 0px 0px',
        border: 'none'
      }}
      header={children &&  selectChild(children).endsWith('Selector')}

      onChange={property => {
        chartContext.useVisibleFields[1](prevState => new Map(prevState).set(property, !prevState.get(property)))
        const visibleProperties = [...visibleFields.values()].filter(value => value).length;
        if (visibleProperties === 0) {
          return;
        }
      }}
      data={
        [...visibleFields.keys()].reduce((acc, key) => {
            const property = properties.values[key]
            const color = thingCtx.usePropertiesColor[0].size != 0 ? `rgb(${thingCtx.usePropertiesColor[0].get(key).join(', ')})` : 'none'

            if (visibleFields.get(key)) {
                acc[key] = <b style={{display: 'inline-block', position: 'relative', paddingLeft: '5px'}}
                              onMouseEnter={() => {
                                  chartContext.useHighlightedField[1](key)
                              }}
                              onMouseLeave={() => {
                                  chartContext.useHighlightedField[1](null)
                              }}
                >
                    <div style={{display: 'inline-block', position: 'relative', paddingLeft: '5px'}}>
                        <span style={{
                            borderLeft: `3px solid ${color}`,
                            height: '100%',
                            position: 'absolute',
                            left: '-5px'
                        }}></span>
                        {property?.label?.en || ''}
                    </div>
                </b>

            } else {
                acc[key] = <div
                >{property?.label?.en || ''}</div>
            }
            return acc
        },{})
      }

    />
  )
}

