import { PageRoleSwitcher } from "@shared/components/page/PageRoleSwitcher"
import { AdminPage } from "./Page.admin"
import { UserPage } from "./Page.user"

type PageProps = { params: { orgAliasId: string, projectAliasId: string, thingAliasId: string } }
export type PageParams = PageProps['params']

export default (props: PageProps) => {
  return (
    <PageRoleSwitcher>
      <AdminPage />
      <UserPage />
    </PageRoleSwitcher>
  )
}