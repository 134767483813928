import { Spacer } from "@colombalink/basedui"
import { useProjectDashboardContext } from "../../DashboardContext"
import { useT } from '@app/i18n'

export const NoThingFoundWithFilter = () => {
    const t = useT()
    const {
        useSelectedThingsIds: [selectedThingsIds],
    } = useProjectDashboardContext()
    return (
        <>
            {
                selectedThingsIds?.length === 0 && (
                    <div>
                        <p className="max-w-4xl">
                            {t['Project:NoDevicesWithSelectedFilters'].get()}
                        </p>
                        <p className="max-w-4xl">
                            {t['Project:PleaseChangeFilters'].get()}
                        </p>
                        <Spacer />
                    </div>
                )
            }
        </>
    )
}