import {
  AddIcon,
  useToast,
} from '@colombalink/basedui'
import DefaultNavigation from '@shared/components/page/navigation/DefaultNavigation'
import React, { useEffect } from 'react'
import { DynamicButtonRow } from '@shared/components/core/DynamicButtonRow'
import { RowMenu } from './RowMenu'
import { Page } from '@shared/components/page/Page'
import { Title } from '@shared/components/core/Title'
import { api } from '@shared/index'
import { useLocation } from 'wouter'
import { useTable } from '@shared/components/core/table/Table'
import AddNewIntegrationToOrgDialog from './AddDialog'
import { Role, getRoleLabel } from '@server/shared'
import { useRouteParams } from '../../../../shared/router'
import { WithI18n } from '@shared/i18n/withI18n'
import i18n from './i18n'
import { useT } from '@app/i18n'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'

type PageProps = { params: { orgAliasId: string } }
export type PageParams = PageProps['params']

const OrganisationsMembersPage = (props: PageProps) => {
  return (
    <Page>
      <DefaultNavigation />
      <Main />
    </Page>
  )
}

const Main = () => {

  return (
    <WithI18n i18n={i18n}>
      <ManageOrganizationsMembers />
    </WithI18n>
  )
}
Main.displayName = 'Main'


export default OrganisationsMembersPage

const ManageOrganizationsMembers = () => {
  const t = useT()

  return (
    <div className="flex flex-col">
      <DynamicButtonRow buttons={[
        {
          icon: <AddIcon />,
          dialogComponent: <AddNewIntegrationToOrgDialog />,
          label: t['OrgIntegration:Add'].get(),
          style: { cursor: 'pointer' }
        }
      ]
      } />
      <IntegrationsTable />
    </div>
  )
}
ManageOrganizationsMembers.displayName = 'ManageOrganizationsMembersMain'

function IntegrationsTable() {
  const t = useT()
  const { orgAliasId } = useRouteParams<PageParams>()
  const integrations = api.integrations.getAllByOrgId.useLiveData({ orgAliasId })
  const [, setLocation] = useLocation()
  const toast = useToast()
  const [tableData, setTableData] = React.useState([])


  const [table, TableComponent] = useTable({
    header: [
      { label: t['OrgIntegration:Name'].get(), key: 'name' },
      { label: t['OrgIntegration:Type'].get(), key: 'typeLabel' },
      { label: '', key: 'action' },
    ],
    data: tableData,
  })


  useEffect(() => {
    if (integrations.data) {
      setTableData(
        integrations.data.map((integration) => {
          return {
            ...integration,
            typeLabel: getTypeLabel(integration.type),
            action: (<RowMenu integration={integration} />),
            customAttribute: {
            },
          }
        })
      )
    }
  }, [integrations.data])

  if (integrations.error) {
    throw integrations.error
  }

  if (integrations.loading) {
    return <>{t['OrgIntegration:Loading'].get()} <LoadingIcon /></>
  }
  return (
    <>
      {TableComponent}
    </>
  )
}



const getTypeLabel = (type: string) => {
  switch (type) {
    case 'integrationChirpstack':
      return 'Chirpstack'
    default:
      return 'Unknown'
  }
}