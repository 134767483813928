import DefaultNavigation from '@shared/components/page/navigation/DefaultNavigation'
import { Button, Input, Spacer } from '@colombalink/basedui'
import { Page } from '@shared/components/page/Page'
import { useLocation } from 'wouter'
import { useEffect, useState } from 'react'
import { Title } from '@shared/components/core/Title'
import { TRPCError } from '@trpc/server'
import { TRPCClientError } from '@trpc/client'
import thing from '@app/pages/Orgs/Thing'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'

export default function Default() {
  return (
    <Page>
      <DefaultNavigation />
      <DefaultMain />
    </Page>
  )
}

const DefaultMain = () => {
  const [notFound, setNotFound] = useState(false)
  const [location, setLocation] = useLocation()
  useEffect(() => {
    const fragments = location.split('/')
    if (fragments[1] === 'users') {
      // if (fragments[3] === 'notifications') {
      //   setLocation(`/user/${fragments[2]}/notifications`)
      //   return
      // }
      if (fragments[3] === 'profile') {
        setLocation(`/user/${fragments[2]}/profile`)
        return
      }
      if (fragments[3] === 'settings') {
        // Handle settings here when available
        setLocation(`/user/${fragments[2]}/settings`)
        return
      }
      if (fragments[3] === 'organizations') {
        setLocation(`/user/${fragments[2]}/organizations`)
        return
      }
      setLocation('/')
      return
    }
    if (fragments[1] === 'org') {
      if (fragments[3] === 'projects') {
        if (fragments[4] === 'editor') {
          setLocation(`/org/${fragments[2]}/projects/editor`)
          return
        }
        setLocation(`/org/${fragments[2]}/projects`)
        return
      }
      if (fragments[3] === 'project') {
        if (fragments[5] === 'members') {
          setLocation(`/org/${fragments[2]}/project/${fragments[4]}/members`)
          return
        }
        if (fragments[5] === 'thing') {
          if (fragments[7] === 'configure') {
            setLocation(
              `/org/${fragments[2]}/project/${fragments[4]}/thing/${fragments[6]}/configure`
            )
            return
          }
          setLocation(
            `/org/${fragments[2]}/project/${fragments[4]}/thing/${fragments[6]}`
          )
          return
        }
        if (fragments[5] === 'things') {
          setLocation(`/org/${fragments[2]}/project/${fragments[4]}/things`)
          return
        }
        if (fragments[5] === 'auditLog') {
          setLocation(`/org/${fragments[2]}/project/${fragments[4]}/auditLog`)
          return
        }
        setLocation(`/org/${fragments[2]}/project/${fragments[4]}`)
        // this is important for if someone puts a url that has no specified project /org/:orgAliasId/project/
        if(!fragments[4]){
          setNotFound(true)
        }
        return
      }
      if (fragments[3] === 'members') {
        setLocation(`/org/${fragments[2]}/members`)
        return
      }
      if (fragments[3] === 'monitors') {
        if (fragments[4] === 'functions') {
          setLocation(`/org/${fragments[2]}/monitors/functions`)
          return
        }
        if (fragments[4] === 'actions') {
          setLocation(`/org/${fragments[2]}/monitors/actions`)
          return
        }
        if (fragments[4] === 'notification-templates') {
          setLocation(`/org/${fragments[2]}/monitors/notifications/templates`)
          return
        }
        if (fragments[4] === 'notification-groups') {
          setLocation(`/org/${fragments[2]}/monitors/notifications/groups`)
          return
        }
        setLocation(`/org/${fragments[2]}`)
        return
      }
      if (fragments[3] === 'auditLog') {
        setLocation(`/org/${fragments[2]}/auditLog`)
        return
      }
      if (fragments[3] === 'integrations') {
        setLocation(`/org/${fragments[2]}/integrations`)
        return
      }
      if (fragments[3] === 'settings') {
        setLocation(`/org/${fragments[2]}/settings/api/tokens`)
        return
      }
      setLocation(`/org/${fragments[2]}`)
      return
    }
    setNotFound(true)
  }, [])

  if (notFound) {
    throw new Error('Page not found')
  }

  return (
    <>
      <LoadingIcon />
    </>
  )
}

DefaultMain.displayName = 'DefaultMain'
