import { useDialog } from "@shared/components/core/useDialog"
import AddNotificationGroupDialog from "./dialogs/AddDialog"
import { Role } from "@server/shared"
import { api } from "@shared/index"
import { useRouteParams } from "@shared/router"
import { NoItemGuidance } from "@shared/components/page/generic/NoItemGuidance"
import { useEffect, useState } from "react"
import { AddIcon} from "@colombalink/basedui"
import { CustomizableButton } from "@shared/components/core/CustomizableButton"
import { WithI18n } from "@shared/i18n/withI18n"
import i18n from "../i18n"
import { useT } from "@app/i18n"
import { LoadingIcon } from "@shared/components/core/LoadingIcon"

const _GroupLandingFragment = ({ children }) => {
    const t = useT()
    const createGroupDialog = useDialog(<AddNotificationGroupDialog />)
    const { orgAliasId } = useRouteParams<{ orgAliasId: string }>()
    const roles = api.user.getRoles.useLiveData()
    const groups = api.organization.monitor.notification.group.getAll.useLiveData()    
    const [hasGroups, setHasGroups] = useState<boolean>(null)

    useEffect(() => {
        if (!roles.data?.asArray) {
            return
        }
        if (!groups.data) {
            return
        }
        const hasGroups = roles.data.asArray.some((role) => {
            if (Array.isArray(role)
                && role[0].startsWith(orgAliasId)
                && Array.isArray(groups.data) && groups.data.length > 0
            ) {
                return true
            }

        })

        setHasGroups(hasGroups)

    }, [roles.data?.asArray, groups.data, orgAliasId])



    if (roles.error) {
        throw roles.error
    }


    if (roles.loading || hasGroups === null) {
        return <LoadingIcon />
    }


    if (hasGroups) {
        return children
    }

    const orgRole = roles.data[orgAliasId] as Role
    if (orgRole === "OWNER") {
        return (
            <NoItemGuidance>

                {
                    t["MonitorGroup:NoGroupsFound"].get()
                }
                <CustomizableButton
                    icon={<AddIcon />}
                    onClick={async () => {
                        await createGroupDialog.open()
                    }}
                >
                    {t["MonitorGroup:CreateGroup"].get()} 
                </CustomizableButton>
            </NoItemGuidance>
        )
    }

    return (
        <NoItemGuidance
        >
            {
                t["MonitorGroup:NoGroupsFound"].get()
            }
            {
                t["MonitorGroup:ContactTheOwner"].get()
            }
        </NoItemGuidance>
    )

}



export const GroupLandingFragment = (props) => (
    <WithI18n i18n={i18n}>
        <_GroupLandingFragment {...props} />
    </WithI18n>
)