import { Dialog, Input, Text, Toast, useToast, Spacer } from '@colombalink/basedui'
import React from 'react'
import { api } from '@shared/index'
import { ZodInputValidator } from '@shared/zod/InputValidator'
import { updateNotificationTemplateParams } from '@server/app'
import { WithI18n } from '@shared/i18n/withI18n'
import i18n from '../../i18n'
import { useT } from '@app/i18n'
import { LoadingIcon } from '@shared/components/core/LoadingIcon'

type EditProps = {
  template: ReturnType<typeof api.organization.monitor.notification.template.getAll.useLiveData>['data'][0]
}

const EditNotificaitonTemplate = (props: EditProps) => {
  const t = useT()
  const updateTemplate = api.organization.monitor.notification.template.update.useMutation()
  const [updateTemplateArgs, setCreateTemplateArgs] = React.useState({
    ...props.template 
  })

  const toast = useToast()

  if (!updateTemplateArgs) {
    return <LoadingIcon />
  }

  return (
    <Dialog label={t["MonitorTemplate:EditTemplate"].get()}>
      <div>
        <Text>
        {t["MonitorTemplate:EditTemplate"].get()}
        </Text>
        <Spacer />
        <Input
          description={t["MonitorTemplate:Name"].get()}
          value={updateTemplateArgs.name}
          pattern="true"
          error={(value) => ZodInputValidator(updateNotificationTemplateParams, updateTemplateArgs, "name")}
          onChange={(name: string) => {
            setCreateTemplateArgs({ ...updateTemplateArgs, name })
          }
          }
        />
        <Spacer />
        <Input
          description={t["MonitorTemplate:MessageTitle"].get()}
          value={updateTemplateArgs.title}
          pattern="true"
          error={(value) => ZodInputValidator(updateNotificationTemplateParams, updateTemplateArgs, "title")}
          onChange={(title: string) => {
            setCreateTemplateArgs({ ...updateTemplateArgs, title })
          }
          }
        />
        <Spacer />
        <Input
          description={t["MonitorTemplate:MessageBody"].get()}
          value={updateTemplateArgs.message}
          pattern="true"
          error={(value) => ZodInputValidator(updateNotificationTemplateParams, updateTemplateArgs, "message")}
          onChange={(message: string) => {
            setCreateTemplateArgs({ ...updateTemplateArgs, message })
          }
          }
        />
        <Dialog.Buttons>
          <Dialog.Cancel displayShortcut={false}>{t["MonitorTemplate:Close"].get()} </Dialog.Cancel>
          <Dialog.Confirm displayShortcut={false} onConfirm={async () => {

            try {
              await updateTemplate.mutateAsync(updateTemplateArgs)
              toast.add(
                <Toast
                  label={t["MonitorTemplate:UpdatedTemplate"].get()}
                  type="success"
                  description={
                    <Text>
                      {t["MonitorTemplate:Template"].get()} <b>{updateTemplateArgs.name}</b> {t["MonitorTemplate:TemplateEditedSuccessfully"].get()}
                    </Text>
                  }
                />
              )
            } catch (e) {
              toast.add(
                <Toast
                  label={t["MonitorTemplate:FailedToEditTemplate"].get()}
                  type="error"
                  description={
                    <Text style={{ whiteSpace: 'normal' }}>
                      {e.message}
                    </Text>
                  }
                />
              )
              throw e
            }
          }}>{t["MonitorTemplate:Save"].get()}</Dialog.Confirm>
        </Dialog.Buttons>
      </div>
    </Dialog>
  )
}



export default (props)=> (
  <WithI18n i18n={i18n}>
    <EditNotificaitonTemplate {...props}  />
  </WithI18n>
)