import React, { useState } from 'react'
import {
  Dialog,
  Input,
  MultiSelect,
  Select,
  Spacer,
  Text,
  Toast,
  useToast,
} from '@colombalink/basedui'
import { api } from '@shared/index'
import { ZodInputValidator } from '@shared/zod/InputValidator'
import { createActionParams } from '@server/app'
import { WithI18n } from '@shared/i18n/withI18n'
import i18n from '../../i18n'
import { useT } from '@app/i18n'

const AddActionDialog = () => {
  const t = useT()
  return (
    
    <Dialog label={t["MonitorAction:AddAction"].get()}>
      <AddActionForm />
    </Dialog>
  )
}

const AddActionForm = () => {
  const t = useT()
  const notificationGroups =
    api.organization.monitor.notification.group.getAll.useLiveData()
  const notificationTemplates =
    api.organization.monitor.notification.template.getAll.useLiveData()
  const createAction = api.organization.monitor.action.create.useMutation()

  const [createActionArgs, setCreateActionArgs] = useState({
    name: '',
    trigger: 'idle',
    triggerType: 'always',
    groups: [],
    notificationTemplate: '',
  })

  const handleOnChange = (key, value) => {
    setCreateActionArgs((prev) => ({
      ...prev,
      [key]: value,
    }))
  }
  const triggers = ['idle', 'alert', 'alarm']
  const triggerOptions= [t["MonitorAction:Idle"].get(), t["MonitorAction:Alert"].get(), t["MonitorAction:Alarm"].get()]

  const triggertypes=[t["MonitorAction:Once"].get(), t["MonitorAction:Always"].get()]
  
  const toast = useToast()

  return (
    <>
      <div className="flex flex-col overflow-y-auto">
        <Input
          value={createActionArgs.name}
          label={t["MonitorAction:Name"].get()}
          placeholder={t["MonitorAction:EnterNameHere"].get()}
          type="text"
          pattern="true"
          error={(value) =>
            ZodInputValidator(createActionParams, createActionArgs, 'name')
          }
          onChange={(name) => handleOnChange('name', name)}
        />
        <Spacer />
        <Select
          label={t["MonitorAction:SelectTrigger"].get()}
          value={createActionArgs.trigger}
          options={triggerOptions}
          onChange={(trigger) => handleOnChange('trigger', triggers[(triggerOptions.indexOf(trigger.toString()))])}
        />
        <Spacer />
        <Select
          label={t["MonitorAction:SelectTriggerType"].get()}
          value={createActionArgs.triggerType}
          options={['always', 'once']}
          onChange={(triggerType) => handleOnChange('triggerType', triggerType)}
        />
        <Spacer />
        <MultiSelect
          values={createActionArgs.groups}
          label={t["MonitorAction:SelectGroups"].get()}
          options={notificationGroups.data?.map((i) => ({
            value: i.id,
            label: i.name,
          }))}
          filterable="create"
          onChange={(groups) => handleOnChange('groups', groups)}
        />
        <Spacer />
        <Select
          value={createActionArgs.notificationTemplate}
          label={t["MonitorAction:SelectTemplate"].get()}
          options={notificationTemplates?.data?.map((i) => ({
            value: i.id,
            label: i.name,
          }))}
          onChange={(template) =>
            handleOnChange('notificationTemplate', template)
          }
        />
      </div>
      <Dialog.Buttons border>
        <Dialog.Cancel>{t["MonitorAction:Close"].get()}</Dialog.Cancel>
        <Dialog.Confirm
          displayShortcut={false}
          onConfirm={async () => {
            createActionArgs.notificationTemplate =
              notificationTemplates.data.find(
                (template) =>
                  template.id === createActionArgs.notificationTemplate
              )?.id

            createActionArgs.groups = notificationGroups.data
              .filter((group) =>
                createActionArgs.groups.some((sub) => sub === group.id)
              )
              .map((group) => group.id)

            try {
              await createAction.mutateAsync(createActionArgs)
              toast.add(
                <Toast
                  label={t["MonitorAction:AddedAction"].get()}
                  type="success"
                  description={
                    <Text>
                      {t["MonitorAction:Action"].get()} <b>{createActionArgs.name}</b> {t["MonitorAction:CreatedSuccessfully"].get()}
                    </Text>
                  }
                />
              )
            } catch (e) {
              toast.add(
                <Toast
                  label={t["MonitorAction:FailedToCreateAction"].get()}
                  type="error"
                  description={
                    <Text style={{ whiteSpace: 'normal' }}>{e.message}</Text>
                  }
                />
              )
              throw e
            }
          }}
        >
          {t["MonitorAction:Save"].get()}
        </Dialog.Confirm>
      </Dialog.Buttons>
    </>
  )
}

export default ()=> (
  <WithI18n i18n={i18n}>
    <AddActionDialog  />
  </WithI18n>
)
